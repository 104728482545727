import unitConverter from "../utils/UnitConverter";
import UnitOfMeasure from "../enums/UnitOfMeasure";
import { BAME } from "../enums/profile/Ethnicity";

const calculateBmi = (weightKgs, heightCms) => {
  const bmi = Number(weightKgs) / (Math.pow(Number(heightCms), 2) / 10000);
  return bmi.toFixed(1);
};

const determineBmiGroup = (ethnicity, physicalConditions) => {
  let bmiGroup = ["nonBame"]; //nonBame is default for all referrals

  if (BAME.includes(ethnicity.value) && physicalConditions.length > 1) {
    bmiGroup.push("bameComorbidities");
  }
  if (physicalConditions.length > 1) {
    bmiGroup.push("comorbidities");
  }
  if (BAME.includes(ethnicity.value)) {
    bmiGroup.push("bame");
  }
  return bmiGroup;
};

const bmiCalculator = ({
  height,
  heightFeet,
  heightInches,
  heightDisplayUnitOfMeasure,
  weight,
  weightStones,
  weightPounds,
  weightDisplayUnitOfMeasure,
  ethnicity,
  physicalConditions,
}) => {
  //Convert height and weight to cm and kg respectively.
  const heightCms =
    heightDisplayUnitOfMeasure === UnitOfMeasure.METRIC
      ? Number(height)
      : unitConverter.feetAndInchesToCentimeters(heightFeet, heightInches);

  const weightKgs =
    weightDisplayUnitOfMeasure === UnitOfMeasure.METRIC
      ? Number(weight)
      : unitConverter.poundsToKilograms(
          unitConverter.stonesToPounds(weightStones) + Number(weightPounds)
        );

  return {
    bmi: Number(calculateBmi(weightKgs, heightCms)),
    bmiGroup: determineBmiGroup(ethnicity, physicalConditions),
  };
};

export default bmiCalculator;
