import React, { useState } from "react";
import { FormGroup, Grid, InputLabel, FormHelperText } from "@material-ui/core";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { FormikNumberInput } from "../../atoms/FormikInput";
import UnitOfMeasure from "../../../enums/UnitOfMeasure";
import unitConverter from "../../../utils/UnitConverter";

const WeightCompositeInput = (props) => {
  const {
    field: { ...fields },
    form: { setFieldValue },
    label,
  } = props;
  const { values } = useFormikContext();
  let weightComponent;
  let weightUnit = values.weightDisplayUnitOfMeasure ?? UnitOfMeasure.METRIC;
  const [weightDisplayUnitOfMeasure, setWeightUnit] = useState(weightUnit);

  const handleWeightUnitChange = (value) => {
    if (value === UnitOfMeasure.IMPERIAL) {
      if (values.weight && values.weight !== "") {
        const [stones, pounds] = unitConverter.kilogramsToStonesAndPounds(values.weight);
        setFieldValue("weightStones", stones.toFixed(0));
        setFieldValue("weightPounds", pounds.toFixed(0));
      }
    } else {
      if (
        (values.weightStones && values.weightStones !== "") ||
        (values.weightPounds && values.weightPounds !== "")
      ) {
        const kg = unitConverter.poundsToKilograms(
          unitConverter.stonesToPounds(values.weightStones) + Number(values.weightPounds)
        );
        setFieldValue("weight", kg.toFixed(1));
      }
    }
    setFieldValue("weightDisplayUnitOfMeasure", value);
    setWeightUnit(value);
  };

  if (weightDisplayUnitOfMeasure === UnitOfMeasure.IMPERIAL) {
    weightComponent = (
      <FormGroup>
        <InputLabel htmlFor={props.id}>{label}</InputLabel>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Field
              fullWidth
              variant="outlined"
              id="weightStones"
              name="weightStones"
              type="number"
              pattern={/^([0-9]{0,3})/g}
              component={FormikNumberInput}
            />
          </Grid>
          <Grid item xs={1} className=" units">
            <span>st</span>
          </Grid>
          <Grid item xs={3}>
            <Field
              fullWidth
              variant="outlined"
              id="weightPounds"
              name="weightPounds"
              type="number"
              pattern={/^([0-9]{0,3})/g}
              component={FormikNumberInput}
            />
          </Grid>
          <Grid item xs={1} className="units">
            <span>lb</span>
          </Grid>
        </Grid>
        <Grid>
          <Grid item sm={12}>
            <ErrorMessage className="Mui-error" component={FormHelperText} name="weightStones" />
          </Grid>
        </Grid>
        <Grid>
          <Grid item sm={12}>
            <ErrorMessage className="Mui-error" component={FormHelperText} name="weightPounds" />
          </Grid>
        </Grid>
        <Grid>
          <Grid item sm={12}>
            <button
              type="button"
              className="unit-converter"
              onClick={() => handleWeightUnitChange(UnitOfMeasure.METRIC)}
            >
              <i className="fas fa-sync unit-converter-icon"></i>
              click here to enter your weight in kg
            </button>
          </Grid>
        </Grid>
      </FormGroup>
    );
  } else {
    weightComponent = (
      <>
        <FormGroup>
          <InputLabel htmlFor={props.id}>{label}</InputLabel>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <FormikNumberInput {...props} pattern={/^([0-9]{0,3}\.?[0-9]{0,1})/g} />
            </Grid>
            <Grid item xs={1} className="units">
              <span>kg</span>
            </Grid>
          </Grid>
          <Grid>
            <Grid item sm={12}>
              <ErrorMessage className="Mui-error" component={FormHelperText} name={fields.name} />
            </Grid>
          </Grid>
          <Grid>
            <Grid item sm={12}>
              <button
                type="button"
                className="unit-converter"
                onClick={() => handleWeightUnitChange(UnitOfMeasure.IMPERIAL)}
              >
                <i className="fas fa-sync unit-converter-icon mr-sm-2"></i>
                click here to enter your weight in st and lb
              </button>
            </Grid>
          </Grid>
        </FormGroup>
      </>
    );
  }

  return <>{weightComponent}</>;
};

export default WeightCompositeInput;
