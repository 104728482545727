import React from "react";
import PropTypes from "prop-types";

/**
 * Formik form stepper - for creating steps in a multi-step form or wizard.
 * @param {React.ReactChildren} children - Children nodes for the form step.
 */
function MultipleQuestionsFormikStep({ children }) {
  return <>{children}</>;
}

MultipleQuestionsFormikStep.propTypes = {
  /** Children nodes for the form step. */
  children: PropTypes.node.isRequired,
};

export default MultipleQuestionsFormikStep;
