import React from "react";
import { Field } from "formik";
import PropTypes from "prop-types";
import "./FormikCheckbox.scss";

const FormikCheckbox = ({ id, name, value, label, ...props }) => {
  return (
    <>
      <label key={value} className="checkbox-label__container">
        <Field type="checkbox" id={id} name={name} value={value} {...props} />
        <span className="checbox-label">{label}</span>
        <span className="customCheckmark"></span>
      </label>
    </>
  );
};

FormikCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default FormikCheckbox;
