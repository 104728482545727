import React, { useEffect } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Form, Formik } from "formik";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import {
  ModalPageHeader,
  ModalPageBody,
  ModalPageFooter,
  ModalPageWrapper,
  ModalPageHeading,
} from "../../../organisms/ModalPage";
import RadioButtonGroup from "../../../molecules/RadioButtonGroup/RadioButtonGroup";
import { update_signup_form_values } from "../../../../store/actions/signupFlowData";
import { MentalHealthValidationSchema } from "../EligibilityCheckerValidation";
import FormikCheckbox from "../../../atoms/FormikCheckbox";
import CheckboxGroupContainer from "../../../atoms/CheckboxGroupContainer";
import { mentalHealthConditions } from "../../../../enums/profile/MentalHealthCondition";

const MentalHealth = () => {
  const dispatch = useDispatch();
  const signupFormValues = useSelector((state) => state.signupFlowData.signupFormValues);
  const mentalHealthData = useSelector(
    (state) => state.signupFlowData.signupPagesData["mental-health"]
  );

  let history = useHistory();
  let { path } = useRouteMatch();

  const initialValues = {
    mentalHealth: signupFormValues?.mentalHealth ?? "",
    mentalConditions: signupFormValues?.mentalConditions ?? [],
  };

  const yesNoOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Mental health | Eligibility checker | Sign up | Gloji",
    });
  }, []);

  const handleSubmit = (values) => {
    if (values.mentalHealth === "no") {
      history.push(`/disabilities`);
      // Save values to store
      dispatch(update_signup_form_values({ ...values, mentalConditions: [] }));
    } else {
      if (values.mentalConditions.includes("eatingDisorder")) {
        //Trigger google analytics event on ineligible eating disorder
        window.gtag("event", "ineligibleEatingDisorder", {
          event_category: "Eligibility Checker",
          event_label: "Ineligible Eating Disorder",
        });
        history.push(`${path}/ineligible-eating-disorder/fail`);
      } else {
        history.push(`${path}/mental-health-management`);
      }
      // Save values to store
      dispatch(update_signup_form_values(values));
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={MentalHealthValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ isSubmitting, values, touched, errors }) => (
          <Form className="eligibility-checker-form mental-health-form">
            <Helmet>
              <title>Mental health | Eligibility checker | Sign up | Gloji</title>
            </Helmet>
            <ModalPageWrapper>
              <ModalPageBody className="with-sticky-footer">
                <ModalPageHeader
                  backButtonOnClick={() => {
                    history.goBack();
                  }}
                >
                  Checking we're a good fit
                </ModalPageHeader>

                <ModalPageHeading>{mentalHealthData?.questions[0].question}</ModalPageHeading>
                <RadioButtonGroup
                  options={yesNoOptions}
                  name="mentalHealth"
                  hasErrors={errors["mentalHealth"] && touched["mentalHealth"]}
                />
                <Accordion square={true} elevation={0} className="eligibility-checker__accrodion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon fontSize="large" />}
                    aria-controls={`mental-question-explanation`}
                  >
                    <span>{mentalHealthData?.questions[0].whyQuestion}</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>{mentalHealthData?.questions[0].whyQuestionDetails}</span>
                  </AccordionDetails>
                </Accordion>

                {values.mentalHealth === "yes" && (
                  <>
                    <ModalPageHeading>Select any that apply to you</ModalPageHeading>
                    <CheckboxGroupContainer name="mentalConditions">
                      {mentalHealthConditions.map((item) => (
                        <FormikCheckbox
                          key={item.value}
                          id={item.value}
                          name="mentalConditions"
                          label={item.label}
                          value={item.value}
                        />
                      ))}
                    </CheckboxGroupContainer>
                  </>
                )}
              </ModalPageBody>
              <ModalPageFooter className="sticky-footer">
                <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                  Continue
                </Button>
              </ModalPageFooter>
            </ModalPageWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MentalHealth;
