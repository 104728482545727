import {
  LOAD_PERSONALISED_CONTENT,
  PERSONALISED_CONTENT_LOADED,
  LOAD_PERSONALISED_CONTENT_FAILED,
} from "../constants";

const initialState = {
  loading: true,
  data: [],
  error: null,
  errorStatus: false,
};

const load_personalised_content = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PERSONALISED_CONTENT:
      return { ...state, loading: true };
    case PERSONALISED_CONTENT_LOADED:
      return { ...state, data: action.payload, loading: false };
    case LOAD_PERSONALISED_CONTENT_FAILED:
      return { ...state, error: action.error, errorStatus: true, loading: false };
    default:
      return state;
  }
};

export default load_personalised_content;
