import UnitOfMeasure from "../../../enums/UnitOfMeasure";
import Truthy from "../../../enums/Truthy";

export const UserProfileVm = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  dateOfBirth: "",
  birthSex: "",
  genderIsBirthSex: "",
  gender: "",
  isPregnant: "",
  isBreastFeeding: "",
  height: "",
  heightFeet: "",
  heightInches: "",
  heightDisplayUnitOfMeasure: UnitOfMeasure.METRIC,
  weight: "",
  weightStones: "",
  weightPounds: "",
  weightDisplayUnitOfMeasure: UnitOfMeasure.METRIC,
  addressLine1: "",
  addressLine2: "",
  town: "",
  county: "",
  postcode: "",
  country: "United Kingdom",
  ethnicity: "",
  otherEthnicity: "",
  firstLanguage: "",
  otherLanguages: [],
  physicalConditions: [],
  otherPhysicalConditions: "",
  mentalConditions: [],
  otherMentalConditions: "",
  disabilities: [],
  otherDisabilities: "",
  rating: 5,
  statement: "",
  confidenceLevel: 5,
};

export const mapUserProfile = (userProfileVm, user) => {
  return {
    userId: user.sub,
    personalDetails: {
      firstName: user.given_name,
      lastName: user.family_name,
      email: user.emails[0],
      phoneNumber: userProfileVm.phoneNumber,
      dateOfBirth: userProfileVm.dateOfBirth,
      birthSex: userProfileVm.birthSex,
      genderIsBirthSex: userProfileVm.genderIsBirthSex,
      gender:
        userProfileVm.genderIsBirthSex === Truthy.TRUE
          ? userProfileVm.birthSex
          : userProfileVm.gender,
      isPregnant: userProfileVm.isPregnant,
      isBreastFeeding: userProfileVm.isBreastFeeding,
      height: userProfileVm.height,
      heightDisplayUnitOfMeasure: userProfileVm.heightDisplayUnitOfMeasure,
      weight: userProfileVm.weight,
      weightDisplayUnitOfMeasure: userProfileVm.weightDisplayUnitOfMeasure,
      address: {
        addressLine1: userProfileVm.addressLine1,
        addressLine2: userProfileVm.addressLine2,
        town: userProfileVm.town,
        county: userProfileVm.county,
        postcode: userProfileVm.postcode,
        country: userProfileVm.country,
      },
    },
    ethnicity: userProfileVm.ethnicity,
    otherEthnicity: userProfileVm.otherEthnicity,
    firstLanguage: userProfileVm.firstLanguage,
    otherLanguages: userProfileVm.otherLanguages,
    healthConditions: {
      physical: userProfileVm.physicalConditions,
      otherPhysicalConditions: userProfileVm.otherPhysicalConditions,
      mental: userProfileVm.mentalConditions,
      otherMentalConditions: userProfileVm.otherMentalConditions,
      disabilities: userProfileVm.disabilities,
      otherDisabilities: userProfileVm.otherDisabilities,
    },
    motivation: {
      rating: userProfileVm.rating,
      statement: userProfileVm.statement,
      confidenceLevel: userProfileVm.confidenceLevel,
    },
    agreedToTerms: user.extension_AgreedToTerms,
    agreedToMarketing: user.extension_AgreedToMarketing,
  };
};
