import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { Container } from "@material-ui/core";
import { greyVelvet } from "../../../assets/img/bg";
import { pillars } from "../../../enums/pillarTypes";
import { CircularLoader } from "../../atoms/Loaders";
import { BackButtonLink } from "../../atoms/Button";
import PageBackground from "../../organisms/PageBackground";
import { PageHeroBanner } from "../../organisms/HeroBanner";
import InfiniteScrollArticleCards from "../../organisms/InfiniteScrollArticleCards";
import "./ArticlePillar.scss";

const ArticlePillar = () => {
  const history = useHistory();
  const [pillarType, setPillarType] = useState(null);
  const [pillarData, setPillarData] = useState(null);

  useEffect(() => {
    const lastIndex = window.location.pathname.lastIndexOf("/");
    if (lastIndex === -1) return setPillarType("not-found");
    setPillarType(window.location.pathname.slice(lastIndex + 1));
  }, []);

  useEffect(() => {
    if (pillarType === null) return;

    if (pillars[pillarType] === undefined) {
      setPillarType("not-found");
      setPillarData(null);
      return;
    }

    setPillarData(pillars[pillarType]);
  }, [pillarType]);

  if (pillarType === "not-found") {
    history.push("/error/404");
    return <CircularLoader />;
  }

  if (pillarData === null) {
    return <CircularLoader />;
  }

  return (
    <PageBackground bgImage={greyVelvet}>
      <Helmet>
        <title>{pillarData.title} Articles | Gloji</title>
      </Helmet>
      <div className="article-pillar article-pillar-page">
        <PageHeroBanner bgImg={pillarData.headerBackground}>
          <div className="article-pillar__header">
            <BackButtonLink linkPath={`/explore/articles`} btnText="Back" ariaLabel="Back" />
            <div className="article-pillar__header__icon">{pillarData.icon}</div>
            <h1 className="article-pillar__header__heading">{pillarData.title}</h1>
          </div>
        </PageHeroBanner>

        <Container className="article-pillar__content">
          <InfiniteScrollArticleCards pillarTags={pillarData.tag} />
        </Container>
      </div>
    </PageBackground>
  );
};

export default ArticlePillar;
