import React from "react";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";

import "./LargeButtonWithIcon.scss";

function LargeButtonWithIcon({ variant, ...rest }) {
  // const textColor = variant === "primary" ? NAVY : MINT;

  return (
    <Button id="add-referral-btn" className="large-button--with-icon" color={variant} {...rest}>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.845 32.5">
          <g transform="translate(0.25 0.25)">
            <path
              d="M12.414,21A4.487,4.487,0,0,0,8,25.535V48.448A4.5,4.5,0,0,0,12.414,53H49.931a4.5,4.5,0,0,0,4.414-4.552V25.535A4.487,4.487,0,0,0,49.931,21Zm0,2.207H49.931a2.189,2.189,0,0,1,.914.207L32.6,40.293a1.981,1.981,0,0,1-2.81,0L11.517,23.414A2.185,2.185,0,0,1,12.414,23.207Zm-2.172,2.035L23.19,37.207,10.483,49.569a2.461,2.461,0,0,1-.276-1.121V25.535a2.53,2.53,0,0,1,.035-.293Zm41.862.017a2.48,2.48,0,0,1,.034.276V48.448a2.48,2.48,0,0,1-.259,1.1L39.207,37.19,52.1,25.259ZM37.6,38.672,50,50.793H12.362l12.448-12.1,3.483,3.224a4.285,4.285,0,0,0,5.81,0l3.5-3.241Z"
              transform="translate(-8 -21)"
              fill="#121e59"
              stroke="#121e59"
              stroke-width="0.5"
            />
          </g>
        </svg>
      </div>
      Send me a new sign-up link
    </Button>
  );
}

export { LargeButtonWithIcon };
