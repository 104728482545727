import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormHelperText,
  InputLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { update_signup_form_values } from "../../../../store/actions/signupFlowData";
import FormikInputOnly from "../../../atoms/formik-input-only";
import { FormikField } from "../../../atoms/FormikField";
import usePostcodeCheck from "../../../hooks/usePostcodeCheck";
import {
  ModalPageBody,
  ModalPageFooter,
  ModalPageHeader,
  ModalPageHeading,
  ModalPageWrapper,
} from "../../../organisms/ModalPage";
import { PostcodeValidationSchema } from "../EligibilityCheckerValidation";
import { LoadingPage } from "../LoadingPage";

const PostcodeChecker = () => {
  const dispatch = useDispatch();
  const postcodePageData = useSelector((state) => state.signupFlowData.signupPagesData?.postcode);

  const storeCurrentPostcode = useSelector(
    (state) => state.signupFlowData.signupFormValues?.postcode
  );
  const [response, getPostcodeChecked] = usePostcodeCheck();
  const [showLoadingPage, setShowLoadingPage] = useState(false);
  const history = useHistory();
  const { path } = useRouteMatch();
  const initialValues = { postcode: storeCurrentPostcode ?? "" };

  useEffect(() => {}, []);

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Postcode | Eligibility checker | Sign up | Gloji",
    });
  }, []);

  useEffect(() => {
    if (response.isPostcodeEligible !== null) {
      if (response.data !== null && !response.data.anySpacesAvailable) {
        history.push(`${path}/no-places-available`);
        return;
      }
      if (response.isPostcodeEligible === true) {
        const serviceCode = response.data.code;
        const serviceId = response.data.id;
        const eligibilityCriteria = response.data.eligibilityCriteria;
        dispatch(update_signup_form_values({ serviceCode, serviceId, eligibilityCriteria }));

        // Show success screen
        history.push(`${path}/success`);
      } else {
        history.push(`${path}/fail`);
        //Trigger google analytics event tag
        window.gtag("event", "ineligible_postcode", {
          event_category: "postcode",
          event_label: storeCurrentPostcode,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLoadingPage]);

  const handleSubmit = async (values) => {
    dispatch(update_signup_form_values(values));
    setShowLoadingPage(true);
    getPostcodeChecked(values.postcode);
  };

  return (
    <>
      {showLoadingPage === true ? (
        <LoadingPage
          isLoading={response.loading}
          callback={() => setShowLoadingPage(false)}
          heading={postcodePageData?.loadingScreen.title ?? ""}
          text={postcodePageData?.loadingScreen.body ?? ""}
        />
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={PostcodeValidationSchema}
          validateOnChange={true}
        >
          {({ isSubmitting, values, actions }) => (
            <Form className="eligibility-checker-form">
              <Helmet>
                <title>Postcode | Eligibility checker | Sign up | Gloji</title>
              </Helmet>
              <ModalPageWrapper>
                <ModalPageBody className="with-sticky-footer">
                  <ModalPageHeader
                    backButtonOnClick={() => {
                      history.goBack();
                    }}
                  >
                    Checking we're a good fit
                  </ModalPageHeader>
                  <ModalPageHeading size="large">
                    {postcodePageData?.questions[0].question}
                  </ModalPageHeading>
                  <InputLabel htmlFor="postcode" className="eligibility-checker__input-label">
                    {postcodePageData?.questions[0].label}
                  </InputLabel>
                  <FormikField
                    className="eligibility-checker__text-input"
                    id="postcode"
                    name="postcode"
                    component={FormikInputOnly}
                    placeholder="e.g EC2M 3TQ"
                  />
                  <ErrorMessage className="Mui-error" component={FormHelperText} name="postcode" />
                  <Accordion square={true} elevation={0} className="eligibility-checker__accrodion">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon fontSize="large" />}
                      aria-controls={`postcode-question-explanation`}
                    >
                      <span>{postcodePageData?.questions[0].whyQuestion}</span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <span>{postcodePageData?.questions[0].whyQuestionDetails}</span>
                    </AccordionDetails>
                  </Accordion>
                </ModalPageBody>
                <ModalPageFooter>
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                    Continue
                  </Button>
                </ModalPageFooter>
              </ModalPageWrapper>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default PostcodeChecker;
