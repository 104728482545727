import React from "react";
import { PropTypes } from "prop-types";
import "./ProgressBar.scss";
import { MINT } from "@thrivetribe/gloji.enums.brand-colors";

const ProgressBar = ({ color, progress }) => {
  const percent = Number((progress * 100).toFixed(0));
  return (
    <div className="progress-bar__container">
      <div
        className="progress-bar__progress"
        style={{ width: `${percent}%`, backgroundColor: `${color}` }}
      ></div>
    </div>
  );
};

ProgressBar.propTypes = {
  color: PropTypes.string,
  progress: PropTypes.number.isRequired,
};

ProgressBar.defaultProps = {
  color: MINT,
};

export default ProgressBar;
