import React, { useEffect, useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Form, Formik } from "formik";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  ModalPageHeader,
  ModalPageBody,
  ModalPageFooter,
  ModalPageWrapper,
  ModalPageHeading,
} from "../../../organisms/ModalPage";
import { LoadingPage } from "../LoadingPage";
import { update_signup_form_values } from "../../../../store/actions/signupFlowData";
import { EthnicityValidationSchema } from "../EligibilityCheckerValidation";
import "./Ethnicity.scss";
import { ethnicityOptions } from "../../../../enums/profile/Ethnicity";
import ReactSelect from "../../../atoms/ReactSelect";

const Ethnicity = () => {
  const dispatch = useDispatch();
  const ethnicityPageData = useSelector((state) => state.signupFlowData.signupPagesData?.ethnicity);
  const storeEthnicityValue = useSelector(
    (state) => state.signupFlowData.signupFormValues?.ethnicity
  );
  const [showLoadingPage, setShowLoadingPage] = useState(false);
  const history = useHistory();
  const [initialValues, setInitialValues] = useState({ ethnicity: storeEthnicityValue || null });

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Ethnicity | Eligibility checker | Sign up | Gloji",
    });
  }, []);

  const handleSubmit = (values) => {
    storeEthnicityValue
      ? setInitialValues({ ethnicity: storeEthnicityValue })
      : setInitialValues(values);
    dispatch(update_signup_form_values(values));
    setShowLoadingPage(true);
  };

  return (
    <>
      {showLoadingPage === true ? (
        <LoadingPage
          callback={() => {
            setShowLoadingPage(false);
            history.push("/bmi");
          }}
          heading={ethnicityPageData?.loadingScreen.title ?? ""}
          text={ethnicityPageData?.loadingScreen.body ?? ""}
        />
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={EthnicityValidationSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(props) => (
            <Form className="eligibility-checker-form ethnicity-form">
              <Helmet>
                <title>Ethnicity | Eligibility checker | Sign up | Gloji</title>
              </Helmet>
              <ModalPageWrapper>
                <ModalPageBody className="with-sticky-footer">
                  <ModalPageHeader
                    backButtonOnClick={() => {
                      history.goBack();
                    }}
                  >
                    Checking we're a good fit
                  </ModalPageHeader>
                  <ModalPageHeading>{ethnicityPageData?.questions[0].question}</ModalPageHeading>
                  <ReactSelect
                    name="ethnicity"
                    options={ethnicityOptions}
                    label={ethnicityPageData?.questions[0].label}
                    labelCssClass="eligibility-checker__input-label"
                  />
                  <Accordion square={true} elevation={0} className="eligibility-checker__accrodion">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon fontSize="large" />}
                      aria-controls={`ethnicity-question-explanation`}
                    >
                      <span>{ethnicityPageData?.questions[0].whyQuestion}</span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <span>{ethnicityPageData?.questions[0].whyQuestionDetails}</span>
                    </AccordionDetails>
                  </Accordion>
                </ModalPageBody>
                <ModalPageFooter className="sticky-footer">
                  <Button type="submit" variant="contained" color="primary">
                    Continue
                  </Button>
                </ModalPageFooter>
              </ModalPageWrapper>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default Ethnicity;
