import PropTypes from "prop-types";
import { Grid, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HeroBannerContainer from "./HeroBannerContainer";
import { homeBannerBg } from "../../../assets/img/bg";

import "./HeroBanner.scss";

const useStyles = makeStyles({
  position: {
    zIndex: 2,
  },
});

function PageHeroBanner({ bgImg, children }) {
  const classes = useStyles();

  return (
    <HeroBannerContainer bgImg={bgImg ?? homeBannerBg}>
      <Container className={classes.position}>
        <Grid
          container
          className="hero-banner__content-container"
          justifyContent="center"
          alignItems="center"
        >
          {children}
        </Grid>
      </Container>
    </HeroBannerContainer>
  );
}

PageHeroBanner.propTypes = {
  bgImg: PropTypes.string,
  children: PropTypes.node,
};

export default PageHeroBanner;
