import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { CircularLoader } from "../../atoms/Loaders";
import { referralService } from "../../services";
import authChallenge from "../../auth/AuthChallenge";
import { logException } from "../../../utils/AppInsightsLogging";

function AccountActivation() {
  let { key } = useParams();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const validateActivationKey = async () => {
      var data = {
        activationKey: key,
      };

      try {
        var response = await referralService.post("/activation-keys/validate", data);

        if (
          response.activationKeyStatus === "Invalid" ||
          response.activationKeyStatus === "Cancelled"
        ) {
          window.location.href = `/sign-up/activation/key-invalid?status=${response.activationKeyStatus}`;
        } else if (response.activationKeyStatus === "Expired") {
          window.location.href = `/sign-up/activation/key-expired?referralCode=${response.referralCode}`;
        } else if (response.activationKeyStatus === "Used") {
          let queryParams = { login_hint: response.email, referral_code: response.referralCode };
          await authChallenge.signIn(queryParams);
        } else {
          let queryParams = { login_hint: response.email, referral_code: response.referralCode };
          await authChallenge.signUp(queryParams);
        }
      } catch (error) {
        alert("An error occured while validating your activation key. Please try again later!");
        logException(error);
      }
    };

    validateActivationKey();
    setIsReady(true);
  }, [key]);

  return (
    <>
      <Helmet>
        <title>Activation | Sign Up | Gloji</title>
      </Helmet>
      {isReady ? <></> : <CircularLoader color="primary" size={40} />}
    </>
  );
}

export default AccountActivation;
