import { mixed, number, string, array, date } from "yup";
import UnitOfMeasure from "../../../enums/UnitOfMeasure";

export const PersonalDetailsValidation = {
  // firstName: string()
  //   .required("First Name is required")
  //   .min(2, "First Name should be between 2 - 50 characters")
  //   .max(50, "First Name should be between 2 - 50 characters")
  //   .matches("^([a-zA-Zs]|-|\u0027)+$", "Please enter a valid name"),
  // lastName: string()
  //   .required("Last Name is required")
  //   .min(2, "First Name should be between 2 - 50 characters")
  //   .max(50, "First Name should be between 2 - 50 characters")
  //   .matches("^([a-zA-Zs]|-|\u0027)+$", "Please enter a valid name"),
  phoneNumber: string().required("Phone Number is required"),
  dateOfBirth: date()
    .required("Date Of Birth is required")
    .max(
      new Date(new Date().setYear(new Date().getFullYear() - 16)),
      "You need to be a least 16 years old"
    ),
  birthSex: string().required("Birth Sex is required"),
  genderIsBirthSex: string().required("Please answer Yes or No"),
  gender: mixed().when("genderIsBirthSex", {
    is: "false",
    then: string()
      .required("Please enter your gender")
      .max(50, "Gender should be between 1 - 50 characters"),
    otherwise: string().notRequired(),
  }),
  isPregnant: mixed().when("birthSex", {
    is: "Female",
    then: string().required("Please answer Yes or No"),
    otherwise: string().notRequired(),
  }),
  isBreastFeeding: mixed().when("birthSex", {
    is: "Female",
    then: string().required("Please answer Yes or No"),
    otherwise: string().notRequired(),
  }),
  height: mixed().when("heightDisplayUnitOfMeasure", {
    is: UnitOfMeasure.METRIC,
    then: number().required("Height is required").positive("Please enter a valid height"),
    otherwise: number().notRequired(),
  }),
  heightFeet: mixed().when("heightDisplayUnitOfMeasure", {
    is: UnitOfMeasure.IMPERIAL,
    then: number().required("Height is required").positive("Please enter a valid height"),
    otherwise: number().notRequired(),
  }),
  weight: mixed().when("weightDisplayUnitOfMeasure", {
    is: UnitOfMeasure.METRIC,
    then: number().required("Weight is required").positive("Please enter a valid weight"),
    otherwise: number().notRequired(),
  }),
  weightStones: mixed().when("weightDisplayUnitOfMeasure", {
    is: UnitOfMeasure.IMPERIAL,
    then: number().required("Weight is required").positive("Please enter a valid weight"),
    otherwise: number().notRequired(),
  }),
  addressLine1: string().required("Address Line 1 is required"),
  addressLine2: string().notRequired(),
  town: string().required("Town/City is required"),
  county: string().notRequired(),
  postcode: string()
    .required("Postcode is required")
    .matches(
      "^([a-zA-Z]{1,2}[0-9][0-9A-Za-z]?[ ]?[0-9][a-zA-Z]{2})+$",
      "Please enter a valid postcode"
    ),
};

export const EthnicityValidation = {
  ethnicity: string().required("Ethnicity is required"),
  otherEthnicity: mixed().when("ethnicity", {
    is: "Other",
    then: string()
      .required("Other Ethnicity is required")
      .max(50, "Other Ethnicity should be between 1 - 50 characters"),
    otherwise: string().notRequired(),
  }),
  firstLanguage: string().required("First Language is required"),
  otherLanguages: array().notRequired(),
};

export const HealthConditionsValidation = {
  physicalConditions: array().notRequired(),
  otherPhysicalConditions: mixed().when("physicalConditions", {
    is: (value) => value.includes("Other"),
    then: string()
      .required("Other Physical Condition is required")
      .max(150, "Other Physical Condition should be between 1 - 150 characters"),
    otherwise: string().notRequired(),
  }),
  mentalConditions: array().notRequired(),
  otherMentalConditions: mixed().when("mentalConditions", {
    is: (value) => value.includes("Other"),
    then: string()
      .required("Other Mental Condition is required")
      .max(150, "Other Mental Condition should be between 1 - 150 characters"),
    otherwise: string().notRequired(),
  }),
  disabilities: array().notRequired(),
  otherDisabilities: mixed().when("disabilities", {
    is: (value) => value.includes("Other"),
    then: string()
      .required("Other Disability is required")
      .max(150, "Other Disability should be between 1 - 150 characters"),
    otherwise: string().notRequired(),
  }),
};

export const MotivationValidation = {
  rating: number().notRequired(), //.required("Please rate your motivation to lose weight"),
  statement: string().required("Please select a motivation statement"),
  confidenceLevel: number().notRequired(), //.required("Please rate your confidence level to lose weight"),
};
