//A tiny function to check if a value is empty or not
export const IsValueEmpty = (value) => {
  return (
    typeof value === undefined ||
    value === undefined ||
    value === null ||
    value === "" ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

export const IsArrayEmpty = (array) => {
  return array.length === 0;
};

export const IsObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};
