import { useRouteMatch, useHistory, Switch, Route } from "react-router";
import { IneligibleScreen } from "../ResultScreens";
import DataInformationConsent from "./DataInformationConsent";
import { ReactComponent as ErrorIcon } from "../../../../assets/img/icons/error.svg";

const DataInformationConsentRoot = () => {
  const { path } = useRouteMatch();
  const history = useHistory();

  const DataInformationConsentErrorScreen = () => {
    return (
      
      <IneligibleScreen
        title="Error creating account | Eligibility checker | Sign up | Gloji"
        heading="Uh oh, we couldn't create your account"
        bodyText="There’s a problem in the system! Not to worry, we’ll resolve the problem straight away. <br/><br/>Contact support@gloji.co.uk from the email you tried to create an account with or give us a call on [0800 054 1190](tel:08000541190)"
        hasContactUs={true}
        backButtonText="Back"
        backButtonOnClick={() => history.push(`${path}`)}
      >
        <ErrorIcon />
      </IneligibleScreen>
  
    );
  };

  return (
    <>
      <Switch>
        <Route exact path={`${path}`} component={DataInformationConsent} />
        <Route path={`${path}/error`} component={DataInformationConsentErrorScreen} />
      </Switch>
    </>
  );
};

export default DataInformationConsentRoot;
