import { useSelector } from "react-redux";
import { useRouteMatch, Switch, Route } from "react-router";
import { IneligibleScreen } from "../ResultScreens";
import MentalHealth from "./MentalHealth";
import MentalHealthManagement from "./MentalHealthManagement";
import MentalillnessRegister from "./MentalillnessRegister";

const MentalHealthRoot = (props) => {
  let { path } = useRouteMatch();

  const MentalHealthManagementPageData = useSelector(
    (state) => state.signupFlowData.signupPagesData["mental-health"]
  );

  const MentalHealthIneligibleScreen = () => {
    return (
      <IneligibleScreen
        title="Ineligible mental health | Eligibility checker | Sign up | Gloji"
        heading={MentalHealthManagementPageData.ineligibleScreens.ineligibleMentalHealth.title}
        bodyText={MentalHealthManagementPageData.ineligibleScreens.ineligibleMentalHealth.body}
        hasContactUs={true}
      />
    );
  };

  const IneligibleEatingDisorderScreen = () => {
    return (
      <IneligibleScreen
        title="Ineligible mental health | Eligibility checker | Sign up | Gloji"
        heading={MentalHealthManagementPageData.ineligibleScreens.ineligibleEatingDisorder.title}
        bodyText={MentalHealthManagementPageData.ineligibleScreens.ineligibleEatingDisorder.body}
        hasContactUs={true}
      />
    );
  };

  return (
    <>
      <Switch>
        <Route exact path={`${path}`} component={MentalHealth} />
        <Route exact path={`${path}/mental-health-management`} component={MentalHealthManagement} />
        <Route exact path={`${path}/mental-illness-register`} component={MentalillnessRegister} />
        <Route
          path={`${path}/mental-health-management/fail`}
          component={MentalHealthIneligibleScreen}
        />
        <Route
          exact
          path={`${path}/ineligible-eating-disorder/fail`}
          component={IneligibleEatingDisorderScreen}
        />
      </Switch>
    </>
  );
};

export default MentalHealthRoot;
