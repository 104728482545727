import React from "react";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";

const SectionHeader = ({ children, className }) => {
  return (
    <header className={`section-header__container ${className || ""}`}>
      <Grid container>
        <Grid item xs={12} md={12}>
          {children}
        </Grid>
      </Grid>
    </header>
  );
};

SectionHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default SectionHeader;
