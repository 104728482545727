import React, { useEffect, useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FormikField } from "../../../atoms/FormikField";
import FormikInputOnly from "../../../atoms/formik-input-only";
import { FormHelperText } from "@material-ui/core";
import { Form, Formik } from "formik";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import {
  ModalPageHeader,
  ModalPageBody,
  ModalPageFooter,
  ModalPageWrapper,
  ModalPageHeading,
} from "../../../organisms/ModalPage";
import RadioButtonGroup from "../../../molecules/RadioButtonGroup/RadioButtonGroup";
import { update_signup_form_values } from "../../../../store/actions/signupFlowData";
import { ErrorMessage } from "formik";
import { SexValidationSchema } from "../EligibilityCheckerValidation";

const SexChecker = () => {
  const dispatch = useDispatch();
  const signupFormValues = useSelector((state) => state.signupFlowData.signupFormValues);
  const sexPageData = useSelector((state) => state.signupFlowData.signupPagesData?.sex);

  let history = useHistory();
  let { path } = useRouteMatch();

  const [initialValues, setInitialValues] = useState({
    sex: signupFormValues?.sex ?? "",
    genderIsBirthSex: signupFormValues?.genderIsBirthSex ?? "",
    gender: signupFormValues?.gender ? signupFormValues?.gender : "",
  });

  const sexOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];

  const yesNoOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Sex | Eligibility checker | Sign up | Gloji",
    });
  }, []);

  const handleSubmit = (values) => {
    if (values.sex === "female") {
      history.push(`${path}/pregnancy`);
    } else {
      history.push(`${path}/loading`);
    }

    // Save values to store
    values.genderIsBirthSex === "yes"
      ? dispatch(update_signup_form_values({ ...values, gender: values.sex }))
      : dispatch(update_signup_form_values(values));
  };

  const handleGenderIsBirthSexChange = () => {
    const selectedValue = document.querySelector('input[name="genderIsBirthSex"]:checked').value;
    if (selectedValue === "no") {
      setInitialValues({ ...initialValues, gender: "" });
      dispatch(update_signup_form_values({ ...initialValues, gender: "" }));
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={SexValidationSchema}
        validateOnChange={false}
        enableReinitialize={true}
      >
        {({ isSubmitting, values, touched, errors }) => (
          <Form className="eligibility-checker-form">
            <Helmet>
              <title>Sex | Eligibility checker | Sign up | Gloji</title>
            </Helmet>
            <ModalPageWrapper>
              <ModalPageBody className="with-sticky-footer">
                <ModalPageHeader
                  backButtonOnClick={() => {
                    history.goBack();
                  }}
                >
                  Checking we're a good fit
                </ModalPageHeader>

                <ModalPageHeading>{sexPageData?.questions[0].question}</ModalPageHeading>
                <RadioButtonGroup
                  options={sexOptions}
                  name="sex"
                  hasErrors={errors[`sex`] && touched["sex"]}
                />
                <Accordion square={true} elevation={0} className="eligibility-checker__accrodion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon fontSize="large" />}
                    aria-controls={`sex-question-explanation`}
                  >
                    <span>{sexPageData?.questions[0].whyQuestion}</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>{sexPageData?.questions[0].whyQuestionDetails}</span>
                  </AccordionDetails>
                </Accordion>

                <ModalPageHeading>{sexPageData?.questions[1].question}</ModalPageHeading>
                <RadioButtonGroup
                  options={yesNoOptions}
                  name="genderIsBirthSex"
                  hasErrors={errors[`genderIsBirthSex`] && touched["genderIsBirthSex"]}
                  onClick={handleGenderIsBirthSexChange}
                />
                <Accordion square={true} elevation={0} className="eligibility-checker__accrodion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon fontSize="large" />}
                    aria-controls={`gender-question-explanation`}
                  >
                    <span>{sexPageData?.questions[1].whyQuestion}</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>{sexPageData?.questions[1].whyQuestionDetails}</span>
                  </AccordionDetails>
                </Accordion>

                {values.genderIsBirthSex === "no" && (
                  <>
                    <ModalPageHeading>{sexPageData?.questions[2].question}</ModalPageHeading>
                    <FormikField
                      className="eligibility-checker__text-input"
                      id="gender"
                      name="gender"
                      component={FormikInputOnly}
                      placeholder="Your gender…"
                    />
                    <ErrorMessage className="Mui-error" component={FormHelperText} name="gender" />
                  </>
                )}
              </ModalPageBody>
              <ModalPageFooter className="sticky-footer">
                <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                  Continue
                </Button>
              </ModalPageFooter>
            </ModalPageWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SexChecker;
