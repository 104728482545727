import React, { useEffect } from "react";
import "./LoadAppointments.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useGetAppointmentsQuery } from "../../../../redux/profileApi";
import useUserId from "../../../hooks/useUserId";
import { useHistory } from "react-router";
import {
  ModalPageBody,
  ModalPageCopy,
  ModalPageFooter,
  ModalPageWrapper,
} from "../../../organisms/ModalPage";
import { Helmet } from "react-helmet";

interface LoadAppointmentsProps {}

export const LoadAppointments: React.FC<LoadAppointmentsProps> = (
  props: LoadAppointmentsProps
) => {
  const userId = useUserId();
  const history = useHistory();
  const { data: appointmentsResponse, isSuccess } = useGetAppointmentsQuery(
    userId ?? skipToken
  );

  useEffect(() => {
    console.log(
      "appointmentsResponse?.dateTime",
      appointmentsResponse?.dateTime
    );
    if (isSuccess && Object.keys(appointmentsResponse?.dateTime).length === 0) {
      return history.push("/no-appointments");
    } else if (isSuccess) {
      return history.push("/user-details");
    }
  }, [appointmentsResponse]);

  return (
    <>
      <Helmet>
        <title>Time to book your first appointment | Gloji</title>
      </Helmet>

      <ModalPageWrapper>
        <ModalPageBody hasStickyFooter={false}>
          <ModalPageCopy>
            <Skeleton className="heading" variant="rect" height={30} />
            <Skeleton variant="rect" height={20} />
            <Skeleton variant="rect" width={"60%"} height={20} />
            <Skeleton variant="rect" height={20} />
            <Skeleton variant="rect" height={20} />
            <Skeleton variant="rect" width={"60%"} height={20} />
          </ModalPageCopy>
        </ModalPageBody>
        <ModalPageFooter hasStickyFooter={false}>
          <Skeleton variant="rect" height={40} />
        </ModalPageFooter>
      </ModalPageWrapper>
    </>
  );
};
