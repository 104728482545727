import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { CardActions, CardContent, CardMedia } from "@material-ui/core";
import RecipePills from "../RecipePills";
import "./RecipeCard.scss";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import PropTypes from "prop-types";
import RecipeCardIcons from "./RecipeCardIcons";

const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
    backgroundColor: "white",
    borderRadius: "10px",
    "& .MuiSvgIcon-root": {
      fontSize: "12px",
    },
  },
  media: {
    height: 208,
    position: "relative",
    borderRadius: "5px",
    margin: "10px",
  },
  content: {
    padding: "0px 12px 12px 12px",
  },
  footer: {
    padding: "0 0 24px 12px ",
  },
});
const truncateString = (string, length) => {
  if (string.length > length) {
    return `${string.substring(0, length)}...`;
  }
  return string;
};
const RecipeCard = ({
  loading = false,
  cardTitle,
  description,
  imgUrl,
  imgTitle,
  dietTypes,
  allergens,
  cardVariant,
  mealType,
  time,
  servings,
}) => {
  const classes = useStyles();

  if (loading) {
    return (
      <Card className={`${classes.root} recipe-card recipe-card--skeleton`} elevation={0}>
        <div className={`${classes.media} recipe-card__image-container`}>
          <div className="recipe-card__pills"></div>
        </div>
        <div className="recipe-card__content-container">
          <div className="recipe-card__title"></div>
          <div className="recipe-card__description"></div>
          <div className="recipe-card__description"></div>
          <div className="recipe-card__description"></div>
        </div>
        <div className="recipe-card__footer">
          <div className="recipe-card__read-more"></div>
        </div>
      </Card>
    );
  }

  return (
    <Card className={`${classes.root} recipe-card ${cardVariant}`} elevation={0}>
      <CardMedia
        className={`${classes.media} recipe-card__image-container`}
        image={imgUrl}
        title={imgTitle}
      >
        <div className="recipe-card__pills pills-container">
          <RecipePills allergens={allergens} dietTypes={dietTypes} />
        </div>
        <div className="overlay"></div>
      </CardMedia>

      <CardContent className={`${classes.content} recipe-card__content-container`}>
        <RecipeCardIcons mealType={mealType} time={time} servings={servings} />
        <h2 className="recipe-card__title">{truncateString(cardTitle, 30)}</h2>
        <p className="recipe-card__description">{truncateString(description, 100)}</p>
      </CardContent>
      <CardActions className={`${classes.footer} recipe-card__footer`}>
        <span className="recipe-card__read-more-link">
          Read on <ArrowForwardIosIcon className="recipe-card__read-more-link-icon" />
        </span>
      </CardActions>
    </Card>
  );
};

RecipeCard.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imgUrl: PropTypes.string.isRequired,
  imgTitle: PropTypes.string,
  dietTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  allergens: PropTypes.arrayOf(PropTypes.string).isRequired,
  cardVariant: PropTypes.string,
};

export default RecipeCard;
