import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { NAVY, MINT } from "../../../enums/brandColors";

const numbersInText = ["one", "two", "three", "four", "five"];

const a11yProps = (index) => {
  return {
    id: `custom-tab-${index}`,
    "aria-controls": `custom-tabpanel-${index}`,
  };
};

const PillTabList = withStyles({
  root: {
    backgroundColor: "#dededa",
    width: "max-content",
    minHeight: "auto",
    borderRadius: "500px",
    marginTop: "12px",
    marginBottom: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "space-between",
  },
  indicator:{
    display: "none"
  }
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const PillTab = withStyles((theme) => ({
  root: {
    minWidth: "auto",
    minHeight: "auto",
    cursor: "pointer",
    backgroundColor: "transparent",
    color: NAVY,
    padding: "3px 12px",
    margin: "2px 2px",
    border: "none",
    borderRadius: "500px",

    "&:hover": {
      color: NAVY,
      textDecoration: "underline",
      opacity: 1,
    },
    "&:focus-visible": {
      outline: "2px NAVY solid",
      color: NAVY,
      opacity: 1,
    },
  },
  selected: {
    backgroundColor: MINT,
  },
  wrapper: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: "12px",
    letterSpacing: "1.2px",
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
}));

const PillTabs = ({ stepData, value, handleChange, handleTabClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <PillTabList value={value} onChange={handleChange} centered indicatorColor={"primary"}>
        {stepData &&
          stepData.parts.map((item, index) => {
            return (
              <PillTab
                label={
                  <>
                    <div
                      onClick={() => {
                        value !== index && handleTabClick(index);
                      }}
                    >{`Part ${numbersInText[index]}`}</div>
                  </>
                }
                {...a11yProps(`${item.partId}`)}
                key={`tab${index}`}
              />
            );
          })}
      </PillTabList>
    </div>
  );
};

export default PillTabs;
