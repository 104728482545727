import React, { useEffect } from "react";
import MarkedDownContent from "../../../organisms/MarkedDownContent";
import {
  ModalPageCopy,
  ModalPageHeading,
  ModalPageWrapper,
  ModalPageBody,
  ModalPageHeroImage,
  ModalPageFooter,
} from "../../../organisms/ModalPage";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";

const EligibleScreen = ({
  title,
  buttonText,
  heading,
  altText,
  bodyText,
  goToNextFunc,
  heroImageUrl,
}) => {
  useEffect(() => {
    if (title) {
      window.gtag("event", "page_view", {
        page_title: `${title}`,
      });
    }
  }, [title]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ModalPageWrapper>
        <ModalPageBody>
          <ModalPageHeroImage altText={altText} imageUrl={heroImageUrl}></ModalPageHeroImage>
          <ModalPageHeading>{heading}</ModalPageHeading>
          <ModalPageCopy>
            <MarkedDownContent content={bodyText} />
          </ModalPageCopy>
        </ModalPageBody>
        <ModalPageFooter>
          <Button onClick={() => goToNextFunc()} variant="contained" color="primary">
            {buttonText}
          </Button>
        </ModalPageFooter>
      </ModalPageWrapper>
    </>
  );
};

EligibleScreen.propTypes = {
  title: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  goToNextFunc: PropTypes.func.isRequired,
  heroImageUrl: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

EligibleScreen.defaultProps = {
  buttonText: "Continue",
};

export default EligibleScreen;
