import React, { useEffect, useState } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationPaths, QueryParameterNames } from "./ApiAuthorizationConstants";
import authService from "./AuthorizeService";
import { CircularLoader } from "../atoms/Loaders";

import { profile_loaded } from "../../store/actions/getProfile";
import { Programmes } from "../../data/Programme/Programmes";
import JourneyStates from "../../enums/journeyStates";
import Pathway from "../../enums/profile/Pathway";

import { programmes } from "../../enums/programmes";
import { IsObjectEmpty } from "../../utils/IsEmpty";
import { profileApi } from "../../redux/profileApi";

export default function AuthorizeRoute(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector((state) => state.profile);
  const overview = useSelector((state) => state.programmeOverview);
  const [state, setState] = useState({
    ready: false,
    authenticated: false,
    accountState: "Unknown",
  });

  const [programmeSlug, setProgrammeSlug] = useState(null);

  const { ready, authenticated, accountState, referralSource } = state;

  const location = props.location;
  var link = document.createElement("a");

  link.href = `${location.pathname}${location.search}${location.hash}`;
  const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
  const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(
    returnUrl
  )}`;

  // FF logic

  useEffect(() => {
    async function populateAuthenticationState() {
      const user = await authService.getUser();

      let accountState = "Unknown";
      let referralSource = "Unknown";
      let authenticated = user && !!user.sub;
      if (authenticated) {
        localStorage.setItem("userID", user && user.sub);
        const response = await dispatch(profileApi.endpoints.getProfileById.initiate(user.sub));
        if (response && response?.data?.accountState) {
          accountState = response.data.accountState;
          dispatch(profile_loaded(response.data));
        } else if (response && response.status === 404) {
          accountState = "None";
        }
        if (response.data?.currentJourney?.id !== undefined) {
          localStorage.setItem("journeyID", response.data.currentJourney.id);
          let programmeId =
            response.data.currentJourney.programmeIds.length === 0
              ? "ffffffff-ffff-4fff-0001-000000000001"
              : response.data.currentJourney.programmeIds[0]; // TODO: Why do some journeys in prod not have programme IDs?
          localStorage.setItem("programmeID", programmeId);
          referralSource = response.data?.currentJourney?.referralSource;
        }
      }

      setState({ ready: true, authenticated, accountState, referralSource });
    }

    async function authenticationChanged() {
      setState({
        ready: false,
        authenticated: false,
        accountState: "Unknown",
        referralSource: "Unknown",
      });
      await populateAuthenticationState();
    }

    let _subscription = authService.subscribe(() => authenticationChanged());
    populateAuthenticationState();

    return () => authService.unsubscribe(_subscription);
  }, [dispatch]);

  useEffect(() => {
    const programmeId = localStorage.getItem("programmeID");
    const journeyState = profile?.data?.currentJourney?.state;

    if (journeyState === undefined || programmeId === undefined) {
      return;
    }

    const programmeDetails = Programmes.filter(
      (programme) => programme.programmeId === programmeId
    );
    const slug = programmeDetails.length > 0 ? programmeDetails[0]["slug"] : null;
    setProgrammeSlug(slug);
  }, [profile.data]);

  // CONDITIONAL REDIRECTING
  useEffect(() => {
    if (!ready) return;

    const isInReferralState =
      profile?.data?.currentJourney?.state === JourneyStates.REFERRAL ? true : false;
    const step0Completed =
      profile?.data?.currentJourney?.flags?.step0Completed === true ? true : false;
    const supportedUser =
      profile?.data?.currentJourney?.pathway === Pathway.SUPPORTED ? true : false;
    const initialWeightLogged =
      profile?.data?.currentJourney?.flags?.initialWeightLogged === true ? true : false;
    const appointmentBooked =
      profile?.data?.currentJourney?.flags?.appointmentBooked === true ? true : false;

    // permanent programme specific flags
    const myProgress = overview?.data?.programme?.features?.myProgress === true ? true : false;
    const bookAppointments =
      overview?.data?.programme?.features?.bookAppointments === true ? true : false;

    // 1 - FORCE STEP 0
    if (!isInReferralState && !step0Completed && programmeSlug !== null) {
      if (
        !window.location.pathname.includes(`/my-path/${programmeSlug}/step-00`) &&
        !window.location.pathname.includes(`/my-path/${programmeSlug}/step-0/part-01`)
      ) {
        history.push(`/my-path/${programmeSlug}/step-00`);
      }
      return <CircularLoader />;
    }

    // 2 - FORCE MY PROGRESS
    if (step0Completed && !initialWeightLogged && myProgress) {
      if (!window.location.pathname.includes("/my-progress")) {
        history.push("/my-progress");
      }

      return <CircularLoader />;
    }

    // 3 - FORCE BOOK APPOINTMENT
    if (
      step0Completed &&
      supportedUser &&
      initialWeightLogged &&
      !appointmentBooked &&
      bookAppointments
    ) {
      if (!window.location.pathname.includes("/book-appointment")) {
        history.push("/book-appointment");
      }
      return <CircularLoader />;
    }

    // 4 - FORCE USER AWAY FROM BOOK APPOINTMENT WHEN ALREADY BOOKED
    if (step0Completed && appointmentBooked) {
      if (window.location.pathname.includes("/book-appointment")) {
        if (!IsObjectEmpty(overview.data) && !bookAppointments) {
          history.push("/");
        }
        history.push("/");
      }
      return <CircularLoader />;
    }
  }, [ready, profile, programmeSlug, history, overview]);

  // SmokeFree programme: Redirect user to homePage when the user tries to access myProgress page via url
  useEffect(() => {
    if (!IsObjectEmpty(overview.data)) {
      if (overview.data.programme.id === programmes["smoke-free"].id) {
        if (window.location.pathname.includes("/my-progress")) {
          history.push("/");
        }
      }
    }
  }, [history, overview]);

  if (!ready) {
    return <CircularLoader />;
  } else {
    const { component: Component, ...rest } = props;
    return (
      <Route
        {...rest}
        render={(renderProps) => {
          if (authenticated && accountState === "EmailNotVerified") {
            if (props.path === "/account/verify-email") {
              return <Component {...renderProps} />;
            }
            return <Redirect to="/account/verify-email" />;
          } else if (authenticated && accountState === "ProfileCompleted") {
            if (props.path === "/profile-builder" || props.path === "/sign-up/profile-builder") {
              return <Redirect to="/" />;
            }
            return <Component {...renderProps} />;
          } else if (authenticated && accountState === "EmailVerified") {
            if (referralSource === "SelfReferral" || referralSource === "Crm") {
              if (props.path === "/sign-up/profile-builder") {
                return <Component {...renderProps} />;
              }
              return <Redirect to="/sign-up/profile-builder" />;
            } else {
              if (props.path === "/profile-builder") {
                return <Component {...renderProps} />;
              }
              return <Redirect to="/profile-builder" />;
            }
          } else if (!authenticated) {
            return <Redirect to={redirectUrl} />;
          } else {
            throw new Error("Unable to verify client profile completion state!");
          }
        }}
      />
    );
  }
}
