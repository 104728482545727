import React from "react";
import MuiTextField from "@material-ui/core/TextField";

function FormikInputOnly({
  field: { ...fields },
  form: { touched, errors, ...rest },
  label,
  ...props
}) {
  return (
    <MuiTextField
      {...props}
      {...fields}
      error={Boolean(touched[fields.name] && errors[fields.name])}
    />
  );
}

export default FormikInputOnly;
