export const howHeardOptions = [
  { value: "SocialMedia", label: "Social media" },
  { value: "Email", label: "Email" },
  { value: "Google", label: "Google" },
  { value: "TextOrSMS", label: "Text/SMS" },
  { value: "GPSurgery", label: "GP Surgery" },
  { value: "LeisureCentreOrGym", label: "Leisure Centre / Gym" },
  { value: "LeafletOrPosterOrBanner", label: "Leaflet / Poster / Banner" },
  { value: "WordOfMouth", label: "Word of mouth" },
  { value: "AnotherOrganization", label: "Another organisation" },
  { value: "HealthCheck", label: "Health check" },
  { value: "NewspaperOrOnlineNews", label: "Newspaper/Online news article" },
  { value: "Radio", label: "Radio" },
  { value: "Other", label: "Other" },
];
