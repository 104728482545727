import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { ContentPageHeroImage } from "../../atoms/HeroImage";
import ContentContainer from "../../organisms/ContentContainer";
import { ARTICLE_TYPES } from "../../../enums/articleTypes";
import ContentPage from "../../templates/ContentPage/ContentPage";
import useContent from "../../hooks/useContent";
import { CircularLoader } from "../../atoms/Loaders";
import { contentService } from "../../services";

const PolicyDocument = (props) => {
  let location = useLocation();
  let slug = location.pathname.substr(1);
  const [data, isReady] = useContent(slug, contentService.getPolicyDocment);
  const pillars = [];
  let heroImage = { url: "", altText: "", imgTitle: "" };

  if (Object.keys(data).length !== 0 && data.asset) {
    heroImage.url = data.asset.file.url || "";
    heroImage.altText = data.asset.description || "";
    heroImage.imgTitle = data.asset.title || "";
  }

  return (
    <>
      {isReady ? (
        <>
          <Helmet>
            <title>{data.title} | Gloji</title>
          </Helmet>
          <ContentPage
            bgImage={ARTICLE_TYPES.default.bgImg}
            heroImage={
              <ContentPageHeroImage
                src={heroImage.url}
                alt={heroImage.altText}
                title={heroImage.imgTitle}
              />
            }
            container={
              <ContentContainer
                title={data.title}
                body={data.body}
                pillars={pillars}
                hasTopBar={false}
              />
            }
          ></ContentPage>
        </>
      ) : (
        <CircularLoader color="primary" size={40} />
      )}
    </>
  );
};

export default PolicyDocument;
