import React from "react";
// import "./NoAppointments.scss";
import {
  ModalPageBody,
  ModalPageCopy,
  ModalPageFooter,
  ModalPageHeading,
  ModalPageWrapper,
} from "../../../organisms/ModalPage";
import { Helmet } from "react-helmet";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";

interface NoAppointmentsProps {}

export const NoAppointments: React.FC<NoAppointmentsProps> = (
  props: NoAppointmentsProps
) => {
  return (
    <>
      <Helmet>
        <title>Time to book your first appointment | Gloji</title>
      </Helmet>

      <ModalPageWrapper>
        <ModalPageBody hasStickyFooter={false}>
          <ModalPageHeading size="large">Check back soon</ModalPageHeading>
          <ModalPageCopy>
            Sorry, there are no appointments available right now. <br />
            <br />
            Come back and try again tomorrow. Got a question? Call us on 0800
            0541 190 or click the button below to send us an email.
          </ModalPageCopy>
        </ModalPageBody>

        <ModalPageFooter hasStickyFooter={false}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              window.location.href = "mailto:support@gloji.co.uk";
            }}
          >
            Contact us
          </Button>
        </ModalPageFooter>
      </ModalPageWrapper>
    </>
  );
};
