import React from "react";
import PropTypes from "prop-types";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const ArrowNext = withStyles({
  root: {
    fontSize: "inherit",
  },
})(ArrowForwardIosIcon);

const NextButton = ({ linkPath, btnText, ariaLabel }) => {
  return (
    <Link to={linkPath} className="back-button" aria-label={ariaLabel}>
      {btnText}
      <ArrowNext role="presentation" className="icon" />
    </Link>
  );
};

NextButton.propTypes = {
  linkPath: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
};

export default NextButton;
