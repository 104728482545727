export const playerOptions = ({
  src,
  videoType = "application/x-mpegURL",
  userId,
  videoId = "testVideoId",
  videoTitle = "testVideoTitle",
  videoSeries = "Weight loss programme",
  videoDuration,
}) => {
  return {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    muted: false,
    sources: [
      {
        src: src,
        type: videoType,
      },
    ],
    plugins: {
      mux: {
        debug: false,
        data: {
          env_key: "4uh78i79trraptqk3k4lu51mo", // hardocoded for demo purpose
          // Metadata
          viewer_user_id: userId,
          experiment_name: "videoJS_player_test_1",
          sub_property_id: "",
          // Player Metadata
          player_name: "Primary Video Player",
          player_version: "v1.0.0",
          player_init_time: Date.now(),
          // Video Metadata
          video_id: videoId,
          video_title: videoTitle,
          video_series: videoSeries,
          video_duration: videoDuration,
          video_stream_type: "on-demand",
          video_cdn: "mux",
        },
      },
    },
  };
};
