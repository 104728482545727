import axios from "axios";
import authAxios from "../../helpers/authAxios";
import { urls } from "../../configs/serviceConfig";
import { logException } from "../../utils/AppInsightsLogging";

class FeatureService {
  async isFeatureEnabled(featureId, reference = null) {
    let url = `${urls.featureProxyService}/api/features/${featureId}/enabled`;

    try {
      if (reference !== null) {
        url = `${
          urls.featureProxyService
        }/api/features/enabled?key=${featureId}&ref=${encodeURIComponent(reference)}`;
        const res = await axios.get(url);
        return res.data;
      } else {
        const res = await authAxios.get(url);
        return res.data;
      }
    } catch (error) {
      logException(error);
      return false;
    }
  }

  static get instance() {
    return featureService;
  }
}

const featureService = new FeatureService();

export default featureService;
