export const Programmes = [
  {
    programmeId: "ffffffff-ffff-4fff-0001-000000000001",
    name: "Lose Weight",
    slug: "lose-weight",
    totalSteps: 13,
    tag: "loseWeight",
  },
  {
    programmeId: "ffffffff-ffff-4fff-0002-000000000001",
    name: "Smoke Free",
    slug: "smoke-free",
    totalSteps: 13,
    tag: "smokeFree",
  },
  {
    programmeId: "ffffffff-ffff-4fff-ffff-000000000001",
    name: "Sitting on Chairs",
    slug: "sitting-on-chairs",
    totalSteps: 3,
    tag: "sittingOnChairs",
  },
  {
    programmeId: "ffffffff-ffff-4fff-fffe-000000000001",
    name: "Standing on Tables",
    slug: "standing-on-tables",
    totalSteps: 2,
    tag: "standingOnTables",
  },
];
