import React, { useEffect } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Form, Formik } from "formik";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  ModalPageHeader,
  ModalPageBody,
  ModalPageFooter,
  ModalPageWrapper,
  ModalPageHeading,
} from "../../../organisms/ModalPage";
import RadioButtonGroup from "../../../molecules/RadioButtonGroup/RadioButtonGroup";
import { update_signup_form_values } from "../../../../store/actions/signupFlowData";
import { DisabilitiesValidationSchema } from "../EligibilityCheckerValidation";
import FormikCheckbox from "../../../atoms/FormikCheckbox";
import CheckboxGroupContainer from "../../../atoms/CheckboxGroupContainer";
import { disabilities } from "../../../../enums/profile/Disability";

const Disabilities = () => {
  const dispatch = useDispatch();
  const signupFormValues = useSelector((state) => state.signupFlowData.signupFormValues);
  const disabilitiesData = useSelector(
    (state) => state.signupFlowData.signupPagesData["disabilities"]
  );

  let history = useHistory();

  const initialValues = {
    anyDisabilities: signupFormValues?.anyDisabilities ?? "",
    disabilities: signupFormValues?.disabilities ?? [],
  };

  const yesNoOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Disabilities | Eligibility checker | Sign up | Gloji",
    });
  }, []);

  const handleSubmit = (values) => {
    if (values.anyDisabilities === "no") {
      history.push(`/disabilities/loading`);
      // Save values to store
      dispatch(update_signup_form_values({ ...values, disabilities: [] }));
    } else {
      const conditions = ["learning", "dyslexia", "developmentDisorder"];
      if (conditions.some((condition) => values.disabilities.includes(condition))) {
        history.push(`/disabilities/disability-register`);
      } else {
        history.push(`/disabilities/loading`);
      }
      // Save values to store
      dispatch(update_signup_form_values(values));
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={DisabilitiesValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ isSubmitting, values, touched, errors }) => (
          <Form className="eligibility-checker-form disabilities-form">
            <Helmet>
              <title>Disabilities | Eligibility checker | Sign up | Gloji</title>
            </Helmet>
            <ModalPageWrapper>
              <ModalPageBody className="with-sticky-footer">
                <ModalPageHeader
                  backButtonOnClick={() => {
                    history.goBack();
                  }}
                >
                  Checking we're a good fit
                </ModalPageHeader>
                <ModalPageHeading>{disabilitiesData?.questions[0].question}</ModalPageHeading>
                <RadioButtonGroup
                  options={yesNoOptions}
                  name="anyDisabilities"
                  hasErrors={errors["anyDisabilities"] && touched["anyDisabilities"]}
                />
                <Accordion square={true} elevation={0} className="eligibility-checker__accrodion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon fontSize="large" />}
                    aria-controls={`disabilities-question-explanation`}
                  >
                    <span>{disabilitiesData?.questions[0].whyQuestion}</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>{disabilitiesData?.questions[0].whyQuestionDetails}</span>
                  </AccordionDetails>
                </Accordion>

                {values.anyDisabilities === "yes" && (
                  <>
                    <ModalPageHeading>Select any that apply to you</ModalPageHeading>
                    <CheckboxGroupContainer name="disabilities">
                      {disabilities.map((item) => (
                        <FormikCheckbox
                          key={item.value}
                          id={item.value}
                          name="disabilities"
                          label={item.label}
                          value={item.value}
                        />
                      ))}
                    </CheckboxGroupContainer>
                  </>
                )}
              </ModalPageBody>
              <ModalPageFooter className="sticky-footer">
                <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                  Continue
                </Button>
              </ModalPageFooter>
            </ModalPageWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Disabilities;
