import { Container } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { PageTitle } from "@thrivetribe/gloji.ui.molecules.page-title";
import { SILVER_EXTRA_LIGHT } from "@thrivetribe/gloji.enums.brand-colors";
import { greyVelvet } from "../../../assets/img/bg";
import { get_programme_overview } from "../../../store/actions/getProgrammeOverview";
import { CircularLoader } from "../../atoms/Loaders";
import { SectionHeader, SectionHeading, SectionIntro } from "../../atoms/SectionHeader";
import useUserId from "../../hooks/useUserId";
import useCompletedSteps from "../../hooks/useCompletedSteps";
import CurrentStepCard from "../../molecules/CurrentStepCard";
import HeroBanner from "../../organisms/HeroBanner";
import PageBackground from "../../organisms/PageBackground";
import ProgrammeOverview from "../../organisms/ProgrammeOverview";
import ProgrammeStates from "../../../enums/programmeStates";
import "./MyPath.scss";
import Stepper from "../../atoms/Stepper";

const MyPath = () => {
  const overview = useSelector((state) => state.programmeOverview);
  const dispatch = useDispatch();
  const [nextLockedStep, setNextLockedStep] = useState(null);
  const [userId] = useUserId();
  const journeyId = localStorage.getItem("journeyID");
  const programmeId = localStorage.getItem("programmeID");
  const completedSteps = useCompletedSteps(overview?.data?.steps);

  useEffect(() => {
    userId && dispatch(get_programme_overview({userId, programmeId, journeyId}));
  }, [dispatch, userId, programmeId, journeyId]);

  useEffect(() => {
    if (overview?.data?.steps === undefined) return;
    const next = overview.data.steps.find((step) => step.locked);
    if (next === undefined) return;
    setNextLockedStep(next);
  }, [overview]);

  const title =
    overview?.data?.programme?.title !== undefined ? "| " + overview.data.programme.title : "";

  return (
    <PageBackground bgImage={greyVelvet}>
      <Helmet>
        <title>My path {title} | Gloji</title>
      </Helmet>
      {overview && overview.loading === false ? (
        <div className="my-path-page">
          {overview?.data != null && overview?.data?.programme?.numberOfSteps != null && (
            <HeroBanner>
              <PageTitle color={SILVER_EXTRA_LIGHT}>My path</PageTitle>
              <Stepper
                totalSteps={overview.data?.programme?.numberOfSteps - 1}
                completedSteps={completedSteps}
                currentStep={
                  overview?.data?.nextAction != null
                    ? overview.data.nextAction.stepNumber
                    : nextLockedStep !== null
                    ? nextLockedStep.stepNumber - 1
                    : overview.data.programme.numberOfSteps - 1
                }
              />
              <CurrentStepCard />
            </HeroBanner>
          )}

          <Container className="main-content__container">
            <section className="">
              <SectionHeader className="programme-header">
                <SectionHeading>Your journey so far</SectionHeading>
                <SectionIntro className="programme-intro">
                  Welcome to your path. Take a look back at the steps you've completed so far. Watch
                  again, or see what's just round the corner.
                </SectionIntro>
                {overview?.data?.state === ProgrammeStates.WAITING_UNLOCK &&
                  nextLockedStep != null && (
                    <SectionIntro className="programme-intro programme-intro--unlock-date">
                      {nextLockedStep.title} unlocks{" "}
                      {moment(nextLockedStep?.unlockDate).format("DD MMMM")}
                    </SectionIntro>
                  )}
              </SectionHeader>
              {overview?.data?.state !== undefined &&
                overview?.data?.startDate !== undefined &&
                overview?.data?.steps !== undefined && (
                  <ProgrammeOverview
                    programmeState={overview?.data?.state}
                    startDate={overview?.data.startDate}
                    steps={overview?.data.steps}
                  />
                )}
            </section>
          </Container>
        </div>
      ) : (
        <CircularLoader color="primary" size={40} />
      )}
    </PageBackground>
  );
};

export default MyPath;
