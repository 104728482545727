import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { ReactComponent as GlojiGymLogo } from "../../../assets/img/glojiGymLogoBlack.svg";
import "./NotificationBar.scss";

const ArrowNext = withStyles({
  root: {
    fontSize: "inherit",
  },
})(ArrowForwardIosIcon);

const NotificationBar = () => {
  const [showBanner, setShowBanner] = useState(false);
  const profile = useSelector((state) => state.profile);

  useEffect(() => {
    const banners = profile?.data?.banners;
    if (banners === undefined) {
      return setShowBanner(false);
    }

    const glojiGymBanner = banners.find((banner) => banner === "GlojiGymBanner");
    if (glojiGymBanner === undefined) {
      return setShowBanner(false);
    }

    return setShowBanner(true);
  }, [profile]);

  return (
    showBanner && (
      <div className="notification-bar">
        <div className="notification-bar__container">
          <GlojiGymLogo className="notification-bar__logo" />
          <div className="notification-bar__content">
            <span>Feel great - mind, body and soul using our FREE online gym.</span>
            <a href="https://gym.gloji.co.uk/" target="_blank" rel="noreferrer">
              Find out more
              <ArrowNext role="presentation" className="icon" />
            </a>
          </div>
        </div>
      </div>
    )
  );
};

export default NotificationBar;
