import React from "react";

import SexChecker from "../SexChecker/SexChecker";
import PregnancyChecker from "../SexChecker/PregnancyChecker";
import { Switch, Route, useHistory, useRouteMatch } from "react-router-dom";
import { LoadingPage } from "../LoadingPage";
import { useSelector } from "react-redux";
import { IneligibleScreen } from "../ResultScreens";

const SexCheckerRoot = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const sexPageData = useSelector((state) => state.signupFlowData.signupPagesData?.sex);
  const signupPagesData = useSelector((state) => state.signupFlowData.signupPagesData);

  const LoadingScreen = () => {
    return (
      <LoadingPage
        callback={() => history.replace("/physical-health")}
        heading={sexPageData?.loadingScreen.title ?? ""}
        text={sexPageData?.loadingScreen.body ?? ""}
      />
    );
  };

  const PregnancyIneligibleScreen = () => {
    return (
      <IneligibleScreen
        title="Ineligible pregnant | Eligibility checker | Sign up | Gloji"
        heading={signupPagesData?.sex.ineligibleScreens?.ineligiblePregnancyStatus?.title}
        bodyText={signupPagesData?.sex.ineligibleScreens?.ineligiblePregnancyStatus?.body}
        hasContactUs={true}
      />
    );
  };

  return (
    <Switch>
      <Route exact path={`${path}`} component={SexChecker} />
      <Route exact path={`${path}/pregnancy`} component={PregnancyChecker} />
      <Route path={`${path}/pregnancy/fail`} component={PregnancyIneligibleScreen} />
      <Route path={`${path}/loading`} component={LoadingScreen} />
    </Switch>
  );
};

export default SexCheckerRoot;
