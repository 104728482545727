//used in old profile builder flow, will be deperecated soon.
const Ethnicity = {
  UNDEFINED: "Undefined",

  WHITE_BRITISH: "WhiteBritish",
  WHITE_IRISH: "WhiteIrish",
  WHITE_GYPSY_OR_TRAVELER: "WhiteGypsyOrTraveler",
  WHITE_OTHER: "WhiteOther",

  MIXED_WHITE_BLACK_CARIBBEAN: "MixedWhiteAndBlackCaribbean",
  MIXED_WHITE_BLACK_AFRICAN: "MixedWhiteAndBlackAfrican",
  MIXED_WHITE_ASIAN: "MixedWhiteAndAsian",
  MIXED_OTHER_BACKGROUND: "MixedOtherOrMultipleEthnicBackground",

  ASIAN_INDIAN: "AsianIndian",
  ASIAN_PAKISTANI: "AsianPakistani",
  ASIAN_BANGLADESHI: "AsianBangladeshi",
  ASIAN_CHINESE: "AsianChinese",
  ASIAN_JAPANESE: "AsianJapanese",
  ASIAN_OTHER: "AsianOther",

  BLACK_AFRICAN: "BlackAfrican",
  BLACK_CARIBBEAN: "BlackCaribbean",
  BLACK_BRITISH: "BlackBritish",
  BLACK_OTHER: "BlackOther",

  ARAB: "Arab",

  OTHER: "Other",
};

//used in eligibility checker self referral flow
const EthnicityOptionsValues = {
  WHITEBRITISH: "whiteBritish",
  WHITEIRISH: "whiteIrish",
  WHITEOTHER: "whiteOther",
  MIXEDWHITEANDBLACKCARIBBEAN: "mixedWhiteAndBlackCaribbean",
  MIXEDWHITEANDBLACKAFRICAN:"mixedWhiteAndBlackAfrican",
  MIXEDWHITEANDASIAN:"mixedWhiteAndAsian",
  MIXEDOTHER:"mixedOther",
  ASIANINDIAN:"asianIndian",
  ASIANPAKISTANI:"asianPakistani",
  ASIANBANGLADESHI: "asianBangladeshi",
  ASIANBRITISHOTHER:"asianBritishOther",
  BLACKCARIBBEAN:"blackCaribbean",
  BLACKAFRICAN:"blackAfrican",
  BLACKBRITISH:"blackBritish",
  OTHERCHINESE:"otherChinese",
  OTHER:"other",
};

export default Ethnicity;

export const ethnicityOptions = [
  { value: EthnicityOptionsValues.WHITEBRITISH, label: "White: British" },
  { value: EthnicityOptionsValues.WHITEIRISH, label: "White: Irish" },
  { value: EthnicityOptionsValues.WHITEOTHER, label: "White: Any other White background" },
  { value: EthnicityOptionsValues.MIXEDWHITEANDBLACKCARIBBEAN, label: "Mixed: White and Black Caribbean" },
  { value: EthnicityOptionsValues.MIXEDWHITEANDBLACKAFRICAN, label: "Mixed: White and Black African" },
  { value: EthnicityOptionsValues.MIXEDWHITEANDASIAN, label: "Mixed: White and Asian" },
  { value: EthnicityOptionsValues.MIXEDOTHER, label: "Mixed: Any other mixed background"},
  { value: EthnicityOptionsValues.ASIANINDIAN, label: "Asian or Asian British: Indian" },
  { value: EthnicityOptionsValues.ASIANPAKISTANI, label: "Asian or Asian British: Pakistani" },
  { value: EthnicityOptionsValues.ASIANBANGLADESHI, label: "Asian or Asian British: Bangladeshi" },
  { value: EthnicityOptionsValues.ASIANBRITISHOTHER, label: "Asian or Asian British: Any other Asian background" },
  { value: EthnicityOptionsValues.BLACKCARIBBEAN, label: "Black or Black British: Caribbean" },
  { value: EthnicityOptionsValues.BLACKAFRICAN, label: "Black or Black British: African" },
  { value: EthnicityOptionsValues.BLACKBRITISH, label: "Black or Black British: Any other Black background" },
  { value: EthnicityOptionsValues.OTHERCHINESE, label: "Other Ethnic Groups: Chinese" },
  { value: EthnicityOptionsValues.OTHER, label: "Other Ethnic Groups: Any other ethnic group" },
];

export const BAME = [
  EthnicityOptionsValues.MIXEDWHITEANDBLACKCARIBBEAN,
  EthnicityOptionsValues.MIXEDWHITEANDBLACKAFRICAN,
  EthnicityOptionsValues.MIXEDWHITEANDASIAN,
  EthnicityOptionsValues.MIXEDOTHER,
  EthnicityOptionsValues.ASIANINDIAN,
  EthnicityOptionsValues.ASIANPAKISTANI,
  EthnicityOptionsValues.ASIANBANGLADESHI,
  EthnicityOptionsValues.ASIANBRITISHOTHER,
  EthnicityOptionsValues.BLACKCARIBBEAN,
  EthnicityOptionsValues.BLACKAFRICAN,
  EthnicityOptionsValues.BLACKBRITISH,
  EthnicityOptionsValues.OTHERCHINESE,
  EthnicityOptionsValues.OTHER,
];

export const NONBAME = [
  EthnicityOptionsValues.WHITEBRITISH,
  EthnicityOptionsValues.WHITEIRISH,
  EthnicityOptionsValues.WHITEOTHER,
];
