const MotivationStatement = {
  UNDEFINED: "Undefined",
  READY_TO_LOSE_WEIGHT: "ReadyToLoseWeight",
  I_AM_HAPPY_AS_I_AM: "IamHappyAsIam",
  NOT_READY_TO_LOSE_WEIGHT: "NotReadyToLoseWeight",
  HAPPY_AND_WEIGHT_NO_CONCERN_TO_ME: "HappyAndWeightNoConcernToMe",
  NOT_SURE_WHY_SERVICE_IS_BEING_REFERRED_TO_ME: "NotSureWHyServiceIsBeingReferredToMe",
};

export default MotivationStatement;
