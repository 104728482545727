import { useState } from "react";
import { IconButton, Drawer } from "@material-ui/core";
import { ReactComponent as HamburgerMenuIcon } from "../../../assets/img/icons/hamburgerMenu.svg";
import { ReactComponent as CloseIcon } from "../../../assets/img/icons/close.svg";
import MobileNavLinks from "./MobileNavLinks";
import MobileNavPolicyLinks from "./MobilePolicyLinks";

const MobileMenu = ({ navLinks, policyLinks }) => {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const mobileMenuIconToggle = (open) => {
    return (
      <div className="mobile-menu__icon-container">
        {open ? (
          <div className="icon mobile-menu__icon-close" onClick={handleDrawerClose}>
            <CloseIcon /> <span className="text mobile-menu__icon-close-text"> close</span>
          </div>
        ) : (
          <div className="icon mobile-menu__icon-menu" onClick={handleDrawerOpen}>
            <HamburgerMenuIcon />
            <span className="text mobile-menu__icon-menu-text"> menu</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="mobile-menu__icon-button-container header-item__container mobile">
      <IconButton className="mobile-menu__icon-button" aria-label="open menu" edge="end">
        {mobileMenuIconToggle(open)}
      </IconButton>

      <Drawer anchor="right" open={open} className="mobile-menu__drawer-container">
        <MobileNavLinks mobileNavItems={navLinks} onClick={handleDrawerClose} />
        <MobileNavPolicyLinks mobileNavPolicyItems={policyLinks} onClick={handleDrawerClose} />
      </Drawer>
    </div>
  );
};
export default MobileMenu;
