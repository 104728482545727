import React from "react";
import VideoCard from "../../molecules/VideoCard";
import { Grid } from "@material-ui/core";
import slugConverter from "../../../utils/SlugConverter";
import "./VideoCards.scss";

const VideoCards = ({ data, additionalData }) => {
  const cardCountClassName =
    data.length === 1 ? "video-cards__container--single" : "video-cards__container--multiple";

  const stepSlug = slugConverter.toSlug(additionalData.stepTitle);
  const Cards = data.map((item) => {
    const videoLocation = {
      pathname: `/my-path/${additionalData.programmeSlug}/${stepSlug}/${additionalData.partSlugShort}/${additionalData.partSlug}/${item.slug}`,
      state: {
        playbackId: `${item.video.videoDetails.playbackId}`,
        thumbnailUrl: item.video.videoDetails.thumbnailUrl,
        videoId: item.video.videoId,
        videoTitle: item.video.title,
        partTitle: additionalData.partTitle,
        stepTitle: additionalData.stepTitle,
        programmeSlug: additionalData.programmeSlug,
        videoProgress: item.video.progress,
        videoWatched: item.video.videoWatched,
        sectionNumber: item.sectionNumber,
      },
    };
    return (
      <Grid item xs={12} sm={6} md={4} lg={4} key={item.title}>
        <VideoCard
          cardTitle={item.title}
          imgUrl={
            item.video.videoDetails.thumbnailUrl ??
            `https://image.mux.com/${item.video.videoDetails.playbackId}/thumbnail.jpg?width=450&time=9`
          }
          watched={item.video.videoWatched}
          isLocked={item.video.locked}
          progress={item.video.progress}
          videoDuration={item.video.videoLengthInSeconds}
          videoLocation={videoLocation}
          sectionId={item.sectionId}
        />
      </Grid>
    );
  });

  return (
    <Grid container className={`video-cards__container ${cardCountClassName}`} spacing={4}>
      {Cards}
    </Grid>
  );
};

export default VideoCards;
