import React from "react";
import PropTypes from "prop-types";

function EditIcon({ color, size, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 19.549 19.549"
      {...props}
    >
      <g data-name="Group 3625" transform="translate(-156 -823)">
        <path
          data-name="Path 2020"
          d="M36.377,27.93h1.862v6.827a2.8,2.8,0,0,1-2.793,2.793H21.793A2.8,2.8,0,0,1,19,34.756V21.1a2.8,2.8,0,0,1,2.793-2.793h6.827v1.862H21.793a.932.932,0,0,0-.931.931V34.756a.932.932,0,0,0,.931.931H35.446a.932.932,0,0,0,.931-.931Zm-12.139-.038,9-9a3.125,3.125,0,0,1,4.419,4.419l-9,9a.93.93,0,0,1-.658.273H24.9a.931.931,0,0,1-.931-.931v-3.1A.93.93,0,0,1,24.238,27.892ZM34.552,20.21l-.893.893,1.787,1.787L36.339,22a1.264,1.264,0,0,0-1.787-1.787ZM25.827,30.722h1.786l6.516-6.516-1.786-1.787-6.516,6.516Z"
          transform="translate(137 805)"
          fill={color}
        ></path>
      </g>
    </svg>
  );
}

EditIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

EditIcon.defaultProps = {
  color: "#121e59",
  size: 19.549,
};

export default EditIcon;
