import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { words } from "@alduino/humanizer/number";
import ProgrammeStates from "../../../enums/programmeStates";
import CurrentStepCardWrapper from "./CurrentStepCardWrapper";
import CompletedProgrammeCard from "../CompletedProgrammeCard/CompletedProgrammeCard";
import CompletedAvailableCard from "../CompletedAvailableCard";

import "./CurrentStepCard.scss";

const CardWrapper = ({ step, programme }) => {
  const subtitle =
    step?.stepNumber > 0 ? `${step?.stepTitle} - Part ${words(step?.partNumber)}` : "Intro Video";

  return (
    <CurrentStepCardWrapper
      step={step}
      programme={programme}
      cardSubtitle={subtitle}
      cardTitle={step?.partTitle}
      description={step?.partWhatIsCovered ?? ""}
      imgUrl={
        step?.video?.thumbnail?.file?.url ??
        `https://image.mux.com/${step?.video?.videoDetails?.playbackId}/thumbnail.jpg?width=450&time=9`
      }
      imgTitle={step?.video?.videoDetails?.title}
    />
  );
};

CardWrapper.propTypes = {
  step: PropTypes.object.isRequired,
  programme: PropTypes.object,
};

const CurrentStepCard = () => {
  const overview = useSelector((state) => state.programmeOverview);

  return (
    <>
      {overview?.data?.state === ProgrammeStates.COMPLETE ? (
        <CompletedProgrammeCard />
      ) : overview?.data?.state === ProgrammeStates.WAITING_UNLOCK ? (
        <CompletedAvailableCard />
      ) : overview?.data?.state === ProgrammeStates.CONTENT_AVAILABLE &&
        overview?.data?.nextAction !== null &&
        overview?.data?.programme !== null &&
        !overview?.loading &&
        !overview?.errorStatus ? (
        <CardWrapper step={overview.data.nextAction} programme={overview.data.programme} />
      ) : (
        <></>
      )}
    </>
  );
};

export default CurrentStepCard;
