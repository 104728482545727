import { useRouteMatch, Switch, Route } from "react-router";
import PostcodeChecker from "./PostcodeChecker";
import { useSelector } from "react-redux";
import { IneligibleScreen, EligibleScreen } from "../ResultScreens";
import { useHistory } from "react-router-dom";
import HeroImage from "../../../../assets/img/sara.png";

const PostcodeCheckerRoot = (props) => {
  let { path } = useRouteMatch();
  const history = useHistory();

  const postcodePageData = useSelector((state) => state.signupFlowData.signupPagesData?.postcode);

  const PostcodeIneligibleScreen = () => {
    return (
      <IneligibleScreen
        title="Ineligible postcode | Eligibility checker | Sign up | Gloji"
        heading={postcodePageData?.ineligibleScreens?.ineligiblePostcode?.title}
        bodyText={postcodePageData?.ineligibleScreens?.ineligiblePostcode?.body}
        hasContactUs={true}
      />
    );
  };

  const NoPlacesAvailableScreen = () => {
    return (
      <IneligibleScreen
        title="No Places Available | Eligibility Checker | Sign Up | Gloji"
        heading={postcodePageData?.ineligibleScreens?.noSpacesAvailable.title}
        bodyText={postcodePageData?.ineligibleScreens?.noSpacesAvailable.body}
        hasContactUs={false}
      />
    );
  };

  const PostcodeEligibleScreen = () => {
    return (
      <EligibleScreen
        title="Eligible postcode | Eligibility checker | Sign up | Gloji"
        heading={postcodePageData.successScreen.title}
        bodyText={postcodePageData.successScreen.body}
        goToNextFunc={() => {
          history.push("/age");
        }}
        heroImageUrl={HeroImage}
        altText="Sara, gloji guide"
      />
    );
  };

  return (
    <>
      <Switch>
        <Route exact path={`${path}`} component={PostcodeChecker} />
        <Route path={`${path}/fail`} component={PostcodeIneligibleScreen} />
        <Route path={`${path}/no-places-available`} component={NoPlacesAvailableScreen} />
        <Route path={`${path}/success`} component={PostcodeEligibleScreen} />
      </Switch>
    </>
  );
};

export default PostcodeCheckerRoot;
