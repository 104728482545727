import React from "react";
import { Route } from "react-router";
import PolicyDocument from "../pages/PolicyDocument";

/**
 * Defines all policy documents routes to be handle by react-router.
 * @returns {ReactChildren} - React Children containing all policy documents routes.
 */
function PolicyDocumentRoutes() {
  return (
    <>
      <Route path="/about" component={PolicyDocument} />
      <Route path="/contact" component={PolicyDocument} />
      <Route path="/accessibility" component={PolicyDocument} />
      <Route path="/terms-and-conditions" component={PolicyDocument} />
      <Route path="/privacy" component={PolicyDocument} />
    </>
  );
}

export default PolicyDocumentRoutes;
