import React from "react";
import { Pill } from "../../atoms/Pills";
import { GREEN } from "../../../enums/brandColors";
import PropTypes from "prop-types";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const RecipePills = ({ dietTypes, allergens }) => {
  const dietTypePills =
    dietTypes.length !== 0
      ? dietTypes.map((item) => {
          return (
            <Pill key={item} className="pill" label={item} pillColor={GREEN} textColor="#FFF7B9" />
          );
        })
      : null;

  const allergensPills =
    allergens.length !== 0
      ? allergens.map((item) => {
          return (
            <Pill
              key={item}
              className="pill"
              label={item}
              pillColor="#364178"
              textColor="#FFF7B9"
              icon={<ErrorOutlineIcon style={{ color: "#FFF7B9" }} />}
            />
          );
        })
      : null;

  return (
    <>
      <div>{dietTypePills}</div>
      <div>{allergensPills}</div>
    </>
  );
};

RecipePills.propTypes = {
  dietTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  allergens: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RecipePills;
