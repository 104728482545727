import React, { useRef, useState } from "react";
import { Form, Formik } from "formik";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import CircularLoader from "../../../atoms/Loaders/CircularLoader";
import "./MultipleQuestionsForm.scss";

/**
 * MultipleQuestionsFormikForm is a single Formik instance whose children are each step of the
 * multi-step form. The form can only
 * progress with valid input, whereas a backwards step is allowed with
 * incomplete data. A snapshot of form state is used as initialValues after each
 * transition. Each page has an optional submit handler, and the top-level
 * submit is called when the final page is submitted.
 * @param {React.ReactChildren} children - The form steps passed in to the component as children.
 * @param {Object} initialValues - Initial form values from all steps.
 * @param {Function} onSubmit - The submit function for the final step.
 * @param {Function} onClose - A close function to exit the form.
 * @param {boolean} validateOnChange - A flag indicating whether to run validations on change events.
 */
function MultipleQuestionsFormikForm({
  children,
  initialValues,
  onSubmit,
  onClose,
  validateOnChange = false,
  handleWatchAgain,
  isStep0,
}) {
  const [currentStep, setCurrentStep] = useState(0);
  const [snapshot, setSnapshot] = useState();

  const steps = React.Children.toArray(children);
  const step = steps[currentStep];
  const totalSteps = steps.length;
  const isLastStep = currentStep === totalSteps - 1;

  const multipleQuestionsFormRef = useRef(null);

  useState(() => {
    setSnapshot(initialValues);
  }, []);

  const next = (values) => {
    var nextStep = Math.min(currentStep + 1, totalSteps - 1);
    setSnapshot(values);
    setCurrentStep(nextStep);
  };

  // const previous = (values) => {
  //   var nextStep = Math.max(currentStep - 1, 0);
  //   setSnapshot(values);
  //   setCurrentStep(nextStep);
  // };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      onSubmit(values, actions);
    } else {
      next(values);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <div container className="form__container">
      {steps.length > 1 && (
        <div className="stepper-container">
          <div className="stepper-wrapper">
            {steps.map((item, index) => {
              const width = 100 / steps.length;
              return (
                <div
                  className={`stepper-item ${currentStep === index && "active"}`}
                  style={{ width: `${width}%`, color: "red" }}
                ></div>
              );
            })}
          </div>
        </div>
      )}
      <Formik
        initialValues={snapshot}
        onSubmit={handleSubmit}
        validationSchema={step.props.validationSchema}
        validateOnChange={validateOnChange}
        innerRef={multipleQuestionsFormRef}
      >
        {({ isSubmitting, values, actions }) => (
          <Form id={"programmeQuestionsForm"}>
            {step}
            <div className="form-footer">
              <div className="buttons__container">
                <div>
                  {/* Back button */}
                  {/* {currentStep > 0 ? (
                    <Button
                      color="tertiary"
                      type="button"
                      arial-label={"Go to prevoious step"}
                      disabled={isSubmitting}
                      onClick={() => previous(values)}
                    >
                      Go Back
                    </Button>
                  ) : null} */}
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    className="lets-carry-on-button"
                    aria-label={isLastStep ? "submit answers" : "continue"}
                  >
                    {isLastStep ? (isStep0 === true ? "All done! start my programme" : "Let's carry on" ) : "Continue"}
                  </Button>
                </div>
                <Button
                  variant="contained"
                  color="secondary"
                  className="watch-again-button"
                  onClick={handleWatchAgain}
                >
                  Watch again
                </Button>
              </div>
            </div>
            {isSubmitting && <CircularLoader />}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default MultipleQuestionsFormikForm;
