import React from "react";
import PropTypes from "prop-types";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { withStyles } from "@material-ui/core/styles";
import { Link as MuiLink } from "@material-ui/core";

const ArrowBack = withStyles({
  root: {
    fontSize: "inherit",
  },
})(ArrowBackIosIcon);

const BackButton = ({ btnText, className, color, ariaLabel, onClick, ...rest }) => {
  return (
    <>
      <MuiLink
        component="button"
        type="button"
        className={`back-button ${className || ""}`}
        color={color}
        aria-label={ariaLabel}
        onClick={onClick}
        {...rest}
      >
        <ArrowBack role="presentation" className="icon" />
        {btnText}
      </MuiLink>
    </>
  );
};

BackButton.propTypes = {
  btnText: PropTypes.string.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  ariaLabel: PropTypes.string,
  onClick: PropTypes.func,
};

export default BackButton;
