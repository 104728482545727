import { useState, useEffect } from "react";
import { urls } from "../../configs/serviceConfig";
import axios from "axios";
import { IsValueEmpty } from "../../utils/IsEmpty";
import { logException } from "../../utils/AppInsightsLogging";

const useEmailVerificationService = (verificationKey) => {
  const [response, setResponse] = useState({
    data: [],
    loading: false,
    errorStatus: false,
    error: null,
  });

  const url = `${urls.profileService}/email-verification/validate`;

  const emailVerification = async () => {
    setResponse({ ...response, loading: true });
    await axios
      .post(url, {
        verificationKey: verificationKey,
      })
      .then((res) => {
        setResponse({ data: res.data, loading: false, errorStatus: false, error: null });
      })
      .catch((error) => {
        console.log(error);
        logException(error);
        setResponse({ data: [], loading: false, errorStatus: true, error: error });
      });
  };

  useEffect(() => {
    !IsValueEmpty(verificationKey) && emailVerification();
  }, [verificationKey]);

  return response;
};
export default useEmailVerificationService;
