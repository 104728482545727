import React, { useState } from "react";
import {
  Grid,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Typography,
  FormHelperText,
} from "@material-ui/core";
import { TextField, RadioGroup } from "formik-material-ui";
import { ErrorMessage, useFormikContext } from "formik";
import { Gender } from "../../../enums/profile";
import Truthy from "../../../enums/Truthy";
import HeightCompositeInput from "../HeightCompositeInput";
import WeightCompositeInput from "../WeightCompositeInput";
import { CustomField } from "../../atoms/InputField";
import { NavyRadio } from "../../atoms/Radio";
import { ScrollTo } from "../../atoms/ScrollTo";

const PersonalDetailsForm = () => {
  const { values, setFieldValue } = useFormikContext();
  const [birthSex, setBirthSex] = useState("");
  const [genderIsBirthSex, setGenderIsBirthSex] = useState("");

  React.useEffect(() => {
    if (values.birthSex === Gender.MALE) {
      setFieldValue("isPregnant", "");
      setFieldValue("isBreastFeeding", "");
    }
    setBirthSex(values.birthSex);
  }, [setFieldValue, values.birthSex]);

  React.useEffect(() => {
    if (values.genderIsBirthSex === Truthy.TRUE) {
      setFieldValue("gender", "");
    }
    setGenderIsBirthSex(values.genderIsBirthSex);
  }, [setFieldValue, values.genderIsBirthSex]);

  return (
    <Grid container spacing={2}>
      <ScrollTo />
      <Grid item xs={12} sm={12}>
        <Typography className="info-text" variant="h2" align="center">
          Tell us a little bit about yourself
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomField
          fullWidth
          variant="outlined"
          id="phoneNumber"
          name="phoneNumber"
          component={TextField}
          label="What is your phone number?"
          placeholder="07890000000"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomField
          fullWidth
          variant="outlined"
          type="date"
          id="dateOfBirth"
          name="dateOfBirth"
          component={TextField}
          placeholder="dd/mm/yyyy"
          label="What is your date of birth?"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormGroup>
          <FormLabel component="legend">What is your birth sex?</FormLabel>
          <CustomField variant="outlined" id="birthSex" name="birthSex" component={RadioGroup}>
            <FormControlLabel
              control={<NavyRadio name="birthSex" value={Gender.MALE} />}
              label={Gender.MALE}
              onClick={() => setBirthSex(Gender.MALE)}
            />
            <FormControlLabel
              control={<NavyRadio name="birthSex" value={Gender.FEMALE} />}
              label={Gender.FEMALE}
              onClick={() => setBirthSex(Gender.FEMALE)}
            />
          </CustomField>
          <ErrorMessage className="Mui-error" component={FormHelperText} name="birthSex" />
        </FormGroup>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormGroup>
          <FormLabel component="legend">Is your gender same as your birth sex?</FormLabel>
          <CustomField
            variant="outlined"
            id="genderIsBirthSex"
            name="genderIsBirthSex"
            component={RadioGroup}
          >
            <FormControlLabel
              control={<NavyRadio />}
              value={Truthy.TRUE}
              label={Truthy.YES}
              onClick={() => setGenderIsBirthSex(Truthy.TRUE)}
            />
            <FormControlLabel
              control={<NavyRadio />}
              value={Truthy.FALSE}
              label={Truthy.NO}
              onClick={() => setGenderIsBirthSex(Truthy.FALSE)}
            />
          </CustomField>
          <ErrorMessage className="Mui-error" component={FormHelperText} name="genderIsBirthSex" />
        </FormGroup>
      </Grid>
      {genderIsBirthSex === Truthy.FALSE ? (
        <Grid item xs={12} sm={12}>
          <CustomField
            fullWidth
            variant="outlined"
            id="gender"
            name="gender"
            component={TextField}
            label="What is your gender?"
          />
        </Grid>
      ) : null}

      {birthSex === Gender.FEMALE ? (
        <>
          <Grid item xs={12} sm={6}>
            <FormLabel component="legend">Are you pregnant?</FormLabel>
            <CustomField
              variant="outlined"
              id="isPregnant"
              name="isPregnant"
              component={RadioGroup}
            >
              <FormControlLabel value={Truthy.TRUE} control={<NavyRadio />} label={Truthy.YES} />
              <FormControlLabel value={Truthy.FALSE} control={<NavyRadio />} label={Truthy.NO} />
            </CustomField>
            <ErrorMessage className="Mui-error" component={FormHelperText} name="isPregnant" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel component="legend">Are you breastfeeding?</FormLabel>
            <CustomField
              variant="outlined"
              id="isBreastFeeding"
              name="isBreastFeeding"
              component={RadioGroup}
            >
              <FormControlLabel value={Truthy.TRUE} control={<NavyRadio />} label={Truthy.YES} />
              <FormControlLabel value={Truthy.FALSE} control={<NavyRadio />} label={Truthy.NO} />
            </CustomField>
            <ErrorMessage className="Mui-error" component={FormHelperText} name="isBreastFeeding" />
          </Grid>
        </>
      ) : null}
      <Grid item xs={12} sm={6}>
        <CustomField
          fullWidth
          variant="outlined"
          id="weight"
          name="weight"
          component={WeightCompositeInput}
          type="number"
          label="What is your weight?"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomField
          fullWidth
          variant="outlined"
          id="height"
          name="height"
          component={HeightCompositeInput}
          type="number"
          label="What is your height?"
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <CustomField
          fullWidth
          variant="outlined"
          id="addressLine1"
          name="addressLine1"
          component={TextField}
          label="The first line of your address"
          placeholder="Address line 1"
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <CustomField
          fullWidth
          variant="outlined"
          id="addressLine2"
          name="addressLine2"
          component={TextField}
          label="The second line of your address"
          placeholder="Address line 2"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomField
          fullWidth
          variant="outlined"
          id="town"
          name="town"
          component={TextField}
          label="The town or city you live in"
          placeholder="Town or city name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomField
          fullWidth
          variant="outlined"
          id="county"
          name="county"
          component={TextField}
          label="The county you live in"
          placeholder="County name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomField
          fullWidth
          variant="outlined"
          id="postcode"
          name="postcode"
          component={TextField}
          label="What is your postcode?"
          placeholder="Postcode"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomField
          fullWidth
          variant="outlined"
          id="country"
          name="country"
          component={TextField}
          label="What country do you live in?"
          value="United Kingdom"
          readOnly={true}
          disabled
        />
      </Grid>
    </Grid>
  );
};

export default PersonalDetailsForm;
