import { FormHelperText } from "@material-ui/core";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { howHeardOptions } from "../../../../enums/profile/HowHeard";
import { update_signup_form_values } from "../../../../store/actions/signupFlowData";
import CheckboxGroupContainer from "../../../atoms/CheckboxGroupContainer";
import FormikInputOnly from "../../../atoms/formik-input-only";
import FormikCheckbox from "../../../atoms/FormikCheckbox";
import { FormikField } from "../../../atoms/FormikField";
import { MultiStep } from "../../../molecules/MultiStep";
import useUserId from "../../../hooks/useUserId";
import {
  ModalPageBody,
  ModalPageFooter,
  ModalPageHeader,
  ModalPageHeading,
  ModalPageWrapper,
} from "../../../organisms/ModalPage";
import { LoadingPage } from "../../EligibilityChecker/LoadingPage";
import { mapProfileBuilderFormValues, questionStepperList } from "../helpers";
import { HowHeardValidationSchema } from "../ProfileBuilderValidation";
import { profileService } from "../../../services";

const HowHeard = () => {
  const dispatch = useDispatch();

  const userId = useUserId();
  const signupFormValues = useSelector((state) => state.signupFlowData.signupFormValues);
  const storeHowHeardValue = useSelector(
    (state) => state.signupFlowData.signupFormValues?.howHeard
  );
  const storeHowHeardOtherValue = useSelector(
    (state) => state.signupFlowData.signupFormValues?.howHeardOther
  );
  const howHeardPageData = useSelector(
    (state) => state.signupFlowData.signupPagesData?.["how-heard"]
  );

  const history = useHistory();
  const { path } = useRouteMatch();

  const [showLoadingPage, setShowLoadingPage] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  const initialValues = {
    howHeard: storeHowHeardValue ?? [],
    howHeardOther: storeHowHeardOtherValue ?? "",
  };

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "How heard | Profile builder | Sign up | Gloji",
    });
  }, []);

  const handleSubmit = async (values) => {
    dispatch(update_signup_form_values(values));
    setShowLoadingPage(true);
    setHasErrors(false);
    let payload = mapProfileBuilderFormValues({ ...signupFormValues, ...values });
    let response = await profileService.updateUserProfile(userId, payload);
    if (response.status === 201) {
      setHasErrors(false);
    } else {
      setHasErrors(true);
    }
  };

  return (
    <>
      {showLoadingPage ? (
        <LoadingPage
          callback={() => {
            if (hasErrors) {
              history.push(`${path}/fail`);
            } else {
              // Navigate to next screen
              history.push("/ready-set-go");
            }
          }}
          heading={howHeardPageData?.loadingScreen.title ?? ""}
          text={howHeardPageData?.loadingScreen.body ?? ""}
        />
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={HowHeardValidationSchema}
          validateOnChange={true}
        >
          {({ isSubmitting, values, touched, errors }) => {
            return (
              <Form className="profile-builder-form">
                <Helmet>
                  <title>How heard | Profile builder | Sign up | Gloji</title>
                </Helmet>
                <ModalPageWrapper>
                  <ModalPageBody className="with-sticky-footer">
                    <ModalPageHeader
                      className="modal-header_center"
                      backButtonOnClick={() => {
                        history.goBack();
                      }}
                    >
                      Profile
                    </ModalPageHeader>

                    <MultiStep step={3} list={questionStepperList} percent={60} />

                    <ModalPageHeading>{howHeardPageData?.questions[0].question}</ModalPageHeading>
                    <CheckboxGroupContainer name="howHeard">
                      {howHeardOptions.map((item) => (
                        <FormikCheckbox
                          key={item.value}
                          id={item.value}
                          name="howHeard"
                          label={item.label}
                          value={item.value}
                        />
                      ))}
                    </CheckboxGroupContainer>
                    {values["howHeard"].includes("Other") && (
                      <>
                        <FormikField
                          className="profile-builder__text-input"
                          id="howHeardOther"
                          name="howHeardOther"
                          component={FormikInputOnly}
                          placeholder="Other...."
                        />
                        <ErrorMessage
                          className="Mui-error"
                          component={FormHelperText}
                          name="howHeardOther"
                        />
                      </>
                    )}
                  </ModalPageBody>
                  <ModalPageFooter className="sticky-footer">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Continue
                    </Button>
                  </ModalPageFooter>
                </ModalPageWrapper>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default HowHeard;
