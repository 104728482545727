import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NAVY } from "../../../enums/brandColors";
import { Field } from "formik";

const useStyles = makeStyles((theme) => ({
  root: {},
  textField: {
    "& .MuiFormLabel-root": {
      fontSize: "0.85rem",
      color: `${NAVY}`,
      position: "relative",
      marginBottom: "0.5rem",
    },
    "& .MuiInputLabel-outlined": {
      transform: "none",
    },
    "& legend": {
      display: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      top: "0px",
    },
  },
}));

const CustomField = (props) => {
  const classes = useStyles();

  return (
    <Field
      fullWidth
      className={`${classes.textField} ${props.className ? props.className : ""}`}
      variant="outlined"
      type={props.type}
      id={props.id}
      name={props.name}
      label={props.label}
      placeholder={props.placeholder}
      component={props.component}
      helperText={props.helperText}
      InputLabelProps={{
        shrink: true,
      }}
      {...props}
    >
      {props.children}
    </Field>
  );
};
export default CustomField;
