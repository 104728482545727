import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  FormHelperText,
  InputLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { update_signup_form_values } from "../../../../store/actions/signupFlowData";
import FormikInputOnly from "../../../atoms/formik-input-only";
import { FormikField } from "../../../atoms/FormikField";
import { FormikNumberInput } from "../../../atoms/FormikInput";
import Toast from "../../../atoms/Toast";
import {
  ModalPageBody,
  ModalPageFooter,
  ModalPageHeader,
  ModalPageHeading,
  ModalPageWrapper,
} from "../../../organisms/ModalPage";
import { referralService, tokenService } from "../../../services";
import { ContactDetailsValidationSchema } from "../EligibilityCheckerValidation";
import { logException } from "../../../../utils/AppInsightsLogging";

const ContactDetails = () => {
  const dispatch = useDispatch();
  const signupFormValues = useSelector((state) => state.signupFlowData.signupFormValues);
  const contactPageData = useSelector(
    (state) => state.signupFlowData.signupPagesData?.["contact-details"]
  );

  let history = useHistory();
  let { path } = useRouteMatch();
  const [errorMessage, setErrorMessage] = useState("");

  const initialValues = {
    phoneNumber: signupFormValues?.phoneNumber ?? "",
    email: signupFormValues?.email ?? "",
    emailConfirmation: signupFormValues?.email ?? "",
  };

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Contact details | Eligibility checker | Sign up | Gloji",
    });
  }, []);

  const handleSubmit = async (values) => {
    // Save values to store
    dispatch(update_signup_form_values(values));

    try {
      const token = await tokenService.getCsrfToken(values.email);

      const { referralExists, profileExists, validRequest } =
        await referralService.checkReferralExists(values.email, token);

      if (!validRequest) {
        history.push(`${path}/unauthorised`);
      } else if (profileExists) {
        history.push(`${path}/existing-user`);
      } else if (referralExists) {
        history.push(`${path}/existing-referral`);
      } else {
        history.push("/keep-in-touch");
      }
    } catch (error) {
      setErrorMessage(error?.message);
      logException(error);
    }
  };

  return (
    <>
      <Toast show={Boolean(errorMessage)} type="error">
        {errorMessage}
      </Toast>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={ContactDetailsValidationSchema}
        validateOnChange={false}
        enableReinitialize={true}
      >
        {({ isSubmitting, values, touched, errors }) => (
          <Form className="eligibility-checker-form">
            <Helmet>
              <title>Contact details | Eligibility checker | Sign up | Gloji</title>
            </Helmet>
            <ModalPageWrapper>
              <ModalPageBody hasStickyFooter={false}>
                <ModalPageHeader
                  backButtonOnClick={() => {
                    history.goBack();
                  }}
                />
                <ModalPageHeading>{contactPageData?.questions[0].question}</ModalPageHeading>
                <FormGroup id="phoneNumber" className="eligibility-checker__form-group">
                  <InputLabel htmlFor="phoneNumber" className="eligibility-checker__input-label">
                    Mobile number
                  </InputLabel>
                  <FormikField
                    id="phoneNumber"
                    className="eligibility-checker__text-input"
                    name="phoneNumber"
                    type="number"
                    component={FormikNumberInput}
                  />
                  <ErrorMessage
                    key="phoneNumber"
                    className="Mui-error"
                    name="phoneNumber"
                    component={FormHelperText}
                  />
                </FormGroup>
                <FormGroup id="email" className="eligibility-checker__form-group">
                  <InputLabel htmlFor="email" className="eligibility-checker__input-label">
                    Enter email address
                  </InputLabel>
                  <FormikField
                    id="email"
                    className="eligibility-checker__text-input"
                    name="email"
                    type="email"
                    component={FormikInputOnly}
                  />
                  <ErrorMessage
                    key="email"
                    className="Mui-error"
                    name="email"
                    component={FormHelperText}
                  />
                </FormGroup>
                <FormGroup id="emailConfirmation">
                  <InputLabel
                    htmlFor="emailConfirmation"
                    className="eligibility-checker__input-label"
                  >
                    Confirm email address
                  </InputLabel>
                  <FormikField
                    id="emailConfirmation"
                    className="eligibility-checker__text-input"
                    name="emailConfirmation"
                    type="email"
                    component={FormikInputOnly}
                  />
                  <ErrorMessage
                    key="emailConfirmation"
                    className="Mui-error"
                    name="emailConfirmation"
                    component={FormHelperText}
                  />
                </FormGroup>
                <Accordion square={true} elevation={0} className="eligibility-checker__accrodion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon fontSize="large" />}
                    aria-controls={`contact-question-explanation`}
                  >
                    <span>{contactPageData?.questions[0].whyQuestion}</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>{contactPageData?.questions[0].whyQuestionDetails}</span>
                  </AccordionDetails>
                </Accordion>
              </ModalPageBody>
              <ModalPageFooter hasStickyFooter={false}>
                <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                  Continue
                </Button>
              </ModalPageFooter>
            </ModalPageWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ContactDetails;
