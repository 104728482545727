/* eslint-disable no-useless-computed-key */
import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { Menu, MenuItem, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { NAVY } from "../../../enums/brandColors";
import { ReactComponent as UserIcon } from "../../../assets/img/icons/headerUserIcon.svg";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPopover-paper": {},
    "& .MuiMenuItem-root": {
      a: {
        textDecoration: "none",
      },
    },
  },
  avatarName: {
    fontFamily: "Poppins",
    fontSize: "0.9rem",
    color: `${NAVY}`,
    paddingLeft: "0.35rem",
  },
}));

const ProfileDropDown = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  let open = Boolean(anchorEl);
  let history = useHistory();

  const handleMenu = (event) => {
    open ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dropdownList =
    props.dropDownItems.length !== 0
      ? props.dropDownItems.map((item) => {
          return (
            <MenuItem onClick={handleClose} key={item.name}>
              <Link to={item.link}> {item.name}</Link>
            </MenuItem>
          );
        })
      : null;

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="profile-options"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        className={`header__user-image-button`}
      >
        <UserIcon className="header__user-image-icon" style={{ color: NAVY, fontSize: 35 }} />
      </IconButton>
      <Menu
        id="profile-options"
        className={`profile-options__container ${classes.root}`}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        onClose={handleClose}
      >
        {dropdownList}
        <MenuItem
          onClick={() => {
            handleClose();
            history.push(props.logOutPath);
          }}
        >
          <Link to={props.logOutPath}> Logout</Link>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileDropDown;
