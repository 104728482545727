import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
    position: "fixed",
    justifyContent: "center",
    top: 0,
    left: 0,
    zIndex: 10000,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(27,30,59,.75)",
  },
  progress: {
    position: "relative",
    top: "50%",
  },
}));

/**
 * A circular loading component used to indicate page loading or busy.
 * @param {Object} props - Properties passed to the component
 * @param {String} props.color - The loader color.
 * @param {String|Number} props.size - The loader size (can be defined as number or string).
 * @param {Object} props.rest - The rest of the properties passed to the component.
 */
function CircularLoader({ color = "primary", size = 40, ...rest }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress
        className={classes.progress}
        color={color}
        size={size}
        {...rest}
        aria-label="Page Loading"
      />
    </div>
  );
}

CircularLoader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rest: PropTypes.object,
};

export default CircularLoader;
