import React from "react";
import { useHistory } from "react-router-dom";
import marked from "marked";
import "./ContentContainer.scss";
import PropTypes from "prop-types";
import { Pill } from "../../atoms/Pills";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { BackButtonLink } from "../../atoms/Button";
import { ARTICLE_TYPES } from "../../../enums/articleTypes";
import { NAVY } from "../../../enums/brandColors";
import { SeperatorWithArrowDown } from "../../atoms/Separator";
import PillGenerator from "../../molecules/PillGenerator";

const useStyles = makeStyles({
  root: (props) => ({
    background: "inherit",
    "& h2": {
      fontSize: "1.5rem",
      color: props.color,
      margin: "25px 0",
    },
    "& h3": {
      fontSize: "1.25rem",
      color: NAVY,
    },
    "& p": {
      fontSize: "1.125rem",
    },
    "& blockquote": {
      backgroundColor: "white",
      padding: "0 20px",
      borderLeft: `10px solid ${props.color}`,
      fontStyle: "italic",
    },
    "& img": {
      width: "100%",
      borderRadius: "10px",
    },
  }),
});

const ContentBody = ({ classes, body }) => {
  return <p className={classes.root} dangerouslySetInnerHTML={{ __html: marked(body) }}></p>;
};

const ContentContainer = ({ title, body, intro, pillars, longerRead, hasTopBar }) => {
  const articleDataPresent = pillars.length !== 0 && ARTICLE_TYPES[pillars[0]] !== undefined;
  let titleBgColor = articleDataPresent ? ARTICLE_TYPES[pillars[0]].color : NAVY;
  let titleColor = articleDataPresent ? NAVY : "white";
  let themeColor = articleDataPresent ? ARTICLE_TYPES[pillars[0]].intenseColor : NAVY;
  const classes = useStyles({ color: themeColor });
  const history = useHistory();

  return (
    <article className="content-container">
      <header className="content-container__header">
        <h1
          className="content-container__title"
          style={{ backgroundColor: titleBgColor, color: titleColor }}
        >
          {title}
        </h1>
      </header>
      {hasTopBar && (
        <div className="content-container__topbar">
          <BackButtonLink
            ariaLabel="Back"
            linkPath=""
            btnText="Back"
            onClick={(e) => {
              e.preventDefault();
              history.goBack();
            }}
          />
          <div className="pills-container">
            <Pill className="pill default" label="Article" />
            {longerRead && <Pill className="pill default" label="Longer read" />}
            <PillGenerator articleTypes={pillars} />
          </div>
        </div>
      )}

      <Grid
        className="content-container__content"
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={9} sm={9} md={9}>
          {intro && (
            <div className="content-container__intro-container">
              <p className="intro-text">{intro}</p>
              <SeperatorWithArrowDown />
            </div>
          )}

          <ContentBody classes={classes} body={body} />
        </Grid>
        <div
          className="bottomColoredBar"
          style={{ backgroundColor: titleBgColor }}
          role="presentation"
        ></div>
      </Grid>
    </article>
  );
};

ContentContainer.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  intro: PropTypes.string,
  pillars: PropTypes.arrayOf(PropTypes.string),
  longerRead: PropTypes.bool,
  hasTopBar: PropTypes.bool.isRequired,
};

export default ContentContainer;
