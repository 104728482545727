import { useState, useEffect } from "react";
import authService from "../auth/AuthorizeService";

/**
 * Custom React hook for using User profile data across multiple components.
 * @returns {Array} [user, isAuthenticated]
 */
export default function useUser() {
  const [user, setUser] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      const user = await authService.getUser();
      setUser(user);
      setIsAuthenticated(!!user);
    };

    getUser();
  }, []);

  return [user, isAuthenticated];
}
