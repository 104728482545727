import PropTypes from "prop-types";
import ListContainer from "../../atoms/ListContainer";
import { NavLink } from "react-router-dom";

const MobileNavPolicyLinks = ({ mobileNavPolicyItems, onClick }) => {
  const mobileNavPolicyItem = mobileNavPolicyItems
    ? mobileNavPolicyItems.map((item) => {
        return (
          <li key={item.name} className="mobile-nav-policy-links__item">
            <NavLink to={`/${item.link}`} exact aria-label={`Visit ${item.name}`} onClick={onClick}>
              {item.name}
            </NavLink>
          </li>
        );
      })
    : null;
  return (
    <div className="mobile-nav-policy-links__container">
      <ListContainer>{mobileNavPolicyItem} </ListContainer>
    </div>
  );
};
MobileNavPolicyLinks.propTypes = {
  mobileNavPolicyItems: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, link: PropTypes.string })
  ),
  onClick: PropTypes.func,
};
export default MobileNavPolicyLinks;
