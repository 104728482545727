import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router";
import { IneligibleScreen } from "../ResultScreens";
import Bmi from "./Bmi";
import BmiEligibleScreen from "./BmiEligibleScreen";

const BmiRoot = () => {
  let { path } = useRouteMatch();
  const BmiPageData = useSelector((state) => state.signupFlowData.signupPagesData?.bmi);

  const BmiTooLow = () => {
    return (
      <IneligibleScreen
        title="Ineligible BMI | Eligibility checker | Sign up | Gloji"
        heading={BmiPageData.ineligibleScreens?.bmiTooLow?.title}
        bodyText={BmiPageData.ineligibleScreens?.bmiTooLow?.body}
        hasContactUs={true}
      />
    );
  };

  const BmiTooHigh = () => {
    return (
      <IneligibleScreen
        title="Ineligible BMI | Eligibility checker | Sign up | Gloji"
        heading={BmiPageData.ineligibleScreens?.bmiTooHigh?.title}
        bodyText={BmiPageData.ineligibleScreens?.bmiTooHigh?.body}
        hasContactUs={true}
      />
    );
  };

  return (
    <>
      <Switch>
        <Route exact path={`${path}`} component={Bmi} />
        <Route path={`${path}/bmitoolow`} component={BmiTooLow} />
        <Route path={`${path}/bmitoohigh`} component={BmiTooHigh} />
        <Route path={`${path}/success`} component={BmiEligibleScreen} />
      </Switch>
    </>
  );
};

export default BmiRoot;
