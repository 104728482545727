export const employmentStatusOptions = [
  { value: "Employed", label: "Employed" },
  { value: "UnemployedSeekingWork", label: "Unemployed and seeking work" },
  { value: "Student", label: "Student" },
  { value: "LongTermSickOrDisabled", label: "Long-term sick or disabled" },
  { value: "UnemployedNotReceivingBenefits", label: "Unemployed and not receiving benefits" },
  { value: "UnpaidVoluntaryWork", label: "Unpaid voluntary work" },
  { value: "Retired", label: "Retired" },
  { value: "Homemaker", label: "Homemaker" },
  { value: "PreferNotToSay", label: "Prefer not to say" },
];
