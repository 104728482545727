import authAxios from "../../helpers/authAxios";
import {
  LOAD_PROGRAMME_OVERVIEW,
  PROGRAMME_OVERVIEW_LOADED,
  LOAD_PROGRAMME_OVERVIEW_FAILED,
} from "../constants";
import { urls } from "../../configs/serviceConfig";
import { logException } from "../../utils/AppInsightsLogging";
import {featureService} from "../../components/services";
import Features from "../../enums/features";

const load_programme_overview = () => {
  return {
    type: LOAD_PROGRAMME_OVERVIEW,
  };
};

const programme_overview_loaded = (payload) => {
  return {
    type: PROGRAMME_OVERVIEW_LOADED,
    payload,
  };
};

const load_programme_overview_failed = (error) => {
  return {
    type: LOAD_PROGRAMME_OVERVIEW_FAILED,
    error,
  };
};

/**
 *
 * @param {{userId: string, programmeId?: string, journeyId?: string, idCollection?: Array<{journeyId: string, programmeId: string}>}} opts
 */
export const get_programme_overview = (opts) => {

    return (dispatch) => {
      featureService.isFeatureEnabled(Features.ARTICLES_MULTIPLE_JOURNEYS)
        .then(f => {
          // Call the new API if the feature flag is enabled
          let url = f
            ? `${urls.programmeService}/v2/user-programme/user/${opts.userId}/overview?ids=${JSON.stringify(opts.idCollection)}`
            : `${urls.programmeService}/user-programme/${opts.programmeId}/user/${opts.userId}/journey/${opts.journeyId}/overview`;

          dispatch(load_programme_overview());

          authAxios.get(url)
            .then((response) => {
              dispatch(programme_overview_loaded(response.data));
            })
            .catch((error) => {
              logException(error);
              dispatch(load_programme_overview_failed(error));
            });
        })
        .catch((error) => {
          logException(error);
          dispatch(load_programme_overview_failed(error));
        });
    };
};
