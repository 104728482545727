import React from "react";
import { Page500 } from "../../pages/Errors";
import { appInsights } from "../../../appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Update state so the next render will show the fallback UI.
    setTimeout(() => {
      this.setState({
        error: error,
        errorInfo: errorInfo,
      });
    }, 5000);

    appInsights.trackException({
      error: error,
      errorInfo: errorInfo,
      severityLevel: SeverityLevel.Error,
    });
  }

  render() {
    if (this.state.error) {
      return <Page500 />;
    }

    return this.props.children;
  }
}
