import post_weight from "./postWeight";
import get_all_weights_reducer from "./getAllWeights";
import load_personalised_content from "./getPersonalisedContent";
import load_content from "./getContent";
import get_programme_step_reducer from "./getProgrammeStep";
import update_current_view_data_reducer from "./updateCurrentViewData";
import post_complete_question_reducer from "./postCompleteQuestion";
import signup_flow_data_reducer from "./signupFlowData";
import load_profile_reducer from "./getProfile";
import get_programme_overview_reducer from "./getProgrammeOverview";
import { programmeApi } from "../../redux/programmeApi";
import { profileApi } from "../../redux/profileApi";
import { referralApi } from "../../redux/referralApi";
import { engagementsApi } from "../../redux/engagementsApi";
import profileBuilderReducer from "../../redux/profileBuilderSlice";

import { configureStore } from "@reduxjs/toolkit";
import { rtkQueryErrorLogger } from "../../redux/middleware/errorLoggingMiddleware";

export const store = configureStore({
  reducer: {
    signupFlowData: signup_flow_data_reducer,
    profile: load_profile_reducer,
    personalisedContent: load_personalised_content,
    content: load_content,
    allWeights: get_all_weights_reducer,
    weightLogging: post_weight,
    programmeStep: get_programme_step_reducer,
    programmeOverview: get_programme_overview_reducer,
    currentViewData: update_current_view_data_reducer,
    completeQuestion: post_complete_question_reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [referralApi.reducerPath]: referralApi.reducer,
    [programmeApi.reducerPath]: programmeApi.reducer,
    [engagementsApi.reducerPath]: engagementsApi.reducer,
    profileBuilder: profileBuilderReducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(programmeApi.middleware)
      .concat(referralApi.middleware)
      .concat(engagementsApi.middleware)
      .concat(profileApi.middleware)
      .concat(rtkQueryErrorLogger),
});
