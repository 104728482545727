import * as yup from "yup";

export const FirstLanguageValidationSchema = yup.object().shape({
  firstLanguage: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required("Please select an option")
    .nullable(),
});

export const ReligionValidationSchema = yup.object().shape({
  religion: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required("Please select an option")
    .nullable(),
});

export const EmploymentStatusValidationSchema = yup.object().shape({
  employmentStatus: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required("Please select an option")
    .nullable(),
});

export const SexualOrientationValidationSchema = yup.object().shape({
  sexualOrientation: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required("Please select an option")
    .nullable(),
});

export const HowHeardValidationSchema = yup.object().shape({
  howHeard: yup
    .array()
    .of(yup.string())
    .min(1, "Please select an option")
    .required("Please select an option"),
  howHeardOther: yup.string().when("howHeard", {
    is: (value) => value.includes("Other"),
    then: yup.string().required("Please enter an answer"),
    otherwise: yup.string().notRequired(),
  }),
});

export const BookAppointmentValidationSchema = yup.object().shape({
  bookingConfirmation: yup
    .boolean()
    .oneOf([true], "Please make sure you've booked your first appointment before continuing"),
});
