import React from "react";

export const SeperatorWithArrowDown = () => {
  return (
    <div>
      <span className="seperator-line__article-intro"> </span>
      <span className="arrow-down1" role="presentation"></span>
      <span className="arrow-down2" role="presentation"></span>
    </div>
  );
};
