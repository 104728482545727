import React from "react";
import { ModalPageBackground } from "../../organisms/ModalPage";
import { MemoryRouter, Switch, Route } from "react-router";
import UserDetails from "./UserDetails/UserDetails";
import { LoadAppointments } from "./LoadAppointments/LoadAppointments";
import { NoAppointments } from "./NoAppointments/NoAppointments";
import BookingCalendar from "./BookingCalendar/BookingCalendar";
import PageBg from "../../../assets/img/bg/eligibilityCheckerBg.png";

const BookAppointmentFlow = () => {
  return (
    <ModalPageBackground className="eligibility-checker-page" bgImage={PageBg}>
      <MemoryRouter>
        <Switch>
          <Route exact path={"/"} component={LoadAppointments} />
          <Route path={"/no-appointments"} component={NoAppointments} />
          <Route path={"/user-details"} component={UserDetails} />
          <Route path={"/book"} component={BookingCalendar} />
        </Switch>
      </MemoryRouter>
    </ModalPageBackground>
  );
};

export default BookAppointmentFlow;
