import React from "react";
import PropTypes from "prop-types";
import "./ContentPageHeroImage.scss";

const ContentPageHeroImage = ({ src, alt, title, className }) => {
  return (
    <img
      className={`content-hero-image ${className || null}`}
      src={`${src}?w=1152`}
      alt={alt}
      title={title}
    />
  );
};

ContentPageHeroImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ContentPageHeroImage;
