import { useMemo, useReducer } from "react";
import PropTypes from "prop-types";

const NEXT = { type: "NEXT" };
const PREVIOUS = { type: "PREVIOUS" };

const pageReducer = (state = { total: 1, page: 1 }, action) => {
  switch (action.type) {
    case "NEXT":
      return state.page === state.total ? state : { ...state, page: state.page + 1 };
    case "PREVIOUS":
      return state.page === 1 ? state : { ...state, page: state.page - 1 };
    default:
      return state;
  }
};

export function useSimplePager(initalState) {
  const [state, dispatch] = useReducer(pageReducer, initalState);
  const pager = useMemo(
    () => ({
      state,
      dispatch,
      NEXT,
      PREVIOUS,
    }),
    [state]
  );

  return pager;
}

useSimplePager.propTypes = {
  initalState: PropTypes.shape({
    total: PropTypes.number,
    page: PropTypes.number,
  }).isRequired,
};
