import authAxios from "../../helpers/authAxios";
import { urls } from "../../configs/serviceConfig";
import { logException } from "../../utils/AppInsightsLogging";

export class ProfileService {
  constructor() {
    this._baseUrl = urls?.profileService;
  }

  async getProfile(userId) {
    const url = `${this._baseUrl}/profile/${userId}`;
    try {
      const response = await authAxios.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      console.error(error);
      logException(error);
      return error.response;
    }
  }

  async postProfile(userId, data = {}) {
    const url = `${this._baseUrl}/profile/${userId}`;
    try {
      const response = await authAxios.post(url, data, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      console.error(error);
      logException(error);
      return error.response;
    }
  }

  async postProfileV2(userId, data = {}) {
    const url = `${this._baseUrl}/v2/profile/${userId}`;
    try {
      const response = await authAxios.post(url, data, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      console.error(error);
      logException(error);
      return error.response;
    }
  }

  static get instance() {
    return profileService;
  }

  async updateUserProfile(userId, data = {}) {
    const url = `${this._baseUrl}/v2/profile/${userId}`;
    try {
      const response = await authAxios.post(url, data, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      console.error(error);
      logException(error);
      return error.response;
    }
  }

  async setJourneyFlags(userId, journeyId, data = {}) {
    const url = `${this._baseUrl}/v2/profile/${userId}/journey/${journeyId}/set-flags`;
    try {
      const response = await authAxios.post(url, data, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      console.error(error);
      logException(error);
      return error.response;
    }
  }

  async startJourney(userId, journeyId) {
    const url = `${this._baseUrl}/v2/profile/${userId}/journey/${journeyId}/start`;
    try {
      const response = await authAxios.post(
        url,
        {},
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (error) {
      console.error(error);
      logException(error);
      return error.response;
    }
  }
}

const profileService = new ProfileService();

export default profileService;
