import {
  LOAD_SIGNUP_COPY,
  SIGNUP_COPY_LOADED,
  LOAD_SIGNUP_COPY_FAILED,
  NEXT_STEP,
  NEXT_SUB_STEP,
  UPDATE_SIGNUP_FORM_VALUES,
  SIGNUP_PAGES_DATA,
} from "../constants";
import { urls } from "../../configs/serviceConfig";
import { mapSignupPagesData } from "../../components/pages/EligibilityChecker/helpers";
import axios from "axios";
import { logException } from "../../utils/AppInsightsLogging";

const load_signup_copy = () => {
  return {
    type: LOAD_SIGNUP_COPY,
  };
};

const signup_copy_loaded = (payload) => {
  return {
    type: SIGNUP_COPY_LOADED,
    payload,
  };
};

const load_signup_copy_failed = (error) => {
  return {
    type: LOAD_SIGNUP_COPY_FAILED,
    error,
  };
};

export const next_step = (payload) => {
  return {
    type: NEXT_STEP,
    payload,
  };
};

export const next_sub_step = (payload) => {
  return {
    type: NEXT_SUB_STEP,
    payload,
  };
};

export const update_signup_form_values = (payload) => {
  return {
    type: UPDATE_SIGNUP_FORM_VALUES,
    payload,
  };
};

export const signup_pages_data = (payload) => {
  return {
    type: SIGNUP_PAGES_DATA,
    payload,
  };
};

export const get_signup_copy = (slug) => {
  const url = `${urls.contentService}/api/sign-up-copy/${slug}`;
  return (dispatch) => {
    dispatch(load_signup_copy());
    axios
      .get(url)
      .then((response) => {       
        const mappedPagesData = mapSignupPagesData(response.data.pages);
        dispatch(signup_copy_loaded(response.data));
        dispatch(signup_pages_data(mappedPagesData));
      })
      .catch((error) => {
        logException(error);
        dispatch(load_signup_copy_failed(error));
      });
  };
};
