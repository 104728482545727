import { DataInformationConsent } from "./DataInformationConsent";

export interface ProfileViewModel {
  personalDetails?: ProfilePersonalDetailsViewModel;
  clientId?: string;
  accountState?: AccountState;
  ethnicity?: Ethnicity;
  otherEthnicity?: string;
  sexualOrientation?: SexualOrientation;
  firstLanguage: Language;
  otherLanguages: Language[];
  profileTags: string[];
  stageTag?: string;
  motivation?: MotivationDetails;
  pathway: Pathway;
  currentJourney: Journey;
  banners: string[];
  religion?: Religion;
  dataInformationConsent: DataInformationConsent;
}

interface ProfilePersonalDetailsViewModel {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  dateOfBirth?: Date;
  birthSex: Sex;
  genderIsBirthSex: boolean;
  gender?: string;
  heightDisplayUnitOfMeasure: UnitOfMeasure;
  weightDisplayUnitOfMeasure: UnitOfMeasure;
}

export enum Sex {
  Undefined,
  Male,
  Female,
}

enum UnitOfMeasure {
  Metric,
  Imperial,
}

enum AccountState {
  EmailNotVerified,
  EmailVerified,
  ProfileCompleted,
}

enum Ethnicity {
  Undefined,
  WhiteBritish,
  WhiteIrish,
  WhiteGypsyOrTraveler,
  WhiteOther,
  MixedWhiteAndBlackCaribbean,
  MixedWhiteAndBlackAfrican,
  MixedWhiteAndAsian,
  MixedOtherOrMultipleEthnicBackground,
  AsianIndian,
  AsianPakistani,
  AsianBangladeshi,
  AsianChinese,
  AsianJapanese,
  AsianOther,
  BlackAfrican,
  BlackCaribbean,
  BlackBritish,
  BlackOther,
  Arab,
  Other,
  MixedOther,
  AsianBritishOther,
  OtherChinese,
  NotKnown,
}

enum SexualOrientation {
  HeterosexualOrStraight,
  GayOrLesbian,
  Bisexual,
  OtherNotListed,
  NotSure,
  PreferNotToSay,
}

enum Language {
  Undefined,
  Arabic,
  Bengali,
  English,
  French,
  German,
  Gujarati,
  Japanese,
  Mandarin,
  Punjabi,
  Portuguese,
  Polish,
  Russian,
  Spanish,
  Urdu,
  Other,
}

interface MotivationDetails {
  rating?: number;
  statement?: MotivationStatement;
  confidenceLevel?: number;
}

enum MotivationStatement {
  Undefined,
  ReadyToLoseWeight,
  IamHappyAsIam,
  NotReadyToLoseWeight,
  HappyAndWeightNoConcernToMe,
  NotSureWhyServiceIsBeingReferredToMe,
}

enum Pathway {
  Unknown = -1,
  Supported = 0,
  Digital = 1,
  InPerson = 2,
  Group = 3,
  Exercise = 4,
  Coaching = 5,
}

interface Journey {
  id: string;
  referralCode?: string;
  referringServiceId: string;
  programmeIds: string[];
  state: JourneyState;
  startDate?: Date;
  completedDate?: Date;
  address?: Address;
  postcode?: string;
  healthConditions?: HealthConditionDetails;
  isPregnant?: boolean;
  isBreastFeeding?: boolean;
  pathway: Pathway;
  referralSource?: ReferralSource;
  howHeard: string[];
  height?: number;
  weight?: number;
  events: JourneyEvent[];
  flags?: JourneyFlags;
}

enum JourneyState {
  Undefined,
  Referral,
  Pending,
  Active,
  FollowUp,
  Complete,
  Relapsed,
  Cancelled,
  Restarted,
}

interface Address {
  addressLine1?: string;
  addressLine2?: string;
  town?: string;
  county?: string;
  postcode?: string;
  country?: string;
}

interface HealthConditionDetails {
  physicalConditions?: PhysicalHealthCondition[];
  otherPhysicalConditions?: string;
  mentalConditions?: MentalHealthCondition[];
  otherMentalConditions?: string;
  disabilities?: Disability[];
  otherDisabilities?: string;
}

enum PhysicalHealthCondition {
  Asthma,
  Arthritis,
  Hypertension,
  Hypercholesterolemia,
  CoronaryHeartDisease,
  Stroke,
  HeartAttack,
  RenalDisease,
  ThromboembolicDisorder,
  HaematologicalDisorder,
  CentralNervousSystemDisorder,
  DiabetesType1,
  DiabetesType2,
  AutoimmuneDisease,
  Cancer,
  HepatitisA,
  HepatitisB,
  HepatitisC,
  EndocrineDisorder,
  Copd,
  OtherRespiratoryDisease,
  GastrointestinalDisorder,
  MusculoskeletalDisorder,
  Pcos,
  OtherGynaecologicalProblems,
  None,
  KidneyDisease,
  COPD,
  ThyroidCondition,
  StrokeOrTIA,
  CHD,
  Diabetes,
  Other,
}

enum MentalHealthCondition {
  Anxiety,
  Depression,
  EatingDisorder,
  AntisocialPersonalityDisorder,
  MultiplePersonalityDisorder,
  Adhd,
  Ocd,
  Autism,
  Bipolar,
  Schizophrenia,
  None,
  ADHD,
  OCD,
  BiPolar,
  SelfHarm,
  SelfMutism,
  Other,
}

enum Disability {
  Physical,
  Learning,
  VisualImpairment,
  Deafness,
  Dyslexia,
  DevelopmentDisorder,
  None,
  PhysicalDisabilityOrInjury,
  Blindness,
  LearningDifficulties,
  DevelopmentalDisorder,
  Other,
}

enum ReferralSource {
  Unknown,
  SelfReferral,
  NonHealthCareProfessional,
  Gp,
  OtherHealthCareProfessional,
  Crm,
}

interface JourneyEvent {
  eventType: JourneyEventType;
  message?: string;
  timestamp: Date;
}

enum JourneyEventType {
  ProgrammeStarted,
  Cancelled,
  Restarted,
  FollowUp,
  Completed,
}

interface JourneyFlags {
  appointmentBooked?: boolean;
  initialWeightLogged?: boolean;
  step0Completed?: boolean;
}

enum Religion {
  Bahai,
  Buddhist,
  Christian,
  Hindu,
  Jain,
  Jewish,
  Muslim,
  Pagan,
  Sikh,
  Zoroastrian,
  Other,
  None,
  PreferNotToSay,
}
