import React from "react";
import {
  Grid,
  Typography,
  FormControlLabel,
  Radio,
  FormLabel,
  FormGroup,
  FormHelperText,
} from "@material-ui/core";
import { Field, ErrorMessage } from "formik";
import { RadioGroup } from "formik-material-ui";
import SliderInput from "../../atoms/SliderInput";
import { MotivationStatement } from "../../../enums/profile";
import { ScrollTo } from "../../atoms/ScrollTo";

const MotivationForm = () => {
  const sliderMarks = [
    {
      value: 1,
      label: "1",
    },

    {
      value: 10,
      label: "10",
    },
  ];

  return (
    <Grid container spacing={2}>
      <ScrollTo />
      <Grid item xs={12} sm={12}>
        <Typography className="info-text" variant="h2" align="center">
          Tell us a little bit about your motivation
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormLabel
          component="legend"
          className="pb-10"
          id="motivation-slider"
          aria-label="Rate your motivation"
        >
          How would you rate your motivation to lose weight? Where 1 is the least motivated and 10
          is the most motivated.
        </FormLabel>
        <Field
          id="rating"
          name="rating"
          component={SliderInput}
          valueLabelDisplay="auto"
          step={1}
          marks={sliderMarks}
          min={1}
          max={10}
          defaultValue={5}
          aria-labelledby="motivation-slider"
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormGroup>
          <FormLabel component="legend" className="pb-10">
            Please select which of the following statements best applies to you
          </FormLabel>
          <Field variant="outlined" id="statement" name="statement" component={RadioGroup}>
            <FormControlLabel
              value={MotivationStatement.READY_TO_LOSE_WEIGHT}
              control={<Radio />}
              label="I know I'm above a healthy weight and ready to lose weight"
            />
            <FormControlLabel
              value={MotivationStatement.I_AM_HAPPY_AS_I_AM}
              control={<Radio />}
              label="I know I'm above a healthy weight but I'm happy as I am"
            />
            <FormControlLabel
              value={MotivationStatement.NOT_READY_TO_LOSE_WEIGHT}
              control={<Radio />}
              label="I know I'm above a healthy weight but I'm not ready to lose weight"
            />
            <FormControlLabel
              value={MotivationStatement.HAPPY_AND_WEIGHT_NO_CONCERN_TO_ME}
              control={<Radio />}
              label="I'm happy as I am and my weight doesn't concern me"
            />
            <FormControlLabel
              value={MotivationStatement.NOT_SURE_WHY_SERVICE_IS_BEING_REFERRED_TO_ME}
              control={<Radio />}
              label="I'm not sure why I've been referred to this service"
            />
          </Field>
          <ErrorMessage className="Mui-error" component={FormHelperText} name="statement" />
        </FormGroup>
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormLabel
          component="legend"
          className="pb-10"
          id="confidence-slider"
          aria-label="Rate your confidence level"
        >
          How confident do you feel that you can lose weight? Where 1 is the least confident and 10
          is the most confident.
        </FormLabel>
        <Field
          id="confidenceLevel"
          name="confidenceLevel"
          component={SliderInput}
          defaultValue={5}
          valueLabelDisplay="auto"
          step={1}
          marks={sliderMarks}
          min={1}
          max={10}
          aria-labelledby="confidence-slider"
        />
      </Grid>
    </Grid>
  );
};

export default MotivationForm;
