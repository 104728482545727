import React, { useState } from "react";
import { Grid, Typography, FormGroup, FormLabel } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import { PhysicalHealthCondition, MentalHealthCondition, Disability } from "../../../enums/profile";
import { CustomField } from "../../atoms/InputField";
import { ScrollTo } from "../../atoms/ScrollTo";

const HealthConditionForm = () => {
  const [hasOtherPhysicalCondition, setHasOtherPhysicalCondition] = useState(false);
  const [hasOtherMentalConditions, setHasOtherMentalConditions] = useState(false);
  const [hasOtherDisabilities, setHasOtherDisabilities] = useState(false);

  const { values, setFieldValue } = useFormikContext();

  React.useEffect(() => {
    const includesOther = values.physicalConditions.includes("Other");
    if (!includesOther) {
      setFieldValue("otherPhysicalConditions", "");
    }
    setHasOtherPhysicalCondition(includesOther);
  }, [setFieldValue, values.physicalConditions]);

  React.useEffect(() => {
    const includesOther = values.mentalConditions.includes("Other");
    if (!includesOther) {
      setFieldValue("otherMentalConditions", "");
    }
    setHasOtherMentalConditions(includesOther);
  }, [setFieldValue, values.mentalConditions]);

  React.useEffect(() => {
    const includesOther = values.disabilities.includes("Other");
    if (!includesOther) {
      setFieldValue("otherDisabilities", "");
    }
    setHasOtherDisabilities(includesOther);
  }, [setFieldValue, values.disabilities]);

  return (
    <Grid container spacing={2}>
      <ScrollTo />
      <Grid item xs={12} sm={12}>
        <Typography className="info-text" variant="h2" align="center">
          Tell us a little bit about your health
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormGroup>
          <FormLabel component="legend" className="pb-10">
            Please select any of the following <strong>physical health</strong> conditions that may
            apply
          </FormLabel>
          <Field
            name="physicalConditions"
            type="checkbox"
            component={CheckboxWithLabel}
            value={PhysicalHealthCondition.COPD}
            Label={{ label: "Chronic obstructive pulmonary disease" }}
          />
          <Field
            name="physicalConditions"
            type="checkbox"
            component={CheckboxWithLabel}
            value={PhysicalHealthCondition.KIDNEY_DISEASE}
            Label={{ label: "Kidney disease or chronic kidney disease" }}
          />
          <Field
            name="physicalConditions"
            type="checkbox"
            component={CheckboxWithLabel}
            value={PhysicalHealthCondition.ASTHMA}
            Label={{ label: "Asthma" }}
          />
          <Field
            name="physicalConditions"
            type="checkbox"
            component={CheckboxWithLabel}
            value={PhysicalHealthCondition.THYROID_CONDITION}
            Label={{ label: "Thyroid Condition" }}
          />
          <Field
            name="physicalConditions"
            type="checkbox"
            component={CheckboxWithLabel}
            value={PhysicalHealthCondition.STROKE_OR_TIA}
            Label={{ label: "Stroke or TIA" }}
          />
          <Field
            name="physicalConditions"
            type="checkbox"
            component={CheckboxWithLabel}
            value={PhysicalHealthCondition.CHD}
            Label={{ label: "Coronary/Cardiovascular Artery disease" }}
          />
          <Field
            name="physicalConditions"
            type="checkbox"
            component={CheckboxWithLabel}
            value={PhysicalHealthCondition.HEART_ATTACK}
            Label={{ label: "Heart attack" }}
          />
          <Field
            name="physicalConditions"
            type="checkbox"
            component={CheckboxWithLabel}
            value={PhysicalHealthCondition.ARTHRITIS}
            Label={{ label: "Arthritis" }}
          />
          <Field
            name="physicalConditions"
            type="checkbox"
            component={CheckboxWithLabel}
            value={PhysicalHealthCondition.DIABETES}
            Label={{ label: "Diabetes" }}
          />
          <Field
            name="physicalConditions"
            type="checkbox"
            component={CheckboxWithLabel}
            value={PhysicalHealthCondition.HYPERTENSION}
            Label={{ label: "Hypertension" }}
          />
          <Field
            name="physicalConditions"
            type="checkbox"
            component={CheckboxWithLabel}
            value={PhysicalHealthCondition.HYPERCHOLESTEROLEMIA}
            Label={{ label: "Hypercholesterolemia" }}
          />
          <Field
            name="physicalConditions"
            type="checkbox"
            component={CheckboxWithLabel}
            value={PhysicalHealthCondition.OTHER}
            Label={{ label: "Other" }}
            onClick={(e) => setHasOtherPhysicalCondition(e.target.checked)}
          />
        </FormGroup>
        {hasOtherPhysicalCondition ? (
          <Grid item xs={12} sm={6}>
            <CustomField
              name="otherPhysicalConditions"
              id="otherPhysicalConditions"
              component={TextField}
              label="Please enter other physical health conditions"
              placeholder="Other physical health conditions"
            />
          </Grid>
        ) : null}
      </Grid>

      <Grid item xs={12} sm={12}>
        <FormGroup>
          <FormLabel component="legend" className="pb-10">
            Please select any of the following <strong>mental health</strong> conditions that may
            apply
          </FormLabel>
          <Field
            name="mentalConditions"
            type="checkbox"
            component={CheckboxWithLabel}
            value={MentalHealthCondition.ANXIETY}
            Label={{ label: "Anxiety" }}
          />
          <Field
            name="mentalConditions"
            type="checkbox"
            component={CheckboxWithLabel}
            value={MentalHealthCondition.DEPRESSION}
            Label={{ label: "Depression" }}
          />
          <Field
            name="mentalConditions"
            type="checkbox"
            component={CheckboxWithLabel}
            value={MentalHealthCondition.ANTISOCIAL_PERSONALITY_DISORDER}
            Label={{ label: "Antisocial Personality Disorder" }}
          />
          <Field
            name="mentalConditions"
            type="checkbox"
            component={CheckboxWithLabel}
            value={MentalHealthCondition.MULTIPLE_PERSONALITY_DISORDER}
            Label={{ label: "Multiple Personality Disorder" }}
          />
          <Field
            name="mentalConditions"
            type="checkbox"
            component={CheckboxWithLabel}
            value={MentalHealthCondition.ADHD}
            Label={{ label: "ADHD" }}
          />
          <Field
            name="mentalConditions"
            type="checkbox"
            component={CheckboxWithLabel}
            value={MentalHealthCondition.OCD}
            Label={{ label: "OCD" }}
          />
          <Field
            name="mentalConditions"
            type="checkbox"
            component={CheckboxWithLabel}
            value={MentalHealthCondition.AUTISM}
            Label={{ label: "Autism" }}
          />
          <Field
            name="mentalConditions"
            type="checkbox"
            component={CheckboxWithLabel}
            value={MentalHealthCondition.BIPOLAR}
            Label={{ label: "BiPolar" }}
          />
          <Field
            name="mentalConditions"
            type="checkbox"
            component={CheckboxWithLabel}
            value={MentalHealthCondition.SCHIZOPHRENIA}
            Label={{ label: "Schizophrenia" }}
          />
          <Field
            name="mentalConditions"
            type="checkbox"
            component={CheckboxWithLabel}
            value={MentalHealthCondition.SELF_HARM}
            Label={{ label: "Self Harm" }}
          />
          <Field
            name="mentalConditions"
            type="checkbox"
            component={CheckboxWithLabel}
            value={MentalHealthCondition.SELF_MUTISM}
            Label={{ label: "Self Mutism" }}
          />
          <Field
            name="mentalConditions"
            type="checkbox"
            component={CheckboxWithLabel}
            value={MentalHealthCondition.OTHER}
            Label={{ label: "Other" }}
            onClick={(e) => setHasOtherMentalConditions(e.target.checked)}
          />
        </FormGroup>

        {hasOtherMentalConditions ? (
          <Grid item xs={12} sm={6}>
            <CustomField
              name="otherMentalConditions"
              id="otherMentalConditions"
              type="text"
              label="Please specify other mental health conditions"
              placeholder="Other mental health conditions"
              component={TextField}
            />
          </Grid>
        ) : null}
      </Grid>

      <Grid item xs={12} sm={12}>
        <FormGroup>
          <FormLabel component="legend" className="pb-10">
            Please select any of the following <strong>disabilities</strong> that may apply
          </FormLabel>
          <Field
            name="disabilities"
            type="checkbox"
            component={CheckboxWithLabel}
            value={Disability.PHYSICAL_DISABILITY_OR_INJURY}
            Label={{ label: "Physical Disability or Injury" }}
          />
          <Field
            name="disabilities"
            type="checkbox"
            component={CheckboxWithLabel}
            value={Disability.DEAFNESS}
            Label={{ label: "Deafness" }}
          />
          <Field
            name="disabilities"
            type="checkbox"
            component={CheckboxWithLabel}
            value={Disability.BLIDNESS}
            Label={{ label: "Blindness" }}
          />
          <Field
            name="disabilities"
            type="checkbox"
            component={CheckboxWithLabel}
            value={Disability.DYSLEXIA}
            Label={{ label: "Dyslexia" }}
          />
          <Field
            name="disabilities"
            type="checkbox"
            component={CheckboxWithLabel}
            value={Disability.LEARNING_DIFFICULTIES}
            Label={{ label: "Learning Difficulties" }}
          />
          <Field
            name="disabilities"
            type="checkbox"
            component={CheckboxWithLabel}
            value={Disability.DEVELOPMENTAL_DISORDER}
            Label={{ label: "Developmental Disorder" }}
          />
          <Field
            name="disabilities"
            type="checkbox"
            component={CheckboxWithLabel}
            value={Disability.OTHER}
            Label={{ label: "Other" }}
            onClick={(e) => setHasOtherDisabilities(e.target.checked)}
          />
        </FormGroup>
        {hasOtherDisabilities ? (
          <Grid item xs={12} sm={6}>
            <CustomField
              name="otherDisabilities"
              id="otherDisabilities"
              type="text"
              label="Please specify other disabilities"
              placeholder="Other disabilities"
              component={TextField}
            />
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default HealthConditionForm;
