export const LOAD_PERSONALISED_CONTENT = "LOAD_PERSONALISED_CONTENT";
export const PERSONALISED_CONTENT_LOADED = "PERSONALISED_CONTENT_LOADED";
export const LOAD_PERSONALISED_CONTENT_FAILED = "LOAD_PERSONALISED_CONTENT_FAILED";

export const LOAD_CONTENT = "LOAD_CONTENT";
export const CONTENT_LOADED = "CONTENT_LOADED";
export const ALL_CONTENT_LOADED = "ALL_CONTENT_LOADED";
export const LOAD_CONTENT_FAILED = "LOAD_CONTENT_FAILED";
export const CLEAR_CONTENT_LOADED = "CLEAR_CONTENT_LOADED";

export const LOAD_PROFILE = "LOAD_PROFILE";
export const PROFILE_LOADED = "PROFILE_LOADED";
export const LOAD_PROFILE_FAILED = "LOAD_PROFILE_FAILED";

export const LOAD_STAGE_ARTICLES = "LOAD_STAGE_ARTICLES";
export const STAGE_ARTICLES_LOADED = "STAGE_ARTICLES_LOADED";
export const LOAD_STAGE_ARTICLES_FAILED = "LOAD_STAGE_ARTICLES_FAILED";

export const LOAD_ALL_WEIGHTS = "LOAD_ALL_WEIGHTS";
export const ALL_WEIGHTS_LOADED = "ALL_WEIGHTS_LOADED";
export const LOAD_ALL_WEIGHTS_FAILED = "LOAD_ALL_WEIGHTS_FAILED";

export const LOG_WEIGHT = "LOG_WEIGHT";
export const WEIGHT_LOGGED = "WEIGHT_LOGGED";
export const LOG_WEIGHT_FAILED = "LOG_WEIGHT_FAILED";
export const LOG_WEIGHT_SUCCESS_RESET = "LOG_WEIGHT_SUCCESS_RESET";
export const LOG_WEIGHT_ERROR_RESET = "LOG_WEIGHT_ERROR_RESET";

export const LOAD_PROGRAMME_OVERVIEW = "LOAD_PROGRAMME_OVERVIEW";
export const PROGRAMME_OVERVIEW_LOADED = "PROGRAMME_OVERVIEW_LOADED";
export const LOAD_PROGRAMME_OVERVIEW_FAILED = "LOAD_PROGRAMME_OVERVIEW_FAILED";

export const LOAD_PROGRAMME_STEP = "LOAD_PROGRAMME_STEP";
export const PROGRAMME_STEP_LOADED = "PROGRAMME_STEP_LOADED";
export const LOAD_PROGRAMME_STEP_FAILED = "LOAD_PROGRAMME_STEP_FAILED";

export const COMPLETE_VIDEO = "COMPLETE_VIDEO";
export const VIDEO_COMPLETED = "VIDEO_COMPLETED";
export const COMPLETE_VIDEO_FAILED = "COMPLETE_VIDEO_FAILED";

export const COMPLETE_PART = "COMPLETE_PART";
export const PART_COMPLETED = "PART_COMPLETED";
export const COMPLETE_PART_FAILED = "COMPLETE_PART_FAILED";
export const PART_COMPLETED_HIDE_MODAL = "PART_COMPLETED_HIDE_MODAL";

export const COMPLETE_QUESTION = "COMPLETE_QUESTION";
export const QUESTION_COMPLETED = "QUESTION_COMPLETED";
export const COMPLETE_QUESTION_FAILED = "COMPLETE_QUESTION_FAILED";
export const COMPLETE_QUESTION_ERROR_RESET = "COMPLETE_QUESTION_ERROR_RESET";

export const UPDATE_CURRENT_TAB_INDEX = "UPDATE_CURRENT_TAB_INDEX";

export const LOAD_SIGNUP_COPY = "LOAD_SIGNUP_COPY";
export const SIGNUP_COPY_LOADED = "SIGNUP_COPY_LOADED";
export const LOAD_SIGNUP_COPY_FAILED = "LOAD_SIGNUP_COPY_FAILED";
export const NEXT_STEP = "NEXT_STEP";
export const NEXT_SUB_STEP = "NEXT_SUB_STEP";
export const UPDATE_SIGNUP_FORM_VALUES = "UPDATE_SIGNUP_FORM_VALUES";

export const SIGNUP_PAGES_DATA = "SIGNUP_PAGES_DATA";
