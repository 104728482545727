import { Route, Switch, useRouteMatch } from "react-router";
import IntroduceYourself from "./IntroduceYourself";


const IntroduceYourselfRoot = (props) => {
  let { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route exact path={`${path}`} component={IntroduceYourself} />
      </Switch>
    </>
  );
};

export default IntroduceYourselfRoot;
