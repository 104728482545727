import { React, useEffect, useState } from "react";
import "./SliderInput.scss";
import classnames from "classnames";
import { useField } from "formik";
import Slider from "@material-ui/core/Slider";
import { IsValueEmpty } from "../../../utils/IsEmpty";

const generateSliderValues = (min, max, interval) => {
  let current = min;
  let values = [];
  while (current <= max) {
    values.push({ value: current > max ? max : current, label: current });
    current = current + interval;
  }

  return values;
};

export const SliderInput = ({
  id,
  ariaLabelledby,
  name,
  maxValue,
  minValue,
  additionalText,
  sampleAnwser,
  interval = 1,
}) => {
  const [marks, setMarks] = useState([]);

  const [field, meta, helpers] = useField(name);

  useEffect(() => {
    var generatedMarks = generateSliderValues(minValue, maxValue, interval);
    setMarks(generatedMarks);
  }, [maxValue, minValue, interval]);

  return (
    <div className="slider-input">
      {sampleAnwser && <div className="slider-input__sample-answer">{sampleAnwser}</div>}
      <div
        className={classnames("slider-input__container", {
          "slider-input__container--error": meta.error && meta.touched,
        })}
      >
        <Slider
          value={field.value}
          onChange={(_, value) => helpers.setValue(value.toString(), true)}
          id={id}
          name={name}
          aria-labelledby={ariaLabelledby}
          step={interval}
          min={minValue}
          max={maxValue}
          valueLabelDisplay="auto"
          marks={marks}
        />
      </div>
      <div
        className={classnames(
          "selected-value",
          {
            "selected-value__error": meta.error && meta.touched,
          },
          { "selected-value--inactive": IsValueEmpty(field.value) }
        )}
      >
        <div className="selected-value__current">{field.value ?? ""}</div>
        <p className="selected-value__label">Your answer </p>
      </div>

      {additionalText && <div className="slider-input__additional">additionalText</div>}
    </div>
  );
};
