import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

const PartContext = React.createContext();

function usePartContext(initialState) {
  const [part, setPart] = useState(initialState);
  const partContext = useMemo(
    () => ({
      part,
      setPart,
    }),
    [part]
  );

  return partContext;
}

function PartProvider({ state, children }) {
  const partContext = usePartContext(state);
  return <PartContext.Provider value={partContext}>{children}</PartContext.Provider>;
}

PartProvider.propTypes = {
  state: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

function usePart() {
  const context = React.useContext(PartContext);
  if (context === undefined) {
    throw new Error("usePart must be used within a PartProvider component");
  }
  return context;
}

function useSections(id) {
  const context = usePart();
  if (context === undefined) {
    throw new Error("useSections must be used within a PartProvider component");
  }
  const { part } = context;
  if (id) return part.sections.find(({ sectionId }) => sectionId === id);
  return part.sections;
}

useSections.propTypes = {
  id: PropTypes.string,
};

function useQuestions(id) {
  const context = useSections();
  if (context === undefined) {
    throw new Error("useQuestions must be used within a PartProvider");
  }
  if (!id) return context.reduce((previous, current) => [...previous, ...current.questions], []);
  return context.reduce((previous, current) => {
    if (current.questions.find(({ questionId }) => questionId === id))
      previous.push(current.questions);
    return previous;
  }, []);
}

useQuestions.propTypes = {
  id: PropTypes.string,
};

export { PartProvider, usePart, useSections, useQuestions };
