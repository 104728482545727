import PropTypes from "prop-types";

const MiddleBannerIcon = ({ children }) => {
  return (
    <span aria-hidden="true" className="middle-banner__icon">
      {children}
    </span>
  );
};

MiddleBannerIcon.propTypes = {
  children: PropTypes.node,
};

export default MiddleBannerIcon;
