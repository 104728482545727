import React, { useEffect } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Form, Formik } from "formik";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  ModalPageHeader,
  ModalPageBody,
  ModalPageFooter,
  ModalPageWrapper,
  ModalPageHeading,
} from "../../../organisms/ModalPage";
import RadioButtonGroup from "../../../molecules/RadioButtonGroup/RadioButtonGroup";
import { update_signup_form_values } from "../../../../store/actions/signupFlowData";
import { PregnancyValidationSchema } from "../EligibilityCheckerValidation";

const PregnancyChecker = (props) => {
  const dispatch = useDispatch();
  const sexPageData = useSelector((state) => state.signupFlowData.signupPagesData?.sex);
  const signupFormValues = useSelector((state) => state.signupFlowData.signupFormValues);

  let history = useHistory();

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Sex | Eligibility checker | Sign up | Gloji",
    });
  }, []);

  const handleSubmit = (values) => {
    if (values.pregnancy === "yes" || values.breastfeeding === "yes") {
      history.push(`/sex/pregnancy/fail`);

      //Trigger google analytics event tag
      window.gtag("event", "ineligible_pregnant", {
        event_category: "pregnant",
        event_label: "ineligible pregnant",
      });
    } else {
      history.push(`/sex/loading`);
    }

    // Save values to store
    dispatch(update_signup_form_values(values));
  };

  const initialValues = {
    pregnancy: signupFormValues?.pregnancy ?? "",
    breastfeeding: signupFormValues?.breastfeeding ?? "",
  };

  const yesNoOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={PregnancyValidationSchema}
        validateOnChange={false}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form className="eligibility-checker-form">
            <Helmet>
              <title>Sex | Eligibility checker | Sign up | Gloji</title>
            </Helmet>
            <ModalPageWrapper>
              <ModalPageBody className="with-sticky-footer">
                <ModalPageHeader
                  backButtonOnClick={() => {
                    history.goBack();
                  }}
                >
                  Checking we're a good fit
                </ModalPageHeader>

                <ModalPageHeading>{sexPageData?.questions[3].question}</ModalPageHeading>
                <RadioButtonGroup
                  options={yesNoOptions}
                  name="pregnancy"
                  hasErrors={errors[`pregnancy`] && touched["pregnancy"]}
                />
                <Accordion square={true} elevation={0} className="eligibility-checker__accrodion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon fontSize="large" />}
                    aria-controls={`pregnancy-question-explanation`}
                  >
                    <span>{sexPageData?.questions[3].whyQuestion}</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>{sexPageData?.questions[3].whyQuestionDetails}</span>
                  </AccordionDetails>
                </Accordion>

                <ModalPageHeading>{sexPageData?.questions[4].question}</ModalPageHeading>
                <RadioButtonGroup
                  options={yesNoOptions}
                  name="breastfeeding"
                  hasErrors={errors[`breastfeeding`] && touched["breastfeeding"]}
                />
                <Accordion square={true} elevation={0} className="eligibility-checker__accrodion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon fontSize="large" />}
                    aria-controls={`breastfeeding-question-explanation`}
                  >
                    <span>{sexPageData?.questions[4].whyQuestion}</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>{sexPageData?.questions[4].whyQuestionDetails}</span>
                  </AccordionDetails>
                </Accordion>
              </ModalPageBody>
              <ModalPageFooter className="sticky-footer">
                <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                  Continue
                </Button>
              </ModalPageFooter>
            </ModalPageWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PregnancyChecker;
