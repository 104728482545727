import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { sexualOrientationOptions } from "../../../../enums/profile/SexualOrientation";
import { update_signup_form_values } from "../../../../store/actions/signupFlowData";
import { MultiStep } from "../../../molecules/MultiStep";
import ReactSelect from "../../../atoms/ReactSelect";
import {
  ModalPageBody,
  ModalPageFooter,
  ModalPageHeader,
  ModalPageHeading,
  ModalPageWrapper,
} from "../../../organisms/ModalPage";
import { questionStepperList } from "../helpers";
import { SexualOrientationValidationSchema } from "../ProfileBuilderValidation";

const SexualOrientation = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const sexualOrientationPageData = useSelector(
    (state) => state.signupFlowData.signupPagesData?.["sexual-orientation"]
  );
  const storeEmploymentStatusValue = useSelector(
    (state) => state.signupFlowData.signupFormValues?.sexualOrientation
  );
  const profile = useSelector((state) => state.profile);
  const bookAppointmentsFlag = useSelector((state) => state.programmeOverview.data.programme.features.bookAppointments);

  const [initialValues, setInitialValues] = useState({
    sexualOrientation: storeEmploymentStatusValue || null,
  });

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Sexual orientation | Profile builder | Sign up | Gloji",
    });
  }, []);

  const handleSubmit = (values) => {
    storeEmploymentStatusValue
      ? setInitialValues({ sexualOrientation: storeEmploymentStatusValue })
      : setInitialValues(values);
    dispatch(update_signup_form_values(values));

    return history.push("/how-heard");
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={SexualOrientationValidationSchema}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {(props) => (
        <Form className="profile-builder-form">
          <Helmet>
            <title>Sexual orientation | Profile builder | Sign up | Gloji</title>
          </Helmet>
          <ModalPageWrapper>
            <ModalPageBody className="with-sticky-footer">
              <ModalPageHeader
                className="modal-header_center"
                backButtonOnClick={() => {
                  history.goBack();
                }}
              >
                Profile
              </ModalPageHeader>

              <MultiStep step={3} list={questionStepperList} percent={40} />

              <ModalPageHeading>
                {sexualOrientationPageData?.questions[0].question}
              </ModalPageHeading>
              <ReactSelect
                name="sexualOrientation"
                options={sexualOrientationOptions}
                label={sexualOrientationPageData?.questions[0].label}
                labelCssClass="profile-builder__input-label"
              />
              <Accordion square={true} elevation={0} className="profile-builder__accrodion">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon fontSize="large" />}
                  aria-controls={`sexualOrientation-question-explanation`}
                >
                  <span>{sexualOrientationPageData?.questions[0].whyQuestion}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <span>{sexualOrientationPageData?.questions[0].whyQuestionDetails}</span>
                    <p>
                      Visit our&nbsp;
                      <a target="_blank" href="/privacy">
                        privacy policy
                      </a>
                      &nbsp;page
                    </p>
                  </div>
                </AccordionDetails>
              </Accordion>
            </ModalPageBody>
            <ModalPageFooter className="sticky-footer">
              <Button type="submit" variant="contained" color="primary">
                Continue
              </Button>
            </ModalPageFooter>
          </ModalPageWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default SexualOrientation;
