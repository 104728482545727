import { Container, Grid } from "@material-ui/core";
import PropTypes from "prop-types";

const ModalPageWrapper = ({ children }) => {
  return (
    <Container>
      <Grid container justifyContent="center">
        <div className="modal-page__wrapper">
          <div className="modal-page__content-container">{children}</div>
        </div>
      </Grid>
    </Container>
  );
};

ModalPageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalPageWrapper;
