import React from "react";
import "./PageNotFound.scss";
import { Helmet } from "react-helmet";
import { Container, Grid } from "@material-ui/core";
import LinkWithIcon from "../../atoms/LinkWithIcon";
import { ReactComponent as HomeIcon } from "../../../assets/img/icons/homeIcon.svg";
import { ReactComponent as ContactUsIcon } from "../../../assets/img/icons/contactUsIcon.svg";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="error-page">
      <Helmet>
        <title>Error 404 | Gloji</title>
      </Helmet>
      <Container>
        <Grid container className="hero-image-wrapper" justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <div className="hero-image-container" role="presentation"></div>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={8}>
            <h1 className="error-message"> Oops! We can’t find that page.</h1>

            <Link
              to="/"
              className="link-with-icon primary lg"
              style={{ width: "100%", fontFamily: "Fredoka One", fontWeight: "normal" }}
            >
              <span className="icon-container"> {<HomeIcon />}</span>
              Take me home
            </Link>
            <LinkWithIcon
              variant="primary"
              size="lg"
              icon={<ContactUsIcon />}
              style={{
                width: "100%",
                fontFamily: "Fredoka One",
                fontWeight: "normal",
                marginBottom: "5rem",
              }}
              href={`mailto:support@gloji.co.uk`}
            >
              Contact us
            </LinkWithIcon>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default PageNotFound;
