import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Container, Grid } from "@material-ui/core";
import { SectionHeader } from "../../atoms/SectionHeader";
import { CircularLoader } from "../../atoms/Loaders";
import "./Step.scss";
import Stepper from "../../atoms/Stepper";
import CustomTabs from "../../organisms/CustomTabs";
import { useDispatch, useSelector } from "react-redux";
import { get_programme_overview } from "../../../store/actions/getProgrammeOverview";
import { get_specific_programme_step } from "../../../store/actions/getProgrammeStep";
import useUserId from "../../hooks/useUserId";
import useCompletedSteps from "../../hooks/useCompletedSteps";
import slugConverter from "../../../utils/SlugConverter";
import { Alert, AlertTitle } from "@material-ui/lab";
import JourneyStates from "../../../enums/journeyStates";
import { BackButtonLink } from "../../atoms/Button";

const Step = () => {
  const { stepNumber } = useParams();
  const profile = useSelector((state) => state.profile);
  const overview = useSelector((state) => state.programmeOverview);
  const content = useSelector((state) => state.programmeStep);
  const [currentStep, setCurrentStep] = useState({});
  const [userId] = useUserId();
  const programmeId = localStorage.getItem("programmeID");
  const journeyId = localStorage.getItem("journeyID");
  const dispatch = useDispatch();
  const completedSteps = useCompletedSteps(overview?.data?.steps);

  useEffect(() => {
    if (userId) {
      overview.loading !== true && dispatch(get_programme_overview({userId, programmeId, journeyId}));
      dispatch(get_specific_programme_step(userId, programmeId, journeyId, parseInt(stepNumber)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userId]);

  useEffect(() => {
    if (Object.keys(content.data).length > 0) {
      setCurrentStep(content.data);
    }
  }, [content.data]);

  return (
    <div className="main-page step-page">
      {content.data && content.loading === false ? (
        <>
          <Helmet>
            <title>
              {`${currentStep.title} | ${slugConverter.toTitleCase(
                currentStep.programmeSlug
              )} |  My Path | Gloji`}
            </title>
          </Helmet>
          <Container className="main-content__container">
            <section>
              {profile.data.currentJourney.state !== JourneyStates.PENDING && (
                <div className="back-button">
                  <BackButtonLink
                    linkPath="/my-path"
                    btnText="My path"
                    ariaLabel="Back to My path"
                  />
                </div>
              )}
              {currentStep.stepNumber !== 0 && (
                <SectionHeader>
                  {overview?.data?.programme !== undefined && (
                    <Stepper
                      totalSteps={overview.data.programme.numberOfSteps - 1}
                      completedSteps={completedSteps}
                      currentStep={parseInt(stepNumber)}
                    />
                  )}
                </SectionHeader>
              )}
              <div className="parts-and-sections ">
                {content.errorStatus ? (
                  <>
                    <Grid container>
                      <Grid item xs={12}>
                        <div style={{ height: "60vh", paddingTop: "4rem" }}>
                          <Alert severity="error">
                            <AlertTitle>{content.error.message} </AlertTitle>
                            Failed to load data
                          </Alert>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  Object.keys(currentStep).length && (
                    <CustomTabs stepData={currentStep ? currentStep : []} />
                  )
                )}
              </div>
            </section>
          </Container>
        </>
      ) : (
        <CircularLoader color="primary" size={40} />
      )}
    </div>
  );
};

export default Step;
