import { LEMON, BERRY, SKY, PUMPKIN, MAUVE, NAVY, GREENISH_CYAN } from "../enums/brandColors";
import {
  OLIVE,
  GRAPE,
  LAVENDER,
  GOJI,
  VIOLET,
  SILVER_EXTRA_LIGHT,
  DARK_GREENISH_CYAN,
} from "../enums/brandColors";
import {
  purpleVelvet,
  blueVelvet,
  greyVelvet,
  orangeVelvet,
  pinkVelvet,
  yellowVelvet,
} from "../assets/img/bg/index";

export const ARTICLE_TYPES = {
  default: {
    name: "",
    color: `${NAVY}`,
    intenseColor: `${SILVER_EXTRA_LIGHT}`,
    bgImg: greyVelvet,
  },
  eatWell: { name: "Nutrition", color: `${LEMON}`, intenseColor: `${OLIVE}`, bgImg: yellowVelvet },
  moveMore: { name: "Movement", color: `${BERRY}`, intenseColor: `${GRAPE}`, bgImg: pinkVelvet },
  drinkLess: {
    name: "Alcohol",
    color: `${SKY}`,
    intenseColor: `${LAVENDER}`,
    bgImg: blueVelvet,
  },
  thinkHappy: {
    name: "Mind",
    color: `${PUMPKIN}`,
    intenseColor: `${GOJI}`,
    bgImg: orangeVelvet,
  },
  sleepBetter: {
    name: "Sleep",
    color: `${MAUVE}`,
    intenseColor: `${VIOLET}`,
    bgImg: purpleVelvet,
  },

  smokeFree: {
    name: "Smoking",
    color: `${GREENISH_CYAN}`,
    intenseColor: `${DARK_GREENISH_CYAN}`,
    bgImg: purpleVelvet,
  },
};
