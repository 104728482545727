import React, { useEffect } from "react";
import { Accordion, AccordionSummary, AccordionDetails, InputLabel } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Form, Formik } from "formik";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  ModalPageHeader,
  ModalPageBody,
  ModalPageFooter,
  ModalPageWrapper,
  ModalPageHeading,
} from "../../../organisms/ModalPage";
import RadioButtonGroup from "../../../molecules/RadioButtonGroup/RadioButtonGroup";
import { update_signup_form_values } from "../../../../store/actions/signupFlowData";
import { MentalHealthManagementValidationSchema } from "../EligibilityCheckerValidation";

const MentalHealthManagement = () => {
  const dispatch = useDispatch();
  const signupFormValues = useSelector((state) => state.signupFlowData.signupFormValues);
  const mentalHealthData = useSelector(
    (state) => state.signupFlowData.signupPagesData["mental-health"]
  );

  let history = useHistory();

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Mental health | Eligibility checker | Sign up | Gloji",
    });
  }, []);

  const handleSubmit = (values) => {
    if (values.mentalHealthManagement === "no") {
      //Trigger google analytics event on ineligible mental health
      window.gtag("event", "ineligibleMentalHealth", {
        event_category: "Eligibility Checker",
        event_label: "Ineligible Mental Health",
      });
      history.push(`/mental-health/mental-health-management/fail`);
    } else {
      history.push(`/mental-health/mental-illness-register`);
    }

    // Save values to store
    dispatch(update_signup_form_values(values));
  };

  const initialValues = {
    mentalHealthManagement: signupFormValues?.mentalHealthManagement ?? "",
  };

  const yesNoOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={MentalHealthManagementValidationSchema}
        validateOnChange={false}
      >
        {({ isSubmitting, values, touched, errors }) => (
          <Form className="eligibility-checker-form mental-health-form">
            <Helmet>
              <title>Mental health| Eligibility checker | Sign up | Gloji</title>
            </Helmet>
            <ModalPageWrapper>
              <ModalPageBody className="with-sticky-footer">
                <ModalPageHeader
                  backButtonOnClick={() => {
                    history.goBack();
                  }}
                >
                  Checking we're a good fit
                </ModalPageHeader>

                <ModalPageHeading>{mentalHealthData?.questions[1].question}</ModalPageHeading>
                <InputLabel htmlFor="postcode" className="eligibility-checker__input-label">
                  {mentalHealthData?.questions[1].description}
                </InputLabel>
                <RadioButtonGroup
                  options={yesNoOptions}
                  name="mentalHealthManagement"
                  hasErrors={errors["mentalHealthManagement"] && touched["mentalHealthManagement"]}
                />
                <Accordion square={true} elevation={0} className="eligibility-checker__accrodion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon fontSize="large" />}
                    aria-controls={`mental-question-explanation`}
                  >
                    <span>{mentalHealthData?.questions[1].whyQuestion}</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>{mentalHealthData?.questions[1].whyQuestionDetails}</span>
                  </AccordionDetails>
                </Accordion>
              </ModalPageBody>
              <ModalPageFooter className="sticky-footer">
                <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                  Continue
                </Button>
              </ModalPageFooter>
            </ModalPageWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MentalHealthManagement;
