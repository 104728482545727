import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { List, Container, Hidden } from "@material-ui/core";
import "./NavBar.scss";
import Pathway from "../../../enums/profile/Pathway";
import { LoginMenu } from "../../auth/LoginMenu";
import MainNavLinks from "./MainNavLinks";
import HeaderLogo from "./HeaderLogo";
import MobileMenu from "./MobileMenu";

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
  },
  navContainerStyles: {
    padding: 0,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },

  linkText: {
    textDecoration: `none`,
    color: `white`,
  },
}));

const policyLinks = [
  { name: "about us", link: "about" },
  { name: "contact us", link: "contact" },
  { name: "accessibility", link: "accessibility" },
  { name: "terms & conditions", link: "terms-and-conditions" },
  { name: "privacy", link: "privacy" },
];

const NavBar = ({ hideMenu }) => {
  const classes = useStyles();

  const [hideNav, setHideNav] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isMyProgressFeatureReady, setIsMyProgressFeatureReady] = useState(false);
  const [navLinks, setNavLinks] = useState([
    { name: "home", link: "" },
    { name: "my path", link: "my-path" },
    { name: "my progress", link: "my-progress" },
  ]);

  const currentJourney = useSelector((state) => state.profile.data.currentJourney);
  const programme = useSelector((state) => state.programmeOverview.data.programme);

  // Hides myProgress nav option for smoke-free programme
  useEffect(() => {
    if (programme) {
      if (programme.features.myProgress === false) {
        const filteredNavLinks = navLinks.filter((item) => item.link !== "my-progress");
        setNavLinks(filteredNavLinks);
      }
      setIsMyProgressFeatureReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programme]);

  // Sets the navbar visible when ready to render
  useEffect(() => {
    if (isMyProgressFeatureReady === true) {
      setIsReady(true);
    }
  }, [isMyProgressFeatureReady]);

  // Sets hide flag
  useEffect(() => {
    if (!currentJourney || !programme) return;

    const step0Completed = currentJourney?.flags?.step0Completed === true ? true : false;
    const supportedUser = currentJourney?.pathway === Pathway.SUPPORTED ? true : false;
    const initialWeightLogged = currentJourney?.flags?.initialWeightLogged === true ? true : false;
    const appointmentBooked = currentJourney?.flags?.appointmentBooked === true ? true : false;

    const logWeight = programme?.features?.logWeight === true ? true : false;
    const bookAppointments = programme?.features?.bookAppointments === true ? true : false;

    if (logWeight && !initialWeightLogged) {
      return setHideNav(true);
    }

    if (
      hideMenu ||
      !step0Completed ||
      (supportedUser && bookAppointments && !appointmentBooked)
    ) {
      return setHideNav(true);
    }

    return setHideNav(false);
  }, [currentJourney, hideMenu, programme]);

  return (
    <AppBar position="static" className={`header__container ${classes.root}`}>
      <Container>
        <Toolbar>
          <List
            component="nav"
            className={`main-nav__container ${classes.navContainerStyles}`}
            role="navigation"
            aria-label="Primary Navigation"
            id="main-navigation"
          >
            <div
              className={`header__logo-container nav-left-block ${
                hideMenu ? "header-item__full-width" : "header-item__container"
              }`}
            >
              <HeaderLogo />
            </div>
            {!hideNav && isReady && (
              <Hidden xsDown>
                <div className="nav-center-block">
                  <MainNavLinks mainNavItems={navLinks} />
                </div>
              </Hidden>
            )}

            <div
              className={`header__user-image-container nav-right-block  ${
                hideMenu ? "header-item__full-width" : "header-item__container"
              }`}
            >
              <LoginMenu />
            </div>
            {!hideNav && isReady && (
              <Hidden smUp>
                <MobileMenu navLinks={navLinks} policyLinks={policyLinks} />
              </Hidden>
            )}
          </List>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;
