import React, { useState, useEffect } from "react";
import moment from "moment";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import "./CustomTabs.scss";
import VideoCards from "../VideoCards";
import ProgressBar from "../../atoms/ProgressBar";
import { ReactComponent as TrophyBlue } from "../../../assets/img/icons/trophyBlue.svg";
import { ReactComponent as TrophyGreen } from "../../../assets/img/icons/trophyGreen.svg";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { post_complete_part } from "../../../store/actions/postCompletePart";
import { profile_loaded } from "../../../store/actions/getProfile";
import { get_specific_programme_step } from "../../../store/actions/getProgrammeStep";
import useUserId from "../../hooks/useUserId";
import { update_current_tab_index } from "../../../store/actions/updateCurrentViewData";
import { CustomModal, ModalContent, ModalTitle, ModalSubtitle } from "../CustomModal";
import { ReactComponent as CelebrationTrophy } from "../../../assets/img/celebration_trophy.svg";
import { PartProvider } from "../../contexts";
import PillTabs from "../../molecules/PillTabs/PillTabs";
import JourneyStates from "../../../enums/journeyStates";
import { ReactComponent as LockIcon } from "../../../assets/img/icons/lockNavy.svg";
import { profileService } from "../../services";
import { programmes } from "../../../enums/programmes";
import { CircularLoader } from "../../atoms/Loaders";
import Toast from "../../atoms/Toast";
import { logException } from "../../../utils/AppInsightsLogging";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  useGetRoutingSlipTrackerQuery,
  useCompletePartCombinedMutation,
} from "../../../redux/programmeApi";

import CircularProgress from "@material-ui/core/CircularProgress";
import { part_completed_hide_modal } from "../../../store/actions/postCompletePart";

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`custom-tabpanel-${index}`}
      aria-labelledby={`custom-tab-${index}`}
      {...other}
    >
      {value === index && <> {children}</>}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const CustomTabs = ({ stepData }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = useUserId();
  const programmeId = localStorage.getItem("programmeID");
  const journeyId = localStorage.getItem("journeyID");
  const [open, setOpen] = useState(false);

  const [rsTrackingNumber, setRsTrackingNumber] = useState(null);

  const currentPart = stepData?.parts[stepData.currentPosition.currentPartNumber - 1];
  const currentTabIndex = useSelector((state) => state.currentViewData.currentTabIndex);
  const step = useSelector((state) => state.programmeStep);
  const profile = useSelector((state) => state.profile);
  const programme = useSelector((state) => state.programmeOverview.data.programme);

  const [routingSlipTracker, setRoutingSlipTracker] = useState(null);

  const [pollingEnabled, setPollingEnabled] = useState(true);
  const [pollingAttempts, setPollingAttempts] = useState(0);
  const [combinedPartCompleteInProgress, setCombinedPartCompleteInProgress] = useState(false);

  const partCompletedShowModal = useSelector(state => state.programmeStep.partCompletedShowModal ?? false);

  const [completePartCombined, result] = useCompletePartCombinedMutation();

  // send combined part complete routing slip
  // once we have a routing slip tracking number, we can start polling for the routing slip tracker
  // a maximum of 5 polling attempts will be made before we stop polling
  const {
    data: rsTracker,
    isError: isErrorRsTracker,
    isUninitialized,
    isSuccess: isSuccessRsTracker,
    refetch: refetchRsTracker,
  } = useGetRoutingSlipTrackerQuery(
    rsTrackingNumber === null ? skipToken : { trackingNumber: rsTrackingNumber, profileId: userId },
    {
      pollingInterval: pollingEnabled ? 1000 : undefined,
    }
    );

  // read state and show the PartCompleted modal if required.
  useEffect(() => {
    if (partCompletedShowModal ?? false === true) {
      setOpen(true);
    }
  }, [partCompletedShowModal]);

  // If all sections in this part have been complete, but the part itself is not complete.  Then invoke the part complete function.

  useEffect(() => {
    let currentPart = step.data.parts[step.data.currentPosition.currentPartNumber - 1];
    if (currentPart.sections.filter(section => section.completed === true).length == currentPart.sections.length) {
      if (currentPart.completed === false && combinedPartCompleteInProgress === false) {
        handlePartComplete(currentPart.partId);
      }
    }
  }, [dispatch, step]);

  useEffect(() => {
    if (rsTrackingNumber !== null) {
      refetchRsTracker();
      setPollingAttempts(0);
      setPollingEnabled(true);
      setRoutingSlipTracker(null);
    }
  }, [rsTrackingNumber]);

  useEffect(() => {
    if (rsTracker?.status) {
      setPollingEnabled(false);
      setCombinedPartCompleteInProgress(false);
    }

    if (pollingAttempts >= 10) {
      setPollingEnabled(false);
    }
    var sum = Number(pollingAttempts) + Number(1);
    setPollingAttempts(sum);
  }, [rsTracker]);

  

  useEffect(() => {
    if (isSuccessRsTracker) {
      setRoutingSlipTracker(rsTracker);
      if (rsTracker.status === "Completed") {
        showPartReward();
      }
    }
  }, [isSuccessRsTracker]);

  useEffect(() => {
    console.log("result updated " + JSON.stringify(result));
    if (result.isSuccess) {
      if (result.data !== {}) {
        setRsTrackingNumber(result.data);
      }
    }
  }, [result]);

  const [value, setValue] = useState(() => {
    if (isNaN(currentTabIndex)) {
      dispatch(update_current_tab_index(0));
      return 0;
    }
    return currentTabIndex;
  });
  const partSlugShort =
    "part-" +
    (currentTabIndex + 1).toLocaleString("en-UK", {
      minimumIntegerDigits: 2,
    });
  const stepId = step.data.stepId;
  const stepNumber = step.data.stepNumber;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const additionalData = (partSlug, partTitle) => {
    return {
      programmeSlug: stepData.programmeSlug,
      stepTitle: stepData.title,
      stepSlug: stepData.stepSlug,
      partSlugShort: partSlugShort,
      partSlug: partSlug,
      partTitle: partTitle,
    };
  };

  const calculatePartProgress = (progress) => {
    const partProgress = Math.round(progress * 100);
    return partProgress;
  };

  const showPartReward = () => {
    //open modal
    setOpen(true);
    document.getElementById("root").classList.add("modal-active");
  };

  //Modal functions
  const handlePartComplete = async (partId) => {
    try {
      if (
        stepData.stepNumber === 0 &&
        profile?.data?.currentJourney?.state === JourneyStates.PENDING
      ) {
        setCombinedPartCompleteInProgress(true);
        completePartCombined({
          profileId: userId,
          journeyId: journeyId,
          programmeId: stepData.programmeId,
          stepId: stepId,
          partId: partId,
          step0Complete: true,
        });
      } else {
        dispatch(post_complete_part(userId, stepData.programmeId, journeyId, partId, stepId));
        showPartReward();
      }
    } catch (error) {
      // TODO: show friendly error message to user here.
      console.log(error);
      logException(error);
    }
  };

  const handleTabClick = (index) => {
    dispatch(update_current_tab_index(index));
  };

  const handleModalClose = async () => {
    dispatch(part_completed_hide_modal());
    const response = await profileService.getProfile(userId);
    if (response && response?.data?.accountState) {
      dispatch(profile_loaded(response.data));
    }
    setOpen(false);
    document.getElementById("root").classList.remove("modal-active");
  };

  const handleLetsMoveOn = () => {
    try {
      // update store with latest step data after completing a part
      dispatch(get_specific_programme_step(userId, programmeId, journeyId, stepNumber));
    } catch (error) {
      // TODO: show friendly error message to user here.
      console.log(error);
      logException(error);
    } finally {
      handleModalClose();
      // NB: logic only works for steps with two tabs
      if (currentTabIndex === 0) {
        handleTabClick(currentTabIndex + 1);
      } else {
        dispatch(update_current_tab_index(0));
        history.push(`/my-path/${stepData.programmeSlug}/step-${stepData.shortTitle}/completed`, {
          completed: true,
          nextStepStartDate: stepData.endDate,
        });
      }
    }
  };

  const handleLetsGo = async () => {
    handleModalClose();
    if (programme?.features?.myProgress === true) {
      return (window.location.href = "/my-progress");
    }
    return (window.location.href = "/");
  };

  const handleReturnMyPath = () => {
    handleModalClose();
    return (window.location.href = "/my-path");
  };

  const handleReturnHome = () => {
    handleModalClose();
    return (window.location.href = "/");
  };

  // Modal Components
  const Modals = () => {
    if (stepData.stepNumber === 0) {
      if (programme?.id === programmes["smoke-free"].id) {
        // Smoke Free
        return (
          <CustomModal
            open={open}
            onClose={handleModalClose}
            id="readyToGoModal"
            modalTitleId="readyToGoModalTitle"
            modalDescriptionId="readyToGoModalDescription"
            aria-labelledby={"readyToGoModalTitle"}
          >
            <ModalContent id="readyToGoModalContent">
              <div className="celebration-img-container"></div>
              <ModalTitle id="readyToGoModalTitle">Ready to go!</ModalTitle>
              <p className="modal-text" id="readyToGoModalDescription">
                Take the first step on your journey with gloji.
              </p>
              <div id="readyToGoModalButtons">
                <Button
                  className="complete-button"
                  variant="contained"
                  color="primary"
                  onClick={handleReturnMyPath}
                >
                  Show me my path
                </Button>
                <Button
                  className="complete-button"
                  variant="contained"
                  color="secondary"
                  onClick={handleReturnHome}
                >
                  Return to home
                </Button>
              </div>
            </ModalContent>
          </CustomModal>
        );
      } else {
        return (
          // Loose Weight
          <CustomModal
            open={open}
            onClose={handleModalClose}
            id="completeFirstPartModal"
            modalTitleId="completeFirstPartModalTitle"
            modalDescriptionId="completeFirstPartModalDescription"
            aria-labelledby={"completeFirstPartModalTitle"}
          >
            <ModalContent id="completeFirstPartModalContent">
              <div className="celebration-img-container"></div>
              <ModalTitle id="completeFirstPartModalTitle">You've got this</ModalTitle>
              <p className="modal-text" id="completeFirstPartModalDescription">
                Today's the start of your journey to a healthier you.
              </p>
              <div id="completeFirstPartModalButtons">
                <Button
                  className="complete-button"
                  variant="contained"
                  color="primary"
                  onClick={handleLetsGo}
                >
                  Let's go!
                </Button>
              </div>
            </ModalContent>
          </CustomModal>
        );
      }
    }

    if (stepData.stepNumber !== 0) {
      return (
        <CustomModal
          open={open}
          onClose={handleModalClose}
          id="completePartModal"
          modalTitleId="completePartModalTitle"
          modalDescriptionId="completePartModalSubtitle"
          aria-labelledby={"completePartModalTitle"}
        >
          <ModalContent id="completePartModalContent" contentClassName="modal-content__flex">
            <CelebrationTrophy id={"celebrationTrophy"} />
            <ModalTitle id="completePartModalTitle">Congratulations</ModalTitle>
            <ModalSubtitle id="completePartModalSubtitle">
              {currentPart?.reward?.text ?? "Congratulations!"}
            </ModalSubtitle>
            <Button
              className="log-weight__submit-button"
              color="primary"
              onClick={handleLetsMoveOn}
            >
              {currentPart?.reward?.buttonText ?? "Continue"}
            </Button>
          </ModalContent>
        </CustomModal>
      );
    }
  };

  const ContinueButton = ({ item, index }) => {
    // for part one for normal steps
    if (item.progress === 1 && index === 0 && !item.completed) {
      return (
        <div className="all-done-button__container">
          <Button
            variant="contained"
            color="primary"
            className="complete-part-button"
            onClick={() => handlePartComplete(item.partId)}
          >
            {combinedPartCompleteInProgress ? (
              <CircularProgress />
            ) : (
              <>
                {stepData.parts.length === 1
                  ? "All done, let's move on"
                  : "All done! Move onto part two"}
              </>
            )}
          </Button>
        </div>
      );
    }

    // for part two for normal steps
    if (item.progress === 1 && index === 1 && !item.completed) {
      return (
        <div className="all-done-button__container">
          <Button
            variant="contained"
            color="primary"
            className=""
            onClick={() => handlePartComplete(item.partId)}
          >
            Great job! Complete step {stepData.stepNumber}
          </Button>
        </div>
      );
    }

    return null;
  };

  return programme ? (
    <div className="custom-tabs">
      {stepData.stepNumber !== 0 && (
        <PillTabs
          stepData={stepData}
          value={value}
          handleChange={handleChange}
          handleTabClick={handleTabClick}
        />
      )}
      <div className="tab-body__container">
        {stepData &&
          stepData.parts.map((item, index) => {
            return (
              <CustomTabPanel value={value} index={index} key={item.title}>
                {stepData.locked && (
                  <div className="lock-state">
                    <LockIcon></LockIcon>
                    <span>Step Unlocks {moment(stepData.unlockDate).format("Do MMMM")}</span>
                  </div>
                )}

                {profile.data.currentJourney.state !== JourneyStates.PENDING &&
                  stepData.stepNumber !== 0 && <h2 className="part__title">{item.title}</h2>}
                {profile.data.currentJourney.state === JourneyStates.PENDING &&
                  stepData.stepNumber === 0 && <h1 className="part__title">{item.title}</h1>}
                <Grid container justifyContent="center" style={{ padding: "0 7px" }}>
                  <Grid item xs={12} sm={7}>
                    <div className="master-progress-bar__container">
                      <div className="master-progress-bar__with-text">
                        <p className="master-progress-bar__text">
                          {calculatePartProgress(item.progress)}% Complete
                        </p>
                        <ProgressBar progress={item.progress > 0 ? item.progress : 0.01} />
                      </div>
                      <div className="master-progress-bar__icon">
                        {calculatePartProgress(item.progress) === 100 ? (
                          <TrophyGreen />
                        ) : (
                          <TrophyBlue />
                        )}
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <ContinueButton item={item} index={index} /> 

                <PartProvider state={item}>
                  <VideoCards
                    data={item.sections}
                    additionalData={additionalData(item.slug, item.title)}
                  />
                </PartProvider>
              </CustomTabPanel>
            );
          })}

        <Modals></Modals>
      </div>
      <Toast
        type="error"
        show={!combinedPartCompleteInProgress && routingSlipTracker?.status === "Faulted"}
      >
        We ran into a problem complting this part, please try again.
      </Toast>
    </div>
  ) : (
    <CircularLoader />
  );
};

export default CustomTabs;
