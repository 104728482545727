import * as T from './MultiStep.type'
import { Step } from '../../atoms/Step'
import './MultiStep.scss'

const MultiStep = ({ list, step, dataTestid, percent }) => (
  <div className="multi-step" data-testid={dataTestid}>
    {list.map((text, key) => (
      <Step
        key={text}
        percent={percent}
        active={step > key}
        current={step === key}
        labelBy={`${dataTestid}-label-${key}`}
        label={text}
      />
    ))}
  </div>
)

MultiStep.defaultProps = T.MultiStep

export default MultiStep
