import React from "react";
import { Helmet } from "react-helmet";

export function Page500() {
  return (
    <main className="container__page-500-error">
      <Helmet>
        <title>Error 500 | Gloji</title>
        <style type="text/css">
          {`
        body {
          background-color: #121e59;
          background: url('/errorBg.jpg') no-repeat center center;
          background-size: cover;          
      }
      
      .container__page-500-error {
          text-align: center;
      }
      
          .container__page-500-error .logo-container {
              min-height: 100px;
              width: 174px;
              margin: 0 auto;
              margin-top: 55px;
          }
      
              .container__page-500-error .logo-container svg {
                  fill: #84f08d;
              }
      
          .container__page-500-error .hero-image-container {
              min-height: 45vh;
              width: 80%;
              margin: 0 auto;
              background: url('/bomb.png') no-repeat center center;
              background-size: contain;
              margin-top: 1rem;
          }
      
          .container__page-500-error .error-message {
              color: #84f08d;
              font-family: "Fredoka One";
              font-size: 50px;
              font-weight: normal;
              margin-bottom: 20px;
          }
      
          .container__page-500-error .contact-text {
              font-size: 20px;
          }
      
          .container__page-500-error p {
              color: white;
              font-family: "Poppins",sans-serif;
          }
      
          .container__page-500-error a {
              color: #84f08d;
          }
      
              .container__page-500-error a:hover {
                  color: #c1f7c6;
              }
      
      @media screen and (min-width:576px) and (max-width:768px) {
          .container__page-500-error .error-message {
              font-size: 40px;
          }
      }
      
      @media screen and (max-width:576px) {
          .container__page-500-error .hero-image-container {
              min-height: 200px;
          }
      
          .container__page-500-error .error-message {
              font-size: 36px;
          }
      }
        
        `}
        </style>
      </Helmet>
      <div className="logo-wrapper">
        <div className="logo-container">
          <a href="/" aria-label="gloji logo">
            <svg
              id="glojiHeaderLogo"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 3267.78 1887.91"
            >
              <path
                d="M3619,1104.12q62.46,47.87,126.89,47.83t128.83-48.8q64.42-48.78,64.42-144.45t-60.51-142.5q-60.54-46.86-130.79-46.85t-130.79,48.8q-60.52,48.81-60.51,143.47T3619,1104.12Z"
                transform="translate(-671.39 -769.35)"
              ></path>
              <path
                d="M3209.57,1104.12Q3272,1152,3336.45,1152t128.84-48.8q64.42-48.78,64.42-144.45t-60.52-142.5q-60.53-46.86-130.78-46.85t-130.79,48.8Q3147.1,867,3147.11,961.62T3209.57,1104.12Z"
                transform="translate(-671.39 -769.35)"
              ></path>
              <path
                d="M1623.63,2110.36c0,46.2-2.44,91.53-12.57,136.76a526.93,526.93,0,0,1-28.87,89.9c-78.54,190.88-276.39,322.58-483.08,320.2h-44.63c-125.27.53-247.64-53.23-332.15-145.53-54.18-58.74-19.75-110.47,39-164.64s119.55-90.3,173.73-31.56c68.65,74.44,205.58,67.44,286.45,17.89,53.52-32.59,93.07-87.18,106.74-148.38.71-3.12,2.79-26.4,4.51-27.16-310,138.42-661.91-106.3-661.33-442.78-3.57-347.4,365.69-595.58,679.77-438.77.6-34.6,26.93-65.44,127.83-65.34,102.51.1,142.59,28.32,144.61,87.75ZM1147.54,1518c-167.6,4.54-250.11,213.38-129,333.16,23.16,22.91,50.32,40.85,81.9,49.72a173,173,0,0,0,87.51,1.33,188.2,188.2,0,0,0,78-40.49,196.51,196.51,0,0,0,56.19-81.84c13.28-36.82,16-78.25,6.16-116.24-8.08-31.08-24.43-59.79-46.11-83.38-21.48-23.37-48.42-42-78.4-52.64A163.65,163.65,0,0,0,1147.54,1518Z"
                transform="translate(-671.39 -769.35)"
              ></path>
              <path
                d="M2303.76,1365q148.77-138.13,340-138.14t338.27,137.26q147,137.27,147,349.78,0,147-76.15,261.24t-185.08,168.25q-108.91,54-225.81,54t-226.7-57.55q-109.83-57.54-185.07-170T2155,1715.67Q2155,1503.15,2303.76,1365Zm209.43,491.61q62.46,47.85,126.89,47.82t128.83-48.8q64.43-48.77,64.42-144.45t-60.51-142.5q-60.54-46.85-130.79-46.85t-130.79,48.8q-60.52,48.82-60.51,143.48T2513.19,1856.61Z"
                transform="translate(-671.39 -769.35)"
              ></path>
              <path
                d="M3912.11,1337.77q1.88,19,1.9,55.06V2032q0,43.69-2.85,65.51t-18,50.31q-24.69,49.4-146.2,49.37-119.62,0-146.2-51.27-13.3-28.47-16.14-49.36t-2.85-66.46V1389q0-43.65,2.85-65.5t16.14-50.32q26.57-49.35,148.1-49.37,91.14,0,129.11,32.28Q3910.2,1282.73,3912.11,1337.77Z"
                transform="translate(-671.39 -769.35)"
              ></path>
              <path
                d="M3500.77,1322.32q2.85,21.86,2.85,65.51V2168.6q0,222.15-153.8,355.06t-338,132.91q-43.65,0-64.55-2.85t-47.47-16.14q-49.35-26.61-49.37-138.6t40.82-140.51Q2932.13,2330,3001.4,2330t122.47-38q53.13-38,53.16-136.7V1374.54q0-66.43,18-97.79t53.17-42.72q35.1-11.38,94-11.39t93,11.39q34.18,11.4,48.42,38.93T3500.77,1322.32Z"
                transform="translate(-671.39 -769.35)"
              ></path>
              <path
                d="M1698.34,1205.23q0-44.38,2.89-66.6t16.41-51.15q27-52.12,150.57-52.12,117.72,0,146.71,52.12,15.43,28.95,18.34,52.12t2.89,67.56v687.1q0,69.49,11.59,87.83t51.15,18.34c26.36,0,34.31,1,43.33,2.9s19.93,7.41,32.81,16.4q38.59,25.11,38.61,137.06,0,121.61-38.61,148.64c-33.48,23.16-103.5,27-229.6,11.58q-148.68-19.28-193-98.45-54.08-94.56-54.05-301.14Z"
                transform="translate(-671.39 -769.35)"
              ></path>
            </svg>
          </a>
        </div>
      </div>
      <div className="hero-image-wrapper">
        <div className="hero-image-container"></div>
      </div>
      <div>
        <h1 className="error-message">Oh no! Looks like our server bombed.</h1>
        <p className="contact-text">
          Head{" "}
          <a href="/" className="link">
            home
          </a>{" "}
          to start over or come back and try again later. Need our help? Contact{" "}
          <a href="mailto:support@gloji.co.uk" className="link">
            support@gloji.co.uk
          </a>
        </p>
      </div>
    </main>
  );
}
