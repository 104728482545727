export const formatValue = (value) => {
  if (value.length === 1) {
    return `0${value}`;
  }
  return value.toString();
};

/**
 * Converts an input age in date format,
 * to an object strings - date, month and year
 *
 * @param {Date} inputAge can be a date or an empty string
 * @returns {Object} {date: "", month: "", year: ""}
 */
export const getInitialAgeValues = (inputAge) => {
  if (Boolean(inputAge)) {
    const inputDate = new Date(inputAge);
    const date = formatValue(inputDate.getDate());
    const month = formatValue(inputDate.getMonth() + 1);
    const year = formatValue(inputDate.getFullYear());
    return { date, month, year };
  }
  return { date: "", month: "", year: "" };
};

/**
 * Determines which error to show
 *
 * @param {import("formik").FormikErrors} errors
 * @param {import("formik").FormikTouched} touched
 * @returns
 */
export const determineErrorFieldName = (errors, touched) => {
  if (touched?.date && Boolean(errors?.date)) return "date";
  if (touched?.month && Boolean(errors?.month)) return "month";
  if (touched?.year && Boolean(errors?.year)) return "year";
  return "date";
};
