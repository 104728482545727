import React, { useEffect, useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { InputLabel } from "@material-ui/core";
import { Form, Formik } from "formik";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import {
  ModalPageHeader,
  ModalPageBody,
  ModalPageFooter,
  ModalPageWrapper,
  ModalPageHeading,
} from "../../../organisms/ModalPage";
import { update_signup_form_values } from "../../../../store/actions/signupFlowData";
import { BmiValidationSchema } from "../EligibilityCheckerValidation";
import WeightCompositeInput from "../../../molecules/WeightCompositeInput";
import UnitOfMeasure from "../../../../enums/UnitOfMeasure";
import HeightCompositeInput from "../../../molecules/HeightCompositeInput";
import { FormikField } from "../../../atoms/FormikField";
import "./Bmi.scss";
import { LoadingPage } from "../LoadingPage";
import bmiCalculator from "../../../../helpers/bmiCalculator";
import { referralService } from "../../../services";
import { mapPathwayCheckValues } from "../helpers";
import Toast from "../../../atoms/Toast";
import { logException } from "../../../../utils/AppInsightsLogging";

const Bmi = () => {
  const dispatch = useDispatch();
  const BmiPageData = useSelector((state) => state.signupFlowData.signupPagesData?.bmi);
  const signupFormValues = useSelector((state) => state.signupFlowData.signupFormValues);

  const bmiCriteria = signupFormValues?.eligibilityCriteria.bmi;
  const history = useHistory();
  const { path } = useRouteMatch();

  const [showLoadingPage, setShowLoadingPage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const initialValues = {
    height: signupFormValues?.height ?? "",
    heightFeet: signupFormValues?.heightFeet ?? "",
    heightInches: signupFormValues?.heightInches ?? "",
    heightDisplayUnitOfMeasure:
      signupFormValues?.heightDisplayUnitOfMeasure ?? UnitOfMeasure.METRIC,
    weight: signupFormValues?.weight ?? "",
    weightStones: signupFormValues?.weightStones ?? "",
    weightPounds: signupFormValues?.weightPounds ?? "",
    weightDisplayUnitOfMeasure:
      signupFormValues?.weightDisplayUnitOfMeasure ?? UnitOfMeasure.METRIC,
  };

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Height & weight | Eligibility checker | Sign up | Gloji",
    });
  }, []);

  function determineBmiPath() {
    if (bmiCriteria !== undefined) {
      const { bmiGroup, bmi } = bmiCalculator(signupFormValues);

      let bmiMinValues = bmiGroup.map((bmiName) => bmiCriteria[bmiName].bmiMin).filter(Boolean);
      let bmiMaxValues = bmiGroup.map((bmiName) => bmiCriteria[bmiName].bmiMax).filter(Boolean);

      let bmiMin = bmiMinValues.length ? Math.min(...bmiMinValues) : null;
      let bmiMax = bmiMaxValues.length ? Math.max(...bmiMaxValues) : null;

      if (bmiMax !== null && bmi > bmiMax) {
        history.push(`${path}/bmitoohigh`);
      } else if (bmiMin !== null && bmi < bmiMin) {
        history.push(`${path}/bmitoolow`);
      } else {
        history.push(`${path}/success`);
      }
      if (bmiMax !== null) {
        if (bmi > bmiMax || bmi < bmiMin) {
          //Trigger google analytics event on ineligible BMI
          window.gtag("event", "ineligibleBMI", {
            event_category: "Eligibility Checker",
            event_label: "Ineligible BMI",
          });
        }
      }
    }
  }

  const handleSubmit = async (values) => {
    dispatch(update_signup_form_values(values));
    setShowLoadingPage(true);
    setErrorMessage("");
    try {
      let payload = mapPathwayCheckValues({ ...signupFormValues, ...values });
      let response = await referralService.checkPathwaySpaces(payload);
      if (response.programmes.length > 0) {
        let offeredSupported = response.programmes[0].offerSupported;
        let offeredDigital = response.programmes[0].offerDigital;

        dispatch(update_signup_form_values({ offeredSupported, offeredDigital }));
      } else {
        throw new Error("An error occurred, please try again.");
      }
    } catch (error) {
      setErrorMessage("An error occurred, please try again");
      logException(error);
    }
  };

  return (
    <>
      {showLoadingPage ? (
        <LoadingPage
          callback={() => {
            if (errorMessage) {
              setShowLoadingPage(false);
            } else {
              determineBmiPath();
            }
          }}
          heading={BmiPageData?.loadingScreen.title ?? ""}
          text={BmiPageData?.loadingScreen.body ?? ""}
        />
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={BmiValidationSchema}
            validateOnChange={false}
          >
            {({ isSubmitting, values, actions }) => (
              <Form className="eligibility-checker-form bmi-form">
                <Helmet>
                  <title>Height & weight | Eligibility checker | Sign up | Gloji</title>
                </Helmet>
                <ModalPageWrapper>
                  <ModalPageBody className="with-sticky-footer">
                    <ModalPageHeader
                      backButtonOnClick={() => {
                        history.goBack();
                      }}
                    >
                      Checking we're a good fit
                    </ModalPageHeader>
                    <ModalPageHeading size="large">
                      {BmiPageData?.questions[0].question}
                    </ModalPageHeading>
                    <InputLabel htmlFor="weight" className="eligibility-checker__input-label">
                      Your weight:
                    </InputLabel>
                    <FormikField
                      className="eligibility-checker__text-input"
                      id="weight"
                      name="weight"
                      component={WeightCompositeInput}
                      type="number"
                    />
                    <InputLabel htmlFor="height" className="eligibility-checker__input-label">
                      Your height:
                    </InputLabel>
                    <FormikField
                      className="eligibility-checker__text-input"
                      id="height"
                      name="height"
                      component={HeightCompositeInput}
                      type="number"
                    />
                    <Accordion
                      square={true}
                      elevation={0}
                      className="eligibility-checker__accrodion"
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon fontSize="large" />}
                        aria-controls={`weight-question-explanation`}
                      >
                        <span>{BmiPageData?.questions[0].whyQuestion}</span>
                      </AccordionSummary>
                      <AccordionDetails>
                        <span>{BmiPageData?.questions[0].whyQuestionDetails}</span>
                      </AccordionDetails>
                    </Accordion>
                  </ModalPageBody>
                  <ModalPageFooter>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Continue
                    </Button>
                  </ModalPageFooter>
                </ModalPageWrapper>
              </Form>
            )}
          </Formik>

          <Toast show={Boolean(errorMessage)} type="error">
            {errorMessage}
          </Toast>
        </>
      )}
    </>
  );
};

export default Bmi;
