import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  ModalPageHeader,
  ModalPageBody,
  ModalPageFooter,
  ModalPageWrapper,
  ModalPageHeading,
  ModalPageQuestionDescription,
} from "../../../organisms/ModalPage";
import RadioButtonGroup from "../../../molecules/RadioButtonGroup/RadioButtonGroup";
import { update_signup_form_values } from "../../../../store/actions/signupFlowData";
import { MentalillnessRegisterValidationSchema } from "../EligibilityCheckerValidation";

const MentalillnessRegister = () => {
  const dispatch = useDispatch();
  const signupFormValues = useSelector((state) => state.signupFlowData.signupFormValues);
  const mentalHealthData = useSelector(
    (state) => state.signupFlowData.signupPagesData["mental-health"]
  );

  let history = useHistory();

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Mental health | Eligibility checker | Sign up | Gloji",
    });
  }, []);

  const handleSubmit = (values) => {
    if (values.mentalillnessRegister === "yes") {
      history.push(`/mental-health/mental-health-management/fail`);
    } else {
      history.push(`/disabilities`);
    }

    // Save values to store
    dispatch(update_signup_form_values(values));
  };

  const initialValues = {
    mentalillnessRegister: signupFormValues?.mentalillnessRegister ?? "",
  };

  const yesNoOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={MentalillnessRegisterValidationSchema}
        validateOnChange={false}
      >
        {({ isSubmitting, values, touched, errors }) => (
          <Form className="eligibility-checker-form mental-health-form">
            <Helmet>
              <title>Mental health| Eligibility checker | Sign up | Gloji</title>
            </Helmet>
            <ModalPageWrapper>
              <ModalPageBody className="with-sticky-footer">
                <ModalPageHeader
                  backButtonOnClick={() => {
                    history.goBack();
                  }}
                >
                  Checking we're a good fit
                </ModalPageHeader>

                <ModalPageHeading>{mentalHealthData?.questions[2].question}</ModalPageHeading>
                <ModalPageQuestionDescription>
                  {mentalHealthData?.questions[2].description}
                </ModalPageQuestionDescription>
                <RadioButtonGroup
                  options={yesNoOptions}
                  name="mentalillnessRegister"
                  hasErrors={errors["mentalillnessRegister"] && touched["mentalillnessRegister"]}
                />
              </ModalPageBody>
              <ModalPageFooter className="sticky-footer">
                <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                  Continue
                </Button>
              </ModalPageFooter>
            </ModalPageWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MentalillnessRegister;
