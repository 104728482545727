import classNames from "classnames";
import { ErrorMessage, useField } from "formik";
import { useEffect } from "react";
import { RemoveSpaces } from "../../../utils/StringUtils";
import CheckboxGroupContainer from "../../atoms/CheckboxGroupContainer";
import FormikCheckbox from "../../atoms/FormikCheckbox";
import { CustomField } from "../../atoms/InputField";
import "./CheckboxGroupWithFreeText.scss";

export const CheckboxGroupWithFreeText = ({ id, name, options, additionalText, sampleAnwser }) => {
  const [field] = useField(name);
  const [fieldOther, metaOther] = useField(name + "Other");
  const [_, __, helpersOtherCombined] = useField(name + "OtherCombined");

  useEffect(() => {
    let indexOfOther = field.value.indexOf("Other");
    if (indexOfOther !== -1) {
      let fullValue = [...field.value];
      fullValue = fullValue.filter((x) => x !== "Other");
      fullValue.push(fieldOther.value);
      helpersOtherCombined.setValue(fullValue);
    } else {
      helpersOtherCombined.setValue(field.value);
    }
  }, [field.value, fieldOther.value]);

  return (
    <>
      <CheckboxGroupContainer name={name} id={id}>
        {options.map((item, itemIndex) => {
          const cleanItem = RemoveSpaces(item);
          return (
            <FormikCheckbox
              key={cleanItem + itemIndex}
              id={cleanItem + itemIndex}
              name={name}
              label={item}
              value={item}
            />
          );
        })}
        <FormikCheckbox
          key={"Other" + (options.length + 1)}
          id={"Other" + (options.length + 1)}
          name={name}
          label={"Other"}
          value={"Other"}
        />
      </CheckboxGroupContainer>
      {field.value?.includes("Other") && (
        <>
          <CustomField
            name={name + "Other"}
            id={name + "Other"}
            className={classNames("programme-answer__input", {
              "programme-answer__input--error": metaOther.error && metaOther.touched,
            })}
            component={"textarea"}
            multiline
            rows={10}
            placeholder={"Tell us your answer..."}
          />
          <span className="programme-answer__validation-message">
            <ErrorMessage name={name + "Other"} />
          </span>
        </>
      )}
    </>
  );
};
