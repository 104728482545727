import { useCallback } from "react";
import MuiTextField from "@material-ui/core/TextField";

function FormikUppercaseInputOnly({
  field: { ...fields },
  form: { setFieldValue, touched, errors },
  label,
  ...props
}) {
  const onChange = useCallback(
    event => {
      let { value } = event.target;
      if (value !== "" && props.mask) {
        const mask = props.mask;
        value = value.match(mask)[0];
      }
      setFieldValue(fields.name, value ? value.toUpperCase() : "");
    },
    [props.mask, setFieldValue, fields.name]
  );
  return (
    <MuiTextField
      {...props}
      {...fields}
      error={Boolean(touched[fields.name] && errors[fields.name])}
      onChange={onChange}
    />
  );
}

export default FormikUppercaseInputOnly;
