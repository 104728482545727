import {
  LOAD_CONTENT,
  CONTENT_LOADED,
  ALL_CONTENT_LOADED,
  LOAD_CONTENT_FAILED,
  CLEAR_CONTENT_LOADED,
} from "../constants";

const initialState = {
  loading: true,
  data: [],
  allContentLoaded: false,
  error: null,
  errorStatus: false,
};

const load_content = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CONTENT:
      return { ...state, loading: true };
    case CLEAR_CONTENT_LOADED:
      return initialState;
    case CONTENT_LOADED:
      if (state.data.length === 0) {
        return { ...state, data: action.payload, loading: false };
      }
      return { ...state, data: [...state.data, ...action.payload], loading: false };
    case ALL_CONTENT_LOADED:
      return {
        ...state,
        loading: false,
        allContentLoaded: true,
      };
    case LOAD_CONTENT_FAILED:
      return { ...state, error: action.error, errorStatus: true, loading: false };
    default:
      return state;
  }
};

export default load_content;
