import { matchPath } from "react-router";

const isEligiblityCheckerPath = (path) => {
  const matchEligibiliyCheckerPath = matchPath(`${path}`, {
    path: "/sign-up/eligibility-checker",
    strict: false,
    exact: false,
  });

  return matchEligibiliyCheckerPath !== null && matchEligibiliyCheckerPath.isExact === true;
};

const isProfileBuilderPath = (path) => {
  const matchNewProfileBuilderPath = matchPath(`${path}`, {
    path: "/sign-up/profile-builder",
    strict: false,
    exact: false,
  });
  const matchOldProfileBuilderPath = matchPath(`${path}`, {
    path: "/profile-builder",
    strict: false,
    exact: false,
  });

  return (
    (matchNewProfileBuilderPath !== null && matchNewProfileBuilderPath.isExact === true) ||
    (matchOldProfileBuilderPath !== null && matchOldProfileBuilderPath.isExact === true)
  );
};

const isAccountVerifyPath = (path) => {
  const matchAccountVerifyPath = matchPath(`${path}`, {
    path: "/account/verify-email",
    strict: false,
    exact: false,
  });

  return matchAccountVerifyPath !== null && matchAccountVerifyPath.isExact === true;
};

export { isEligiblityCheckerPath, isProfileBuilderPath, isAccountVerifyPath };
