import React from "react";
import PropTypes from "prop-types";

/**
 * Renders a page background along with other page content.
 * @param {String} bgImage - The background image to render.
 * @param {ReactChildren} children - Other page content.
 */
const ModalPageBackground = ({ bgImage, children, className }) => {
  return (
    <div
      className={`main-page modal-page ${className ? className : ""}`}
      style={{ backgroundImage: bgImage ? `url(${bgImage})` : "" }}
    >
      {children}
    </div>
  );
};

ModalPageBackground.propTypes = {
  bgImage: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default ModalPageBackground;
