import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PageBackground from "../../organisms/PageBackground";
import { myProgressBg } from "../../../assets/img/bg";
import { Container, Grid } from "@material-ui/core";
import authService from "../../auth/AuthorizeService";
import { ButtonLink } from "../../atoms/Button";
import useResendLink from "../../hooks/useResendLink";
import Toast from "../../atoms/Toast";
import { logException } from "../../../utils/AppInsightsLogging";

const EmailVerification = () => {
  const [userEmail, setUserEmail] = useState();
  const [response, resendLink] = useResendLink(userEmail);

  useEffect(() => {
    authService
      .getUser()
      .then((res) => {
        setUserEmail(res.emails[0]);
      })
      .catch((error) => {
        logException(error);
        console.log(error);
      });
  }, []);

  const handleResendLink = () => {
    resendLink();
  };

  useEffect(() => {
    //Trigger google analytics event at the start of email verification
    window.gtag("event", "accountCreated", {
      event_category: "Email verification",
      event_label: "Account Created",
    });

    window.gtag("event", "page_view", {
      page_title: "Verify Email | Sign up | Gloji",
    });
  }, []);

  return (
    <PageBackground bgImage={myProgressBg} className="verify-email-page">
      <Helmet>
        <title> Verify Email | Sign up | Gloji </title>
      </Helmet>
      <>
        <Container>
          <Grid container justifyContent="center">
            <div className="verify-email-page__wrapper">
              <div className="verify-email-page__content-container">
                <div className="verify-email-page__content-wrapper">
                  <h1 className="verify-email-page__title"> Verification </h1>
                  <h2 className="verify-email-page__sub-title">Verify your email address</h2>
                  <p className="verify-email-page__copy">
                    <span style={{ overflowWrap: "break-word" }}>
                      We want to make sure we’ve got everything right so we’ve sent you an email
                      to&nbsp;
                      {userEmail}
                    </span>
                    <span style={{ marginTop: "30px" }}>
                      Just click the link in the email (don’t take too long, it expires in 1 hour)
                      and we can keep on rolling with your sign-up.
                    </span>
                    <span style={{ marginTop: "15px", fontSize: "16px" }}>
                      Can’t see it? Might be hiding in your spam folder.
                    </span>
                  </p>
                  <ButtonLink
                    label="Resend link"
                    color="secondary"
                    style={{ fontWeight: "600" }}
                    onClick={handleResendLink}
                  />
                </div>
              </div>
              <Toast show={response.linkSent} type="success">
                Email on it's way to {userEmail}
              </Toast>
              <Toast show={response.errorStatus} type="error">
                Error occurred: {response.error}
              </Toast>
            </div>
          </Grid>
        </Container>
      </>
    </PageBackground>
  );
};
export default EmailVerification;
