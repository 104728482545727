import React from "react";
import PropTypes from "prop-types";

const ModalPageSvg = ({ children, className }) => {
  return <div className={`modal-page__svg ${className}`}>{children}</div>;
};
ModalPageSvg.propTypes = {
  children: PropTypes.node,
};

export default ModalPageSvg;
