import authAxios from "../../helpers/authAxios";
import {
  LOAD_PROGRAMME_STEP,
  PROGRAMME_STEP_LOADED,
  LOAD_PROGRAMME_STEP_FAILED,
} from "../constants";

import { logException } from "../../utils/AppInsightsLogging";
import { urls } from "../../configs/serviceConfig";

const load_programme_step = () => {
  return {
    type: LOAD_PROGRAMME_STEP,
  };
};

const programme_step_loaded = (payload) => {
  return {
    type: PROGRAMME_STEP_LOADED,
    payload,
  };
};

const load_programme_step_failed = (error) => {
  return {
    type: LOAD_PROGRAMME_STEP_FAILED,
    error,
  };
};

export const get_programme_step = (userId, programmeId, journeyId) => {
  let url = `${urls.programmeService}/user-programme/${programmeId}/user/${userId}/journey/${journeyId}/current-step`;

  return (dispatch) => {
    dispatch(load_programme_step());
    authAxios
      .get(url)
      .then((response) => {
        dispatch(programme_step_loaded(response.data));
      })
      .catch((error) => {
        logException(error);
        dispatch(load_programme_step_failed(error));
      });
  };
};

export const get_specific_programme_step = (userId, programmeId, journeyId, stepNumber) => {
  let url = `${urls.programmeService}/user-programme/${programmeId}/user/${userId}/journey/${journeyId}/step/${stepNumber}`;

  return (dispatch) => {
    dispatch(load_programme_step());
    authAxios
      .get(url)
      .then((response) => {
        dispatch(programme_step_loaded(response.data));
      })
      .catch((error) => {
        logException(error);
        dispatch(load_programme_step_failed(error));
      });
  };
};
