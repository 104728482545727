export const firstLanguageOptions = [
  { value: "Arabic", label: "Arabic" },
  { value: "Bengali", label: "Bengali" },
  { value: "English", label: "English" },
  { value: "French", label: "French" },
  { value: "German", label: "German" },
  { value: "Gujarati", label: "Gujarati" },
  { value: "Japanese", label: "Japanese" },
  {
    value: "Mandarin",
    label: "Mandarin",
  },
  { value: "Punjabi", label: "Punjabi" },
  { value: "Portuguese", label: "Portuguese" },
  { value: "Polish", label: "Polish" },
  { value: "Russian", label: "Russian" },
  { value: "Spanish", label: "Spanish" },
  { value: "Urdu", label: "Urdu" },
  { value: "Other", label: "Other, not listed" },
];
