import { Route, Switch, useRouteMatch } from "react-router";
import PathwayEligibleScreen from "./PathwayEligibleScreen";


const PathwayRoot = (props) => {
  let { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route exact path={`${path}`} component={PathwayEligibleScreen} />
      </Switch>
    </>
  );
};

export default PathwayRoot;
