import React, { Component, Fragment } from "react";
import authService from "./AuthorizeService";
import { ApplicationPaths } from "./ApiAuthorizationConstants";
import ProfileDropDown from "../organisms/NavBar/ProfileDropDown";
import { Menu, MenuItem } from "@material-ui/core";

// const CustomList = withStyles({
//   root: {
//     alignItems: "center",
//     display: "flex",
//     "& .MuiListItem-root": {
//       whiteSpace: "nowrap",
//     },
//   },
// })(List);

const dropDownItems = [
  /*
  { name: "my account", link: "my-account" },
  { name: "settings", link: "settings" },
  */
];

export class LoginMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      userName: null,
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  async populateState() {
    const [isAuthenticated, user] = await Promise.all([
      authService.isAuthenticated(),
      authService.getUser(),
    ]);
    this.setState({
      isAuthenticated,
      userName: user && user.given_name,
    });
  }

  render() {
    const { isAuthenticated, userName } = this.state;
    if (!isAuthenticated) {
      const signUpPath = `${ApplicationPaths.Signup}`;
      const loginPath = `${ApplicationPaths.Login}`;
      return this.anonymousView(signUpPath, loginPath);
    } else {
      const profilePath = `${ApplicationPaths.Profile}`;
      const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
      return this.authenticatedView(userName, profilePath, logoutPath);
    }
  }

  authenticatedView(userName, profilePath, logoutPath) {
    return (
      <Fragment>
        <ProfileDropDown logOutPath={logoutPath} dropDownItems={dropDownItems} />
      </Fragment>
    );
  }

  anonymousView(signUpPath, loginPath) {
    return (
      <div className="">
        <Menu open={false}>
          <MenuItem>Profile</MenuItem>
          <MenuItem>Account</MenuItem>
        </Menu>
      </div>
    );
  }
}
