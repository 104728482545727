import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NAVY } from "../../../enums/brandColors";
import { Select } from "formik-material-ui";
import { FormControl, InputLabel } from "@material-ui/core/";

import { Field } from "formik";

const useStyles = makeStyles(() => ({
  root: {},
  formControl: {
    minWidth: "100%",
    "& .MuiFormLabel-root": {
      fontSize: "0.85rem",
      color: `${NAVY}`,
      position: "relative",
      marginBottom: "0.5rem",
    },
    "& legend": {
      display: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      top: "0px",
    },

    "& .MuiInputLabel-root ": {
      transform: "none",
    },

    "& .MuiSelect-icon": {
      color: NAVY,
    },
  },
}));

const CustomSelect = (props) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor={props.id}>{props.label} </InputLabel>
      <Field
        fullWidth
        variant="outlined"
        type={props.type}
        id={props.id}
        name={props.name}
        label={props.label}
        placeholder={props.placeholder}
        component={Select}
        InputLabelProps={{
          shrink: true,
        }}
        select={true}
      >
        {props.children}
      </Field>
    </FormControl>
  );
};
export default CustomSelect;
