import { LOAD_ALL_WEIGHTS, ALL_WEIGHTS_LOADED, LOAD_ALL_WEIGHTS_FAILED } from "../constants";

const initialState = {
  loading: false,
  data: [],
  errorStatus: false,
  error: null,
};

const get_all_weights_reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ALL_WEIGHTS:
      return { ...state, loading: true };
    case ALL_WEIGHTS_LOADED:
      return { ...state, loading: false, data: action.payload };
    case LOAD_ALL_WEIGHTS_FAILED:
      return { ...state, loading: false, error: action.error, errorStatus: true };
    default:
      return state;
  }
};

export default get_all_weights_reducer;
