import PropTypes from "prop-types";

const HeroBannerContainer = ({ children, bgImg }) => {
  return (
    <section className="hero-banner__container" style={{ backgroundImage: `url(${bgImg})` }}>
      {children}
    </section>
  );
};

HeroBannerContainer.propTypes = {
  children: PropTypes.node,
  bgImg: PropTypes.string,
};

export default HeroBannerContainer;
