import axios from "../../helpers/authAxios";
import {
  LOAD_PERSONALISED_CONTENT,
  PERSONALISED_CONTENT_LOADED,
  LOAD_PERSONALISED_CONTENT_FAILED,
} from "../constants";
import { urls } from "../../configs/serviceConfig";
import { logException } from "../../utils/AppInsightsLogging";

export const load_personalised_content = () => {
  return { type: LOAD_PERSONALISED_CONTENT };
};

export const personalised_content_loaded = (payload) => {
  return { type: PERSONALISED_CONTENT_LOADED, payload };
};
export const load_personalised_content_failed = (error) => {
  return { type: LOAD_PERSONALISED_CONTENT_FAILED, error };
};
export const fetch_personalised_content = (id, publicTags) => {
  const url = `${urls.userFeedService}/user/${id}/content?publicTags=${publicTags}`;
  return (dispatch) => {
    dispatch(load_personalised_content());
    axios
      .get(url)
      .then((response) => dispatch(personalised_content_loaded(response.data)))
      .catch((error) => {
        logException(error);
        dispatch(load_personalised_content_failed(error));
      });
  };
};
