import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import { LargeButtonWithIcon } from "../../atoms/LargeButtonWithIcon";
import { referralService } from "../../services";
import { logException } from "../../../utils/AppInsightsLogging";

import "./ActivationKey.scss";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const ExpiredKey = () => {
  const [newLinkSent, setNewLinkSent] = useState(false);
  let query = useQuery();
  let referralCode = query.get("referralCode");

  const handleSendNewSignupLink = async () => {
    // Perform api call, handle errors
    var data = {
      referralCode: referralCode,
    };
    try {
      var response = await referralService.post("/activation-keys", data);
      if (response.referralCode === referralCode) {
        setNewLinkSent(true);
      } else {
        alert("Unable to send new activation key at this time. Please try again later!");
      }
    } catch (error) {
      setNewLinkSent(false);
      logException(error);
      alert("An error occured sending your activation key. Please try again later!");
    }
  };

  const handleShowSendNewSignupLink = () => {
    setNewLinkSent(false);
  };

  return (
    <>
      <div className="signup-link__content-container">
        {!newLinkSent ? (
          <Container>
            <Helmet>
              <title>Expired Key | Sign Up | Gloji</title>
            </Helmet>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} md={12}>
                <h1 className="signup-link__page-title"> Oops! Your link to sign up expired</h1>
                <p className="signup-link__page-text">
                  Don’t worry, it’s not a problem, we can send you a new one.
                  <br /> For it to work, just be sure to use it within 7 days.
                </p>
                <LargeButtonWithIcon onClick={handleSendNewSignupLink} />
              </Grid>
            </Grid>
          </Container>
        ) : (
          <Container>
            <Helmet>
              <title>New Key Requested | Sign Up | Gloji</title>
            </Helmet>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={12} md={10}>
                <h1 className="signup-link__page-title"> It’s on its way</h1>
                <p className="signup-link__page-text">
                  We’ve sent you another chance to sign up. It might take a few minutes to land in
                  your inbox so don’t worry if you can’t see it right away. <br /> <br /> Checked
                  your junk mail? Still nothing after 5 minutes? &nbsp;
                  <span
                    className="link"
                    onClick={handleShowSendNewSignupLink}
                    aria-label="Click this to try again"
                  >
                    You can always try again.
                  </span>
                </p>
              </Grid>
            </Grid>
          </Container>
        )}
      </div>
    </>
  );
};
