import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import { Container } from "@material-ui/core";
import * as Yup from "yup";
import { FormikWizard, FormikWizardStep } from "../../../organisms/MultiStepForms/StepWizard";
import { UserProfileVm, mapUserProfile } from "../UserProfileVm";
import {
  PersonalDetailsValidation,
  EthnicityValidation,
  HealthConditionsValidation,
  MotivationValidation,
} from "../UserProfileValidation";
import authService from "../../../auth/AuthorizeService";
import UnitOfMeasure from "../../../../enums/UnitOfMeasure";
import unitConverter from "../../../../utils/UnitConverter";
import {
  EthnicityForm,
  HealthConditionForm,
  MotivationForm,
  PersonalDetailsForm,
} from "../../../molecules/profileForms";
import { CircularLoader } from "../../../atoms/Loaders";
import {
  CustomModal,
  ModalContent,
  ModalTitle,
  ModalCloseButton,
} from "../../../organisms/CustomModal";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import "../profile.scss";
import { profileService } from "../../../services";

/**
 * Profile builder wizard component.
 * Uses FormikWizard and FormikWizardStep components to compose the profile builder forms into a multi-step form.
 * @returns Profile Builder Wizard
 */
const ProfileBuilder = () => {
  const [user, setUser] = useState({});
  const [ready, setReady] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    async function getUser() {
      var loggedInUser = await authService.getUser();
      setUser(loggedInUser);
      setReady(true);
    }
    getUser();
  }, []);

  const submitForm = async (values, actions) => {
    if (values.heightDisplayUnitOfMeasure === UnitOfMeasure.IMPERIAL) {
      const cm = unitConverter.feetAndInchesToCentimeters(values.heightFeet, values.heightInches);
      values.height = cm.toFixed(2);
    }
    if (values.weightDisplayUnitOfMeasure === UnitOfMeasure.IMPERIAL) {
      const kg = unitConverter.poundsToKilograms(
        unitConverter.stonesToPounds(values.weightStones) + Number(values.weightPounds)
      );
      values.weight = kg.toFixed(2);
    }
    var profileData = mapUserProfile(values, user);
    var response = await profileService.postProfile(profileData.userId, profileData);
    if (response.status === 201 || response.status === 200) {
      setIsSuccess(true);
      setOpen(true);
      // window.location.href = "/";
    } else {
      setIsSuccess(false);
      setOpen(true);
    }
  };
  const handleModalClose = () => {
    setOpen(false);
    window.location.href = "/";
  };

  return !ready ? (
    <CircularLoader />
  ) : user && user.profile && user.profile.userId ? (
    <Redirect to="/" />
  ) : (
    <Container className="profile-builder-container">
      <Helmet>
        <title>Profile builder | Gloji</title>
      </Helmet>
      <FormikWizard
        title={`Welcome to Gloji`}
        description="To get you started, let's build your profile"
        headerTextCenter
        finishButtonClasses="next-btn"
        nextButtonClasses="next-btn"
        previousButtonClasses="back-btn"
        completedIcon="far fa-check-circle fa-sm"
        initialValues={UserProfileVm}
        onSubmit={submitForm}
      >
        <FormikWizardStep
          stepName="Personal Details"
          validationSchema={Yup.object(PersonalDetailsValidation)}
        >
          <PersonalDetailsForm />
        </FormikWizardStep>
        <FormikWizardStep
          stepName="Ethnicity & Language"
          validationSchema={Yup.object(EthnicityValidation)}
        >
          <EthnicityForm />
        </FormikWizardStep>
        <FormikWizardStep
          stepName="Health"
          validationSchema={Yup.object(HealthConditionsValidation)}
        >
          <HealthConditionForm />
        </FormikWizardStep>
        <FormikWizardStep stepName="Motivation" validationSchema={Yup.object(MotivationValidation)}>
          <MotivationForm />
        </FormikWizardStep>
      </FormikWizard>
      <CustomModal
        open={open}
        onClose={handleModalClose}
        id="profileCreationModal"
        modalTitleId="profileCreationModalTitle"
        modalDescriptionId="profileCreationModalDescription"
      >
        <ModalContent id="profileCreationModalContent">
          {isSuccess ? (
            <>
              <div className="celebration-img-container"></div>
              <ModalTitle id="profileCreationModalTitle">All done!</ModalTitle>
              <p className="modal-text" id="profileCreationModalDescription">
                Now we know a little more about you, you're ready to start using gloji.
              </p>
              <Button
                className="lets-go-button"
                variant="contained"
                color="primary"
                onClick={() => (window.location.href = "/")}
              >
                Let's go
              </Button>
            </>
          ) : (
            <>
              <ModalTitle id="profileCreationModalTitle">Something’s gone wrong</ModalTitle>
              <p className="modal-text">
                Give it another try. If that doesn’t work, you can reach us at
                <a href="mailto:support@gloji.co.uk" className="link default">
                  &nbsp;support@gloji.co.uk
                </a>
              </p>
              <Button
                className="lets-go-button"
                variant="contained"
                color="primary"
                onClick={() => (window.location.href = "/")}
              >
                Try again
              </Button>
            </>
          )}
        </ModalContent>
        <ModalCloseButton onClick={handleModalClose} />
      </CustomModal>
    </Container>
  );
};

export default ProfileBuilder;
