import { Alert, AlertTitle } from "@material-ui/lab";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import HeroImage from "../../../../assets/img/welcomeToGloji.jpg";
import { get_signup_copy } from "../../../../store/actions/signupFlowData";
import { CircularLoader } from "../../../atoms/Loaders";
import MarkedDownContent from "../../../organisms/MarkedDownContent";
import {
  ModalPageBody,
  ModalPageCopy,
  ModalPageFooter,
  ModalPageHeading,
  ModalPageHeroImage,
  ModalPageWrapper,
} from "../../../organisms/ModalPage";
import "./LandingPage.scss";

const LandingPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const signupFlowData = useSelector((state) => state.signupFlowData);

  useEffect(() => {
    dispatch(get_signup_copy("profile-builder"));
  }, [dispatch]);

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Welcome to Gloji | Profile builder | Sign up | Gloji",
    });
  }, []);

  const handleLetsFinishOff = () => {
    //Trigger google analytics event at the start of profile builder journey
    window.gtag("event", "startProfileBuilder", {
      event_category: "Profile builder",
      event_label: "Start Profile Builder",
    });
    history.push("/first-language");
  };

  return (
    <>
      <Helmet>
        <title>Welcome to Gloji | Profile builder | Sign up | Gloji</title>
      </Helmet>
      <ModalPageWrapper>
        <ModalPageBody hasStickyFooter={false}>
          {signupFlowData.loading === true ? (
            <CircularLoader />
          ) : signupFlowData.errorStatus === true ? (
            <>
              <ModalPageCopy>
                <Alert severity="error" style={{ marginTop: "2rem" }}>
                  <AlertTitle>{signupFlowData.error.message} </AlertTitle>
                  Failed to load data
                </Alert>
              </ModalPageCopy>
            </>
          ) : signupFlowData.data.landingPage ? (
            <>
              <ModalPageHeading size="large">
                {signupFlowData.data.landingPage.title}
              </ModalPageHeading>
              <ModalPageHeroImage
                className="landing-page-image"
                imageUrl={HeroImage}
                altText="Welcome to Gloji"
              />
              <ModalPageCopy>
                <MarkedDownContent content={signupFlowData.data.landingPage.body} />
              </ModalPageCopy>
            </>
          ) : (
            <CircularLoader />
          )}
        </ModalPageBody>
        <ModalPageFooter hasStickyFooter={false}>
          <Button onClick={handleLetsFinishOff} variant="contained" color="primary">
            Let's finish off
          </Button>
        </ModalPageFooter>
      </ModalPageWrapper>
    </>
  );
};

export default LandingPage;
