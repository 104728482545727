export const ProfileBuilderSteps = {
  BloodReadings: `/profile-builder/date-of-birth`,
  DiabetesRiskScore: `/profile-builder/marketing-consent`,
  General: `/profile-builder/pregnancy`,
};

export type ProfileBuilderStepsType = typeof ProfileBuilderSteps;

export enum ProfileBuilderStep {
  Landing,
  DateOfBirth,
  MarketingConsent,
  Pregnancy,
  ReadySetGo,
}
