import "./ConfigurableProfileBuilder.scss";

import {
  ProfileBuilderConfiguration,
  Page,
} from "../../../../../types/ProfileBuilderConfig";
import { DateOfBirth } from "./DateOfBirth";
import { MarketingConsent } from "./MarketingConsent";
import { Pregnancy } from "./Pregnancy";
import { ProfileBuilderStep } from "./profileBuilderSteps";
import { useState, useEffect } from "react";
import LandingPage from "../../SmokeFree/LandingPage/LandingPage";
import ReadySetGo from "../../ReadySetGo/ReadySetGo";
import { ModalPageBackground } from "../../../../organisms/ModalPage";
import eligibilityCheckerBg from "../../../../../assets/img/bg/eligibilityCheckerBg.png";
import useUserId from "../../../../hooks/useUserId";
import { useGetProfileByIdQuery } from "../../../../../redux/profileApi";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { Sex } from "../../../../../types/ProfileViewModel";

interface Props {
  builderConfig: ProfileBuilderConfiguration;
}

export const ConfigurableProfileBuilder: React.FC<Props> = (props: Props) => {
  const [steps, setSteps] = useState<ProfileBuilderStep[]>([]);
  const [currentStep, setCurrentStep] = useState<ProfileBuilderStep>();
  const [userId] = useUserId();
  const { data: profile } = useGetProfileByIdQuery(userId ?? skipToken);

  useEffect(() => {
    setSteps([ProfileBuilderStep.Landing]);

    props.builderConfig.pages?.forEach((page: Page) => {
      if (page.name === "Date of Birth") {
        setSteps((steps) => [...steps, ProfileBuilderStep.DateOfBirth]);
      }
      if (page.name === "Marketing Consent") {
        setSteps((steps) => [...steps, ProfileBuilderStep.MarketingConsent]);
      }
      if (
        page.name === "Pregnancy" &&
        profile.personalDetails.birthSex !== Sex.Male
      ) {
        setSteps((steps) => [...steps, ProfileBuilderStep.Pregnancy]);
      }
    });

    setSteps((steps) => [...steps, ProfileBuilderStep.ReadySetGo]);
  }, []);

  useEffect(() => {
    setCurrentStep(steps[0]);
  }, [steps]);

  const navigateToNextStep = () => {
    const currentIndex = steps.indexOf(currentStep);

    const nextStep = steps[currentIndex + 1];
    setCurrentStep(nextStep);
  };

  const navigateToPreviousStep = () => {
    const currentIndex = steps.indexOf(currentStep);

    const nextStep = steps[currentIndex - 1];
    setCurrentStep(nextStep);
  };

  return (
    <ModalPageBackground
      className="profile-builder-page configurable-profile-builder"
      bgImage={eligibilityCheckerBg}
    >
      {currentStep === ProfileBuilderStep.Landing && (
        <LandingPage navigatToNextStep={navigateToNextStep} />
      )}
      {currentStep === ProfileBuilderStep.DateOfBirth && (
        <DateOfBirth
          navigatToNextStep={navigateToNextStep}
          navigateToPreviousStep={navigateToPreviousStep}
        />
      )}
      {currentStep === ProfileBuilderStep.MarketingConsent && (
        <MarketingConsent
          navigatToNextStep={navigateToNextStep}
          navigateToPreviousStep={navigateToPreviousStep}
        />
      )}
      {currentStep === ProfileBuilderStep.Pregnancy && (
        <Pregnancy
          navigatToNextStep={navigateToNextStep}
          navigateToPreviousStep={navigateToPreviousStep}
        />
      )}
      {currentStep === ProfileBuilderStep.ReadySetGo && <ReadySetGo />}
    </ModalPageBackground>
  );
};
