/**
 * Units utility class for converting between different measurement units.
 */
class UnitConverter {
  /**
   * Converts inches to feet
   *
   * @param inches
   * @returns {array} [feet, inches]
   */
  inchesToFeet(inches) {
    const feet = Math.trunc(Number(inches) / 12);
    const inchesRemainder = Math.round(inches % 12);

    if (inchesRemainder === 12) return [feet + 1, 0];

    return [feet, inchesRemainder];
  }

  /**
   * Converts inches to centimeters
   *
   * @param inches
   * @returns {number} in centimeters
   */
  inchesToCentimeters(inches) {
    return Number(inches) / 0.393701;
  }

  /**
   * Converts centimeters to feet and inches
   *
   * @param centimeters
   * @returns {array} [feet, inches]
   */
  centimetersToFeetAndInches(centimeters) {
    const totalInches = Number(centimeters) / 2.54;
    const feet = Math.floor(totalInches / 12);
    const inches = totalInches - 12 * feet;
    return [feet, inches];
  }

  /**
   * Converts feet and inches to centimeters
   *
   * @param feet
   * @param inches
   * @returns {number} in centimeters
   */
  feetAndInchesToCentimeters(feet, inches) {
    return Number(feet) * 30.48 + Number(inches) * 2.54;
  }

  /**
   * Converts centimeters to inches
   *
   * @param centimeters
   * @returns {number} in inches
   */
  centimetersToInches(centimeters) {
    return Number(centimeters) * 0.393701;
  }

  /**
   * Converts Centimeters to meters
   *
   * @param centimeters
   * @returns {Array} [meters, centimeters]
   */
  centimetersToMeters(centimeters) {
    const meters = Number(centimeters) / 100;
    const centimeterRemainder = parseInt((meters % 1) * 100, 10);

    return [Math.floor(meters), centimeterRemainder];
  }

  /**
   * Converts pounds to kilograms
   *
   * @param pounds
   * @returns {number} in kilograms
   */
  poundsToKilograms(pounds) {
    return Number(pounds) / 2.20462262185;
  }

  /**
   * Converts kilograms to pounds
   *
   * @param kgs
   * @returns {number} in pounds
   */
  kilogramsToPounds(kgs) {
    return Number(kgs) * 2.20462262185;
  }

  /**
   * Converts kilograms to stones and pounds
   *
   * @param kgs
   * @returns {array} [stones, pounds]
   */
  kilogramsToStonesAndPounds(kgs) {
    const totalPounds = Number(kgs) * 2.20462262185;
    const stones = Math.floor(totalPounds / 14);
    const pounds = totalPounds - 14 * stones;
    return [stones, pounds];
  }

  /**
   * Converts pounds to stones
   *
   * @param pounds
   * @returns {Array} [stones, pounds]
   */
  poundsToStones(pounds) {
    const stones = Number(pounds) / 14;
    const poundsRemainder = (stones % 1) * 14;
    const stonesRemainder = Math.trunc(stones);

    return [stonesRemainder, poundsRemainder];
  }

  /**
   * Converts kilograms to stones
   *
   * @param kilograms
   * @returns {number} in stones
   */
  kilogramsToStones(kgs) {
    return Number(kgs) * 0.157473;
  }

  /**
   * Converts stones to pounds
   * @param stones
   * @returns {number} in pounds
   */
  stonesToPounds(stones) {
    return Number(stones) * 14;
  }

  static get instance() {
    return unitConverter;
  }
}

const unitConverter = new UnitConverter();

export default unitConverter;
