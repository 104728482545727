import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

const appInsightsConnectionString = window.env.appInsightsCs;

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    connectionString: appInsightsConnectionString,
    extensions: [reactPlugin],
  },
});

if (appInsightsConnectionString) {
  appInsights.loadAppInsights();
}

export { reactPlugin, appInsights };
