import React from "react";
import PropTypes from "prop-types";

const ModalPageHeading = ({ children, size = "small" }) => {
  return (
    <h1 className={`modal-page__title${size === "large" ? " modal-page__title--large" : ""}`}>
      {children}
    </h1>
  );
};
ModalPageHeading.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.oneOf(["small", "large"]),
};

export default ModalPageHeading;
