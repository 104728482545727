import PropTypes from "prop-types";

/**
 * Generates a simple alphanumeric ID with specified character length.
 * Useful for React key props or throw-away IDs/Keys
 * @param {number} keyLength The length of the ID to generate. Default is 8 characters.
 * @returns Unique key or ID.
 */
function SimpleId(keyLength = 8) {
  return Math.random().toString(36).substr(2, Number(keyLength));
}

SimpleId.propTypes = {
  keyLength: PropTypes.number,
};

export default SimpleId;
