import * as T from './Step.type'
import './Step.scss'
import { ReactComponent as TickIcon } from "../../../assets/img/icons/tick.svg";

const Step = ({ percent, active, current, dataTestid, labelBy, label }) => (
  <div
    data-testid={dataTestid}
    percent={percent}
    active={active}
    className={`step${current ? '__current' : ''}`}
  >
    <div
      role="progressbar"
      aria-labelledby={labelBy}
      active={active}
      className={`step__disc${active ? '--active' : ''}`}
    >
      {active && <TickIcon />}
    </div>

    <div className="step__label" id={labelBy}>{label}</div>
  </div>
)

Step.defaultProps = T.Step

export default Step
