import { React, useEffect, useState } from "react";
import "./NumericalInput.scss";
import { Field } from "formik";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classnames from "classnames";
import { useField } from "formik";

export const NumericalInput = ({
  id,
  name,
  maxValue,
  minValue = 0,
  additionalText,
  sampleAnwser,
  interval = 1,
}) => {
  const [addDisabled, setAddDisabled] = useState(false);
  const [subtractDisabled, setSubtractDisabled] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  const [field, meta, helpers] = useField(name);

  useEffect(() => {
    setAddDisabled((field.value ?? 0) >= maxValue);
    setSubtractDisabled((field.value ?? 0) <= minValue);
  }, [field.value, maxValue, minValue]);

  return (
    <div className="numerical-input">
      {sampleAnwser && <div className="numerical-input__sample-answer">{sampleAnwser}</div>}

      <div
        className={classnames("numerical-input__container", {
          "numerical-input__container--error": meta.error && meta.touched,
        })}
      >
        <Field
          className="numerical-input__field"
          id={id}
          name={name}
          onFocus={() => setIsFocus(true)}
          onBlur={() => {
            setIsFocus(false);
            helpers.setTouched(name);
          }}
          onChange={field.onChange}
          variant="outlined"
          value={field.value}
        />
        <div
          className={classnames("numerical-input__buttons", {
            "numerical-input__buttons--active": isFocus,
          })}
        >
          <button
            type="button"
            className={classnames({ "button--disabled": addDisabled })}
            onClick={(e) => {
              let parsedInput = parseFloat(field.value);
              helpers.setValue(
                // This is a way to deal with the floating point errors in js
                isNaN(parsedInput) ? minValue : parseFloat((parsedInput + interval).toFixed(10)),
                true
              );
            }}
            disabled={addDisabled}
          >
            <ExpandMoreIcon className="up-arrow" role="presentation" />
          </button>
          <button
            type="button"
            className={classnames({ "button--disabled": subtractDisabled })}
            onClick={(e) => {
              let parsedInput = parseFloat(field.value);
              helpers.setValue(
                // This is a way to deal with the floating point errors in js
                isNaN(parsedInput) ? minValue : parseFloat((parsedInput - interval).toFixed(10)),
                true
              );
            }}
            disabled={subtractDisabled}
          >
            <ExpandMoreIcon className="down-arrow" role="presentation" />
          </button>
        </div>
      </div>
      {additionalText && <div className="numerical-input__additional">additionalText</div>}
    </div>
  );
};
