import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import ArticleCard from "../../molecules/Card";
import { clear_content, fetch_content } from "../../../store/actions/getContent";
import RecipeCards from "../RecipeCards";

const SkeletonCards = ({ qty }) => {
  const Cards = [];
  for (let i = 0; i < qty; i++) {
    Cards.push(
      <Grid item xs={12} sm={6} md={4} lg={4} key={Math.floor(Math.random() * 1000000)}>
        <ArticleCard loading={true} cardTitle="" description="" imgUrl="" articleTypes={[""]} />
      </Grid>
    );
  }
  return Cards;
};

const InfiniteScrollRecipeCards = ({ pillarTags = null }) => {
  const dispatch = useDispatch();
  const content = useSelector((state) => state.content);
  const [take, setTake] = useState(6);
  const [skip, setSkip] = useState(0);
  const observer = useRef();

  useEffect(() => {
    dispatch(clear_content());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetch_content(pillarTags, take, skip, undefined, "recipe"));
    setSkip((prev) => prev + take);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const loadMore = () => {
    if (content.allContentLoaded) return;
    dispatch(fetch_content(pillarTags, take, skip, undefined, "recipe"));
    setSkip((prev) => prev + take);
  };

  const lastArticleElementRef = useCallback(
    (node) => {
      if (content.loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) loadMore();
      });
      if (node) observer.current.observe(node);
    },
    [content]
  );

  return (
    <section>
      <RecipeCards data={content.data} ref={lastArticleElementRef} />
      {content.loading && !content.allContentLoaded && (
        <Grid container className="recipe-cards__container" spacing={4}>
          <SkeletonCards qty={take} />
        </Grid>
      )}
    </section>
  );
};

InfiniteScrollRecipeCards.propTypes = {
  pillarTags: PropTypes.array,
};

export default InfiniteScrollRecipeCards;
