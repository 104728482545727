import React from "react";
import Calendar from "react-calendar";
import PropTypes from "prop-types";
import moment from "moment";
import { ReactComponent as ArrowBack } from "../../../assets/img/arrowLeft.svg";
import { ReactComponent as ArrowForward } from "../../../assets/img/arrowRight.svg";

import "./CustomCalendar.scss";

const CustomCalendar = ({
  dateValue,
  setDateValue,
  checkDisabledDates,
  minBookingDate,
  maxBookingDate,
  closeCalendar,
  submitCalendar,
  error,
}) => {
  const navigationAriaLabel = moment().format("MMMM YYYY");

  return (
    <>
      <div
        className={`calendar-container ${
          error.present && error.type === "calendar" && "calendar-container--error"
        }`}
      >
        <div className="calendar-header">
          <span>Select a date</span>
        </div>

        <Calendar
          onChange={setDateValue}
          value={dateValue}
          tileDisabled={checkDisabledDates}
          view={"month"}
          minDate={minBookingDate}
          maxDate={maxBookingDate}
          navigationAriaLabel={navigationAriaLabel}
          prevLabel={<ArrowBack className="arrow" />}
          prev2Label={<></>}
          prevAriaLabel={"Previous"}
          nextLabel={<ArrowForward className="arrow" />}
          next2Label={<></>}
          nextAriaLabel={"Next"}
        />

        <div className="calendar-footer">
          <button onClick={() => closeCalendar()}>Cancel</button>
          <button onClick={() => submitCalendar()} disabled={dateValue === undefined}>
            Ok
          </button>
        </div>
      </div>

      {error.present && error.type === "calendar" && (
        <div className="calendar-error-msg">
          <span>Press ok to confirm your appointment date</span>
        </div>
      )}
    </>
  );
};

CustomCalendar.propTypes = {
  dateValue: PropTypes.instanceOf(Date),
  setDateValue: PropTypes.func.isRequired,
  checkDisabledDates: PropTypes.func.isRequired,
  maxBookingDate: PropTypes.instanceOf(Date).isRequired,
  closeCalendar: PropTypes.func.isRequired,
  submitCalendar: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
};

export default CustomCalendar;
