import React from "react";
import { Helmet } from "react-helmet";
import { greyVelvet } from "../../../assets/img/bg";
import { recipesBg } from "../../../assets/img/bg";
import PageBackground from "../../organisms/PageBackground";
import { PageHeroBanner } from "../../organisms/HeroBanner";
import "./Recipes.scss";

import { Container } from "@material-ui/core";
import InfiniteScrollRecipeCards from "../../organisms/InfiniteScrollRecipeCards/InfiniteScrollRecipeCards";

const Recipes = () => {
  return (
    <>
      <PageBackground bgImage={greyVelvet}>
        <Helmet>
          <title>Latest recipes | Gloji</title>
        </Helmet>
        <div className="recipes-page">
          <PageHeroBanner bgImg={recipesBg}>
            <div className="recipes-banner__header">
              <h1 className="recipes-banner__heading">Recipes</h1>
            </div>
          </PageHeroBanner>
          <Container className="main-content__container recipes-content">
            <InfiniteScrollRecipeCards />
          </Container>
        </div>
      </PageBackground>
    </>
  );
};

export default Recipes;
