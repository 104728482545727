import "./MiddleBanner.scss";
import MiddleBannerContainer from "./MiddleBannerContainer";
import MiddleBannerContent from "./MiddleBannerContent";
import PropTypes from "prop-types";
import { glojiGymBg } from "../../../assets/img/bg";

const MiddleBanner = ({ username }) => {
  return (
    <MiddleBannerContainer bgImg={glojiGymBg || null}>
      <MiddleBannerContent> </MiddleBannerContent>
    </MiddleBannerContainer>
  );
};

MiddleBanner.propTypes = {
  username: PropTypes.string,
};

export default MiddleBanner;
