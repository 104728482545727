import authAxios from "../../helpers/authAxios";
import { COMPLETE_VIDEO, VIDEO_COMPLETED, COMPLETE_VIDEO_FAILED } from "../constants";
import { urls } from "../../configs/serviceConfig";
import { logException } from "../../utils/AppInsightsLogging";

const complete_video = () => {
  return {
    type: COMPLETE_VIDEO,
  };
};

const video_completed = (payload) => {
  return {
    type: VIDEO_COMPLETED,
    payload,
  };
};

const complete_video_failed = (error) => {
  return {
    type: COMPLETE_VIDEO_FAILED,
    error,
  };
};

export const post_complete_video = (
  userId,
  programmeId,
  journeyId,
  stepId,
  videoId,
  secondsWatched,
  completed
) => {
  const url = `${urls.programmeService}/user-programme/${programmeId}/user/${userId}/journey/${journeyId}/complete-video`;

  return (dispatch) => {
    dispatch(complete_video());

    authAxios
      .post(url, {
        stepId: stepId,
        videoId: videoId,
        secondsWatched: secondsWatched,
        completed: completed,
      })
      .then(async (response) => {
        await dispatch(video_completed(response.data));
      })
      .catch((error) => {
        logException(error);
        dispatch(complete_video_failed(error));
      });
  };
};
