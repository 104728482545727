import { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./NavDropdownMenu.scss";

const NavDropdownMenu = ({ className, id }) => {
  let location = useLocation();

  useEffect(() => {
    // check and apply active class on the menuItem
    if (className) {
      const container = document.getElementsByClassName(className);
      if (container) {
        const matches = container[0].querySelectorAll(".active");
        matches.length > 0
          ? container[0].classList.add("active")
          : container[0].classList.remove("active");
      }
    }
  }, [className, location]);

  return (
    <li
      id="dropdown-menu-item__container"
      className={classnames("dropdown-menu-item__container", className)}
    >
      <span className="main-nav-links__dropdown-item" data-for={id} data-tip>
        <span aria-label="explore" id="explore-nav-item">
          explore
          <ExpandMoreIcon className="arrow-icon" role="presentation" />
        </span>
      </span>
      <ReactTooltip
        id={id}
        className="dropdown-menu__tooltip"
        effect="solid"
        delayHide={200}
        delayUpdate={500}
        place={"bottom"}
        clickable={true}
        arrowColor="transparent"
        backgroundColor="#121e59"
      >
        <div className="dropdown-menu">
          <ul>
            <li className="dropdown-menu__links">
              <NavLink to={`/explore/articles`} exact={true} aria-label={`visit articles page`}>
                articles
              </NavLink>
            </li>
            <li className="dropdown-menu__links">
              <NavLink to={`/explore/recipes`} exact={true} aria-label="visit recipes page">
                recipes
              </NavLink>
            </li>
          </ul>
        </div>
      </ReactTooltip>
    </li>
  );
};

NavDropdownMenu.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default NavDropdownMenu;
