import React, { useEffect, useState } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import { FormControl, FormHelperText, FormGroup } from "@material-ui/core";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { referralService } from "../../../services";
import {
  ModalPageHeader,
  ModalPageBody,
  ModalPageFooter,
  ModalPageWrapper,
  ModalPageHeading,
  ModalPageQuestionDescription,
} from "../../../organisms/ModalPage";
import { update_signup_form_values } from "../../../../store/actions/signupFlowData";
import { DataInformationConsentValidationSchema } from "../EligibilityCheckerValidation";
import FormikCheckbox from "../../../atoms/FormikCheckbox";
import CheckboxGroupContainer from "../../../atoms/CheckboxGroupContainer";
import "./DataInformationConsent.scss";
import { mapSignupFormValues } from "../helpers";
import Toast from "../../../atoms/Toast";
import authChallenge from "../../../auth/AuthChallenge";

const DataConsentFieldNames = [
  "privacyPolicyAgreement",
  "dataProcessingAgreement",
  "gpContactAgreement",
];

const CheckboxFormGroup = ({ name, label, touched, hasErrors, showError }) => {
  return (
    <>
      <FormGroup
        className={`checkboxes__container ${
          touched && hasErrors && showError ? "checkboxes__container--has-errors" : ""
        }`}
      >
        <FormikCheckbox key={name} id={name} name={name} label={label} />
      </FormGroup>
      {showError && <ErrorMessage className="Mui-error" component={FormHelperText} name={name} />}
    </>
  );
};

const getDataConsentFieldWithErrors = (errors) => {
  return DataConsentFieldNames.map((name) => {
    return { name: name, hasErrors: errors[name] };
  }).filter((errorList) => errorList.hasErrors);
};

const CheckboxFormControl = ({ touched, errors, children, className }) => {
  let validErrors = getDataConsentFieldWithErrors(errors);

  return (
    <FormControl component="fieldset" className={`$checkbox-group__container ${className ?? ""}`}>
      <FormGroup
        className={`checkboxes__container ${
          touched && validErrors.length > 1 ? "checkboxes__container--has-errors" : ""
        }`}
      >
        {children}
      </FormGroup>
      {validErrors.length > 1 && (
        <ErrorMessage
          className="Mui-error"
          component={FormHelperText}
          name={validErrors[0]?.name}
        />
      )}
    </FormControl>
  );
};

const DataInformationConsent = () => {
  const dispatch = useDispatch();
  const signupFormValues = useSelector((state) => state.signupFlowData.signupFormValues);

  const history = useHistory();
  let { path } = useRouteMatch();
  const [errorMessage, setErrorMessage] = useState("");

  const initialValues = {
    privacyPolicyAgreement: signupFormValues?.privacyPolicyAgreement ?? false,
    dataProcessingAgreement: signupFormValues?.dataProcessingAgreement ?? false,
    gpContactAgreement: signupFormValues?.gpContactAgreement ?? false,
    marketingAgreement: signupFormValues?.marketingAgreement ?? false,
  };

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Consent | Eligibility checker | Sign up | Gloji",
    });
  }, []);

  const handleSubmit = async (values) => {
    let isSuccessfulSignUp = true;

    // Save values to store
    dispatch(update_signup_form_values(values));

    //Trigger google analytics event on end of eligibility checker journey
    window.gtag("event", "completeEligibilityChecker", {
      event_category: "Eligibility Checker",
      event_label: "completeEligibilityChecker",
    });

    window.dataLayer.push({
      event: "c_events",
      event_category: "Signup",
      event_action: "Eligibility Checker Complete",
    });

    const formValues = mapSignupFormValues({ ...signupFormValues, ...values });
    setErrorMessage("");
    try {
      let response = await referralService.createNewSelfReferral(formValues);
      let queryParams = {
        login_hint: signupFormValues.email,
        referral_code: response.referralCode,
      };
      try {
        await authChallenge.signUp(queryParams);
      } catch (error) {
        isSuccessfulSignUp = false;
      }
    } catch (error) {
      setErrorMessage(error?.message);
    }
    if (!isSuccessfulSignUp) {
      history.push(`${path}/error`);
    }
  };

  return (
    <>
      <Toast show={Boolean(errorMessage)} type="error">
        {errorMessage}
      </Toast>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={DataInformationConsentValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ isSubmitting, values, touched, errors }) => (
          <Form className="eligibility-checker-form data-information-consent-form">
            <Helmet>
              <title>Consent | Eligibility checker | Sign up | Gloji</title>
            </Helmet>
            <ModalPageWrapper>
              <ModalPageBody className="with-sticky-footer">
                <ModalPageHeader
                  backButtonOnClick={() => {
                    history.goBack();
                  }}
                />

                <ModalPageHeading>Your consent</ModalPageHeading>
                <ModalPageQuestionDescription>
                  Before we carry on, we need to check you're happy with a few things.
                </ModalPageQuestionDescription>
                <ol className="modal-page__question-description">
                  <li>
                    You've read, understand and will abide by our <strong>Privacy Notice</strong>{" "}
                    <a
                      href="https://app.gloji.co.uk/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>
                    . Take a moment to read it as it covers how we collect, use, disclose and
                    protect your personal information.
                  </li>
                  <li>
                    You're happy with us <strong>processing your health data</strong>, including
                    collecting, storing and using this data within the platform. You can find out
                    more about how we keep your data safe by reading our Privacy Notice{" "}
                    <a
                      href="https://app.gloji.co.uk/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>
                    .
                  </li>

                  <li>
                    You're happy for our <strong>Marketing</strong> team to get in touch to share
                    messages like promotional offers, newsletters and service updates.
                  </li>
                </ol>
                <ModalPageQuestionDescription>
                  Your consent is voluntary and you can opt out at any time, but please note that
                  certain features of our platform may be unavailable without your consent.
                </ModalPageQuestionDescription>
                <ModalPageQuestionDescription>
                  Got a question or concern? Get in touch: support@gloji.co.uk.
                </ModalPageQuestionDescription>
                <ModalPageQuestionDescription>
                  Happy to proceed? Great! Click below to confirm you've read, understand and give
                  your consent.
                </ModalPageQuestionDescription>
                <CheckboxFormControl
                  touched={touched}
                  errors={errors}
                  className="i-agree-checkbox-container"
                >
                  <CheckboxFormGroup
                    name="privacyPolicyAgreement"
                    label="I've read and agree to the Privacy Notice."
                    touched={touched["privacyPolicyAgreement"]}
                    hasErrors={errors["privacyPolicyAgreement"]}
                    showError={getDataConsentFieldWithErrors(errors).length === 1}
                  />
                  <CheckboxFormGroup
                    name="dataProcessingAgreement"
                    label={
                      "I'm happy with you processing my health data and sharing it with Public Health for reporting purposes."
                    }
                    touched={touched["dataProcessingAgreement"]}
                    hasErrors={errors["dataProcessingAgreement"]}
                    showError={getDataConsentFieldWithErrors(errors).length === 1}
                  />
                  <CheckboxFormGroup
                    name="gpContactAgreement"
                    label={"I'm happy with you sharing the outcome of my programme with my GP."}
                    touched={touched["gpContactAgreement"]}
                    hasErrors={errors["gpContactAgreement"]}
                    showError={getDataConsentFieldWithErrors(errors).length === 1}
                  />
                </CheckboxFormControl>
                <CheckboxGroupContainer name="dataInformation">
                  <FormikCheckbox
                    key={"marketingAgreement"}
                    id={"marketingAgreement"}
                    name="marketingAgreement"
                    label={"I consent to receiving marketing communications."}
                  />
                </CheckboxGroupContainer>
              </ModalPageBody>
              <ModalPageFooter className="sticky-footer">
                <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                  Create account
                </Button>
              </ModalPageFooter>
            </ModalPageWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default DataInformationConsent;
