import {
  alcoholBg,
  AlcoholIcon,
  mindBg,
  MindIcon,
  movementBg,
  MovementIcon,
  nutritionBg,
  NutritionIcon,
  sleepBg,
  SleepIcon,
  SmokeFreeIcon,
  smokingBg,
} from "../assets/img/articles";

export const pillars = {
  alcohol: {
    headerBackground: alcoholBg,
    icon: <AlcoholIcon />,
    title: "Alcohol",
    tag: ["drinkLess"],
  },
  mind: {
    headerBackground: mindBg,
    icon: <MindIcon />,
    title: "Mind",
    tag: ["thinkHappy"],
  },
  movement: {
    headerBackground: movementBg,
    icon: <MovementIcon />,
    title: "Movement",
    tag: ["moveMore"],
  },
  nutrition: {
    headerBackground: nutritionBg,
    icon: <NutritionIcon />,
    title: "Nutrition",
    tag: ["eatWell"],
  },
  sleep: {
    headerBackground: sleepBg,
    icon: <SleepIcon />,
    title: "Sleep",
    tag: ["sleepBetter"],
  },
  smoking: {
    headerBackground: smokingBg,
    icon: <SmokeFreeIcon />,
    title: "Smoking",
    tag: ["smokeFree"],
  },
};
