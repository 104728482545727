const IsValueEmpty = (value) => {
  return (
    typeof value === undefined ||
    value === undefined ||
    value === null ||
    value === "" ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

export const RemoveSpaces = (s) => {
  return !IsValueEmpty(s) ? s.replace(/\s+/g, "") : s;
};
