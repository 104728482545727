import React from "react";
import PropTypes from "prop-types";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const ArrowBack = withStyles({
  root: {
    fontSize: "inherit",
  },
})(ArrowBackIosIcon);

const BackButtonLink = ({ linkPath, className, btnText, ariaLabel, onClick, ...rest }) => {
  return (
    <Link
      to={linkPath}
      className={`back-button-link ${className || ""}`}
      aria-label={ariaLabel}
      onClick={onClick}
      {...rest}
    >
      <ArrowBack role="presentation" className="icon" />
      {btnText}
    </Link>
  );
};

BackButtonLink.propTypes = {
  linkPath: PropTypes.string.isRequired,
  className: PropTypes.string,
  btnText: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default BackButtonLink;
