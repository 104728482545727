import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "../../atoms/EditIcon";
import { usePart, useSections } from "../../contexts";
import { useSimplePager } from "../../hooks/useSimplePager";
import Stepper from "../Stepper";
import { handleA11yClick } from "../../../helpers/a11yHelpers";
import { CustomModal, ModalContent, ModalCloseButton } from "../../organisms/CustomModal";
import { QuestionForm } from "./QuestionForm";
import { TopRightModalCloseButton } from "../../atoms/TopRightModalCloseButton";

import "./QuestionReview.scss";

const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
    backgroundColor: "white",
    "& .MuiSvgIcon-root": {
      fontSize: "35px",
      color: "#121e59 ",
    },
    "& .MuiIconButton-root": {
      padding: 0,
    },
    "& .MuiIconButton-edgeEnd": {
      marginRight: 0,
      marginLeft: -7,
    },
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  expandIcon: {
    fontSize: "35px",
    color: "#121e59 ",
  },
  summary: {
    display: "flex",
    flexDirection: "row-reverse",
    height: 40,
    minHeight: 0,
    padding: 0,
    paddingTop: 5,
    "&.Mui-expanded": {
      minHeight: 0,
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: 0,
    },
  },
  details: {
    display: "block",
    padding: 0,
  },
});

function QuestionReview({ sectionId, videoLocation }) {
  const [open, setOpen] = useState(false);
  const { part } = usePart();
  const section = useSections(sectionId);
  const classes = useStyles();
  const { state, dispatch, NEXT, PREVIOUS } = useSimplePager({
    total: section?.questions?.length ?? 1,
    page: 1,
  });

  let index = state.page - 1;
  let showEditButton = part.completed !== true;
  let hasMultipleQuestions = state.total > 1;

  const editYourThoughts = () => {
    // if (videoLocation && videoLocation.pathname && videoLocation.state) {
    //   videoLocation.state = { ...videoLocation.state, openQuestions: true };
    //   history.push(videoLocation.pathname, videoLocation.state);
    // }
    handleModalOpen();
  };

  // Modal functions
  const handleModalOpen = () => {
    setOpen(true);
    document.getElementById("root").classList.add("modal-active");
  };

  const handleModalClose = () => {
    setOpen(false);
    document.getElementById("root").classList.remove("modal-active");
  };

  return (
    <>
      {section && section.completed && section?.questions?.length > 0 ? (
        <div className="question-review">
          <hr />
          <Accordion className={classes.root} square={true} elevation={0}>
            <AccordionSummary
              className={classes.summary}
              expandIcon={<ExpandMoreIcon className={classes.expandIcon} fontSize="large" />}
              aria-controls={`${sectionId}-questions-content`}
              id={`${sectionId}-questions-header`}
            >
              <Typography>
                <b>Show your thoughts</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              {hasMultipleQuestions && (
                <Stepper steps={section.questions} currentStep={state.page} />
              )}

              <div className="question-review__body">
                <p className="question-text">
                  <em>{section.questions[index].questionText}</em>
                </p>
                <p className="answer-text">
                  <b>Your thoughts:</b>{" "}
                  {section.questions[index].responseGiven
                    ? section.questions[index].response.join(", ")
                    : null}
                </p>
              </div>
              <hr />

              <div
                className={`question-review__footer ${
                  showEditButton
                    ? "space-between"
                    : hasMultipleQuestions
                    ? "flex-end"
                    : "display-none"
                }`}
              >
                {showEditButton && (
                  <div
                    className="button edit-thoughts-button"
                    tabIndex="0"
                    onClick={editYourThoughts}
                    onKeyPress={(event) => handleA11yClick(event, () => editYourThoughts())}
                  >
                    <span className="edit-icon">
                      <EditIcon />
                    </span>
                    <span className="edit-text">Edit your thoughts</span>
                  </div>
                )}
                {hasMultipleQuestions && (
                  <div className="pager">
                    <span
                      className={`button pager-previous ${state.page === 1 && "first"} `}
                      tabIndex="0"
                      onClick={() => dispatch(PREVIOUS)}
                      onKeyPress={(event) => handleA11yClick(event, () => dispatch(PREVIOUS))}
                    >
                      Previous
                    </span>
                    <span className="pager-divider">|</span>
                    <span
                      className={`button pager-next ${state.page === state.total && "last"} `}
                      tabIndex="0"
                      onClick={() => dispatch(NEXT)}
                      onKeyPress={(event) => handleA11yClick(event, () => dispatch(NEXT))}
                    >
                      Next
                    </span>
                  </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      ) : null}
      <CustomModal
        open={open}
        onClose={handleModalClose}
        id="questionModal"
        modalTitleId="questionModal"
        modalDescriptionId=""
        aria-labelledby={"question"}
      >
        <TopRightModalCloseButton onClick={handleModalClose} />
        <ModalContent
          containerClassName={"programme-questions__modal-content"}
          id="questionModalContent"
        >
          {section.questions[index] !== undefined && (
            <QuestionForm question={section.questions[index]} onSuccess={handleModalClose} />
          )}
        </ModalContent>
      </CustomModal>
    </>
  );
}

QuestionReview.propTypes = {
  sectionId: PropTypes.string.isRequired,
  videoLocation: PropTypes.object.isRequired,
};

export default QuestionReview;
