const Features = {
  // List of features. For example:
  // SOME_FEATURE_NAME: "1234-whatever-it-is-called"
  PILLAR_FILTER: "10073-pillar-filter",
  ARTICLES: "233-gloji-articles",
  CONFIGURABLE_PROFILE_BUILDER: "20407-profile-builder",
  ARTICLES_MULTIPLE_JOURNEYS: '21363-articles-multiple-journeys',
};

export default Features;
