import { useEffect } from "react";

import { useRouteMatch, Switch, Route } from "react-router";
import AgeChecker from "./AgeChecker";
import { useSelector } from "react-redux";
import { IneligibleScreen } from "../ResultScreens";
import usePostcodeCheck from "../../../hooks/usePostcodeCheck";

const AgeCheckerRoot = (props) => {
  let { path } = useRouteMatch();
  const agePageData = useSelector((state) => state.signupFlowData.signupPagesData?.age);
  const [response, getPostcodeChecked] = usePostcodeCheck();
  const storePostcode = useSelector((state) => state.signupFlowData.signupFormValues?.postcode);

  useEffect(() => {
    getPostcodeChecked(storePostcode);
  }, []);

  const AgeIneligibleScreen = () => {
    return (
      <IneligibleScreen
        title="Ineligible age | Eligibility checker | Sign up | Gloji"
        heading={agePageData.ineligibleScreens.null.title}
        bodyText={
          agePageData.ineligibleScreens.null.body.replace(
            "{{serviceAgeCriteria}}",
            response.data?.eligibilityCriteria.minimumAge
          ) ?? ""
        }
        hasContactUs={true}
      />
    );
  };

  return (
    <>
      <Switch>
        <Route exact path={`${path}`} component={AgeChecker} />
        <Route path={`${path}/fail`} component={AgeIneligibleScreen} />
      </Switch>
    </>
  );
};

export default AgeCheckerRoot;
