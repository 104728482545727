import { UPDATE_CURRENT_TAB_INDEX } from "../constants";

// This will contain various actions that are needed to update
// the store with app's current page compoenents state

export const update_current_tab_index = (payload) => {
  return {
    type: UPDATE_CURRENT_TAB_INDEX,
    payload,
  };
};
