import React, { useEffect } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Form, Formik } from "formik";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  ModalPageHeader,
  ModalPageBody,
  ModalPageFooter,
  ModalPageWrapper,
  ModalPageHeading,
  ModalPageQuestionDescription,
  ModalPageSubHeading,
} from "../../../organisms/ModalPage";
import { update_signup_form_values } from "../../../../store/actions/signupFlowData";
import { KeepInTouchValidationSchema } from "../EligibilityCheckerValidation";
import FormikCheckbox from "../../../atoms/FormikCheckbox";
import CheckboxGroupContainer from "../../../atoms/CheckboxGroupContainer";
import "./KeepInTouch.scss";

const KeepInTouch = () => {
  const dispatch = useDispatch();
  const signupFormValues = useSelector((state) => state.signupFlowData.signupFormValues);
  const KeepInTouchData = useSelector(
    (state) => state.signupFlowData.signupPagesData["keep-in-touch"]
  );

  const history = useHistory();

  const initialValues = {
    keepInTouch: signupFormValues?.keepInTouch ?? false,
    preferences: signupFormValues?.preferences ?? [],
  };

  const preferences = [
    { value: "email", label: "Email" },
    { value: "sms", label: "SMS" },
    { value: "phone", label: "Phone" },
  ];

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Keep in touch | Eligibility checker | Sign up | Gloji",
    });
  }, []);

  const handleSubmit = async (values) => {
    // Save values to store
    dispatch(update_signup_form_values(values));
    history.push(`/data-information-consent`);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={KeepInTouchValidationSchema}
      validateOnChange={true}
    >
      {({ isSubmitting, values, touched, errors }) => (
        <Form className="eligibility-checker-form keep-in-touch-form">
          <Helmet>
            <title>Consent | Eligibility checker | Sign up | Gloji</title>
          </Helmet>
          <ModalPageWrapper>
            <ModalPageBody className="with-sticky-footer">
              <ModalPageHeader
                backButtonOnClick={() => {
                  history.goBack();
                }}
              />
              <ModalPageHeading>{KeepInTouchData?.questions[0].question}</ModalPageHeading>
              <>
                <ModalPageSubHeading> About your gloji programme </ModalPageSubHeading>
                <ModalPageQuestionDescription>
                  {KeepInTouchData?.questions[0].description}
                </ModalPageQuestionDescription>
                <CheckboxGroupContainer className="i-agree-checkbox-container" name="keepInTouch">
                  <FormikCheckbox
                    key={"keepInTouch"}
                    id={"keepInTouch"}
                    name="keepInTouch"
                    label={"I agree"}
                  />
                </CheckboxGroupContainer>
                <Accordion square={true} elevation={0} className="eligibility-checker__accrodion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon fontSize="large" />}
                    aria-controls={`keep-in-touch-question-explanation`}
                  >
                    <span>{KeepInTouchData?.questions[0].whyQuestion}</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>{KeepInTouchData?.questions[0].whyQuestionDetails}</span>
                  </AccordionDetails>
                </Accordion>
              </>

              <>
                <ModalPageSubHeading> About new, shiny, exciting things </ModalPageSubHeading>
                <ModalPageQuestionDescription>
                  {KeepInTouchData?.questions[1].description}
                </ModalPageQuestionDescription>
                <CheckboxGroupContainer name="preferences">
                  {preferences.map((item) => (
                    <FormikCheckbox
                      key={item.value}
                      id={item.value}
                      name="preferences"
                      label={item.label}
                      value={item.value}
                    />
                  ))}
                </CheckboxGroupContainer>
                <Accordion square={true} elevation={0} className="eligibility-checker__accrodion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon fontSize="large" />}
                    aria-controls={`preferences-question-explanation`}
                  >
                    <span>{KeepInTouchData?.questions[1].whyQuestion}</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>{KeepInTouchData?.questions[1].whyQuestionDetails}</span>
                  </AccordionDetails>
                </Accordion>
              </>
            </ModalPageBody>
            <ModalPageFooter className="sticky-footer">
              <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                Continue
              </Button>
            </ModalPageFooter>
          </ModalPageWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default KeepInTouch;
