import { LOAD_PROFILE, PROFILE_LOADED, LOAD_PROFILE_FAILED } from "../constants";

export const load_profile = () => {
  return { type: LOAD_PROFILE };
};
export const profile_loaded = (payload) => {
  return { type: PROFILE_LOADED, payload };
};
export const load_profile_failed = (error) => {
  return { type: LOAD_PROFILE_FAILED, error };
};
