import { useSelector } from "react-redux";
import { useRouteMatch, Switch, Route, useHistory } from "react-router";
import { LoadingPage } from "../LoadingPage";
import Disabilities from "./Disabilities";
import DisabilityRegister from "./DisabilityRegister";

const DisabilitiesRoot = () => {
  const disabilitiesData = useSelector(
    (state) => state.signupFlowData.signupPagesData["disabilities"]
  );
  const { path } = useRouteMatch();
  const history = useHistory();

  const LoadingScreen = () => {
    return (
      <LoadingPage
        callback={() => history.replace("/ethnicity")}
        heading={disabilitiesData?.loadingScreen.title ?? ""}
        text={disabilitiesData?.loadingScreen.body ?? ""}
      />
    );
  };

  return (
    <>
      <Switch>
        <Route exact path={`${path}`} component={Disabilities} />
        <Route exact path={`${path}/disability-register`} component={DisabilityRegister} />
        <Route exact path={`${path}/loading`} component={LoadingScreen} />
      </Switch>
    </>
  );
};

export default DisabilitiesRoot;
