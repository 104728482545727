import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { NAVY } from "../../../enums/brandColors";
import Radio from "@material-ui/core/Radio";

const NavyRadio = withStyles({
  root: {
    color: NAVY,
    "&$checked": {
      color: NAVY,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default NavyRadio;
