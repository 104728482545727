import axios from "axios";
import { useState } from "react";
import { urls } from "../../configs/serviceConfig";
import { logException } from "../../utils/AppInsightsLogging";

const usePostcodeCheck = () => {
  const [response, setResponse] = useState({
    data: null,
    loading: false,
    errorStatus: false,
    error: null,
    isPostcodeEligible: null,
  });

  const getPostcodeChecked = async (postcode) => {
    const url = `${urls?.referralService}/api/referring-services/postcode/${postcode}`;
    setResponse({
      ...response,
      loading: true,
      data: null,
      errorStatus: false,
      isPostcodeEligible: null,
    });
    await axios
      .get(url)
      .then((res) => {
        setResponse({
          data: res.data,
          loading: false,
          errorStatus: false,
          error: null,
          isPostcodeEligible: true,
        });
      })
      .catch((error) => {
        console.log(error);
        setResponse({
          data: null,
          loading: false,
          errorStatus: true,
          error: error,
          isPostcodeEligible: false,
        });
        logException(error);
      });
  };

  return [response, getPostcodeChecked];
};
export default usePostcodeCheck;
