import React, { useState } from "react";
import { FormGroup, Grid, InputLabel, FormHelperText } from "@material-ui/core";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { FormikNumberInput } from "../../atoms/FormikInput";
import UnitOfMeasure from "../../../enums/UnitOfMeasure";
import unitConverter from "../../../utils/UnitConverter";

const HeightCompositeInput = (props) => {
  const {
    field: { ...fields },
    form: { setFieldValue },
    label,
  } = props;
  const { values } = useFormikContext();
  let heightComponent;
  let heightUnit = values.heightDisplayUnitOfMeasure ?? UnitOfMeasure.METRIC;
  const [heightDisplayUnitOfMeasure, setHeightUnit] = useState(heightUnit);

  const handleHeightUnitChange = (value) => {
    if (value === UnitOfMeasure.IMPERIAL) {
      if (values.height && values.height !== "") {
        const [feet, inches] = unitConverter.centimetersToFeetAndInches(values.height);
        setFieldValue("heightFeet", feet.toFixed(0));
        setFieldValue("heightInches", inches.toFixed(0));
      }
    } else {
      if (
        (values.heightFeet && values.heightFeet !== "") ||
        (values.heightInches && values.heightInches !== "")
      ) {
        const cm = unitConverter.feetAndInchesToCentimeters(values.heightFeet, values.heightInches);
        setFieldValue("height", cm.toFixed(0));
      }
    }
    setFieldValue("heightDisplayUnitOfMeasure", value);
    setHeightUnit(value);
  };

  if (heightDisplayUnitOfMeasure === UnitOfMeasure.IMPERIAL) {
    heightComponent = (
      <FormGroup>
        <InputLabel htmlFor={props.id}>{label}</InputLabel>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Field
              fullWidth
              variant="outlined"
              id="heightFeet"
              name="heightFeet"
              type="number"
              pattern={/^([0-9]{0,3})/g}
              component={FormikNumberInput}
            />
          </Grid>
          <Grid item xs="1" className="units">
            <span>ft</span>
          </Grid>
          <Grid item xs={3}>
            <Field
              fullWidth
              variant="outlined"
              id="heightInches"
              name="heightInches"
              type="number"
              pattern={/^([0-9]{0,3})/g}
              component={FormikNumberInput}
            />
          </Grid>
          <Grid item xs={1} className="units">
            <span>in</span>
          </Grid>
        </Grid>
        <Grid>
          <Grid item sm={12}>
            <ErrorMessage className="Mui-error" component={FormHelperText} name="heightFeet" />
          </Grid>
        </Grid>
        <Grid>
          <Grid item sm={12}>
            <ErrorMessage className="Mui-error" component={FormHelperText} name="heightInches" />
          </Grid>
        </Grid>
        <Grid>
          <Grid item sm={12}>
            <button
              type="button"
              className="unit-converter"
              onClick={() => handleHeightUnitChange(UnitOfMeasure.METRIC)}
            >
              <i className="fas fa-sync unit-converter-icon"></i>
              click here to enter your height in cm
            </button>
          </Grid>
        </Grid>
      </FormGroup>
    );
  } else {
    heightComponent = (
      <FormGroup>
        <InputLabel htmlFor={props.id}>{label}</InputLabel>
        <Grid container spacing={1}>
          <Grid item xs={3} className="">
            <FormikNumberInput {...props} pattern={/^([0-9]{0,3})/g} />
          </Grid>
          <Grid item xs={1} className="units">
            <span>cm</span>
          </Grid>
        </Grid>
        <Grid>
          <Grid item sm={12}>
            <ErrorMessage component={FormHelperText} name={fields.name} />
          </Grid>
        </Grid>
        <Grid>
          <Grid item sm={12}>
            <button
              type="button"
              className="unit-converter"
              onClick={() => handleHeightUnitChange(UnitOfMeasure.IMPERIAL)}
            >
              <i className="fas fa-sync unit-converter-icon"></i>
              click here to enter your height in ft and in
            </button>
          </Grid>
        </Grid>
      </FormGroup>
    );
  }

  return <>{heightComponent}</>;
};

export default HeightCompositeInput;
