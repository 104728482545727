import {
  LOAD_PROGRAMME_STEP,
  PROGRAMME_STEP_LOADED,
  LOAD_PROGRAMME_STEP_FAILED,
  COMPLETE_PART,
  PART_COMPLETED,
  PART_COMPLETED_HIDE_MODAL,
} from "../constants";

const initialState = {
  loading: false,
  data: {},
  errorStatus: false,
  error: null,
};

const get_programme_step_reducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPLETE_PART:
      return { ...state, loading: true, partCompletedShowModal: false };
    case PART_COMPLETED:
      return { ...state, loading: false, data: action.payload, partCompletedShowModal: true };
    case PART_COMPLETED_HIDE_MODAL:
      return { ...state, loading: false, partCompletedShowModal: false };
    case LOAD_PROGRAMME_STEP:
      return { ...state, loading: true };
    case PROGRAMME_STEP_LOADED:
      return { ...state, loading: false, data: action.payload };
    case LOAD_PROGRAMME_STEP_FAILED:
      return { ...state, loading: false, error: action.error, errorStatus: true };
    default:
      return state;
  }
};

export default get_programme_step_reducer;
