import { FormGroup, FormHelperText, InputLabel } from "@material-ui/core";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { update_signup_form_values } from "../../../../store/actions/signupFlowData";
import FormikInputOnly from "../../../atoms/formik-input-only";
import { FormikField } from "../../../atoms/FormikField";
import {
  ModalPageBody,
  ModalPageCopy,
  ModalPageFooter,
  ModalPageHeader,
  ModalPageHeading,
  ModalPageWrapper,
} from "../../../organisms/ModalPage";
import { IntroduceYourselfValidationSchema } from "../EligibilityCheckerValidation";

const IntroduceYourself = () => {
  const dispatch = useDispatch();
  const namePageData = useSelector(
    (state) => state.signupFlowData.signupPagesData?.["introduce-yourself"]
  );
  const signupFormValues = useSelector((state) => state.signupFlowData.signupFormValues);

  const history = useHistory();
  const initialValues = {
    firstName: signupFormValues?.firstName ?? "",
    lastName: signupFormValues?.lastName ?? "",
  };

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Name | Eligibility checker | Sign up | Gloji",
    });
  }, []);

  const handleSubmit = (values) => {
    dispatch(update_signup_form_values(values));
    history.push(`/contact-details`);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={IntroduceYourselfValidationSchema}
      validateOnChange={false}
    >
      {({ errors, touched }) => {
        return (
          <Form className="eligibility-checker-form">
            <Helmet>
              <title> Name | Eligibility checker | Sign up | Gloji </title>
            </Helmet>
            <ModalPageWrapper>
              <ModalPageBody hasStickyFooter={false}>
                <ModalPageHeader
                  backButtonOnClick={() => {
                    history.goBack();
                  }}
                />
                <ModalPageHeading>{namePageData?.questions[0].question}</ModalPageHeading>
                <ModalPageCopy>
                  <span style={{ marginTop: "15px", marginBottom: "20px" }}>
                    {namePageData?.questions[0].description}
                  </span>
                </ModalPageCopy>
                <FormGroup className="eligibility-checker__form-group">
                  <InputLabel htmlFor="firstName" className="eligibility-checker__input-label">
                    First name
                  </InputLabel>
                  <FormikField
                    id="firstName"
                    name="firstName"
                    className="eligibility-checker__text-input"
                    component={FormikInputOnly}
                  />
                  <ErrorMessage
                    key="firstName"
                    className="Mui-error"
                    name="firstName"
                    component={FormHelperText}
                  />
                </FormGroup>
                <FormGroup>
                  <InputLabel htmlFor="lastName" className="eligibility-checker__input-label">
                    Last name
                  </InputLabel>
                  <FormikField
                    id="lastName"
                    name="lastName"
                    className="eligibility-checker__text-input"
                    component={FormikInputOnly}
                  />
                  <ErrorMessage
                    key="lastName"
                    className="Mui-error"
                    name="lastName"
                    component={FormHelperText}
                  />
                </FormGroup>
              </ModalPageBody>
              <ModalPageFooter hasStickyFooter={false}>
                <Button type="submit" variant="contained" color="primary">
                  Continue
                </Button>
              </ModalPageFooter>
            </ModalPageWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

export default IntroduceYourself;
