import {
  LOG_WEIGHT,
  WEIGHT_LOGGED,
  LOG_WEIGHT_FAILED,
  LOG_WEIGHT_SUCCESS_RESET,
  LOG_WEIGHT_ERROR_RESET,
} from "../constants";

const initialState = {
  loading: false,
  data: [],
  isWeightLogged: false,
  isSuccess: false,
  errorStatus: false,
  error: null,
};

const post_weight_reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOG_WEIGHT:
      return { ...state, loading: true };
    case WEIGHT_LOGGED:
      return {
        ...state,
        loading: false,
        data: action.payload,
        isWeightLogged: true,
        isSuccess: true,
      };
    case LOG_WEIGHT_FAILED:
      return { ...state, loading: false, error: action.error, errorStatus: true };
    case LOG_WEIGHT_SUCCESS_RESET:
      return { ...state, isSuccess: false };
    case LOG_WEIGHT_ERROR_RESET:
      return { ...state, errorStatus: false, error: null };
    default:
      return state;
  }
};

export default post_weight_reducer;
