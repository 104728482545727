import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";

/**
 * Custome Button component for rendering all custom buttons.
 * @param {object} props - React properties passed to the component
 * @returns Button
 */
const CustomButton = (props) => {
  const { className, variant, type, ariaLabel, color, btnText, ...rest } = props;
  return (
    <Button
      className={`${className || ""}`}
      variant={variant}
      type={type}
      aria-label={ariaLabel}
      color={color || ""}
      disableElevation
      {...rest}
    >
      {btnText}
    </Button>
  );
};

CustomButton.propTypes = {
  variant: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
};

export default CustomButton;
