import React from "react";
import PropTypes from "prop-types";

const ModalPageHeroImage = ({ imageUrl, altText, className }) => {
  return <img src={imageUrl} alt={altText} className={`modal-page__hero-image ${className}`}  />;
};
ModalPageHeroImage.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
};

export default ModalPageHeroImage;
