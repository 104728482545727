import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import {
  ModalPageBody,
  ModalPageCopy,
  ModalPageFooter,
  ModalPageHeading,
  ModalPageWrapper,
} from "../../../organisms/ModalPage";
import { CircularLoader } from "../../../atoms/Loaders";

import "./UserDetails.scss";

const UserDetails = () => {
  const history = useHistory();
  const profile = useSelector((state) => state.profile);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (profile?.data?.personalDetails !== undefined) {
      setLoading(false);
    }
  }, [profile.data.personalDetails]);

  const handleLetsDoIt = () => {
    return history.push("/book");
  };

  if (loading) {
    return <CircularLoader />;
  }

  return (
    <>
      <Helmet>
        <title>Time to book your first appointment | Gloji</title>
      </Helmet>

      <ModalPageWrapper>
        <ModalPageBody hasStickyFooter={false}>
          <ModalPageHeading size="large">Time to book your first appointment!</ModalPageHeading>
          <ModalPageCopy>
            We'll get in touch using the contact details you gave us when you signed up.
          </ModalPageCopy>

          <div className="user-details">
            <div className="user-details__info">
              <span className="user-details__info__title">Email</span>
              <span>{profile?.data?.personalDetails?.email}</span>
            </div>
            <div className="user-details__info">
              <span className="user-details__info__title">Phone</span>
              <span>{profile?.data?.personalDetails?.phoneNumber}</span>
            </div>
          </div>
        </ModalPageBody>

        <ModalPageFooter hasStickyFooter={false}>
          <Button onClick={() => handleLetsDoIt()}>Let's do it</Button>
        </ModalPageFooter>
      </ModalPageWrapper>
    </>
  );
};

export default UserDetails;
