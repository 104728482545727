import { useRouteMatch, Switch, Route } from "react-router";
import FirstLanguage from "./FirstLanguage";

const FirstLanguageRoot = (props) => {
  let { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={`${path}`} component={FirstLanguage} />
      </Switch>
    </>
  );
};

export default FirstLanguageRoot;
