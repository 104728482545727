import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactComponent as ArticlesAndGuidesIcon } from "../../../../assets/img/icons/articlesAndGuides.svg";
import { ReactComponent as StepByStepVideosIcon } from "../../../../assets/img/icons/stepByStepVideos.svg";
import { ReactComponent as TrackProgressIcon } from "../../../../assets/img/icons/trackYourProgress.svg";
import { ReactComponent as UnlimitedRecipesIcon } from "../../../../assets/img/icons/unlimitedRecipes.svg";
import { ReactComponent as OnlineGymIcon } from "../../../../assets/img/icons/onlineGym.svg";
import HeroImage from "../../../../assets/img/meetGlojiMentors.jpg";
import { update_signup_form_values } from "../../../../store/actions/signupFlowData";
import {
  ModalPageBody,
  ModalPageCopy,
  ModalPageFooter,
  ModalPageHeading,
  ModalPageHeroImage,
  ModalPageWrapper,
} from "../../../organisms/ModalPage";
import "./Bmi.scss";

const BmiEligibleScreen = () => {
  const dispatch = useDispatch();
  const BmiPageData = useSelector((state) => state.signupFlowData.signupPagesData?.bmi);
  const signupValues = useSelector((state) => state.signupFlowData.signupFormValues);
  const offeredSupported = signupValues?.offeredSupported;
  const offeredDigital = signupValues?.offeredDigital;

  const history = useHistory();

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Eligible BMI | Eligibility checker | Sign up | Gloji",
    });
  }, []);

  const handleClick = () => {
    if (offeredSupported && offeredDigital) {
      history.push("/pathway");
    } else if (offeredSupported && !offeredDigital) {
      dispatch(update_signup_form_values({ pathway: "Supported" }));
      history.push("/introduce-yourself");
    } else {
      dispatch(update_signup_form_values({ pathway: "Digital" }));
      history.push("/introduce-yourself");
    }
  };

  return (
    <>
      <Helmet>
        <title>Eligible BMI | Eligibility checker | Sign up | Gloji</title>
      </Helmet>
      <ModalPageWrapper>
        <ModalPageBody hasStickyFooter={false}>
          <ModalPageHeading>{BmiPageData.successScreen.title}</ModalPageHeading>
          <ModalPageHeroImage altText="Sara, gloji guide" imageUrl={HeroImage}></ModalPageHeroImage>
          <ModalPageCopy>
            <p>
              We feel like we know you better already! Here’s some of the things you can look
              forward to using while you’re with us.
            </p>
            <div className="list-with-icon">
              <StepByStepVideosIcon />
              <p>Step-by-step videos</p>
            </div>
            <div className="list-with-icon">
              <ArticlesAndGuidesIcon />
              <p>Articles and guides picked just for you</p>
            </div>
            <div className="list-with-icon">
              <UnlimitedRecipesIcon />
              <p>Easy and delicious recipes</p>
            </div>
            <div className="list-with-icon">
              <TrackProgressIcon />
              <p>An easy way to track your progress</p>
            </div>
            <div className="list-with-icon">
              <OnlineGymIcon />
              <p>Online gym with live and on-demand sessions</p>
            </div>
            <p className="glory-story">
              "I'm no longer thinking about whether to do exercise, I just put the trainers on and
              get started" Jo's gloji story
            </p>
          </ModalPageCopy>
        </ModalPageBody>
        <ModalPageFooter hasStickyFooter={false}>
          <Button onClick={handleClick} variant="contained" color="primary">
            Let's go
          </Button>
        </ModalPageFooter>
      </ModalPageWrapper>
    </>
  );
};

export default BmiEligibleScreen;
