import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { FormControl, FormLabel, FormGroup } from "@material-ui/core";
import "./RadioButtonGroup.scss";
import { makeStyles } from "@material-ui/core/styles";
import { NAVY } from "@thrivetribe/gloji.enums.brand-colors";
import FormikRadioButton from "../../atoms/FormikRadioButton";
import { ErrorMessage, useField } from "formik";
import { FormHelperText } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
  formControl: {
    marginBottom: "6px",
    "& .MuiFormLabel-root": {
      fontSize: "1rem",
      color: `${NAVY} !important;`,
      position: "relative",
      marginBottom: "0.5rem",
      lineHeight: "1.5rem",
    },
    "& .MuiFormGroup-root": {
      backgroundColor: "white",
      padding: "12px",
    },
  },
}));

const RadioButtonGroup = ({ label, options, name, hasErrors, onClick, className }) => {
  const classes = useStyles();
  const [field, meta, helpers] = useField(name);

  return (
    <FormControl
      component="fieldset"
      className={classNames(classes.formControl, "radio-group__container", className)}
    >
      {label != null && (
        <FormLabel component="legend" className="radio-group__label">
          {label}
        </FormLabel>
      )}
      <FormGroup
        className={`radios__container${
          hasErrors || (meta.error && meta.touched) ? " radios__container--has-errors" : ""
        }`}
      >
        {options.map((item) => (
          <FormikRadioButton
            key={item.value}
            id={item.value}
            name={name}
            label={item.label}
            value={item.value}
            onClick={onClick ?? null}
          />
        ))}
      </FormGroup>
      <ErrorMessage className="Mui-error" component={FormHelperText} name={name} />
    </FormControl>
  );
};

RadioButtonGroup.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  hasErrors: PropTypes.bool,
  onClick: PropTypes.func,
};

export default RadioButtonGroup;
