import React from "react";
import { ModalPageBackground } from "../../../../organisms/ModalPage";
import { MemoryRouter, Route, Switch } from "react-router";
import PageBg from "../../../../../assets/img/bg/eligibilityCheckerBg.png";
import { LandingPage } from "../../SmokeFree";
import ReadySetGo from "../../ReadySetGo/ReadySetGo";

/**
 * Renders profileBuilder pages
 * @returns SmokeFreeJourney profileBuilder
 */

const SmokeFreeJourney = () => {
  return (
    <ModalPageBackground className="profile-builder-page" bgImage={PageBg}>
      <MemoryRouter>
        <Switch>
          <Route exact path={"/"} component={LandingPage} />
          <Route path={"/ready-set-go"} component={ReadySetGo} />
        </Switch>
      </MemoryRouter>
    </ModalPageBackground>
  );
};

export default SmokeFreeJourney;
