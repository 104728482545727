const PhysicalHealthCondition = {
  NONE: "None",
  KIDNEY_DISEASE: "KidneyDisease",
  COPD: "COPD",
  ASTHMA: "Asthma",
  THYROID_CONDITION: "ThyroidCondition",
  STROKE_OR_TIA: "StrokeOrTIA",
  CHD: "CHD",
  HEART_ATTACK: "HeartAttack",
  ARTHRITIS: "Arthritis",
  DIABETES: "Diabetes",
  HYPERTENSION: "Hypertension",
  HYPERCHOLESTEROLEMIA: "Hypercholesterolemia",
  OTHER: "Other",
};

export const physicalHealthConditions = [
  { value: "asthma", label: "Asthma" },
  { value: "arthritis", label: "Arthritis" },
  { value: "hypertension", label: "Hypertension" },
  { value: "hypercholesterolemia", label: "Hypercholesterolemia" },
  { value: "copd", label: "Chronic Obstructive Pulmonary Disorder (COPD)" },
  { value: "coronaryHeartDisease", label: "Coronary heart disease" },
  { value: "renalDisease", label: "Renal disease (Kidney)" },
  { value: "thromboembolicDisorder", label: "Thromboembolic disorder" },
  { value: "haematologicalDisorder", label: "Haematological disorder" },
  { value: "centralNervousSystemDisorder", label: "Central nervous system disorder" },
  { value: "stroke", label: "Stroke" },
  { value: "heartAttack", label: "Heart attack" },
  { value: "diabetesType1", label: "Diabetes type 1" },
  { value: "diabetesType2", label: "Diabetes type 2" },
  { value: "autoimmuneDisease", label: "Autoimmune disease" },
  { value: "cancer", label: "Cancer" },
  { value: "hepatitisA", label: "Infectious Hepatitis A" },
  { value: "hepatitisB", label: "Serum Hepatitis B" },
  { value: "hepatitisC", label: "Hepatitis C" },
  { value: "endocrineDisorder", label: "Endocrine disorder" },
  { value: "otherRespiratoryDisease", label: "Other respiratory disease" },
  { value: "gastrointestinalDisorder", label: "Gastrointestinal disorder" },
  { value: "musculoskeletalDisorder", label: "Musculoskeletal disorder" },
  { value: "pcos", label: "Polycystic ovary syndrome (PCOS)" },
  { value: "otherGynaecologicalProblems", label: "Other gynaecological problems" },
];

export default PhysicalHealthCondition;
