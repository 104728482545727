import React, { useState, useEffect } from "react";
import { Grid, MenuItem, Typography, FormHelperText } from "@material-ui/core";
import { ErrorMessage, useFormikContext } from "formik";
import { TextField } from "formik-material-ui";
import { Ethnicity, Language } from "../../../enums/profile";
import { CustomSelect } from "../../atoms/Select";
import { CustomField } from "../../atoms/InputField";
import { ScrollTo } from "../../atoms/ScrollTo";

const EthnicityForm = () => {
  const { values, setFieldValue } = useFormikContext();
  const [ethnicity, setEthnicity] = useState("");

  useEffect(() => {
    if (values.ethnicity !== ethnicity) {
      setEthnicity(values.ethnicity);
    }
    if (values.ethnicity !== Ethnicity.OTHER) {
      setFieldValue("otherEthnicity", "");
    }
  }, [values.ethnicity, ethnicity, setFieldValue]);

  return (
    <Grid container spacing={2}>
      <ScrollTo />
      <Grid item xs={12} sm={12}>
        <Typography className="info-text" variant="h2" align="center">
          Tell us a little bit about your background
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomSelect
          fullWidth
          variant="outlined"
          id="ethnicity"
          name="ethnicity"
          label="What is your ethnicity?"
        >
          <MenuItem value={Ethnicity.WHITE_BRITISH}>White: British</MenuItem>
          <MenuItem value={Ethnicity.WHITE_IRISH}>White: Irish</MenuItem>
          <MenuItem value={Ethnicity.WHITE_GYPSY_OR_TRAVELER}>White: Gypsy or Traveller</MenuItem>
          <MenuItem value={Ethnicity.WHITE_OTHER}>White: Other</MenuItem>
          <MenuItem value={Ethnicity.MIXED_WHITE_BLACK_CARIBBEAN}>
            Mixed: White and Black Caribbean
          </MenuItem>
          <MenuItem value={Ethnicity.MIXED_WHITE_BLACK_AFRICAN}>
            Mixed: White and Black African
          </MenuItem>
          <MenuItem value={Ethnicity.MIXED_WHITE_ASIAN}>Mixed: White and Asian</MenuItem>
          <MenuItem value={Ethnicity.MIXED_OTHER_BACKGROUND}>
            Mixed: Other or Multiple Ethnic Background
          </MenuItem>
          <MenuItem value={Ethnicity.ASIAN_INDIAN}>Asian: Indian</MenuItem>
          <MenuItem value={Ethnicity.ASIAN_PAKISTANI}>Asian: Pakistani</MenuItem>
          <MenuItem value={Ethnicity.ASIAN_BANGLADESHI}>Asian: Bangladeshi</MenuItem>
          <MenuItem value={Ethnicity.ASIAN_CHINESE}>Asian: Chinese</MenuItem>
          <MenuItem value={Ethnicity.ASIAN_JAPANESE}>Asian: Japanese</MenuItem>
          <MenuItem value={Ethnicity.ASIAN_OTHER}>Asian: Other</MenuItem>
          <MenuItem value={Ethnicity.BLACK_AFRICAN}>Black: African</MenuItem>
          <MenuItem value={Ethnicity.BLACK_CARIBBEAN}>Black: Caribbean</MenuItem>
          <MenuItem value={Ethnicity.BLACK_BRITISH}>Black: British</MenuItem>
          <MenuItem value={Ethnicity.BLACK_OTHER}>Black: Other</MenuItem>
          <MenuItem value={Ethnicity.ARAB}>Arab</MenuItem>
          <MenuItem value={Ethnicity.OTHER}>Other: Please Specify</MenuItem>
        </CustomSelect>
        <ErrorMessage className="Mui-error" component={FormHelperText} name="ethnicity" />
      </Grid>
      {ethnicity === Ethnicity.OTHER ? (
        <Grid item xs={12} sm={6}>
          <CustomField
            fullWidth
            variant="outlined"
            id="otherEthnicity"
            name="otherEthnicity"
            placeholder="Other ethnicity"
            label="Please specify other ethnicity"
            component={TextField}
          />
        </Grid>
      ) : null}

      <Grid item xs={12} sm={6}>
        <CustomSelect
          fullWidth
          variant="outlined"
          id="firstLanguage"
          name="firstLanguage"
          label="What is your first language?"
        >
          <MenuItem value={Language.ENGLISH}>English</MenuItem>
          <MenuItem value={Language.FRENCH}>French</MenuItem>
          <MenuItem value={Language.GERMAN}>German</MenuItem>
          <MenuItem value={Language.SPANISH}>Spanish</MenuItem>
          <MenuItem value={Language.PORTUGUESE}>Portuguese</MenuItem>
          <MenuItem value={Language.POLISH}>Polish</MenuItem>
          <MenuItem value={Language.ARABIC}>Arabic</MenuItem>
          <MenuItem value={Language.PUNJABI}>Punjabi</MenuItem>
          <MenuItem value={Language.URDU}>Urdu</MenuItem>
          <MenuItem value={Language.BENGALI}>Bengali</MenuItem>
          <MenuItem value={Language.GUJARATI}>Gujarati</MenuItem>
          <MenuItem value={Language.MANDARIN}>Mandarin</MenuItem>
          <MenuItem value={Language.JAPANESE}>Japanese</MenuItem>
          <MenuItem value={Language.RUSSIAN}>Russian</MenuItem>
        </CustomSelect>
        <ErrorMessage className="Mui-error" component={FormHelperText} name="firstLanguage" />
      </Grid>
      {/* <Grid item xs={12} sm={6}>
        <div className="pb-10">
          <span>Any other languages (select multiple)?</span>
        </div>
       <CustomSelect
          fullWidth
          variant="outlined"
          id="otherLanguages"
          name="otherLanguages"
          component={TextField}
          select={true}
          SelectProps={{
            multiple: true,
          }}
          label="Other Languages"
        >
          <MenuItem value={Language.ENGLISH}>English</MenuItem>
          <MenuItem value={Language.FRENCH}>French</MenuItem>
          <MenuItem value={Language.GERMAN}>German</MenuItem>
          <MenuItem value={Language.SPANISH}>Spanish</MenuItem>
          <MenuItem value={Language.PORTUGUESE}>Portuguese</MenuItem>
          <MenuItem value={Language.POLISH}>Polish</MenuItem>
          <MenuItem value={Language.ARABIC}>Arabic</MenuItem>
          <MenuItem value={Language.PUNJABI}>Punjabi</MenuItem>
          <MenuItem value={Language.URDU}>Urdu</MenuItem>
          <MenuItem value={Language.BENGALI}>Bengali</MenuItem>
          <MenuItem value={Language.GUJARATI}>Gujarati</MenuItem>
          <MenuItem value={Language.MANDARIN}>Mandarin</MenuItem>
          <MenuItem value={Language.JAPANESE}>Japanese</MenuItem>
          <MenuItem value={Language.RUSSIAN}>Russian</MenuItem>
       </CustomSelect>
      </Grid> */}
    </Grid>
  );
};

export default EthnicityForm;
