import { useState } from "react";
import { urls } from "../../configs/serviceConfig";
import axios from "axios";
import { logException } from "../../utils/AppInsightsLogging";

const useResendLink = (userEmail) => {
  const [response, setResponse] = useState({
    linkSent: false,
    loading: false,
    errorStatus: false,
    error: null,
  });

  const url = `${urls.profileService}/email-verification/send`;

  const resendLink = async () => {
    setResponse({ ...response, loading: true, linkSent: false, errorStatus: false });
    await axios
      .post(url, {
        email: userEmail,
      })
      .then((res) => {
        setResponse({ linkSent: true, loading: false, errorStatus: false, error: null });
      })
      .catch((error) => {
        console.log(error);
        setResponse({ linkSent: false, loading: false, errorStatus: true, error: error });

        logException(error);
      });
  };

  return [response, resendLink];
};
export default useResendLink;
