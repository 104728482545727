import { useRouteMatch, Switch, Route } from "react-router";
import SexualOrientation from "./SexualOrientation";

const SexualOrientationRoot = (props) => {
  let { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={`${path}`} component={SexualOrientation} />
      </Switch>
    </>
  );
};

export default SexualOrientationRoot;
