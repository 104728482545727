import axios from "../../helpers/authAxios";
import {
  LOAD_CONTENT,
  CONTENT_LOADED,
  ALL_CONTENT_LOADED,
  LOAD_CONTENT_FAILED,
  CLEAR_CONTENT_LOADED,
} from "../constants";
import { urls } from "../../configs/serviceConfig";
import { logException } from "../../utils/AppInsightsLogging";

export const load_content = () => {
  return { type: LOAD_CONTENT };
};

export const clear_content = () => {
  return { type: CLEAR_CONTENT_LOADED };
};

export const content_loaded = (payload) => {
  return { type: CONTENT_LOADED, payload };
};

export const all_content_loaded = () => {
  return { type: ALL_CONTENT_LOADED };
};

export const load_content_failed = (error) => {
  return { type: LOAD_CONTENT_FAILED, error };
};

export const fetch_content = (pillarTags, take, skip, publicTags, contentType) => {
  const url = `${urls.contentService}/api/content/query/search`;

  return (dispatch) => {
    dispatch(load_content());
    axios
      .post(url, {
        contentType: contentType,
        pillarTags: pillarTags,
        take,
        skip,
        publicTags,
      })
      .then((response) => dispatch(content_loaded(response.data)))
      .catch((error) => {
        if (error.response.status === 404) {
          dispatch(all_content_loaded(error));
        } else {
          dispatch(load_content_failed(error));
        }
        logException(error);
      });
  };
};
