import {
  COMPLETE_QUESTION,
  QUESTION_COMPLETED,
  COMPLETE_QUESTION_FAILED,
  COMPLETE_QUESTION_ERROR_RESET,
} from "../constants";

const initialState = {
  loading: false,
  data: [],
  isQuestionCompleted: false,
  errorStatus: false,
  error: null,
};

const post_complete_question_reducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPLETE_QUESTION:
      return { ...state, loading: true, isQuestionCompleted: false };
    case QUESTION_COMPLETED:
      return {
        ...state,
        loading: false,
        data: action.payload,
        isQuestionCompleted: true,
        errorStatus: false,
      };
    case COMPLETE_QUESTION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        errorStatus: true,
        isQuestionCompleted: false,
      };
    case COMPLETE_QUESTION_ERROR_RESET:
      return { ...state, errorStatus: false, error: null };
    default:
      return state;
  }
};

export default post_complete_question_reducer;
