import React from "react";
import LoadingScreen from "../../organisms/LoadingScreen/LoadingScreen";
import { Helmet } from "react-helmet";
import { ModalPageWrapper, ModalPageBody, ModalPageBackground } from "../../organisms/ModalPage";
import { GlojiLoader } from "../../atoms/Loaders";
import PageBg from "../../../assets/img/bg/eligibilityCheckerBg.png";

export const LoadingPage = ({ heading, text, callback, isLoading }) => {
  const defaultArtificialDelay =
    window.env.environment === "dev" || window.env.environment === "uat" ? 250 : 5000;

  return (
    <ModalPageBackground className="eligibility-checker-page" bgImage={PageBg}>
      <Helmet>
        <title>Loading</title>
      </Helmet>
      <ModalPageWrapper>
        <ModalPageBody hasStickyFooter={false}>
          <LoadingScreen
            heading={heading}
            svgAnimation={<GlojiLoader />}
            text={text}
            artificialDelay={defaultArtificialDelay}
            callback={callback}
            isLoading={isLoading}
          />
        </ModalPageBody>
      </ModalPageWrapper>
    </ModalPageBackground>
  );
};
