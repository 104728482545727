import React from "react";
import "./EligibilityCheckerRoot.scss";
import {
  LandingPage,
  PostcodeCheckerRoot,
  AgeCheckerRoot,
  SexCheckerRoot,
  EthnicityRoot,
  PhysicalHealthRoot,
  MentalHealthRoot,
  DisabilitiesRoot,
  BmiRoot,
  KeepInTouchRoot,
  ContactDetailsRoot,
  IntroduceYourselfRoot,
  DataInformationConsentRoot,
  PathwayRoot,
} from "..";
import { ModalPageBackground } from "../../../organisms/ModalPage";
import { MemoryRouter, Route, Switch } from "react-router";
import PageBg from "../../../../assets/img/bg/eligibilityCheckerBg.png";

const EligibilityCheckerRoot = () => {
  return (
    <ModalPageBackground className="eligibility-checker-page" bgImage={PageBg}>
      <MemoryRouter>
        <Switch>
          <Route exact path={"/"} component={LandingPage} />
          <Route path={"/postcode"} component={PostcodeCheckerRoot} />
          <Route path={"/age"} component={AgeCheckerRoot} />
          <Route path={"/sex"} component={SexCheckerRoot} />
          <Route path={"/ethnicity"} component={EthnicityRoot} />
          <Route path={"/physical-health"} component={PhysicalHealthRoot} />
          <Route path={"/mental-health"} component={MentalHealthRoot} />
          <Route path={"/disabilities"} component={DisabilitiesRoot} />
          <Route path={"/bmi"} component={BmiRoot} />
          <Route path={"/pathway"} component={PathwayRoot} />
          <Route path={"/introduce-yourself"} component={IntroduceYourselfRoot} />
          <Route path={"/contact-details"} component={ContactDetailsRoot} />
          <Route path={"/keep-in-touch"} component={KeepInTouchRoot} />
          <Route path={"/data-information-consent"} component={DataInformationConsentRoot} />
        </Switch>
      </MemoryRouter>
    </ModalPageBackground>
  );
};

export default EligibilityCheckerRoot;
