import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams, useLocation, Redirect, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { greyVelvet } from "../../../assets/img/bg";
import eligibilityCheckerBg from "../../../assets/img/bg/eligibilityCheckerBg.png";
import PageBackground from "../../organisms/PageBackground";
import { PageHeroBanner } from "../../organisms/HeroBanner";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import slugConverter from "../../../utils/SlugConverter";
import Stepper from "../../atoms/Stepper";
import LogWeightWidget from "../../molecules/LogWeightWidget";

import "./StepCompleted.scss";
import { SectionHeader, SectionHeading, SectionIntro } from "../../atoms/SectionHeader";
import ArticleCards from "../../organisms/Cards";
import useContent from "../../hooks/useContent";
import useCompletedSteps from "../../hooks/useCompletedSteps";
import { get_programme_overview } from "../../../store/actions/getProgrammeOverview";
import { ReactComponent as CelebrationTrophyLeft } from "../../../assets/img/celebration_trophy_left.svg";
import { ReactComponent as CelebrationTrophyRight } from "../../../assets/img/celebration_trophy_right.svg";
import { contentService } from "../../services";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#f7f7f7",
    borderRadius: "10px",
    "& .MuiSvgIcon-root": {
      fontSize: "12px",
    },
  },
  container: {
    display: "flex",
    backgroundColor: "#f7f7f7",
    justifyContent: "center",
    padding: "50px 0px",
  },
  articlesContainer: {
    backgroundColor: "#f5f4f2",
    color: "#121e59",
    flexDirection: "column",
    padding: "0px 0px 60px 0px",
  },
});

function StepCompleted() {
  const [canViewNextStep, setCanViewNextStep] = useState(false);
  const dispatch = useDispatch();
  let { programme, stepNumber } = useParams();
  let location = useLocation();
  let history = useHistory();
  const classes = useStyles();
  const overview = useSelector((state) => state.programmeOverview);
  const completedSteps = useCompletedSteps(overview?.data?.steps);
  const logWeightFlag = overview?.data?.programme?.features?.logWeight === true ? true : false;

  const [content, areArticlesReady] = useContent(
    parseInt(stepNumber),
    contentService.getArticlesByStageTag
  );

  const { completed, nextStepStartDate } = location.state;

  useEffect(() => {
    const userId = localStorage.getItem("userID");
    const programmeId = localStorage.getItem("programmeID");
    const journeyId = localStorage.getItem("journeyID");
    dispatch(get_programme_overview({userId, programmeId, journeyId}));
  }, [dispatch]);

  useEffect(() => {
    if (nextStepStartDate === undefined) return;
    if (overview.data.nextAction !== null) {
      setCanViewNextStep(true);
      return null;
    }
    return setCanViewNextStep(moment().isAfter(moment(nextStepStartDate)));
  }, [nextStepStartDate, overview.data.nextAction]);

  if (!location.state) {
    return <Redirect to={`/my-path/${programme}/step-${stepNumber}`} />;
  }

  if (!completed || !nextStepStartDate)
    return <Redirect to={`/my-path/${programme}/step-${stepNumber}`} />;

  const programmeTitle = slugConverter.toTitleCase(programme);

  const getNextStepNumber = () => {
    const nextStepNumber = Number(stepNumber) + 1;
    return nextStepNumber < 10 ? `0${nextStepNumber}` : `${nextStepNumber}`;
  };

  const getNextStepUnlockMessage = () => {
    const nextStepNumber = getNextStepNumber();
    const formattedUnlockDate = moment(nextStepStartDate).format("DD MMMM");
    return `Step ${nextStepNumber} unlocks ${formattedUnlockDate}`;
  };

  const goToNextStep = () => {
    return history.push(`/my-path/${programme}/step-${getNextStepNumber()}`);
  };

  const goToMyPath = () => {
    return history.push(`/my-path`);
  };

  const goToHome = () => {
    return history.push("/");
  };

  const goToMyProgress = () => {
    return history.push("/my-progress");
  };

  const goToLeaveFeedback = () => {
    window.open(
      "https://forms.office.com/pages/responsepage.aspx?id=8FRB0iSdCUiujTMHnSDxR6PwczXn5nRJuxTo42SeKcFUOUY5V0FHQVMxRFE3SjFNVkZNU1FYUldSRi4u"
    );
  };

  return (
    <PageBackground bgImage={greyVelvet}>
      <Helmet>
        <title>
          Complete | Step {stepNumber} | {programmeTitle} | My Path | Gloji
        </title>
      </Helmet>
      <PageHeroBanner bgImg={eligibilityCheckerBg}>
        <Grid item xs={12} style={{ marginTop: 60 }}>
          {overview?.data?.programme !== undefined && (
            <div className="hero-banner__completed">
              <CelebrationTrophyLeft />
              <Stepper
                totalSteps={overview.data.programme.numberOfSteps - 1}
                completedSteps={completedSteps}
                currentStep={parseInt(stepNumber)}
              />
              <CelebrationTrophyRight />
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          {Number(stepNumber) < 12 && (
            <h1 className="hero-banner__message">
              Amazing! You've
              <br />
              completed Step {stepNumber}
            </h1>
          )}
          {Number(stepNumber) === 12 && (
            <h1 className="hero-banner__message hero-banner__message--completed">
              Hooray! You've completed the final step
            </h1>
          )}
        </Grid>

        {Number(stepNumber) === 12 && (
          <Grid item xs={12} md={9} xl={6} className="hero-banner__content">
            <p>You did it! You should be really proud of yourself.</p>
            <p>
              What next? You can carry on reading articles or watching your
              fave videos again (and again) and filling in any blanks you might have left along the
              way.
            </p>
            <p>
              Want to tell us what you loved or what we could do better? We'd love to hear your
              feedback. Tell all!
            </p>
          </Grid>
        )}

        <Grid item xs={12}>
          <div className="cta-button__container">
            {canViewNextStep === true && Number(stepNumber) < 12 && (
              <div className="cta-button__container--available">
                <Button variant="contained" color="primary" onClick={goToNextStep}>
                  Move on to next step
                </Button>
                <Button variant="contained" color="secondary" onClick={goToMyPath}>
                  Take me to my path
                </Button>
              </div>
            )}

            {!canViewNextStep && Number(stepNumber) < 12 && (
              <>
                <div className="cta-button__container--locked">
                  <Button variant="contained" color="primary" onClick={goToMyPath}>
                    Take me to my path
                  </Button>
                  <Button variant="contained" color="secondary" onClick={goToHome}>
                    Return to home
                  </Button>
                </div>
                <h2 className="hero-banner__unlock-message">{getNextStepUnlockMessage()}</h2>
              </>
            )}

            {Number(stepNumber) === 12 && (
              <div className="cta-button__container--completed">
                <Button variant="contained" color="primary" onClick={goToLeaveFeedback}>
                  Leave your feedback
                </Button>
                <Button variant="contained" color="secondary" onClick={goToMyPath}>
                  Take me to my path
                </Button>
              </div>
            )}
          </div>
        </Grid>
      </PageHeroBanner>
      {logWeightFlag === true ? (
        <section>
        <Container className={`${classes.container}`}>
          <LogWeightWidget title="Logged your weight recently?" handleLogWeight={goToMyProgress} />
        </Container>
      </section>
      ) : (
        <></>
      )}
      {areArticlesReady === true ? (
        <section>
          <Container className={`${classes.container} ${classes.articlesContainer}`}>
            <SectionHeader>
              <SectionHeading>Turbocharge your journey</SectionHeading>
              <SectionIntro>
                Got a couple minutes to delve a little deeper? We've picked a few of our articles
                for you to read that'll add to what you've already learnt this week and really boost
                your chances of success!
              </SectionIntro>
            </SectionHeader>
            <ArticleCards data={content} />
          </Container>
        </section>
      ) : (
        <></>
      )}
    </PageBackground>
  );
}

export default StepCompleted;
