import authAxios from "../../helpers/authAxios";
import {
  LOG_WEIGHT,
  WEIGHT_LOGGED,
  LOG_WEIGHT_FAILED,
  LOG_WEIGHT_SUCCESS_RESET,
  LOG_WEIGHT_ERROR_RESET,
} from "../constants";
import { urls } from "../../configs/serviceConfig";
import moment from "moment";
import { logException } from "../../utils/AppInsightsLogging";

const log_weight = () => {
  return {
    type: LOG_WEIGHT,
  };
};

const weight_logged = (payload) => {
  return {
    type: WEIGHT_LOGGED,
    payload,
  };
};

const log_weight_failed = (error) => {
  return {
    type: LOG_WEIGHT_FAILED,
    error,
  };
};

export const log_weight_success_reset = () => {
  return {
    type: LOG_WEIGHT_SUCCESS_RESET,
  };
};

export const log_weight_error_reset = () => {
  return {
    type: LOG_WEIGHT_ERROR_RESET,
  };
};

export const post_weight = (userId, journeyId, weight, weightType) => {
  const url = `${urls.weightService}/api/user/${userId}/weight`;
  let today = moment().startOf("day").format("YYYY-MM-DD");

  return (dispatch) => {
    dispatch(log_weight());

    authAxios
      .put(url, {
        journeyId: journeyId,
        weightInKilos: weight,
        weightDate: today,
        weightType: weightType,
      })
      .then(async (response) => {
        await dispatch(weight_logged(response.data));
      })
      .catch((error) => {
        logException(error);
        dispatch(log_weight_failed(error));
      });
  };
};
