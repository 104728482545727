import React from "react";
import { Field } from "formik";
import PropTypes from "prop-types";
import "./FormikRadioButton.scss";

const FormikRadioButton = ({ id, name, value, label, ...props }) => {
  return (
    <label key={value} className="radio-label__container">
      <div>
        <Field type="radio" id={id} name={name} value={value} {...props} />
        <span className="radio-label">{label}</span>
        <span className="customRadiomark"></span>
      </div>
    </label>
  );
};

FormikRadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default FormikRadioButton;
