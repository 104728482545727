import {
  ModalPageBody,
  ModalPageFooter,
  ModalPageHeader,
  ModalPageHeading,
  ModalPageWrapper,
  ModalPageQuestionDescription,
} from "../../../../organisms/ModalPage";
import { MultiStep } from "../../../../molecules/MultiStep";
import { questionStepperList } from "../../helpers";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { FormGroup, FormHelperText, FormControl } from "@material-ui/core";
import { ErrorMessage, Form, Formik } from "formik";
import { Helmet } from "react-helmet";

import * as yup from "yup";
import FormikCheckbox from "../../../../atoms/FormikCheckbox";
import CheckboxGroupContainer from "../../../../atoms/CheckboxGroupContainer";
import { useSelector, useDispatch } from "react-redux";
import { saveMarketingConsent } from "../../../../../redux/profileBuilderSlice";

interface Props {
  navigatToNextStep: () => void;
  navigateToPreviousStep: () => void;
}

const DataConsentFieldNames = [
  "privacyPolicyAgreement",
  "dataProcessingAgreement",
  "gpContactAgreement",
];

const CheckboxFormGroup = ({ name, label, touched, hasErrors, showError }) => {
  return (
    <>
      <FormGroup
        className={`checkboxes__container ${
          touched && hasErrors && showError
            ? "checkboxes__container--has-errors"
            : ""
        }`}
      >
        <FormikCheckbox key={name} id={name} name={name} label={label} />
      </FormGroup>
      {showError && (
        <ErrorMessage
          className="Mui-error"
          component={FormHelperText}
          name={name}
        />
      )}
    </>
  );
};

const consentErrorMessage =
  "To offer you a free place with gloji, we need your consent before we can carry on signing you up.";

export const DataInformationConsentValidationSchema = yup.object().shape({
  privacyPolicyAgreement: yup.boolean().oneOf([true], consentErrorMessage),
  dataProcessingAgreement: yup.boolean().oneOf([true], consentErrorMessage),
  gpContactAgreement: yup.boolean().oneOf([true], consentErrorMessage),
});

export const MarketingConsent: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const storedMarketingConsent = useSelector(
    (state: any) => state.profileBuilder.marketingConsent
  );

  const initialValues = {
    privacyPolicyAgreement:
      storedMarketingConsent?.privacyPolicyAgreement ?? false,
    dataProcessingAgreement:
      storedMarketingConsent?.dataProcessingAgreement ?? false,
    gpContactAgreement: storedMarketingConsent?.gpContactAgreement ?? false,
    marketingAgreement: storedMarketingConsent?.marketingAgreement ?? false,
  };

  const handleSubmit = (values) => {
    dispatch(saveMarketingConsent(values));
    props.navigatToNextStep();
  };

  const getDataConsentFieldWithErrors = (errors) => {
    return DataConsentFieldNames.map((name) => {
      return { name: name, hasErrors: errors[name] };
    }).filter((errorList) => errorList.hasErrors);
  };

  const CheckboxFormControl = ({ touched, errors, children, className }) => {
    let validErrors = getDataConsentFieldWithErrors(errors);

    return (
      <FormControl
        component="fieldset"
        className={`$checkbox-group__container ${className ?? ""}`}
      >
        <FormGroup
          className={`checkboxes__container ${
            touched && validErrors.length > 1
              ? "checkboxes__container--has-errors"
              : ""
          }`}
        >
          {children}
        </FormGroup>
        {validErrors.length > 1 && (
          <ErrorMessage
            className="Mui-error"
            component={FormHelperText}
            name={validErrors[0]?.name}
          />
        )}
      </FormControl>
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={DataInformationConsentValidationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ isSubmitting, values, touched, errors }) => (
        <Form className="profile-builder-form">
          <Helmet>
            <title>Consent | Eligibility checker | Sign up | Gloji</title>
          </Helmet>
          <ModalPageWrapper>
            <ModalPageBody hasStickyFooter={false}>
              <ModalPageHeader
                className="modal-header_center"
                backButtonOnClick={() => {
                  props.navigateToPreviousStep();
                }}
              >
                Profile
              </ModalPageHeader>
              <MultiStep step={3} list={questionStepperList} percent={90} />

              <ModalPageHeading>Your consent</ModalPageHeading>
              <ModalPageQuestionDescription>
                Before we carry on, we need to check you're happy with a few
                things.
              </ModalPageQuestionDescription>
              <ol className="modal-page__question-description">
                <li>
                  You've read, understand and will abide by our{" "}
                  <strong>Privacy Notice</strong>{" "}
                  <a
                    href="https://app.gloji.co.uk/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                  . Take a moment to read it as it covers how we collect, use,
                  disclose and protect your personal information.
                </li>
                <li>
                  You're happy with us{" "}
                  <strong>processing your health data</strong>, including
                  collecting, storing and using this data within the platform.
                  You can find out more about how we keep your data safe by
                  reading our Privacy Notice{" "}
                  <a
                    href="https://app.gloji.co.uk/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                  .
                </li>

                <li>
                  You're happy for our <strong>Marketing</strong> team to get in
                  touch to share messages like promotional offers, newsletters
                  and service updates.
                </li>
              </ol>
              <ModalPageQuestionDescription>
                Your consent is voluntary and you can opt out at any time, but
                please note that certain features of our platform may be
                unavailable without your consent.
              </ModalPageQuestionDescription>
              <ModalPageQuestionDescription>
                Got a question or concern? Get in touch: support@gloji.co.uk.
              </ModalPageQuestionDescription>
              <ModalPageQuestionDescription>
                Happy to proceed? Great! Click below to confirm you've read,
                understand and give your consent.
              </ModalPageQuestionDescription>
              <CheckboxFormControl
                touched={touched}
                errors={errors}
                className="i-agree-checkbox-container"
              >
                <CheckboxFormGroup
                  name="privacyPolicyAgreement"
                  label="I've read and agree to the Privacy Notice."
                  touched={touched["privacyPolicyAgreement"]}
                  hasErrors={errors["privacyPolicyAgreement"]}
                  showError={getDataConsentFieldWithErrors(errors).length === 1}
                />
                <CheckboxFormGroup
                  name="dataProcessingAgreement"
                  label={
                    "I'm happy with you processing my health data and sharing it with Public Health for reporting purposes."
                  }
                  touched={touched["dataProcessingAgreement"]}
                  hasErrors={errors["dataProcessingAgreement"]}
                  showError={getDataConsentFieldWithErrors(errors).length === 1}
                />
                <CheckboxFormGroup
                  name="gpContactAgreement"
                  label={
                    "I'm happy with you sharing the outcome of my programme with my GP."
                  }
                  touched={touched["gpContactAgreement"]}
                  hasErrors={errors["gpContactAgreement"]}
                  showError={getDataConsentFieldWithErrors(errors).length === 1}
                />
              </CheckboxFormControl>
              <CheckboxGroupContainer name="dataInformation">
                <FormikCheckbox
                  key={"marketingAgreement"}
                  id={"marketingAgreement"}
                  name="marketingAgreement"
                  label={"I consent to receiving marketing communications."}
                />
              </CheckboxGroupContainer>
            </ModalPageBody>
            <ModalPageFooter hasStickyFooter={false}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Continue
              </Button>
            </ModalPageFooter>
          </ModalPageWrapper>
        </Form>
      )}
    </Formik>
  );
};
