import { useState, useEffect } from "react";
import MultipleQuestionsFormikForm from "./MultipleQuestionsFormikForm";
import MultipleQuestionsFormikStep from "./MultipleQuestionsFormikStep";

import { IsArrayEmpty } from "../../../../utils/IsEmpty";
import { completeAnswersPostBody } from "../../../../helpers/questionHelpers";
import { useDispatch, useSelector } from "react-redux";
import { post_complete_question } from "../../../../store/actions/postCompleteQuestion";
import { createValidationSchema, getQuestionInput } from "../../../../helpers/questionHelpers";

const MultipleQuestions = ({
  currentSectionQuestions,
  handleWatchAgain,
  userId,
  programmeId,
  journeyId,
  history,
  locationPath,
  locationState,
  stepId,
  isStep0,
}) => {
  const generateInputValuesObject = () => {
    var inputValuesObject = {};
    currentSectionQuestions.map((item, index) => {
      inputValuesObject[`programmeAnswerInput${index}`] = item?.responseGiven
        ? item?.response[0]
        : "";

      if (item.answerType === "MultiSelectOther") {
        inputValuesObject[`programmeAnswerInput${index}Other`] = "";
      }
    });

    return inputValuesObject;
  };

  const [inputValues, setInputValues] = useState(generateInputValuesObject());
  const [questionIds, setQuestionIds] = useState([]);
  const dispatch = useDispatch();
  const completeQuestion = useSelector((state) => state.completeQuestion);

  useEffect(() => {
    // generate inital values for inputs
    generateInputValuesObject();
  }, []);

  useEffect(() => {
    const qIdsArray =
      !IsArrayEmpty(currentSectionQuestions) &&
      currentSectionQuestions.map((item) => item.questionId);
    setQuestionIds(qIdsArray);
  }, [currentSectionQuestions]);

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(false);
    const valueEntries = Object.entries(values);
    // we only want the full combined value of the multiselect and the "other" text field to be submitted
    valueEntries.forEach(([key, value], index) => {
      if (key.includes("OtherCombined")) {
        var multiKey = key.replace("OtherCombined", "");
        delete values[multiKey];
        delete values[multiKey + "Other"];
      }
    });

    const answersArray = Object.values(values);

    const postBody = completeAnswersPostBody(stepId, questionIds, answersArray);
    // post params: userId, programmeId, journeyId, body, history, locationPath, locationState;
    dispatch(
      post_complete_question(
        userId,
        programmeId,
        journeyId,
        postBody,
        history,
        locationPath,
        locationState
      )
    );
  };

  return (
    <MultipleQuestionsFormikForm
      initialValues={inputValues}
      handleWatchAgain={handleWatchAgain}
      onSubmit={handleSubmit}
      validateOnChange={true}
      isStep0={isStep0}
    >
      {currentSectionQuestions.map((item, index) => {
        const schema = createValidationSchema(item, index);

        return (
          <MultipleQuestionsFormikStep validationSchema={schema} key={index}>
            <div>
              <div className="question-and-answer__container">
                <div className="question__container">
                  <p className="question" id="question">
                    {item.questionText}
                  </p>
                </div>
                <div className="answer__container">{getQuestionInput(item, index)}</div>
              </div>
              <div className="helper-text__container">
                <p className="helper-text">
                  Don’t worry, there’s no right or wrong here, whatever comes to mind is best. You
                  can always review your answers again at the end.
                </p>
              </div>
              {completeQuestion.errorStatus && (
                <div className="error-text__container">
                  <p className="submit-error-text">
                    Error occured while performing this request, Please try again!
                  </p>
                </div>
              )}
            </div>
          </MultipleQuestionsFormikStep>
        );
      })}
    </MultipleQuestionsFormikForm>
  );
};

export default MultipleQuestions;
