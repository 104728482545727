import React, { useEffect } from "react";
import { Route, Switch } from "react-router";
import Layout from "./templates/layout";
import { FeatureFlagRoute } from "./routes";
import Features from "../enums/features";
import Home from "./pages/Home";
import Article from "./pages/Article";
import Articles from "./pages/Articles";
import Recipes from "./pages/Recipes";
import ArticlePillar from "./pages/ArticlePillar/ArticlePillar";
import AuthorizeRoute from "./auth/AuthorizeRoute";
import { AccountActivation, ExpiredKey, InvalidKey } from "./pages/AccountActivation";
import MyProgress from "./pages/MyProgress";
import PageNotFound from "./pages/PageNotFound";
import { Redirect, useHistory } from "react-router-dom";
import PolicyDocument from "./pages/PolicyDocument";
import MyPath from "./pages/MyPath";
import VideoPage from "./pages/VideoPage";
import Step from "./pages/Step";
import StepCompleted from "./pages/StepCompleted";
import { EligibilityCheckerRoot } from "./pages/EligibilityChecker";
import { ProfileBuilderRoot, ProfileBuilderSelfRoot } from "./pages/ProfileBuilder";
import { ValidateEmailVerification, EmailVerification } from "./pages/EmailVerification";
import BookAppointmentFlow from "./pages/BookAppointmentFlow";
import { get_programme_overview } from "../store/actions/getProgrammeOverview";
import { useDispatch, useSelector } from "react-redux";
import { IsObjectEmpty } from "../utils/IsEmpty";
import Recipe from "./pages/Recipe";

export default function App() {
  const history = useHistory();

  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);

  useEffect(() => {
    if (!IsObjectEmpty(profile.data)) {
      const opts = profile.data.currentJourneys !== undefined
        ? {
          userId: profile.data.userId,
          idCollection: profile.data.currentJourneys.map(cj => ({ journeyId: cj.id, programmeId: cj.programmeId }))
        }
        : {
            userId: profile.data.userId,
            programmeId: profile.data.currentJourney.programmeIds[0],
            journeyId: profile.data.currentJourney.id,
        }
      dispatch(get_programme_overview(opts));
    }
  }, [dispatch, profile.data]);

  // When the modal is active and the user navigates back then remove modal-active class
  useEffect(() => {
    return history.listen(() => {
      document.getElementById("root").classList.remove("modal-active");
    });
  }, [history]);

  return (
    <Layout>
      <Switch>
        <Redirect from="/index.html" to="/" />
        <AuthorizeRoute exact path="/" component={Home} />
        <AuthorizeRoute exact path="/profile-builder" component={ProfileBuilderRoot} />
        <AuthorizeRoute exact path="/sign-up/profile-builder" component={ProfileBuilderSelfRoot} />
        <AuthorizeRoute exact path="/explore/articles" component={Articles} />
        <AuthorizeRoute exact path="/explore/recipes" component={Recipes} />
        <AuthorizeRoute path="/explore/recipes/:slug" component={Recipe} />
        <AuthorizeRoute exact path="/explore/articles/nutrition" component={ArticlePillar} />
        <AuthorizeRoute exact path="/explore/articles/movement" component={ArticlePillar} />
        <AuthorizeRoute exact path="/explore/articles/alcohol" component={ArticlePillar} />
        <AuthorizeRoute exact path="/explore/articles/mind" component={ArticlePillar} />
        <AuthorizeRoute exact path="/explore/articles/sleep" component={ArticlePillar} />
        <AuthorizeRoute exact path="/explore/articles/smoking" component={ArticlePillar} />
        <AuthorizeRoute path="/explore/articles/:slug" component={Article} />
        <AuthorizeRoute path="/my-progress" component={MyProgress} />
        <AuthorizeRoute exact path="/my-path" component={MyPath} />
        <AuthorizeRoute exact path="/my-path/:programme/step-:stepNumber" component={Step} />
        <AuthorizeRoute
          exact
          path="/my-path/:programme/step-:stepNumber/completed"
          component={StepCompleted}
        />
        <AuthorizeRoute
          exact
          path="/my-path/:programmeSlug/:stepTitleSlug/part-:partNumberString/:partTitleSlug/:sectionSlug"
          component={VideoPage}
        />
        <AuthorizeRoute exact path="/book-appointment" component={BookAppointmentFlow} />
        <AuthorizeRoute exact path="/account/verify-email" component={EmailVerification} />
        <Route exact path="/account/verify-email/:key" component={ValidateEmailVerification} />
        <Route exact path="/sign-up/activation/key-invalid" component={InvalidKey} />
        <Route exact path="/sign-up/activation/key-expired" component={ExpiredKey} />
        <Route exact path="/sign-up/eligibility-checker" component={EligibilityCheckerRoot} />
        <Route path="/sign-up/activation/:key" component={AccountActivation} />
        <Route path="/about" component={PolicyDocument} />
        <Route path="/contact" component={PolicyDocument} />
        <Route path="/accessibility" component={PolicyDocument} />
        <Route path="/terms-and-conditions" component={PolicyDocument} />
        <Route path="/privacy" component={PolicyDocument} />
        <Route path="/error/404" component={PageNotFound} />
        <Route path="/authentication" />
        <Route path="*">
          <Redirect push to="/error/404" />
          <PageNotFound />
        </Route>
      </Switch>
    </Layout>
  );
}
