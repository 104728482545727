import { createTheme } from "@material-ui/core/styles";
import { NAVY, MINT } from "../../../enums/brandColors";

const customTheme = createTheme({
  spacing: 5,
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1200,
      xl: 1200,
    },
  },

  background: {},
  palette: {
    primary: {
      main: `${MINT}`,
    },
    secondary: {
      main: `${NAVY}`,
    },
  },
  typography: {
    h1: {
      fontSize: 32,
      fontFamily: "Fredoka One",
      color: `${NAVY}`,
    },
    h2: {
      fontSize: 24,
      fontFamily: "Fredoka One",
      color: `${NAVY}`,
    },
    h3: {
      fontSize: 18,
      fontFamily: "Fredoka One",
      color: `${NAVY}`,
    },
    h4: {
      fontSize: 16,
      fontFamily: "Fredoka One",
      color: `${NAVY}`,
    },
    h5: {
      fontFamily: "Fredoka One",
      color: `${NAVY}`,
    },
    h6: {
      fontFamily: "Fredoka One",
      color: `${NAVY}`,
    },
    title: {
      fontSize: "20px",
      fontFamily: "Fredoka One",
      color: `${NAVY}`,
    },
    body1: {
      fontFamily: "Poppins",
    },
    body2: {
      fontFamily: "Poppins",
    },
  },
});

customTheme.overrides = {
  MuiContainer: {
    root: {
      maxWidth: `1200px !important`,
      paddingLeft: "10px !important",
      paddingRight: "10px !important",
    },
  },

  MuiToolbar: {
    root: {
      padding: `0`,
    },
    gutters: {
      padding: `0 !important`,
    },
  },
  MuiListItemText: {
    root: {
      fontSize: 20,
      fontFamily: "Fredoka One",
      color: `${NAVY}`,
    },
  },

  MuiMenu: {
    paper: {
      maxHeight: "100%",
      top: "50px",
    },
  },

  MuiMenuItem: {
    root: {
      fontFamily: "Poppins",
      color: `${NAVY}`,
    },
  },
  MuiFormLabel: {
    root: {
      fontSize: "0.85rem",
      color: `${NAVY}`,
    },
  },
  MuiOutlinedInput: {
    root: {
      "&$error": {
        "& $notchedOutline": { borderColor: "#d32f2f" },
      },
    },
  },
  MuiFormHelperText: {
    root: {
      "&.Mui-error": {
        color: "#d32f2f",
        fontFamily: "Poppins",
      },
    },
  },
};

export default customTheme;
