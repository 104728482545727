import React from "react";
import PropTypes from "prop-types";

export const ModalContent = ({ children, id, containerClassName, contentClassName }) => {
  return (
    <div className={`modal-content__container ${containerClassName || ``}`}>
      <div id={id} className={`modal-content ${contentClassName || ``}`}>
        {children}
      </div>
    </div>
  );
};

ModalContent.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
};
