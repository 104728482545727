import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import CircularLoader from "../../atoms/Loaders/CircularLoader";
import useUserId from "../../hooks/useUserId";
import { useDispatch, useSelector } from "react-redux";
import { update_answer } from "../../../store/actions/postCompleteQuestion";
import { useParams } from "react-router";
import { programmes } from "../../../enums/programmes";
import { getQuestionInput, createValidationSchema } from "../../../helpers/questionHelpers";
import { createResponeArray } from "../../../helpers/questionHelpers";

export function QuestionForm({ question, onSuccess }) {
  const { programme } = useParams();
  const userId = useUserId();
  const journeyId = localStorage.getItem("journeyID");
  const dispatch = useDispatch();
  const completeQuestion = useSelector((state) => state.completeQuestion);
  const currentStep = useSelector((state) => state.programmeStep);
  const [values, setValues] = useState(null);
  const [validationSchema, setValidationSchema] = useState(null);

  const generateInputValuesObject = () => {
    console.log("question" + JSON.stringify(question));
    var inputValuesObject = {};

    if (question.answerType === "MultiSelect") {
      inputValuesObject["programmeAnswerInput0"] = question.responseGiven ? question.response : "";
      return inputValuesObject;
    }

    if (question.answerType === "MultiSelectOther") {
      var optionResponses = [];
      var otherResponse = "";

      question.response.forEach((item) => {
        if ([...question.answerOptions].includes(item)) {
          optionResponses.push(item);
        } else {
          otherResponse = item;
        }
      });

      if (otherResponse !== "") {
        optionResponses.push("Other");
        inputValuesObject["programmeAnswerInput0Other"] = otherResponse;
      }

      inputValuesObject["programmeAnswerInput0"] = optionResponses;

      return inputValuesObject;
    }

    inputValuesObject["programmeAnswerInput0"] = question.responseGiven ? question.response[0] : "";
    return inputValuesObject;
  };

  const questionVm = {
    programmeAnswerInput0: question.responseGiven
      ? question.response.length > 1
        ? question.response
        : question.response[0]
      : "",
  };

  useEffect(() => {
    setValidationSchema(createValidationSchema(question));
  }, [question]);

  const handleSubmit = useCallback(
    (values, actions) => {
      let programmeId = programmes[programme]?.id;
      let data = {
        stepId: currentStep.data.stepId,
        questionId: question.questionId,
        response: createResponeArray(values),
      };
      dispatch(update_answer(userId, programmeId, journeyId, data));
      setValues(values);
      actions.setSubmitting(false);
    },
    [dispatch, programme, question, userId, currentStep.data.stepId, journeyId]
  );

  useEffect(() => {
    if (!completeQuestion.loading && !completeQuestion.errorStatus) {
      if (values) {
        question.response = createResponeArray(values);
        onSuccess && onSuccess();
      }
    }
  }, [completeQuestion.errorStatus, completeQuestion.loading, onSuccess, question, values]);

  return (
    <div className="form__container">
      <Formik
        initialValues={generateInputValuesObject()}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnChange={false}
      >
        {({ isSubmitting }) => (
          <Form id={"programmeQuestionsForm"}>
            <div className="question-and-answer__container">
              <div className="question__container">
                <p className="question" id="question">
                  {question.questionText}
                </p>
              </div>
              <div className="answer__container">{getQuestionInput(question)}</div>
            </div>
            <div className="helper-text__container">
              <p className="helper-text">
                Don’t worry, there’s no right or wrong here, whatever comes to mind is best. You can
                always review your answers again at the end.
              </p>
            </div>
            {completeQuestion.errorStatus && (
              <div className="error-text__container">
                <p className="submit-error-text">
                  An error occurred while saving your thoughts! Please try again later.
                </p>
              </div>
            )}
            <div className="form-footer">
              <div className="buttons__container">
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    className="lets-carry-on-button"
                    aria-label="submit answers"
                  >
                    Save my thoughts
                  </Button>
                </div>
              </div>
            </div>
            {isSubmitting && <CircularLoader />}
          </Form>
        )}
      </Formik>
    </div>
  );
}

QuestionForm.propTypes = {
  question: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
