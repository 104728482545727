import { ReactComponent as Logo } from "../../../assets/img/logo.svg";

const HeaderLogo = () => {
  return (
    <a
      href="/"
      className="header__logo"
      title="Go to gloji home page"
      aria-label="Go to gloji home page"
    >
      <Logo id="glojiHeaderLogo" />
    </a>
  );
};

export default HeaderLogo;
