import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";

import "./ActivationKey.scss";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const InvalidKey = () => {
  let query = useQuery();
  let status = query.get("status");
  return (
    <>
      <div className="signup-link__content-container">
        {status === "Cancelled" ? (
          <Container>
            <Helmet>
              <title>Referral Cancelled | Sign Up | Gloji</title>
            </Helmet>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} md={12}>
                <h1 className="signup-link__page-title">Oops! Your referral has been cancelled.</h1>
                <p className="signup-link__page-text">
                  Please contact support@gloji.co.uk for more information.
                </p>
              </Grid>
            </Grid>
          </Container>
        ) : (
          <Container>
            <Helmet>
              <title>Invalid Key | Sign Up | Gloji</title>
            </Helmet>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} md={12}>
                <h1 className="signup-link__page-title"> Oops! Your link to sign up is invalid.</h1>
                <p className="signup-link__page-text">
                  Please contact support@gloji.co.uk for more information.
                </p>
              </Grid>
            </Grid>
          </Container>
        )}
      </div>
    </>
  );
};
