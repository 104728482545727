import PropTypes from "prop-types";

const FooterContainer = ({ children, footerBg }) => {
  return (
    <footer
      className="footer-container"
      style={{ backgroundImage: `url(${footerBg})` }}
      role="contentinfo"
      aria-label="Footer"
    >
      {children}
    </footer>
  );
};

FooterContainer.propTypes = {
  children: PropTypes.node,
  footerBg: PropTypes.string,
};

export default FooterContainer;
