import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import useUserId from "../../../hooks/useUserId";
import authAxios from "../../../../helpers/authAxios";
import { urls } from "../../../../configs/serviceConfig";
import { CircularLoader } from "../../../atoms/Loaders";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { Pill } from "../../../atoms/Pills";
import Toast from "../../../atoms/Toast";
import CustomCalendar from "../../../molecules/CustomCalendar";
import {
  ModalPageBody,
  ModalPageCopy,
  ModalPageFooter,
  ModalPageHeading,
  ModalPageWrapper,
} from "../../../organisms/ModalPage";
import { CustomModal, ModalContent, ModalTitle } from "../../../organisms/CustomModal";
import { logException } from "../../../../utils/AppInsightsLogging";
import { useGetAppointmentsQuery } from "../../../../redux/profileApi";

import "./BookingCalendar.scss";
import { profileService } from "../../../services";
import { skipToken } from "@reduxjs/toolkit/query/react";

const BookingCalendar = () => {
  const history = useHistory();
  const userId = useUserId();
  const journeyId = localStorage.getItem("journeyID");
  const profile = useSelector((state) => state.profile);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ present: false, type: "" });
  const [booked, setBooked] = useState(false);

  const [timingData, setTimingData] = useState(undefined);
  const [dateValue, setDateValue] = useState();
  const [timingValue, setTimingValue] = useState();

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [timingsOpen, setTimingsOpen] = useState(false);

  const currentDate = moment();
  const [minBookingDate, setMinBookingDate] = useState(new Date());
  const [maxBookingDate, setMaxBookingDate] = useState(moment());

  const {
    data: appointmentsResponse,
    error: appointmentsError,
    isLoading,
    refetch,
  } = useGetAppointmentsQuery(userId ?? skipToken);

  useEffect(() => {
    const daysToLookIntoFuture =
      appointmentsResponse?.daysAvailableToBook !== undefined
        ? appointmentsResponse.daysAvailableToBook
        : 14;
    setMaxBookingDate(maxBookingDate.add(daysToLookIntoFuture, "day"));
  }, [appointmentsResponse]);

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "When can we talk? | Book an Appointment | Gloji",
    });
  }, []);

  useEffect(() => {
    setMinBookingDate(new Date(Object.keys(appointmentsResponse?.dateTime ?? {})[0]));
  }, []);

  useEffect(() => {
    if (!calendarOpen) return null;

    // REWRITE DAY HEADINGS
    const newDays = ["M", "T", "W", "T", "F", "S", "S"];
    for (let i = 1; i < 8; i++) {
      const element = document.querySelector(
        `.react-calendar__month-view__weekdays__weekday:nth-child(${i}) abbr`
      );
      element.innerHTML = newDays[i - 1];
    }
  }, [calendarOpen]);

  useEffect(() => {
    if (appointmentsError?.present && appointmentsError?.type === "double-booked") {
      return refetch();
    }
  }, [appointmentsError]);

  useEffect(() => {
    if (dateValue === undefined) return null;
    setTimingValue(undefined);

    const isDateAvailable = checkIfDateAvailable(dateValue);
    if (!isDateAvailable) return setTimingData(undefined);

    const selectedDateFormatted = moment(dateValue).format("YYYY-MM-DD");
    const times = appointmentsResponse.dateTime[selectedDateFormatted];
    setTimingData(times);
    if (!timingsOpen) setTimingsOpen(true);
  }, [dateValue]);
  /* eslint-enable */

  const checkDisabledDates = ({ activeStartDate, date, view }) => {
    // RETURN TRUE = DISABLED
    // RETURN FALSE = AVAILABLE

    // BELOW HANDLES DATES IN PAST + DATES PAST MAX FUTURE DATE + MAKES CURRENT DATE AVAILBALE
    const passedInDate = moment(date);
    const dateIsBefore = passedInDate.isBefore(currentDate);
    const dateIsAfterMax = passedInDate.isAfter(maxBookingDate);
    const dateIsCurrent = passedInDate.isSame(currentDate, "day");
    if (dateIsCurrent) return false;
    if (dateIsBefore || dateIsAfterMax) return true;

    // CHECK REMAINING DATES ARE AVAILABLE FROM THE API
    const isDateAvailable = checkIfDateAvailable(passedInDate);
    return !isDateAvailable; // INVERT FOR THE ABOVE COMMENTS IN THIS FUNC
  };

  const checkIfDateAvailable = (dateToCheck) => {
    const availableDates = Object.keys(appointmentsResponse.dateTime);
    const dateToCheckFormatted = moment(dateToCheck).format("YYYY-MM-DD");
    const isDateAvailable = availableDates.find((date) => date === dateToCheckFormatted)
      ? true
      : false;
    return isDateAvailable;
  };

  const resetCalendar = () => {
    setTimeout(() => {
      setError({ present: false, type: "" });
    }, 3500);
    setCalendarOpen(false);
    setTimingsOpen(false);
    setTimingData(undefined);
    setDateValue(undefined);
    setTimingValue(undefined);
  };

  const closeCalendar = () => {
    setError({ present: false, type: "" });
    resetCalendar();
  };

  const submitCalendar = () => {
    setError({ present: false, type: "" });
    setCalendarOpen(false);
  };

  const selectTime = (timing) => {
    setError({ present: false, type: "" });
    setTimingValue(timing);
  };

  const submitBooking = async () => {
    if (dateValue === undefined && calendarOpen === false) {
      return setError({ present: true, type: "date-box" });
    }
    if (calendarOpen === true) {
      return setError({ present: true, type: "calendar" });
    }
    if (timingValue === undefined) {
      return setError({ present: true, type: "timing" });
    }

    setLoading(true);

    if (profile?.data?.currentJourney?.flags?.appointmentBooked === true) {
      setLoading(false);
      setError({ present: true, type: "already-booked" });
      return setTimeout(() => {
        setError({ present: false, type: "" });
        history.push("/");
      }, 3500);
    }

    try {
      await createBooking();
    } catch (error) {
      logException(error);
      setLoading(false);
      if (error.response.status === 404) {
        setError({ present: true, type: "double-booked" });
      } else {
        setError({ present: true, type: "unknown" });
      }
      return resetCalendar();
    }

    try {
      await updateBookAppointmentFlag();
    } catch (error) {
      logException(error);
      setLoading(false);
      if (error.response.status !== 200) {
        setError({ present: true, type: "flag-not-updated" });
      }
      return resetCalendar();
    }

    setBooked(true);
    document.getElementById("root").classList.add("modal-active");
    return setLoading(false);
  };

  const createBooking = async () => {
    const url = `${urls.profileService}/v2/profile/${userId}/appointments/gloji`;
    const data = {
      day: moment(dateValue).format("YYYY-MM-DD"),
      time: timingValue,
      firstName: profile?.data?.personalDetails?.firstName,
      lastName: profile?.data?.personalDetails?.lastName,
      email: profile?.data?.personalDetails?.email,
      phoneNumber: profile?.data?.personalDetails?.phoneNumber,
    };
    const response = await authAxios.post(url, data, {
      withCredentials: true,
    });
    return response;
  };

  const updateBookAppointmentFlag = async () => {
    const payload = {
      step0Completed: true,
      initialWeightLogged: true,
      appointmentBooked: true,
    };
    const response = await profileService.setJourneyFlags(userId[0], journeyId, payload);
    return response;
  };

  const handleModalClose = () => {
    setBooked(false);
    return document.getElementById("root").classList.remove("modal-active");
  };

  const handleReturnMyPath = () => {
    handleModalClose();
    return (window.location.href = "/my-path");
  };

  const handleReturnHome = () => {
    handleModalClose();
    return (window.location.href = "/");
  };

  if (isLoading || loading) {
    return <CircularLoader />;
  }

  return (
    <>
      <Helmet>
        <title>When can we talk? | Book an Appointment | Gloji</title>
      </Helmet>
      <ModalPageWrapper>
        <ModalPageBody hasStickyFooter={false}>
          <ModalPageHeading size="large">When can we talk?</ModalPageHeading>
          <ModalPageCopy>
            Let us know when works for you to have a 40 minute call with your Health mentor.
          </ModalPageCopy>

          {!calendarOpen && (
            <div className="booking-calendar__date">
              <span>Select a date</span>
              <div
                className={`booking-calendar__date__input ${
                  error.present &&
                  error.type === "date-box" &&
                  "booking-calendar__date__input--error"
                }`}
                onClick={() => setCalendarOpen(true)}
              >
                {dateValue !== undefined ? (
                  <span>{moment(dateValue).format("DD/MM/YYYY")}</span>
                ) : (
                  <span>DD/MM/YYYY</span>
                )}
              </div>
              {error.present && error.type === "date-box" && (
                <div className="booking-calendar__date__error-msg">
                  <span>We need to know a date to contact you</span>
                </div>
              )}
            </div>
          )}

          {calendarOpen && (
            <CustomCalendar
              dateValue={dateValue}
              setDateValue={setDateValue}
              checkDisabledDates={checkDisabledDates}
              minBookingDate={minBookingDate}
              maxBookingDate={maxBookingDate.toDate()}
              closeCalendar={closeCalendar}
              submitCalendar={submitCalendar}
              error={error}
            />
          )}

          {timingsOpen && timingData !== undefined && (
            <div className="booking-calendar__timings-container">
              <h4>Select a time</h4>
              <div className="booking-calendar__timings">
                {timingData.map((timing) => (
                  <Pill
                    key={timing}
                    className={`pill default ${timingValue === timing && "pill--active"} ${
                      error.present && error.type === "timing" && "pill--error"
                    }`}
                    label={timing}
                    handleClick={() => selectTime(timing)}
                  />
                ))}
              </div>
              {error.present && error.type === "timing" && (
                <div className="booking-calendar__timings__error-msg">
                  <span>We need to know a time to contact you</span>
                </div>
              )}
            </div>
          )}
        </ModalPageBody>

        <ModalPageFooter hasStickyFooter={false}>
          <Button onClick={() => submitBooking()}>Book me in!</Button>
        </ModalPageFooter>
      </ModalPageWrapper>

      {booked && (
        <CustomModal
          open={booked}
          onClose={handleModalClose}
          id="completeBookingModal"
          modalTitleId="completeBookingModalTitle"
          modalDescriptionId="completeBookingModalDescription"
          aria-labelledby={"completeBookingModalTitle"}
        >
          <ModalContent id="completeBookingModalContent">
            <div className="celebration-img-container"></div>
            <ModalTitle id="completeBookingModalTitle">Ready to go!</ModalTitle>
            <p className="modal-text" id="completeBookingModalDescription">
              Take the first step on your journey with gloji.
            </p>
            <div id="completeBookingModalButtons">
              <Button
                className="complete-button"
                variant="contained"
                color="primary"
                onClick={handleReturnMyPath}
              >
                Show me my path
              </Button>
              <Button
                className="complete-button"
                variant="contained"
                color="secondary"
                onClick={handleReturnHome}
              >
                Return to home
              </Button>
            </div>
          </ModalContent>
        </CustomModal>
      )}

      {error.present && error.type === "already-booked" && (
        <Toast type="error" show={true}>
          You've already booked an appointment, returning home...
        </Toast>
      )}
      {error.present && error.type === "double-booked" && (
        <Toast type="error" show={true}>
          Your slot was just taken, please choose another time.
        </Toast>
      )}
      {error.present && error.type === "flag-not-updated" && (
        <Toast type="error" show={true}>
          Your account could not be marked as booked, please try again.
        </Toast>
      )}
      {error.present && error.type === "unknown" && (
        <Toast type="error" show={true}>
          An unknown error has occurred, please reload and try again.
        </Toast>
      )}
    </>
  );
};

export default BookingCalendar;
