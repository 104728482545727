import React, { useState } from "react";
import { Form, Formik } from "formik";
import classnames from "classnames";
import { Button } from "../../../atoms/Button";
import { SectionHeader, SectionHeading, SectionIntro } from "../../../atoms/SectionHeader";
import { CircularLoader } from "../../../atoms/Loaders";
import "./FormWizard.scss";

// FormikWizard is a single Formik instance whose children are each page of the
// multi-step form. The form is submitted on each forward transition (can only
// progress with valid input), whereas a backwards step is allowed with
// incomplete data. A snapshot of form state is used as initialValues after each
// transition. Each page has an optional submit handler, and the top-level
// submit is called when the final page is submitted.
const FormikWizard = (props) => {
  const { children, initialValues, onSubmit } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const [snapshot, setSnapshot] = useState(initialValues);

  const steps = React.Children.toArray(children);
  const step = steps[currentStep];
  const totalSteps = steps.length;
  const isLastStep = currentStep === totalSteps - 1;

  const next = (values) => {
    var nextStep = Math.min(currentStep + 1, totalSteps - 1);
    setSnapshot(values);
    setCurrentStep(nextStep);
  };

  const previous = (values) => {
    var nextStep = Math.max(currentStep - 1, 0);
    setSnapshot(values);
    setCurrentStep(nextStep);
  };

  const handleSubmit = async (values, bag) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values, bag);
    }
    if (isLastStep) {
      return onSubmit(values, bag);
    } else {
      bag.setTouched({});
      next(values);
    }
  };

  return (
    <Formik
      initialValues={snapshot}
      onSubmit={handleSubmit}
      validationSchema={step.props.validationSchema}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <div className="form-wizard">
            <div className="form-wizard__header" data-background-color="primary">
              <SectionHeader>
                <SectionHeading>{props.title}</SectionHeading>
                <SectionIntro>{props.description}</SectionIntro>
              </SectionHeader>
              <div className="wizard-navigation">
                <ul className="nav nav-pills">
                  {steps.map((step, key) => {
                    return (
                      <li className="nav-item" key={key}>
                        <a
                          href={props.location}
                          className={`nav-link ${classnames(
                            { active: key === currentStep },
                            { checked: key < currentStep }
                          )}`}
                        >
                          {key < currentStep ? <i className={props.completedIcon} /> : null}
                          <span>
                            {props.progressbar ? <p>{step.props.stepName}</p> : step.props.stepName}
                          </span>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="form-wizard__body"> {step}</div>
            <div className="form-wizard__footer">
              <div className="form-wizard__footer-button back">
                {currentStep > 0 ? (
                  <Button
                    type="button"
                    variant="outlined"
                    className={classnames("btn-previous", {
                      [props.previousButtonClasses]: props.previousButtonClasses !== undefined,
                    })}
                    onClick={() => previous(values)}
                    btnText={
                      props.previousButtonText !== undefined ? props.previousButtonText : "Back"
                    }
                    disabled={isSubmitting}
                    ariaLabel={"Go to prevoious step"}
                  />
                ) : null}
              </div>
              <div className="form-wizard__footer-button next">
                <Button
                  type="submit"
                  variant="contained"
                  className={classnames("btn-finish d-inline-block", {
                    [props.finishButtonClasses]: props.finishButtonClasses !== undefined,
                  })}
                  disabled={isSubmitting}
                  btnText={isSubmitting ? "Submitting..." : isLastStep ? "Finish" : "Next"}
                  ariaLabel={
                    isSubmitting
                      ? "Submitting form"
                      : isLastStep
                      ? "Finish the setup"
                      : "Go to Next Step"
                  }
                  color="primary"
                />
              </div>
            </div>
          </div>
          {isSubmitting && <CircularLoader />}
        </Form>
      )}
    </Formik>
  );
};

export default FormikWizard;
