import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NAVY } from "@thrivetribe/gloji.enums.brand-colors";
import { Field } from "formik";

const useStyles = makeStyles(() => ({
  root: {},
  textField: {
    "& .MuiFormLabel-root": {
      fontSize: "1rem",
      color: `${NAVY} !important;`,
      position: "relative",
      marginBottom: "0.5rem",
      lineHeight: "1.5rem",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 10px 10px 18px",
      color: NAVY,
    },
    "& .MuiInputLabel-outlined": {
      transform: "none !important",
    },
    "& legend": {
      display: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      top: "0px",
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": { borderColor: NAVY },
    },
    " & .MuiOutlinedInput-root.Mui-error": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
      },
    },
    "& .MuiInputBase-root": {
      backgroundColor: "white",
      "& input": {
        fontSize: "1.25rem",
      },
      "& fieldset": {
        borderColor: "transparent",
      },
    },
    "& .MuiFormHelperText-root": {
      fontSize: "1rem",
      color: "#bc3a0c !important",
      fontFamily: "Poppins",
      marginLeft: "0rem",
    },
  },
}));

const FormikField = ({ className, children, component, ...rest }) => {
  const classes = useStyles();

  return (
    <Field
      fullWidth
      className={`${className} ${classes.textField}`}
      variant="outlined"
      component={component}
      InputLabelProps={{
        shrink: true,
      }}
      {...rest}
    >
      {children}
    </Field>
  );
};
export default FormikField;
