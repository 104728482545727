import { UPDATE_CURRENT_TAB_INDEX } from "../constants";

const initialState = {
  currentTabIndex: 0,
};

const update_current_view_data_reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_TAB_INDEX:
      return { ...state, currentTabIndex: action.payload };
    default:
      return state;
  }
};

export default update_current_view_data_reducer;
