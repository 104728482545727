import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./LoadingScreen.scss";

/**
 * Will Show the loading page until both the loading flag is true and the artificialDelay has ran out, then it will execute the callback function
 */

const LoadingScreen = ({
  heading,
  svgAnimation,
  text,
  callback,
  artificialDelay = null,
  isLoading = false,
}) => {
  const [isTimoutComplete, setIsTimeoutComplete] = useState(false);

  useEffect(() => {
    if (artificialDelay != null) {
      setTimeout(() => {
        setIsTimeoutComplete(true);
      }, artificialDelay);
    } else {
      setIsTimeoutComplete(true);
    }
  }, [artificialDelay]);

  useEffect(() => {
    if (callback != null && !isLoading && isTimoutComplete) {
      callback();
    }
  }, [isLoading, isTimoutComplete, callback]);

  return (
    <div className="loading-screen">
      {heading && <h1 className="loading-screen__heading">{heading}</h1>}
      {svgAnimation}
      <div className="loading-screen__text">{text}</div>
    </div>
  );
};

LoadingScreen.propTypes = {
  heading: PropTypes.string,
  svgAnimation: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  callback: PropTypes.func,
  isLoading: PropTypes.bool,
  artificialDelay: PropTypes.number,
};

export default LoadingScreen;
