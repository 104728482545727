import NumericalInput from "../components/molecules/NumericalInput";
import { ErrorMessage } from "formik";
import SliderInput from "../components/molecules/SliderInput";
import RadioButtonGroup from "../components/molecules/RadioButtonGroup/RadioButtonGroup";
import CheckboxGroupContainer from "../components/atoms/CheckboxGroupContainer";
import FormikCheckbox from "../components/atoms/FormikCheckbox";
import { RemoveSpaces } from "../utils/StringUtils";
import { CheckboxGroupWithFreeText } from "../components/molecules/CheckboxGroupWithFreeText/CheckboxGroupWithFreeText";
import { CustomField } from "../components/atoms/InputField";
import { IsValueEmpty } from "../utils/IsEmpty";
import * as yup from "yup";

export const getQuestionInput = (question, index = 0) => {
  switch (question.answerType) {
    case "Numerical":
      return (
        <>
          <NumericalInput
            name={`programmeAnswerInput${index}`}
            id={`programmeAnswerInput${index}`}
            minValue={question?.parameters?.minimum}
            maxValue={question?.parameters?.maximum}
            interval={question?.parameters?.interval}
            sampleAnwser={question?.sampleAnswer}
          />
          <span className="programme-answer__validation-message">
            <ErrorMessage name={`programmeAnswerInput${index}`} />
          </span>
        </>
      );
    case "Scale":
      return (
        <>
          <SliderInput
            ariaLabelledby="question"
            name={`programmeAnswerInput${index}`}
            id={`programmeAnswerInput${index}`}
            minValue={question?.parameters?.minimum}
            maxValue={question?.parameters?.maximum}
            interval={question?.parameters?.interval}
            sampleAnwser={question?.sampleAnswer}
          />
          <span className="programme-answer__validation-message">
            <ErrorMessage name={`programmeAnswerInput${index}`} />
          </span>
        </>
      );
    case "SingleSelectHorizontal":
      return (
        <>
          {question.sampleAnswer && (
            <div className="single-select__sample-answer">{question.sampleAnswer}</div>
          )}
          <RadioButtonGroup
            className="single-select-horizontal"
            options={question.answerOptions.map((item) => {
              return { value: item, label: item };
            })}
            name={`programmeAnswerInput${index}`}
          />
        </>
      );
    case "SingleSelectVertical":
      return (
        <>
          {question.sampleAnswer && (
            <div className="single-select__sample-answer">{question.sampleAnswer}</div>
          )}
          <RadioButtonGroup
            className="single-select-vertical"
            options={question.answerOptions.map((item) => {
              return { value: item, label: item };
            })}
            name={`programmeAnswerInput${index}`}
          />
        </>
      );
    case "MultiSelect":
      return (
        <CheckboxGroupContainer name={`programmeAnswerInput${index}`}>
          {question.sampleAnswer && (
            <div className="multi-select__sample-answer">{question.sampleAnswer}</div>
          )}
          {question.answerOptions.map((item, itemIndex) => {
            const cleanItem = RemoveSpaces(item);
            return (
              <FormikCheckbox
                key={cleanItem + itemIndex}
                id={cleanItem + itemIndex}
                name={`programmeAnswerInput${index}`}
                label={item}
                value={item}
              />
            );
          })}
        </CheckboxGroupContainer>
      );
    case "MultiSelectOther":
      return (
        <>
          {question.sampleAnswer && (
            <div className="multi-select__sample-answer">{question.sampleAnswer}</div>
          )}
          <CheckboxGroupWithFreeText
            name={`programmeAnswerInput${index}`}
            options={question.answerOptions}
          />
        </>
      );
    default:
      return (
        <>
          <CustomField
            name={`programmeAnswerInput${index}`}
            id={`programmeAnswerInput${index}`}
            className="programme-answer__input"
            component={"textarea"}
            multiline
            rows={10}
            placeholder={question.sampleAnswer || "Your thoughts…"}
          />
          <span className="programme-answer__validation-message">
            <ErrorMessage name={`programmeAnswerInput${index}`} />
          </span>
        </>
      );
  }
};

export const createValidationSchema = (question, index = 0) => {
  const baseShape = {};
  const INT_REGEX = /^[-]?[0-9]+$/;
  const FLOAT_REGEX = /^[-]?([0-9]*[.])?[0-9]+$/;

  switch (question.answerType) {
    case "Numerical":
      const hasMinMax =
        !IsValueEmpty(question?.parameters?.minimum) &&
        !IsValueEmpty(question?.parameters?.maximum);

      baseShape[`programmeAnswerInput${index}`] = yup
        .string()
        .matches(
          (question?.parameters.interval?.toString() ?? "").includes(".") ? FLOAT_REGEX : INT_REGEX,
          "Please enter a number."
        )
        .test(
          "number-min",
          `Please enter a number between ${question.parameters.minimum}-${question.parameters.maximum}.`,
          (num) => (hasMinMax ? !(parseFloat(num) < question.parameters.minimum) : true)
        )
        .test(
          "number-max",
          `Please enter a number between ${question.parameters.minimum}-${question.parameters.maximum}.`,

          (num) => (hasMinMax ? !(parseFloat(num) > question.parameters.maximum) : true)
        )
        .required("Please enter a number.");

      break;
    case "Scale":
      baseShape[`programmeAnswerInput${index}`] = yup
        .string()
        .matches(
          (question?.parameters.interval?.toString() ?? "").includes(".") ? FLOAT_REGEX : INT_REGEX,
          "Please select a number."
        )
        .test(
          "number-min",
          `Please select a number between ${question.parameters.minimum}-${question.parameters.maximum}.`,
          (num) => !(parseFloat(num) < question.parameters.minimum)
        )
        .test(
          "number-max",
          `Please select a number between ${question.parameters.minimum}-${question.parameters.maximum}.`,

          (num) => !(parseFloat(num) > question.parameters.maximum)
        )
        .required("Please select a number.");
      break;
    case "MultiSelect":
      baseShape[`programmeAnswerInput${index}`] = yup
        .array()
        .min(1, "Please select at least one option");
      break;
    case "MultiSelectOther":
      baseShape[`programmeAnswerInput${index}`] = yup
        .array()
        .required("Please select at least one option")
        .min(1, "Please select at least one option");
      baseShape[`programmeAnswerInput${index}Other`] = yup
        .mixed()
        .when(`programmeAnswerInput${index}`, {
          is: (x) => x?.includes("Other"),
          then: yup
            .string()
            .trim()
            .min(2, "Your answer is too short! Must be between 2 to 500 characters.")
            .max(500, "Your answer is too long! Must be between 2 to 500 characters.")
            .required("Let us know your answer"),
          otherwise: yup.string().notRequired(),
        });
      break;
    case "SingleSelectHorizontal":
    case "SingleSelectVertical":
      baseShape[`programmeAnswerInput${index}`] = yup.string().required("Please select an option");
      break;
    default:
      baseShape[`programmeAnswerInput${index}`] = yup
        .string()
        .trim()
        .min(2, "Your answer is too short! Must be between 2 to 500 characters.")
        .max(500, "Your answer is too long! Must be between 2 to 500 characters.")
        .required(
          "Your thoughts are really going to help you on your journey. Take a moment to answer if you can."
        );
  }

  return yup.object().shape(baseShape);
};

export const completeAnswersPostBody = (stepId, questionIds, answers) => {
  const answersArray = [];

  answers.forEach((item, index) => {
    const answerObj = {};
    answerObj["questionId"] = questionIds[index];
    answerObj["response"] = Array.isArray(item)
      ? [...item.map((x) => x.toString())]
      : [item.toString()];
    answersArray.push(answerObj);
  });
  return {
    StepId: stepId,
    answers: answersArray,
  };
};

export const createResponeArray = (response) => {
  if (response["programmeAnswerInput0OtherCombined"] != null) {
    return [...response["programmeAnswerInput0OtherCombined"].map((x) => x.toString())];
  }

  return Array.isArray(response["programmeAnswerInput0"])
    ? [...response["programmeAnswerInput0"].map((x) => x.toString())]
    : [response["programmeAnswerInput0"].toString()];
};
