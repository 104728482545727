import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

/**
 * @param {number} top - The property of the ScrollToOptions dictionary which specifies the number of pixels along the Y axis
 *  to scroll the window or element.
 * @param {number} left - The property of the ScrollToOptions dictionary which specifies the number of pixels along the X axis
 *  to scroll the window or element.
 * @param {number} behavior - The property of the ScrollToOptions dictionary which specifies whether the scrolling should animate
 *  smoothly, or happen instantly in a single jump.
 * @returns null
 */
function ScrollTo({ top = 0, left = 0, behavior = "auto" }) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top, left, behavior });
  }, [behavior, left, pathname, top]);

  return null;
}

ScrollTo.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  behavior: PropTypes.oneOf(["auto", "smooth"]),
};

export default ScrollTo;
