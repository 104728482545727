import authAxios from "../../helpers/authAxios";
import {
  COMPLETE_QUESTION,
  QUESTION_COMPLETED,
  COMPLETE_QUESTION_FAILED,
  COMPLETE_QUESTION_ERROR_RESET,
} from "../constants";
import { urls } from "../../configs/serviceConfig";
import { logException } from "../../utils/AppInsightsLogging";

const complete_question = () => {
  return {
    type: COMPLETE_QUESTION,
  };
};

const question_completed = (payload) => {
  return {
    type: QUESTION_COMPLETED,
    payload,
  };
};

const complete_question_failed = (error) => {
  return {
    type: COMPLETE_QUESTION_FAILED,
    error,
  };
};

export const complete_question_error_reset = () => {
  return {
    type: COMPLETE_QUESTION_ERROR_RESET,
  };
};

export const post_complete_question = (
  userId,
  programmeId,
  journeyId,
  body,
  history,
  locationPath,
  locationState
) => {
  const url = `${urls.programmeService}/user-programme/${programmeId}/user/${userId}/journey/${journeyId}/complete-questions`;

  return (dispatch) => {
    dispatch(complete_question());

    authAxios
      .post(url, body)
      .then(async (response) => {
        await dispatch(question_completed(response.data));
        history.push({
          pathname: locationPath,
          state: locationState,
        });
      })
      .catch((error) => {
        logException(error);
        dispatch(complete_question_failed(error));
      });
  };
};

export const update_answer = (userId, programmeId, journeyId, data) => {
  const url = `${urls.programmeService}/user-programme/${programmeId}/user/${userId}/journey/${journeyId}/complete-question`;

  return (dispatch) => {
    dispatch(complete_question());

    authAxios
      .post(url, data)
      .then(async (response) => {
        await dispatch(question_completed(response.data));
      })
      .catch((error) => {
        logException(error);
        dispatch(complete_question_failed(error));
      });
  };
};
