import {
  ModalPageBody,
  ModalPageFooter,
  ModalPageHeader,
  ModalPageHeading,
  ModalPageWrapper,
} from "../../../../organisms/ModalPage";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { Helmet } from "react-helmet";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import * as yup from "yup";
import RadioButtonGroup from "../../../../molecules/RadioButtonGroup/RadioButtonGroup";
import { useSelector, useDispatch } from "react-redux";
import { savePregnancyState } from "../../../../../redux/profileBuilderSlice";
import { MultiStep } from "../../../../molecules/MultiStep";
import { questionStepperList } from "../../helpers";

interface Props {
  navigatToNextStep: () => void;
  navigateToPreviousStep: () => void;
}

const PregnancyValidationSchema = yup.object().shape({
  areYouPregnant: yup.string().required("Please select an option"),
});

const yesNoOptions = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

export const Pregnancy: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const storedAreYouPregnant = useSelector(
    (state: any) => state.profileBuilder.areYouPregnant
  );

  const handleSubmit = (values) => {
    dispatch(savePregnancyState(values.areYouPregnant));
    props.navigatToNextStep();
  };

  return (
    <Formik
      initialValues={{ areYouPregnant: storedAreYouPregnant }}
      onSubmit={handleSubmit}
      validationSchema={PregnancyValidationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ isSubmitting, values, touched, errors }) => (
        <Form className="profile-builder-form">
          <Helmet>
            <title>Mental health | Eligibility checker | Sign up | Gloji</title>
          </Helmet>
          <ModalPageWrapper>
            <ModalPageBody className="with-sticky-footer">
              <ModalPageHeader
                className="modal-header_center"
                backButtonOnClick={() => {
                  props.navigateToPreviousStep();
                }}
              >
                Profile
              </ModalPageHeader>
              <MultiStep step={3} list={questionStepperList} percent={60} />

              <ModalPageHeading>Are you pregnant?</ModalPageHeading>
              <RadioButtonGroup
                options={yesNoOptions}
                name="areYouPregnant"
                hasErrors={
                  errors["areYouPregnant"] && touched["areYouPregnant"]
                }
              />
              <Accordion
                square={true}
                elevation={0}
                className="eligibility-checker__accrodion"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon fontSize="large" />}
                  aria-controls={`mental-question-explanation`}
                >
                  <span>
                    We know, this stuff is personal! Find out why we need to
                    check.
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  <span>
                    While you can access general support to stop smoking through
                    gloji smoke free, we strongly advise that you access the
                    tailored support that is available through antenatal stop
                    smoking services. You can access specialist stop smoking
                    support by informing your midwife that you smoke at any of
                    your antenatal appointments. See&nbsp;
                    <a
                      href="https://www.nhs.uk/pregnancy/keeping-well/stop-smoking/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Stop smoking in pregnancy - NHS
                    </a>
                    &nbsp;to find out more about the benefits for yourself and
                    your baby to stopping smoking in pregnancy.
                  </span>
                </AccordionDetails>
              </Accordion>
            </ModalPageBody>
            <ModalPageFooter className="sticky-footer">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Continue
              </Button>
            </ModalPageFooter>
          </ModalPageWrapper>
        </Form>
      )}
    </Formik>
  );
};
