import { programmes } from "../../../enums/programmes";
import UnitOfMeasure from "../../../enums/UnitOfMeasure";
import unitConverter from "../../../utils/UnitConverter";

export const mapSignupPagesData = (pagesData) => {
  const mappedData = {};
  let mappedIneligibleScreenData = {};
  pagesData.forEach((item) => {
    item.ineligibleScreens.forEach((page) => {
      mappedIneligibleScreenData[page.eligibilityCondition] = page;
    });
    item.ineligibleScreens = mappedIneligibleScreenData;
    mappedIneligibleScreenData = {};
    mappedData[item.slug] = item;
  });

  return mappedData;
};

export const mapSignupFormValues = (signupFormValues) => {
  const mappedSignupFormValues = {
    personalDetails: {
      firstName: signupFormValues?.firstName,
      lastName: signupFormValues?.lastName,
      email: signupFormValues?.email,
      postcode:  signupFormValues?.postcode,
      phoneNumber: signupFormValues?.phoneNumber,
      dateOfBirth: signupFormValues?.age 
        ? new Date(
            Date.UTC(
              signupFormValues.age.getFullYear(), 
              signupFormValues.age.getMonth(), 
              signupFormValues.age.getDate()
            )
          ).toISOString().split('T')[0]
        : null,
      birthSex: signupFormValues?.sex,
      customGender: signupFormValues?.gender,
      ethnicity: signupFormValues?.ethnicity.value,
    },
    physicalHealthConditions: signupFormValues?.physicalConditions,
    mentalHealthConditions: signupFormValues?.mentalConditions,
    weightInKilos:
      signupFormValues?.weightDisplayUnitOfMeasure === UnitOfMeasure.METRIC
        ? Number(signupFormValues?.weight)
        : unitConverter.poundsToKilograms(
            unitConverter.stonesToPounds(signupFormValues?.weightStones) +
              Number(signupFormValues?.weightPounds)
          ),
    heightInCm:
      signupFormValues?.heightDisplayUnitOfMeasure === UnitOfMeasure.METRIC
        ? Number(signupFormValues?.height)
        : unitConverter.feetAndInchesToCentimeters(
            signupFormValues?.heightFeet,
            signupFormValues?.heightInches
          ),
    disabilities: signupFormValues?.disabilities,
    serviceCode: signupFormValues?.serviceCode,
    programmeIds: [programmes["lose-weight"].id],
    pathway: signupFormValues?.pathway,
    marketingConsent: {
      email: signupFormValues?.preferences.includes("email"),
      sms: signupFormValues?.preferences.includes("sms"),
      phone: signupFormValues?.preferences.includes("phone"),
    },
    dataInformationConsent: {
      marketingAgreement: signupFormValues?.marketingAgreement,
      privacyPolicyAgreement: signupFormValues?.privacyPolicyAgreement,
      dataProcessingAgreement: signupFormValues?.dataProcessingAgreement,
      gpContactAgreement: signupFormValues?.gpContactAgreement,
    },
  };
  return mappedSignupFormValues;
};

export const mapPathwayCheckValues = (signupFormValues) => {
  const mappedPathwayValues = {
    serviceId: signupFormValues?.serviceId,
    programmeId: programmes["lose-weight"].id,
    physicalHealthConditions: signupFormValues?.physicalConditions,
    mentalHealthConditions: signupFormValues?.mentalConditions,
    weightInKilos:
      signupFormValues?.weightDisplayUnitOfMeasure === UnitOfMeasure.METRIC
        ? Number(signupFormValues?.weight)
        : unitConverter.poundsToKilograms(
            unitConverter.stonesToPounds(signupFormValues?.weightStones) +
              Number(signupFormValues?.weightPounds)
          ),
    heightInCm:
      signupFormValues?.heightDisplayUnitOfMeasure === UnitOfMeasure.METRIC
        ? Number(signupFormValues?.height)
        : unitConverter.feetAndInchesToCentimeters(
            signupFormValues?.heightFeet,
            signupFormValues?.heightInches
          ),
  };
  return mappedPathwayValues;
};
