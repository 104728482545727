import React from "react";
import PropTypes from "prop-types";

const SectionHeading = ({ className, children }) => {
  return <h1 className={`section-header__heading ${className || ""}`}> {children} </h1>;
};

SectionHeading.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default SectionHeading;
