import { useState, useEffect, useCallback } from "react";
import { FormControl, FormGroup, Grid, OutlinedInput } from "@material-ui/core";
import { Button } from "@thrivetribe/gloji.ui.atoms.button/dist/button";
import { useDispatch, useSelector } from "react-redux";
import { post_weight } from "../../../store/actions/postWeight";
import { log_weight_error_reset } from "../../../store/actions/postWeight";
import LatestWeight from "../../../utils/LatestWeight";
import UnitOfMeasure from "../../../enums/UnitOfMeasure";
import unitConverter from "../../../utils/UnitConverter";
import { CircularLoader } from "@thrivetribe/gloji.ui.atoms.loader";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";

import "./LogWeightForm.scss";
import { weightTypes } from "../../../enums/weightTypes";

const LogWeightForm = ({ allWeights, closeModal, onSuccess, setDisplayWeightWarning }) => {
  const [weightUnit, setWeightUnit] = useState(UnitOfMeasure.METRIC); // We need a way to retrieve the users preferred UnitOfMeasure here.
  const [displayWeightError, setDisplayWeightError] = useState(false);
  const [userWarnedLowWeight, setUserWarnedLowWeight] = useState(false);
  const [formValues, setFormValues] = useState({
    kgs: null,
    stones: null,
    pounds: null,
  });

  const dispatch = useDispatch();
  const weightLogging = useSelector((state) => state.weightLogging);

  const toOneDecimalPlace = (value) => {
    return Math.round(Number(value) * 10) / 10;
  };

  const handleInputChange = useCallback(
    (event) => {
      const element = event.target;
      let value = event.target.value;
      if (element.pattern) {
        const regex = new RegExp(element.pattern);
        value = value.match(regex)[0];
      }
      setFormValues({ ...formValues, [element.name]: value });
    },
    [formValues]
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    let weightInKilos;
    //hide error messages
    setDisplayWeightError(false);
    setDisplayWeightWarning(false);

    if (weightUnit === UnitOfMeasure.IMPERIAL) {
      weightInKilos = unitConverter.poundsToKilograms(
        unitConverter.stonesToPounds(formValues.stones) + Number(formValues.pounds)
      );
    } else {
      weightInKilos = Number(formValues.kgs);
    }

    weightInKilos = toOneDecimalPlace(weightInKilos);

    if (weightInKilos <= 35) {
      setDisplayWeightError(true);
      return;
    }
    if (weightInKilos <= 50 && userWarnedLowWeight === false) {
      setUserWarnedLowWeight(true);
      setDisplayWeightWarning(true);
      return;
    }

    dispatch(
      post_weight(
        localStorage.getItem("userID"),
        localStorage.getItem("journeyID"),
        weightInKilos,
        weightTypes.PROGRAMME
      )
    );
  };

  useEffect(() => {
    if (weightLogging.errorStatus === true) {
      setTimeout(() => {
        dispatch(log_weight_error_reset());
      }, 3000);
    }
  }, [weightLogging.errorStatus, dispatch]);

  useEffect(() => {
    weightLogging.isSuccess === true && closeModal();
  }, [closeModal, weightLogging.isSuccess]);

  useEffect(() => {
    weightLogging.isSuccess === true && onSuccess();
    //Trigger google analytics event tag
    weightLogging.isSuccess === true &&
      window.gtag("event", "weight_log", {
        event_category: "Add",
        event_label: "user.weightlog",
      });
  }, [onSuccess, weightLogging.isSuccess]);

  const handleWeightUnitChange = (unit) => {
    setDisplayWeightError(false);
    setUserWarnedLowWeight(false);
    if (unit === UnitOfMeasure.IMPERIAL) {
      if (formValues.kgs && formValues.kgs !== "") {
        const [stones, pounds] = unitConverter.kilogramsToStonesAndPounds(formValues.kgs);
        setFormValues({ ...formValues, stones: stones.toFixed(0), pounds: pounds.toFixed(0) });
      }
    } else {
      if (
        (formValues.stones && formValues.stones !== "") ||
        (formValues.pounds && formValues.pounds !== "")
      ) {
        const kgs = unitConverter.poundsToKilograms(
          unitConverter.stonesToPounds(formValues.stones) + Number(formValues.pounds)
        );
        setFormValues({ ...formValues, kgs: toOneDecimalPlace(kgs) });
      }
    }
    setWeightUnit(unit);
  };

  return (
    <>
      <form>
        <FormGroup className="log-weight__form-control">
          {weightLogging.loading !== true ? (
            weightLogging.isSuccess === false && weightLogging.errorStatus === false ? (
              weightUnit === UnitOfMeasure.IMPERIAL ? (
                <div className="imperial-weight-inputs">
                  <div className="input__flex-container">
                    <FormControl error={displayWeightError}>
                      <OutlinedInput
                        id="logWeightInStones"
                        className="log-weight__input"
                        name="stones"
                        type="number"
                        value={formValues.stones}
                        onChange={handleInputChange}
                        inputProps={{
                          "aria-label": "Log weight in stones",
                          pattern: "^[0-9]{0,2}",
                        }}
                      />
                    </FormControl>
                    <div className="metric-label__container">
                      <p className="metric-label__text">st</p>
                    </div>
                  </div>
                  <div className="input__flex-container">
                    <FormControl error={displayWeightError}>
                      <OutlinedInput
                        id="logWeightInPounds"
                        className="log-weight__input"
                        name="pounds"
                        type="number"
                        value={formValues.pounds}
                        onChange={handleInputChange}
                        inputProps={{
                          "aria-label": "Log weight in pounds",
                          pattern: "^[0-9]{0,2}",
                        }}
                      />
                    </FormControl>
                    <div className="metric-label__container">
                      <p className="metric-label__text">lb</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="input__flex-container">
                  <FormControl error={displayWeightError}>
                    <OutlinedInput
                      id="logWeightInKgs"
                      className="log-weight__input"
                      name="kgs"
                      type="number"
                      value={formValues.kgs}
                      onChange={handleInputChange}
                      inputProps={{
                        "aria-label": "Log weight in kilograms",
                        pattern: "^[0-9]{0,3}(\\.[0-9]{0,1})?",
                      }}
                    />
                  </FormControl>
                  <div className="metric-label__container">
                    <p className="metric-label__text">kg</p>
                  </div>
                </div>
              )
            ) : (
              <div className="logging-status__container">
                {weightLogging.isWeightLogged === true && weightLogging.errorStatus === false && (
                  <div className="logging-status__success">
                    <CheckCircleIcon />
                  </div>
                )}
                {weightLogging.errorStatus === true && (
                  <div className="logging-status__error">
                    <p>
                      <ErrorIcon /> Uh oh..Error occurred, please try again{" "}
                    </p>
                  </div>
                )}
              </div>
            )
          ) : (
            <div className="logging-status__container">
              <CircularLoader color="secondary" style={{ marginTop: "0rem" }} />
            </div>
          )}

          {displayWeightError === true && (
            <div className="logging-status__error">
              <p>
                Please enter a valid weight. Did you mean to enter{" "}
                {weightUnit === UnitOfMeasure.METRIC ? "st & lbs" : "kg"}? Click below to change
                units{" "}
              </p>
            </div>
          )}

          <Button
            className="log-weight__submit-button"
            color="primary"
            type="submit"
            onClick={handleSubmit}
            disabled={weightLogging.errorStatus}
          >
            Submit
          </Button>
          {weightUnit === UnitOfMeasure.IMPERIAL ? (
            <Grid>
              <Grid item sm={12}>
                <button
                  type="button"
                  className="unit-converter"
                  onClick={() => handleWeightUnitChange(UnitOfMeasure.METRIC)}
                >
                  <i className="fas fa-sync unit-converter-icon mr-sm-2"></i>
                  <span>Show kgs</span>
                </button>
              </Grid>
            </Grid>
          ) : (
            <Grid>
              <Grid item sm={12}>
                <button
                  type="button"
                  className="unit-converter"
                  onClick={() => handleWeightUnitChange(UnitOfMeasure.IMPERIAL)}
                >
                  <i className="fas fa-sync unit-converter-icon mr-sm-2"></i>
                  <span>Show st & lbs</span>
                </button>
              </Grid>
            </Grid>
          )}
        </FormGroup>
      </form>
    </>
  );
};

export default LogWeightForm;
