import { Grid } from "@material-ui/core";
import moment from "moment";
import React from "react";
import PropTypes from "prop-types";
import ProgrammeStep from "../../molecules/ProgrammeStep";

import "./ProgrammeOverview.scss";

const ProgrammeOverview = ({ programmeState, startDate, steps = [] }) => {
  const calcDiffInDays = () => {
    if (startDate === "") return 0;

    const start = moment(startDate);
    const today = moment(new Date());
    return today.diff(start, "days");
  };

  const calcHeightProgress = () => {
    const initalPercentageAsFloat = 0.08333; // Step 0 bar = 1 week
    const daysCompleteOutOfProgrammeAsFloat = calcDiffInDays() / 84;
    const programmeCompletedPercentage =
      (daysCompleteOutOfProgrammeAsFloat + initalPercentageAsFloat) * 100;
    const cssBarTopPxProperty = "20px"; // From linked CSS file, allows correct adjustment
    const cssHeight = `calc(${programmeCompletedPercentage}% - ${cssBarTopPxProperty})`;

    if (programmeCompletedPercentage > 96) return "96%"; // Ensure green bar never is taller than navy bar
    return cssHeight;
  };

  return (
    <div className="programme-overview">
      <Grid container spacing={3}>
        <div className="programme-overview__navy"></div>
        <div className="programme-overview__green" style={{ height: calcHeightProgress() }}></div>
        {steps.length > 0 &&
          steps.map((step, index) => {
            return (
              <Grid key={step.stepNumber} item xs={12} style={{ width: "100%" }}>
                <ProgrammeStep isLastStep={step.stepNumber === steps.length - 1} {...step} />
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

ProgrammeOverview.propTypes = {
  programmeState: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  steps: PropTypes.array.isRequired,
};

export default ProgrammeOverview;
