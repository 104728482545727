import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
} from "@material-ui/core";
import { MultiStep } from "../../../../molecules/MultiStep";
import { questionStepperList } from "../../helpers";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { FormikField } from "../../../../atoms/FormikField";
import { FormikNumberInput } from "../../../../atoms/FormikInput";
import { ErrorMessage, Form, Formik } from "formik";
import { Helmet } from "react-helmet";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import {
  ModalPageBody,
  ModalPageCopy,
  ModalPageFooter,
  ModalPageHeader,
  ModalPageHeading,
  ModalPageWrapper,
} from "../../../../organisms/ModalPage";
import {
  determineErrorFieldName,
  formatValue,
  getInitialAgeValues,
} from "./utils";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { saveDateOfBirth } from "../../../../../redux/profileBuilderSlice";

interface Props {
  navigatToNextStep: () => void;
  navigateToPreviousStep: () => void;
}

export const DateOfBirthValidationSchema = yup.object().shape({
  date: yup
    .string()
    .required("Please enter a day value")
    .min(1, "Please enter a day value")
    .test({
      name: "is-date-valid",
      message: "Please enter a valid day value",
      test: (value) => value && Number(value) > 0 && Number(value) < 32,
    }),
  month: yup
    .string()
    .required("Please enter a month value")
    .min(1, "Please enter a month value")
    .test({
      name: "is-month-valid",
      message: "Please enter a valid month",
      test: (value) => value && Number(value) > 0 && Number(value) < 13,
    }),
  year: yup
    .string()
    .required("Please enter a year value")
    .length(4, "Please add a valid year value")
    .test({
      name: "is-year-valid",
      message: "Please add a valid year value",
      test: (value) => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        return value && Number(value) <= currentYear;
      },
    }),
});

export const DateOfBirth: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const storedDateOfBirth = useSelector(
    (state: any) => state.profileBuilder.dateOfBirth
  );
  const initialValues = getInitialAgeValues(storedDateOfBirth ?? "");

  const handleSubmit = (values, formikhelpers) => {
    formikhelpers.setSubmitting(true);
    // validate full dob
    const dateOfBirth = `${formatValue(values.year)}/${formatValue(
      values.month
    )}/${formatValue(values.date)}`;
    const momentDate = moment(dateOfBirth, "YYYY/MM/DD", true);
    const isValidDate = momentDate.isValid();

    var ageInYears = moment().diff(momentDate, "years");
    if (ageInYears < 18) {
      formikhelpers.setErrors({ date: "You must be over 18 years old" });
      formikhelpers.setSubmitting(false);
      return;
    } else if (ageInYears > 110) {
      formikhelpers.setErrors({
        date: "Please enter a date of birth younger than 110 years old",
      });
      formikhelpers.setSubmitting(false);
      return;
    }

    if (isValidDate) {
      const age = new Date(dateOfBirth);
      dispatch(saveDateOfBirth(age));
      formikhelpers.setSubmitting(false);
      props.navigatToNextStep();
    } else {
      formikhelpers.setErrors({ date: "Please add the right date" });
      formikhelpers.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={DateOfBirthValidationSchema}
      validateOnChange={false}
    >
      {({ handleChange, errors, touched }) => {
        const fieldName = determineErrorFieldName(errors, touched);
        return (
          <Form className="profile-builder-form">
            <Helmet>
              <title> Age | Eligibility checker | Sign up | Gloji </title>
            </Helmet>
            <ModalPageWrapper>
              <ModalPageBody className="with-sticky-footer">
                <ModalPageHeader
                  className="modal-header_center"
                  backButtonOnClick={() => {
                    props.navigateToPreviousStep();
                  }}
                >
                  Profile
                </ModalPageHeader>
                <MultiStep step={3} list={questionStepperList} percent={30} />
                <ModalPageHeading>When were you born?</ModalPageHeading>
                <ModalPageCopy>
                  <span style={{ marginTop: "15px", marginBottom: "20px" }}>
                    Age is just a number... but it's a number we need
                    nonetheless.
                  </span>
                </ModalPageCopy>
                <FormGroup id="date-of-birth">
                  <InputLabel
                    htmlFor="date-of-birth"
                    className="eligibility-checker__input-label"
                  >
                    Date of birth
                  </InputLabel>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <FormikField
                        id="date"
                        name="date"
                        className="eligibility-checker__text-input"
                        type="number"
                        pattern={/^([0-9]{0,2})/g}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          console.log("form level change detedted");
                          handleChange(e);
                        }}
                        component={FormikNumberInput}
                        placeholder="DD"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormikField
                        id="month"
                        className="eligibility-checker__text-input"
                        name="month"
                        type="number"
                        pattern={/^([0-9]{0,2})/g}
                        component={FormikNumberInput}
                        placeholder="MM"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormikField
                        id="year"
                        className="eligibility-checker__text-input"
                        name="year"
                        type="number"
                        pattern={/^([0-9]{0,4})/g}
                        component={FormikNumberInput}
                        placeholder="YYYY"
                      />
                    </Grid>
                  </Grid>

                  <ErrorMessage
                    key={fieldName}
                    className="Mui-error"
                    name={fieldName}
                    component={FormHelperText}
                  />
                </FormGroup>
                <Accordion
                  square={true}
                  elevation={0}
                  className="eligibility-checker__accrodion"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon fontSize="large" />}
                    aria-controls={`age-question-explanation`}
                  >
                    <span>
                      We totally agree, you're as young as you feel, so why do
                      we need to know your age?
                    </span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span>
                      You've got to be over a certain age to get into this
                      club... plus we want to remember your birthday!
                    </span>
                  </AccordionDetails>
                </Accordion>
              </ModalPageBody>
              <ModalPageFooter className="sticky-footer">
                <Button type="submit" variant="contained" color="primary">
                  Continue
                </Button>
              </ModalPageFooter>
            </ModalPageWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};
