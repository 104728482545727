import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  ModalPageHeader,
  ModalPageBody,
  ModalPageFooter,
  ModalPageWrapper,
  ModalPageHeading,
  ModalPageQuestionDescription,
} from "../../../organisms/ModalPage";
import RadioButtonGroup from "../../../molecules/RadioButtonGroup/RadioButtonGroup";
import { update_signup_form_values } from "../../../../store/actions/signupFlowData";
import { DisabilityRegisterValidationSchema } from "../EligibilityCheckerValidation";

const DisabilityRegister = () => {
  const dispatch = useDispatch();
  const signupFormValues = useSelector((state) => state.signupFlowData.signupFormValues);
  const disabilitiesData = useSelector(
    (state) => state.signupFlowData.signupPagesData["disabilities"]
  );

  let history = useHistory();

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Disabilities | Eligibility checker | Sign up | Gloji",
    });
  }, []);

  const handleSubmit = (values) => {
    history.push(`/disabilities/loading`);
    // Save values to store
    dispatch(update_signup_form_values(values));
  };

  const initialValues = {
    disabilityRegister: signupFormValues?.disabilityRegister ?? "",
  };

  const yesNoOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={DisabilityRegisterValidationSchema}
        validateOnChange={false}
      >
        {({ isSubmitting, values, touched, errors }) => (
          <Form className="eligibility-checker-form disabilities-form">
            <Helmet>
              <title>Disabilities | Eligibility checker | Sign up | Gloji</title>
            </Helmet>
            <ModalPageWrapper>
              <ModalPageBody className="with-sticky-footer">
                <ModalPageHeader
                  backButtonOnClick={() => {
                    history.goBack();
                  }}
                >
                  Checking we're a good fit
                </ModalPageHeader>
                <ModalPageHeading>{disabilitiesData?.questions[1].question}</ModalPageHeading>
                <ModalPageQuestionDescription>
                  {disabilitiesData?.questions[1].description}
                </ModalPageQuestionDescription>
                <RadioButtonGroup
                  options={yesNoOptions}
                  name="disabilityRegister"
                  hasErrors={errors["disabilityRegister"] && touched["disabilityRegister"]}
                />
              </ModalPageBody>
              <ModalPageFooter className="sticky-footer">
                <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                  Continue
                </Button>
              </ModalPageFooter>
            </ModalPageWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default DisabilityRegister;
