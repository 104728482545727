import authService from "../components/auth/AuthorizeService";

export const authorizationHeaders = (headers: Headers) => {
  return authService.getAccessToken().then((token: any) => {
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
      headers.set("Accept", "application/json");
    }
    return headers;
  });
};
