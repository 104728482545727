import moment from "moment";
import { programmes } from "../../../../enums/programmes";
import PropTypes from "prop-types";

const PageContent = ({ programmeId, username }) => {
  const smokeFreeContent = (username) => {
    return (
      <>
        <p>
          We promise to set you up with everything you need to build better habits, go smoke free
          and enjoy living a healthier life.
        </p>
        <p>Boost your chances of success right now by committing to yourself and your journey.</p>
        <p>
          “Today, {moment().format("DD MMMM, YYYY")}, I, {username}, am taking the first step to
          start making small changes towards becoming smoke free and feeling great.”
        </p>
      </>
    );
  };

  const loseWeightContent = (username) => {
    return (
      <>
        <p>
          Our promise to you is to provide the practical tools you need to build better habits and
          enjoy living a healthier life.
        </p>
        <p>
          There’s one more thing you can do right now to boost your chances of success and that’s
          commit to yourself!
        </p>
        <p>
          “Today, {moment().format("DD MMMM, YYYY")}, is the day that I,{username}, take the first
          step on my gloji journey towards making small changes towards my ambitions and feeling
          good”
        </p>
      </>
    );
  };

  const renderPageContent = (programmeId, username) => {
    switch (programmeId) {
      case programmes["lose-weight"].id:
        return loseWeightContent(username);
      case programmes["smoke-free"].id:
        return smokeFreeContent(username);
      default:
        return <p> No data to show </p>;
    }
  };

  return <>{renderPageContent(programmeId, username)}</>;
};

PageContent.propTypes = {
  programmeId: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};

export default PageContent;
