import axios from "axios";
import { logException } from "../../utils/AppInsightsLogging";

class TokenService {
  async getCsrfToken(email) {
    const encodedEmail = encodeURIComponent(email);
    let url = `/api/anti-forgery-token/${encodedEmail}`;

    try {
      const res = await axios.get(url);
      const token = res.data;
      return token;
    } catch (error) {
      logException(error);
      throw new Error("An error occurred, please try again");
    }
  }
}

const tokenService = new TokenService();

export default tokenService;
