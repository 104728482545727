import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

export const continueLocationState = (content, nextSection, currentPart) => {
  return {
    playbackId: `${nextSection && nextSection.video.videoDetails.playbackId}`,
    thumbnailUrl: nextSection.video.videoDetails.thumbnailUrl,
    videoId: nextSection && nextSection.video.videoId,
    videoTitle: nextSection && nextSection.title,
    partTitle: currentPart && currentPart.title,
    stepTitle: content && content.data.title,
    programmeSlug: content && content.data.programmeSlug,
    videoProgress: nextSection && nextSection.video.progress,
    videoWatched: nextSection && nextSection.video.videoWatched,
    sectionNumber: nextSection && nextSection.sectionNumber,
  };
};

const Continue = ({ content, nextSection, currentPart, children }) => {
  const { stepTitleSlug, programmeSlug, partNumberString } = useParams();
  return (
    <Link
      to={{
        pathname: `/my-path/${programmeSlug}/${stepTitleSlug}/part-${partNumberString}/${
          currentPart && currentPart.slug
        }/${nextSection && nextSection.slug}`,
        state: continueLocationState(content, nextSection, currentPart),
      }}
      className="video-card__container-link"
      aria-label={`Play video: ${nextSection && nextSection.title}`}
    >
      {children}
    </Link>
  );
};

export default Continue;
