import { FormHelperText } from "@material-ui/core";
import { useField, ErrorMessage } from "formik";
import Select from "react-select";
import { NAVY } from "@thrivetribe/gloji.enums.brand-colors";
import { InputLabel } from "@material-ui/core";
import PropTypes from "prop-types";

const ReactSelect = ({ name, options, label, labelCssClass }) => {
  const [field, meta, helpers] = useField(name);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "none",
      color: `${NAVY}`,
      fontSize: "20px",
      padding: 10,
      backgroundColor: state.isSelected ? "#f6f6f6" : "inherit",
      fontWeight: state.isSelected ? "bold" : "inherit",
      "&:hover": {
        backgroundColor: "#f6f6f6",
        fontWeight: "bold",
      },
    }),
    control: (provided, state) => ({
      ...provided,

      "&:hover": { border: `1px solid ${NAVY}` },
      borderRadius: "5px",
      border: state.isFocused
        ? `1px solid ${NAVY}`
        : meta.touched && meta.error
        ? `1px solid #d32f2f`
        : `1px solid transparent`,
      outline: state.isFocused
        ? `1px solid ${NAVY}`
        : meta.touched && meta.error
        ? `1px solid #d32f2f`
        : `none`,
      boxShadow: "none",
      backgroundColor: "#f7f7f7",
      fontSize: "20px",
      paddingLeft: "10px",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transition: "all .2s ease",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
      svg: {
        fill: `${NAVY}`,
        height: "25px",
        width: " 25px",
      },
    }),
    menu: (provided) => ({
      ...provided,
      border: `1px solid ${NAVY}`,
      outline: `1px solid ${NAVY}`,
      boxShadow: "none",
      marginTop: "-2px",
      borderTop: "none",
      borderRadius: "0px 0px 5px 5px",
      width: "100%",
      padding: "0px",
    }),
    menuPortal: (provided) => ({
      ...provided,
      marginBottom: "50px",
    }),
    input: (provided) => ({
      ...provided,
      color: `${NAVY}`,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#131C4F80",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: `${NAVY}`,
    }),

    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
  };

  return (
    <>
      {label !== undefined && (
        <InputLabel htmlFor={`${name}-input`} className={labelCssClass}>
          {label}
        </InputLabel>
      )}

      <Select
        name={name}
        id={name}
        inputId={`${name}-input`}
        value={field.value}
        onChange={(value) => helpers.setValue(value)}
        options={options}
        onBlur={() => helpers.setTouched(true)}
        styles={customStyles}
        placeholder="Select an option"
      />
      <ErrorMessage className="Mui-error" component={FormHelperText} name={name} />
    </>
  );
};

ReactSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  labelCssClass: PropTypes.string,
};

export default ReactSelect;
