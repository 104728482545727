import { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * Custom React hook for using content data.
 * @param {String} contentId - The content ID to load
 * @param {Function} serviceFunc - The service function that gets the content data from API.
 * @returns {Array} [data, isReady]
 */
function useContent(contentId, serviceFunc) {
  const [data, setData] = useState({});
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const getContent = async () => {
      let content = await serviceFunc(contentId);
      if (Object.keys(content).length !== 0) {
        setData(content);
        setIsReady(true);
      }
    };

    getContent();
  }, [contentId, serviceFunc]);

  return [data, isReady];
}

useContent.propTypes = {
  contentId: PropTypes.string.isRequired,
  serviceFunc: PropTypes.func.isRequired,
};

export default useContent;
