import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Container } from "@material-ui/core";
import { SectionHeader } from "../../atoms/SectionHeader";
import { CircularLoader } from "../../atoms/Loaders";
import { greyVelvet } from "../../../assets/img/bg";
import PageBackground from "../../organisms/PageBackground";
import "./VideoPage.scss";
import VjsPlayer from "../../molecules/VjsPlayer";
import { Grid } from "@material-ui/core";
//videoJS player styles
import "video.js/dist/video-js.css";
import VideoOverlay from "../../molecules/VideoOverlay";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { useHistory, useLocation, useParams } from "react-router";
import useUserId from "../../hooks/useUserId";
import slugConverter from "../../../utils/SlugConverter";
import { useDispatch, useSelector } from "react-redux";
import { get_specific_programme_step } from "../../../store/actions/getProgrammeStep";
import { Link } from "react-router-dom";
import { BackButtonLink } from "../../atoms/Button";
import Continue, { continueLocationState } from "./Continue";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { CustomModal, ModalContent } from "../../organisms/CustomModal";
import { IsArrayEmpty, IsValueEmpty } from "../../../utils/IsEmpty";
import { complete_question_error_reset } from "../../../store/actions/postCompleteQuestion";
import MultipleQuestions from "../../organisms/MultiStepForms/MultipleQuestions";
import { Alert, AlertTitle } from "@material-ui/lab";
import { TopRightModalCloseButton } from "../../atoms/TopRightModalCloseButton";
import { contentService } from "../../services";
import { logException } from "../../../utils/AppInsightsLogging";

const numbersInText = ["one", "two", "three", "four", "five"];

const VideoPage = () => {
  const playerRef = useRef(null);
  const location = useLocation();
  const { stepTitleSlug, programmeSlug, sectionSlug, partNumberString } = useParams();
  const partNumber = Number(partNumberString) - 1;
  const [videoProps, setVideoProps] = useState(null);
  const { playbackId, thumbnailUrl, videoId, videoTitle, videoWatched } = videoProps || {};
  const [partTitle, setPartTitle] = useState();
  const [stepTitle, setStepTitle] = useState();

  const content = useSelector((state) => state.programmeStep);
  const completeQuestion = useSelector((state) => state.completeQuestion);
  const dispatch = useDispatch();
  const [userId] = useUserId();
  const programmeId = localStorage.getItem("programmeID");
  const journeyId = localStorage.getItem("journeyID");
  const [stepNumber, setStepNumber] = useState(undefined);
  const [videoEnded, setVideoEnded] = useState(false);
  const [currentPart, setCurrentPart] = useState(null);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(null);
  const [currentSectionQuestions, setCurrentSectionQuestions] = useState([]);
  const [currentSection, setCurrentSection] = useState(undefined);
  const [nextSection, setNextSection] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [lastSectionCompleted, setLastSectionCompleted] = useState(null);
  const [thisIsLastSection, setThisIsLastSection] = useState(false);
  const [thereIsNoNextSection, setThereIsNoNextSection] = useState(false);
  const [open, setOpen] = useState(false);
  const [responseGiven, setResponseGiven] = useState(null);
  const history = useHistory();

  // load required stepNumber
  useEffect(() => {
    if (content.data.stepNumber !== undefined) {
      return setStepNumber(content.data.stepNumber);
    }

    if (stepTitleSlug === "step-0") {
      return setStepNumber("00");
    }

    const stepNumber = stepTitleSlug.slice(-2);
    return setStepNumber(parseInt(stepNumber));
  }, [content.data.stepNumber]);

  // load stepData
  useEffect(() => {
    if (stepNumber !== undefined) {
      dispatch(get_specific_programme_step(userId, programmeId, journeyId, stepNumber));
    }
  }, [dispatch, stepNumber]);

  // setCurrentPart and setStepTitle
  useEffect(() => {
    if (Object.keys(content.data).length > 0) {
      setCurrentPart(content.data.parts[partNumber]);
      setStepTitle(content.data.title);
    }
  }, [content.data, partNumber]);

  //setCurrentSection
  useEffect(() => {
    if (currentPart) {
      const generateSectionFromParam = async () => {
        const filteredSections = await currentPart.sections.filter(
          (item) => item.slug === sectionSlug
        );
        if (!IsArrayEmpty(filteredSections)) {
          setCurrentSection(filteredSections[0]);
        } else {
          setCurrentSection(null);
        }
      };
      generateSectionFromParam();
    }
  }, [currentPart, currentSection, sectionSlug]);

  // setCurrentSection's sectionId, sectionNumber ,sectionIndex & sectionQuestions
  useEffect(() => {
    if (currentSection) {
      setCurrentSectionIndex(currentSection.sectionNumber - 1);
      setCurrentSectionQuestions(currentSection.questions);
    }
  }, [currentSection]);

  // setVideoProps
  useEffect(() => {
    if (currentSection) {
      const currentVideoprops = {
        playbackId: currentSection.video.videoDetails.playbackId,
        thumbnailUrl: currentSection.video.videoDetails.thumbnailUrl,
        videoId: currentSection.video.videoId,
        videoTitle: currentSection.video.videoTitle,
        videoProgress: currentSection.video.progress,
        videoWatched: currentSection.video.videoWatched,
      };
      setVideoProps(currentVideoprops);
    }
  }, [currentSection]);

  // setNextSection
  useEffect(() => {
    if (currentPart) {
      if (currentPart.sections.length > currentSectionIndex + 1) {
        setNextSection(currentPart["sections"][currentSectionIndex + 1]);
      } else {
        // do something for 'There is no next step'
        setThereIsNoNextSection(true);
      }
    }
  }, [currentPart, currentSectionIndex]);

  // setResponseGiven
  useEffect(() => {
    if (currentSectionQuestions) {
      const op = currentSectionQuestions.filter((item) => item.responseGiven === false);
      op.length === 0 ? setResponseGiven(true) : setResponseGiven(false);
    }
  }, [currentPart, currentSectionQuestions]);

  // setPartTitle
  useEffect(() => {
    if (currentPart) {
      setPartTitle(currentPart.title);
    }
  }, [currentPart]);

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    // you can handle player events here
    player.on("ended", () => {
      setVideoEnded(true);
      document.fullscreenElement && document.exitFullscreen();
      if (thisIsLastSection) {
        // when a last section doesn't have any questions and video is ended
        // then setLastSectionCompleted to true
        responseGiven && setLastSectionCompleted(true);
      }
    });
  };

  const handleWatchAgain = () => {
    setVideoEnded(false);
    open && handleModalClose();
    const player = document.querySelector("video");
    player.currentTime = 0;
    player.play();
  };

  useEffect(() => {
    if (completeQuestion.isQuestionCompleted) {
      // load latest step data
      if (stepNumber !== undefined) {
        dispatch(get_specific_programme_step(userId, programmeId, journeyId, stepNumber));
      }
      setVideoEnded(false);
      handleModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completeQuestion.isQuestionCompleted]);

  // setLastSectionCompleted, setThisIsLastSection
  useEffect(() => {
    if (currentPart) {
      const lastSectionIndex = currentPart.sections.length - 1;
      const lastSection = currentPart.sections[lastSectionIndex];
      !lastSectionCompleted && setLastSectionCompleted(lastSection.completed);

      if (lastSectionIndex === currentSectionIndex) {
        setThisIsLastSection(true);
      }
    }
  }, [currentPart, lastSectionCompleted, currentSectionIndex]);

  // setVideoSrc
  useEffect(() => {
    const setSecurePlaybackUrl = async () => {
      var tokenRequest = {
        playbackId: playbackId,
        audience: "v",
        durationInSeconds: 600,
        userId: userId,
      };

      let token = "";

      try {
        setVideoSrc(null);
        token = await contentService.post(`/videos/${playbackId}/token`, tokenRequest);
        //console.log(token);
      } catch (error) {
        logException(error);
        console.log("error", error);
      } finally {
        let secureUrl = `https://stream.mux.com/${playbackId}.m3u8?token=${token}`;
        //setCurrentPlaybackId(playbackId);
        setVideoSrc(secureUrl);
      }
    };

    // update videoSrc when videoProps are changed
    !IsValueEmpty(playbackId) && setSecurePlaybackUrl();
  }, [playbackId, userId, videoProps]);

  const handleContinue = () => {
    if (!responseGiven) {
      // do something here when response(s) yet to be given
    } else {
      // load latest step data
      if (stepNumber !== undefined) {
        dispatch(get_specific_programme_step(userId, programmeId, journeyId, stepNumber));
      }
      // for next video
      setVideoEnded(false);
      handleModalClose();
    }
  };

  // Modal functions
  const handleModalOpen = () => {
    setOpen(true);
    document.getElementById("root").classList.add("modal-active");
    //reset error
    dispatch(complete_question_error_reset());
  };

  const handleModalClose = () => {
    setOpen(false);
    document.getElementById("root").classList.remove("modal-active");
  };

  useEffect(() => {
    if (location.state && location.state.openQuestions) handleModalOpen();
  }, [location.state]);

  return (
    <PageBackground bgImage={greyVelvet} className="video-page">
      <Helmet>
        {videoTitle && stepTitle && (
          <title>{` ${videoTitle} | ${stepTitle} | ${slugConverter.toSentenceCase(
            programmeSlug
          )} | My Path | Gloji`}</title>
        )}
        {!videoTitle && !stepTitle && <title>My Path | Gloji</title>}
      </Helmet>
      {content &&
      content.loading === false &&
      currentSection !== undefined &&
      stepNumber !== undefined ? (
        <>
          <Container className="main-content__container video-player-page-content__container">
            <>
              {currentSection !== null ? (
                content.errorStatus === false ? (
                  videoSrc ? (
                    <section className="video-player-content__section">
                      <SectionHeader>
                        <div className="top-mini-navigation__container">
                          <div className="back-button__container">
                            <BackButtonLink
                              linkPath={`/my-path/${programmeSlug}/${stepTitleSlug}`}
                              btnText="Back"
                              ariaLabel="Back"
                            />
                          </div>
                          <div className="next-button__container">
                            {videoWatched && (
                              <div className="continue-button__container">
                                {lastSectionCompleted && thisIsLastSection ? (
                                  <Link
                                    to={`/my-path/${programmeSlug}/${stepTitleSlug}`}
                                    className="continue-next__text-button"
                                    aria-label="Continue"
                                  >
                                    Review
                                    <ArrowForwardIosIcon className="arrow-forward-icon" />
                                  </Link>
                                ) : !IsArrayEmpty(currentSectionQuestions) && !responseGiven ? (
                                  <div
                                    onClick={handleModalOpen}
                                    className="continue-next__text-button"
                                    aria-label="Continue"
                                  >
                                    Continue <ArrowForwardIosIcon className="arrow-forward-icon" />
                                  </div>
                                ) : (
                                  currentPart &&
                                  nextSection && (
                                    <Continue
                                      content={content}
                                      nextSection={nextSection}
                                      currentPart={currentPart}
                                    >
                                      <div
                                        onClick={handleContinue}
                                        className="continue-next__text-button"
                                        aria-label="Continue"
                                      >
                                        Continue
                                        <ArrowForwardIosIcon className="arrow-forward-icon" />
                                      </div>
                                    </Continue>
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        <h1 className="step-topic-number">
                          {stepTitle} - Part&nbsp;
                          {`${numbersInText[partNumber]}`}
                        </h1>
                        <h2 className="step-topic-title">{partTitle && partTitle}</h2>
                      </SectionHeader>
                      <div className="player-container">
                        <Grid container alignItems="center" justifyContent="center">
                          <Grid item xs={12} md={10}>
                            {videoProps && videoSrc && (
                              <VjsPlayer
                                videoSrc={videoSrc}
                                thumbnailUrl={
                                  thumbnailUrl ??
                                  `https://image.mux.com/${playbackId}/thumbnail.jpg?width=450&time=9`
                                }
                                onReady={handlePlayerReady}
                                programmeSlug={programmeSlug}
                                stepId={content.data.stepId}
                                videoId={videoId}
                                videoWatched={videoWatched}
                                playbackId={playbackId}
                              >
                                {videoEnded && (
                                  <VideoOverlay
                                    handleWatchAgain={handleWatchAgain}
                                    handleContinue={handleContinue}
                                  >
                                    <div className="continue-button__container">
                                      {lastSectionCompleted && thisIsLastSection ? (
                                        <Link
                                          to={`/my-path/${programmeSlug}/${stepTitleSlug}`}
                                          className="continue-next__text-button"
                                          aria-label="Continue"
                                          style={{ textDecoration: "none" }}
                                        >
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            className=""
                                            id=""
                                          >
                                            All done! Let's move on
                                          </Button>
                                        </Link>
                                      ) : !IsArrayEmpty(currentSectionQuestions) &&
                                        !responseGiven ? (
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          className=""
                                          id=""
                                          onClick={handleModalOpen}
                                        >
                                          Continue
                                        </Button>
                                      ) : (
                                        currentPart &&
                                        nextSection && (
                                          <Continue
                                            content={content}
                                            nextSection={nextSection}
                                            currentPart={currentPart}
                                          >
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              className=""
                                              id=""
                                              onClick={handleContinue}
                                            >
                                              Continue
                                            </Button>
                                          </Continue>
                                        )
                                      )}
                                    </div>
                                  </VideoOverlay>
                                )}
                              </VjsPlayer>
                            )}
                            <h3 className="video-title">{videoTitle && videoTitle}</h3>
                          </Grid>
                        </Grid>
                      </div>
                    </section>
                  ) : (
                    <CircularLoader color="primary" size={40} />
                  )
                ) : (
                  <>
                    <Grid container>
                      <Grid item xs={12}>
                        <div style={{ height: "60vh", paddingTop: "4rem" }}>
                          <Alert severity="error">
                            <AlertTitle>{content.error?.message} </AlertTitle>
                            Failed to load data
                          </Alert>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                )
              ) : (
                <>
                  {/* Redirect to 404  or display some error message when the SectionSlug doesn't match data  */}
                  <Grid container>
                    <Grid item xs={12}>
                      <div style={{ height: "60vh", padding: "4rem 1rem 1rem" }}>
                        <Alert severity="error">
                          <AlertTitle>Page you are looking for does not exist</AlertTitle>
                        </Alert>
                      </div>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          </Container>

          <CustomModal
            open={open}
            onClose={handleModalClose}
            id="questionsModal"
            modalTitleId=""
            modalDescriptionId=""
            aria-labelledby={"question"}
          >
            <TopRightModalCloseButton onClick={handleModalClose} />
            {completeQuestion.loading === false ? (
              <ModalContent
                id="questionsModalContent"
                containerClassName={"programme-questions__modal-content"}
              >
                <MultipleQuestions
                  currentSectionQuestions={currentSectionQuestions}
                  handleWatchAgain={handleWatchAgain}
                  userId={userId}
                  programmeId={content.data.programmeId}
                  journeyId={journeyId}
                  stepId={content.data.stepId}
                  history={history}
                  locationPath={
                    thereIsNoNextSection
                      ? `/my-path/${programmeSlug}/${stepTitleSlug}`
                      : nextSection &&
                        currentPart &&
                        `/my-path/${programmeSlug}/${stepTitleSlug}/part-${partNumberString}/${currentPart.slug}/${nextSection.slug}`
                  }
                  locationState={
                    thereIsNoNextSection
                      ? null
                      : content &&
                        nextSection &&
                        currentPart &&
                        continueLocationState(content, nextSection, currentPart)
                  }
                    isStep0={content.data.stepNumber === 0 }
                />
              </ModalContent>
            ) : (
              <CircularLoader color="primary" size={40} />
            )}
          </CustomModal>
        </>
      ) : (
        <CircularLoader color="primary" size={40} />
      )}
    </PageBackground>
  );
};

export default VideoPage;
