import React from "react";
import { Route, Switch } from "react-router";
import ApiAuthorizationRoutes from "../auth/ApiAuthorizationRoutes";
import { ApplicationPaths } from "../auth/ApiAuthorizationConstants";

/**
 * Defines all authentication routes to be handle by react-router.
 * @returns {ReactChildren} A React node containing all authentication routes e.g /authentication/login.
 */
function AuthenticationRoutes() {
  return (
    <Switch>
      <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
    </Switch>
  );
}

export default AuthenticationRoutes;
