import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ContactDetails from "../ContactDetails/ContactDetails";
import { IneligibleScreen } from "../ResultScreens";
import { ReactComponent as ErrorIcon } from "../../../../assets/img/icons/error.svg";

const determineUrl = () => {
  if (window.env.environment === "dev") return "https://dev-app.gloji.co.uk";
  if (window.env.environment === "uat") return "https://uat-app.gloji.co.uk";
  return "https://app.gloji.co.uk";
};

const ContactDetailsRoot = () => {
  const { path } = useRouteMatch();

  const signupFormValues = useSelector((state) => state.signupFlowData.signupFormValues);
  const contactPageData = useSelector(
    (state) => state.signupFlowData.signupPagesData?.["contact-details"]
  );

  const ExistingReferralScreen = () => {
    return (
      <IneligibleScreen
        title="Existing referral | Eligibility checker | Sign up | Gloji"
        heading={contactPageData?.ineligibleScreens?.existingReferral?.title}
        bodyText={
          contactPageData?.ineligibleScreens?.existingReferral?.body.replace(
            "{email address}",
            signupFormValues?.email
          ) ?? ""
        }
        hasContactUs={false}
      />
    );
  };

  const ExistingUserScreen = () => {
    return (
      <IneligibleScreen
        title="Existing user | Eligibility checker | Sign up | Gloji"
        heading={contactPageData?.ineligibleScreens?.existingUser?.title}
        bodyText={
          contactPageData?.ineligibleScreens?.existingUser?.body.replace(
            "{email address}",
            signupFormValues?.email
          ) ?? ""
        }
        buttonText="Go to Login"
        onClick={() => {
          window.location.href = determineUrl();
        }}
        hasContactUs={true}
      />
    );
  };

  const UnauthorisedAccessScreen = () => {
    return (
      <IneligibleScreen
        title="Unauthorised access | Eligibility checker | Sign up | Gloji"
        heading={contactPageData?.ineligibleScreens?.unauthorisedAccess?.title}
        bodyText={contactPageData?.ineligibleScreens?.unauthorisedAccess?.body}
        hasContactUs={true}
      >
        <ErrorIcon />
      </IneligibleScreen>
    );
  };

  return (
    <Switch>
      <Route exact path={`${path}`} component={ContactDetails} />
      <Route path={`${path}/existing-user`} component={ExistingUserScreen} />
      <Route path={`${path}/existing-referral`} component={ExistingReferralScreen} />
      <Route path={`${path}/unauthorised`} component={UnauthorisedAccessScreen} />
    </Switch>
  );
};

export default ContactDetailsRoot;
