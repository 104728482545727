import { appInsights } from "../appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

export const logException = (e) => {
  console.dir(e);

  appInsights.trackException({
    error: e,
    severityLevel: SeverityLevel.Error,
  });
};
