import PropTypes from "prop-types";

/**
 * Gets the latest weight from an array of weights.
 * @param {Array} weights - An array of the user's logged weights.
 * @returns {Object} latestWeight - The user's last logged weight.
 */
function LatestWeight(weights) {
  let sortedWeights =
    weights &&
    weights
      .filter((w) => w.id !== null)
      .sort((current, next) => new Date(next.weightDate) - new Date(current.weightDate));

  let latestWeight = sortedWeights && (sortedWeights.length !== 0 ? sortedWeights[0] : null);
  return latestWeight;
}

LatestWeight.propTypes = {
  weights: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default LatestWeight;
