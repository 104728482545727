import PropTypes from "prop-types";
import ListContainer from "../../atoms/ListContainer";
import { NavLink } from "react-router-dom";
import NavAccordionMenu from "../../molecules/NavAccordionMenu";

const MobileNavLinks = ({ mobileNavItems, onClick }) => {
  const mobileNavItem = mobileNavItems
    ? mobileNavItems.map((item) => {
      return (
        <li key={item.name} className="mobile-nav-links__item">
          <NavLink
            to={`/${item.link}`}
            exact={item.link === ""} // ensure Home page does not stay permanently active
            aria-label={`Visit ${item.name}`}
            onClick={onClick}
          >
            {item.name}
          </NavLink>
        </li>
      );
    })
    : null;
  return (
    <div className="mobile-nav-links__container">
      <ListContainer>
        {mobileNavItem}
        <NavAccordionMenu onClick={onClick} />
      </ListContainer>
    </div>
  );
};
MobileNavLinks.propTypes = {
  mobileNavItems: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, link: PropTypes.string })
  ),
  onClick: PropTypes.func,
};
export default MobileNavLinks;
