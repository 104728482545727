import {
  LOAD_SIGNUP_COPY,
  SIGNUP_COPY_LOADED,
  LOAD_SIGNUP_COPY_FAILED,
  NEXT_STEP,
  NEXT_SUB_STEP,
  UPDATE_SIGNUP_FORM_VALUES,
  SIGNUP_PAGES_DATA,
} from "../constants";

const initialState = {
  loading: false,
  data: [],
  errorStatus: false,
  error: null,
  nextStep: false,
  nextSubStep: false,
  signupFormValues: null,
  isPostcodeEligible: false,
  signupPagesData: null,
};

const signup_flow_data_reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SIGNUP_COPY:
      return { ...state, loading: true };
    case SIGNUP_COPY_LOADED:
      return { ...state, loading: false, data: action.payload };
    case LOAD_SIGNUP_COPY_FAILED:
      return { ...state, loading: false, error: action.error, errorStatus: true };
    case NEXT_STEP:
      return { ...state, nextStep: action.payload };
    case NEXT_SUB_STEP:
      return { ...state, nextSubStep: action.payload };
    case UPDATE_SIGNUP_FORM_VALUES:
      return { ...state, signupFormValues: { ...state.signupFormValues, ...action.payload } };
    case SIGNUP_PAGES_DATA:
      return { ...state, signupPagesData: action.payload };
    default:
      return state;
  }
};

export default signup_flow_data_reducer;
