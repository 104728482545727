import PropTypes from "prop-types";

const MiddleBannerContainer = ({ children, bgImg }) => {
  return (
    <section className="middle-banner__container" style={{ backgroundImage: `url(${bgImg}` }}>
      <div className="overlay" role="presentation"></div>
      {children}
    </section>
  );
};

MiddleBannerContainer.propTypes = {
  children: PropTypes.node,
  bgImg: PropTypes.string,
};

export default MiddleBannerContainer;
