import React, { useEffect, useState } from "react";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { Helmet } from "react-helmet";
import HeroImage from "../../../../assets/img/veronika.jpg";
import { CircularLoader } from "../../../atoms/Loaders";
import useUser from "../../../hooks/useUser";
import {
  ModalPageBody,
  ModalPageCopy,
  ModalPageFooter,
  ModalPageHeading,
  ModalPageHeroImage,
  ModalPageWrapper,
} from "../../../organisms/ModalPage";
import { CustomModal, ModalContent, ModalTitle } from "../../../organisms/CustomModal";
import "./ReadySetGo.scss";
import { useSelector } from "react-redux";
import { programmes } from "../../../../enums/programmes";
import PageContent from "./PageContent";
import { profileService } from "../../../services";
import { Alert } from "@material-ui/lab";
import useUserId from "../../../hooks/useUserId";
import { mapCompleteProfileRequest } from "../ProfileBuilderJourneys/ConfigurableProfileBuilder/utils";
import { useCompleteProfileMutation, useGetProfileByIdQuery } from "../../../../redux/profileApi";
import { skipToken } from "@reduxjs/toolkit/query/react";

const ReadySetGo = () => {
  const [user] = useUser();
  const [userId] = useUserId();
  const { data: profile } = useGetProfileByIdQuery(userId ?? skipToken);
  const storedProfileBuilder = useSelector((state) => state.profileBuilder);
  const currentProgrammeId = profile.currentJourney.programmeIds[0];
  const [open, setOpen] = useState(false);
  const [smokeFreeProfile, setSmokeFreeProfile] = useState({ isLoading: false, isError: null });
  const [completeProfile, { isSuccess: isSuccessCompleteProfile }] = useCompleteProfileMutation();

  useEffect(() => {
    if (currentProgrammeId === programmes["lose-weight"].id) {
      window.gtag("event", "page_view", {
        page_title: "Ready Set Go! | Profile builder | Sign up | Gloji",
      });
    }
  }, [currentProgrammeId]);

  const handleModalClose = () => {
    setOpen(false);
    window.location.href = "/";
    document.getElementById("root").classList.remove("modal-active");

    //Trigger google analytics event at completion of profile builder journey
    if (currentProgrammeId === programmes["lose-weight"].id) {
      window.gtag("event", "completeProfileBuilder", {
        event_category: "Profile builder",
        event_label: "Complete Profile Builder",
      });

      window.dataLayer.push({
        event: "c_events",
        event_category: "Signup",
        event_action: "Profile Builder Complete",
      });
    }
  };

  const handleModalOpen = () => {
    setOpen(true);
    document.getElementById("root").classList.add("modal-active");
  };

  const handleLetsGo = async () => {
    if (
      currentProgrammeId === programmes["smoke-free"].id &&
      profile.currentJourney.referringServiceId === "a2fd0164-30ac-4394-a17b-063b28e46dae"
    ) {
      console.log("stored profile builder", storedProfileBuilder);

      const requestData = mapCompleteProfileRequest(
        userId,
        profile.currentJourney.referringServiceId,
        profile.currentJourney.programmeIds[0],
        storedProfileBuilder
      );

      try {
        await completeProfile(requestData).unwrap();
        window.location.href = "/";
      } catch (error) {
        setSmokeFreeProfile({ isLoading: false, isError: true });
      }

      return;
    }

    if (currentProgrammeId === programmes["smoke-free"].id) {
      setSmokeFreeProfile({ isLoading: true, isError: null });
      let response = await profileService.postProfileV2(userId);
      if (response.status === 201 || response.status === 200) {
        window.location.href = "/";
        setSmokeFreeProfile({ isLoading: false, isError: null });
      } else {
        setSmokeFreeProfile({ isLoading: false, isError: true });
      }
    } else {
      handleModalClose();
    }
  };

  return (
    <>
      <Helmet>
        <title>Ready Set Go! | Profile builder | Sign up | Gloji</title>
      </Helmet>
      <ModalPageWrapper>
        <ModalPageBody hasStickyFooter={false}>
          {user && user.given_name ? (
            <>
              <div className="ready-set-go-page-image__container">
                <ModalPageHeroImage
                  className="ready-set-go-page-image"
                  imageUrl={HeroImage}
                  altText="Veronika, gloji mentor"
                />
              </div>
              <ModalPageHeading size="large">Ready, set, go!</ModalPageHeading>
              <ModalPageCopy>
                <PageContent programmeId={currentProgrammeId} username={user.given_name} />
              </ModalPageCopy>
              <CustomModal
                open={open}
                onClose={handleModalClose}
                id="readySetGoModal"
                modalTitleId="readySetGoModalTitle"
                modalDescriptionId="readySetGoModalDescription"
              >
                <ModalContent id="readySetGoModalContent">
                  <div className="celebration-img-container"></div>
                  <ModalTitle id="readySetGoModalTitle">Whoop! Whoop!</ModalTitle>
                  <p className="modal-text" id="readySetGoModalDescription">
                    You’re ready to start using gloji.
                  </p>
                  {smokeFreeProfile.isError && (
                    <Alert severity="error" style={{ marginBottom: "1rem" }}>
                      Something went wrong!
                    </Alert>
                  )}
                  {smokeFreeProfile.isLoading ? (
                    <CircularLoader />
                  ) : (
                    <Button
                      className="lets-go-button"
                      variant="contained"
                      color="primary"
                      onClick={handleLetsGo}
                    >
                      Let's go
                    </Button>
                  )}
                </ModalContent>
              </CustomModal>
            </>
          ) : (
            <CircularLoader />
          )}
        </ModalPageBody>
        <ModalPageFooter hasStickyFooter={false}>
          <Button onClick={handleModalOpen} variant="contained" color="primary">
            I'm in
          </Button>
        </ModalPageFooter>
      </ModalPageWrapper>
    </>
  );
};

export default ReadySetGo;
