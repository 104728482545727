import PropTypes from "prop-types";
import HeroBannerContainer from "./HeroBannerContainer";
import HeroBannerContent from "./HeroBannerContent";
import { homeBannerBg } from "../../../assets/img/bg";

import "./HeroBanner.scss";

const HeroBanner = ({ bgImg, children }) => {
  return (
    <HeroBannerContainer bgImg={bgImg ?? homeBannerBg}>
      <HeroBannerContent>{children}</HeroBannerContent>
    </HeroBannerContainer>
  );
};

HeroBanner.propTypes = {
  bgImg: PropTypes.string,
  children: PropTypes.node,
};

export default HeroBanner;
