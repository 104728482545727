import authAxios from "../../helpers/authAxios";
import { LOAD_ALL_WEIGHTS, ALL_WEIGHTS_LOADED, LOAD_ALL_WEIGHTS_FAILED } from "../constants";
import { urls } from "../../configs/serviceConfig";
import { logException } from "../../utils/AppInsightsLogging";

const load_all_weights = () => {
  return {
    type: LOAD_ALL_WEIGHTS,
  };
};

const all_weights_loaded = (payload) => {
  return {
    type: ALL_WEIGHTS_LOADED,
    payload,
  };
};

const load_all_weights_failed = (error) => {
  return {
    type: LOAD_ALL_WEIGHTS_FAILED,
    error,
  };
};

export const get_all_weights = (userId) => {
  const url = `${urls.weightService}/api/user/${userId}/weight/graph-data`;

  return (dispatch) => {
    dispatch(load_all_weights());
    authAxios
      .get(url)
      .then((response) => {
        dispatch(all_weights_loaded(response.data));
      })
      .catch((error) => {
        logException(error);
        dispatch(load_all_weights_failed(error));
      });
  };
};
