import { Grid } from "@material-ui/core";
import { ReactComponent as Logo } from "../../../assets/img/logo.svg";

const FooterLogo = () => {
  return (
    <Grid item xs={12} className="footer__logo-container">
      <a
        href="/"
        className="footer__logo"
        title="Go to gloji home page"
        aria-label="Go to gloji home page"
      >
        <Logo id="glojiFooterLogo" />
      </a>
    </Grid>
  );
};

export default FooterLogo;
