import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import useCompletedSteps from "../../hooks/useCompletedSteps";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import Stepper from "../../atoms/Stepper";
import { ReactComponent as CelebrationTrophyLeft } from "../../../assets/img/celebration_trophy_left.svg";
import { ReactComponent as CelebrationTrophyRight } from "../../../assets/img/celebration_trophy_right.svg";

import "./CompletedAvailableCard.scss";

const CompletedAvailableCard = () => {
  const overview = useSelector((state) => state.programmeOverview);
  const completedSteps = useCompletedSteps(overview?.data?.steps);
  const [nextStep, setNextStep] = useState(null);
  const [prevStep, setPrevStep] = useState(null);

  const location = useLocation();
  const isMyPath = location.pathname.includes("my-path") ? true : false;

  useEffect(() => {
    const next = overview.data.steps.find((step) => step.locked);
    setNextStep(next);

    const prev = overview?.data?.steps[next.stepNumber - 1];
    setPrevStep(prev);
  }, [overview]);

  return isMyPath ? (
    <div className="hero-card__completed-available">
      <h2>Look how far you've come!</h2>
    </div>
  ) : !isMyPath && nextStep !== null && prevStep !== null && overview?.data ? (
    <>
      <div className="hero-card__completed-celebration">
        <CelebrationTrophyLeft />
        <Stepper
          totalSteps={overview.data.programme.numberOfSteps - 1}
          completedSteps={completedSteps}
          currentStep={prevStep.stepNumber}
        />
        <CelebrationTrophyRight />
      </div>
      <div className="hero-card__completed-home">
        <h2>Amazing! You've completed {prevStep.title}</h2>
        <Link to={`/my-path`} aria-label="Take me to My Path">
          <Button tabIndex="-1" variant="contained" color="primary">
            Take me to My Path
          </Button>
        </Link>
        <span className="hero-card__completed-home__date">
          {nextStep?.title} unlocks {moment(nextStep?.unlockDate).format("DD MMMM")}
        </span>
      </div>
    </>
  ) : (
    <></>
  );
};

export default CompletedAvailableCard;
