import React from "react";
import { Slider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { fieldToTextField } from "formik-material-ui";
import * as colors from "../../../enums/brandColors";
import PropTypes from "prop-types";

const CustomSlider = withStyles({
  root: {
    color: "#E5E5E5",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    boxShadow: "2px 2px 6px rgba(0,0,0,0.16)",
    border: "none",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "2px 2px 6px rgba(0,0,0,0.16)",
      // border: "2px solid currentColor",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 8px)",
    fontWeight: "bold",
    fontSize: "1rem",
    "& span:first-child": {
      backgroundColor: colors.MINT,
    },
  },
  track: {
    height: 8,
    borderRadius: 4,
    color: colors.MINT,
  },
  rail: {
    height: 8,
    borderRadius: 4,
    opacity: 1,
  },
})(Slider);

const SliderField = (props) => {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;
  const onChange = React.useCallback(
    (event, value) => {
      setFieldValue(name, value);
    },
    [setFieldValue, name]
  );
  return (
    <CustomSlider
      {...fieldToTextField(props)}
      aria-labelledby={props.ariaLabelledby}
      onChange={onChange}
    />
  );
};

SliderField.propTypes = {
  setFieldValue: PropTypes.number, // double check its type
  name: PropTypes.string,
  ariaLabelledby: PropTypes.string.isRequired,
};

export default SliderField;
