import { useRouteMatch, Switch, Route, useHistory } from "react-router";
import HowHeard from "./HowHeard";
import { IneligibleScreen } from "../../EligibilityChecker/ResultScreens";
import { ReactComponent as ErrorIcon } from "../../../../assets/img/icons/error.svg";

const HowHeardRoot = (props) => {
  let { path } = useRouteMatch();
  const history = useHistory();

  const HowHeardErrorScreen = () => {
    return (
      <IneligibleScreen
        title="Profile error | Profile builder | Sign up | Gloji"
        heading="Uh oh, something’s gone wrong!"
        bodyText="We weren’t able to create your profile. Please try again later or get in touch with gloji"
        hasContactUs={true}
        backButtonText="Back"
        backButtonOnClick={() => history.push(`${path}`)}
      >
        <ErrorIcon />
      </IneligibleScreen>
    );
  };

  return (
    <>
      <Switch>
        <Route exact path={`${path}`} component={HowHeard} />
        <Route path={`${path}/fail`} component={HowHeardErrorScreen} />
      </Switch>
    </>
  );
};

export default HowHeardRoot;
