import React from "react";
import PropTypes from "prop-types";
import { FormControl, FormLabel, FormGroup } from "@material-ui/core";
import "./CheckboxGroupContainer.scss";
import { makeStyles } from "@material-ui/core/styles";
import { NAVY } from "@thrivetribe/gloji.enums.brand-colors";
import { ErrorMessage } from "formik";
import { FormHelperText } from "@material-ui/core";
import { useField } from "formik";

const useStyles = makeStyles(() => ({
  formControl: {
    marginBottom: "1.375rem",
    "& .MuiFormLabel-root": {
      fontSize: "1rem",
      color: `${NAVY} !important;`,
      position: "relative",
      marginBottom: "0.5rem",
      lineHeight: "1.5rem",
    },
    "& .MuiFormGroup-root": {
      backgroundColor: "white",
    },
  },
}));

const CheckboxGroupContainer = ({ label, name, hasErrors, children, className }) => {
  const classes = useStyles();

  const [field, meta, helpers] = useField(name);

  return (
    <FormControl
      component="fieldset"
      className={`${classes.formControl} checkbox-group__container ${className ?? ""}`}
    >
      {label && (
        <FormLabel component="legend" className="checkbox-group__label">
          {label}
        </FormLabel>
      )}
      <FormGroup
        className={`checkboxes__container ${
          meta.error && meta.touched ? "checkboxes__container--has-errors" : ""
        }`}
      >
        {children}
      </FormGroup>
      <ErrorMessage className="Mui-error" component={FormHelperText} name={name} />
    </FormControl>
  );
};

CheckboxGroupContainer.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.node.isRequired,
  hasErrors: PropTypes.bool,
  className: PropTypes.string,
};

export default CheckboxGroupContainer;
