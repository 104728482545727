import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { ReactComponent as WeightIcon } from "../../../assets/img/icons/weightIcon.svg";
import { useSelector } from "react-redux";
import { IsObjectEmpty } from "../../../utils/IsEmpty";
import CircularLoader from "@thrivetribe/gloji.ui.atoms.loader/dist/circular-loader";
import { NAVY } from "@thrivetribe/gloji.enums.brand-colors/dist/brandColors";

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    borderRadius: "10px",
    "& .MuiSvgIcon-root": {
      fontSize: "12px",
    },
  },
  widget: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#121E59",
    width: "336px",
    borderRadius: "10px",
    padding: "16px 10px",
  },
  loaderContainer: {
    padding: " 16px 10px",
    width: "25rem",
    height: "5rem",
    backgroundColor: NAVY,
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    textAlign: "center",
    marginBlockStart: 0,
    marginBlockEnd: 0,
    font: "normal normal bold 20px/27px Poppins",
    letterSpacing: "-0.4px",
    paddingBottom: "20px",
    color: "#f7f7f7",
  },
  button: {
    backgroundColor: "#f5f4f2",
    margin: "0 auto",
    width: "100%",
    height: "50px",
    display: "flex",
    borderRadius: "5px",

    "& .MuiButton-label": {
      font: "normal normal normal 17px/14px Fredoka One",
      letterSpacing: "0px",
      textTransform: "none",
    },

    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#888eab",
    },
    "& svg": {
      marginRight: "16px",
    },
  },
});

const LogWeightWidget = ({ title, handleLogWeight }) => {
  const classes = useStyles();
  const overview = useSelector((state) => state.programmeOverview);
  const [displayWidget, setDisplayWidget] = useState(false);

  useEffect(() => {
    if (!IsObjectEmpty(overview.data)) {
      setDisplayWidget(overview.data.programme.features.logWeight);
    }
  }, [overview]);

  return (
    <>
      {overview.loading ? (
        <div className={classes.loaderContainer}>
          <CircularLoader />
        </div>
      ) : displayWidget ? (
        <div className={classes.widget}>
          <div className={classes.title}> {title} </div>
          <Button className={classes.button} color="tertiary" onClick={handleLogWeight}>
            <WeightIcon />
            log my weight
          </Button>
        </div>
      ) : null}
    </>
  );
};

LogWeightWidget.propTypes = {
  title: PropTypes.string.isRequired,
  handleLogWeight: PropTypes.func.isRequired,
};

export default LogWeightWidget;
