import React from "react";
import PropTypes from "prop-types";

export const ModalSubtitle = ({ children, id, ...rest }) => {
  return (
    <div className="modal-subtitle__container">
      <h3 className="modal-subtitle" id={id} {...rest}>
        {children}
      </h3>
    </div>
  );
};

ModalSubtitle.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
};
