import React from "react";
import {
  FirstLanguageRoot,
  LandingPage,
  ReligionRoot,
  EmploymentStatusRoot,
  SexualOrientationRoot,
  HowHeardRoot,
} from "../../index";
import { ModalPageBackground } from "../../../../organisms/ModalPage";
import { MemoryRouter, Route, Switch } from "react-router";
import PageBg from "../../../../../assets/img/bg/eligibilityCheckerBg.png";
import ReadySetGo from "../../ReadySetGo/ReadySetGo";

/**
 * Renders profileBuilder pages
 * @returns LoseWeightJourney profileBuilder
 */

const LoseWeightJourney = () => {

  return (
    <ModalPageBackground className="profile-builder-page" bgImage={PageBg}>
      <MemoryRouter>
        <Switch>
          <Route exact path={"/"} component={LandingPage} />
          <Route path={"/first-language"} component={FirstLanguageRoot} />
          <Route path={"/religion"} component={ReligionRoot} />
          <Route path={"/employment-status"} component={EmploymentStatusRoot} />
          <Route path={"/sexual-orientation"} component={SexualOrientationRoot} />
          <Route path={"/how-heard"} component={HowHeardRoot} />
          <Route path={"/ready-set-go"} component={ReadySetGo} />
        </Switch>
      </MemoryRouter>
    </ModalPageBackground>
  );
};

export default LoseWeightJourney;
