import { createSlice } from "@reduxjs/toolkit";

const profileBuilderSlice = createSlice({
  name: "profileBuilder",
  initialState: {
    dateOfBirth: undefined,
    marketingConsent: undefined,
    areYouPregnant: undefined,
  },
  reducers: {
    saveDateOfBirth(state, action) {
      state.dateOfBirth = action.payload;
    },
    saveMarketingConsent(state, action) {
      state.marketingConsent = action.payload;
    },
    savePregnancyState(state, action) {
      state.areYouPregnant = action.payload;
    },
  },
});

export const { saveDateOfBirth, saveMarketingConsent, savePregnancyState } =
  profileBuilderSlice.actions;
export default profileBuilderSlice.reducer;
