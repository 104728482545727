import React from "react";
import PropTypes from "prop-types";
import { Grid, Container } from "@material-ui/core";
import PageBackground from "../../organisms/PageBackground/PageBackground";
import "./ContentPage.scss";

/**
 * Content page template used for rendering CMS content.
 * @param {String} bgImage - The page background image.
 * @param {ReactElement} heroImage - Used to render the HeroImage component.
 * @param {ReactElement} container - The content container component
 */
function ContentPage({ bgImage, heroImage, container }) {
  return (
    <PageBackground bgImage={bgImage}>
      <Container className="page-container content-page ">
        <Grid container className="grid-container" justifyContent="center">
          <Grid item xs={12} sm={12} md={12} className="">
            {heroImage}
          </Grid>
          <Grid item xs={12} sm={10} md={8}>
            {container}
          </Grid>
        </Grid>
      </Container>
    </PageBackground>
  );
}

ContentPage.propTypes = {
  bgImage: PropTypes.string.isRequired,
  heroImage: PropTypes.element.isRequired,
  container: PropTypes.element.isRequired,
};

export default ContentPage;
