import React from "react";
import { Pill } from "../../atoms/Pills";
import "./PillGenerator";
import { ARTICLE_TYPES } from "../../../enums/articleTypes";
import { NAVY } from "../../../enums/brandColors";
import PropTypes from "prop-types";

const PillGenerator = ({ articleTypes }) => {
  const pills =
    articleTypes.length !== 0
      ? articleTypes.map((item) => {
          if (ARTICLE_TYPES[item] === undefined) return null;
          return (
            <Pill
              key={item}
              className="pill colored"
              label={ARTICLE_TYPES[item].name}
              pillColor={ARTICLE_TYPES[item].color}
              textColor={`${NAVY}`}
            />
          );
        })
      : null;

  return pills;
};

PillGenerator.propTypes = {
  articleTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PillGenerator;
