import React from "react";
import PropTypes from "prop-types";
import "./VideoOverlay.scss";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";

const VideoOverlay = ({ children, handleWatchAgain, handleContinue }) => {
  return (
    <div className="video-overlay__container" id="videoOverlayContainer">
      <div className="buttons-container">
        {children}
        <Button
          variant="outlined"
          color="tertiary"
          className="watch-again-button"
          onClick={handleWatchAgain}
          style={{ color: "white" }}
        >
          Watch again
        </Button>
      </div>
    </div>
  );
};

VideoOverlay.propTypes = {
  handleWatchAgain: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired,
};

export default VideoOverlay;
