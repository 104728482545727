import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { ContentPageHeroImage } from "../../atoms/HeroImage";
import ContentContainer from "../../organisms/ContentContainer";
import ContentPage from "../../templates/ContentPage/ContentPage";
import useContent from "../../hooks/useContent";
import { contentService } from "../../services";
import { ARTICLE_TYPES } from "../../../enums/articleTypes";
import { CircularLoader } from "../../atoms/Loaders";

import "./Article.scss";

const Article = (props) => {
  let { slug } = useParams();
  const [data, isReady] = useContent(slug, contentService.getArticle);
  const [articleTypeTagData, setArticleTypeTagData] = useState(ARTICLE_TYPES["default"]);

  const articleTypesArr = (Object.keys(data).length !== 0 && data.pillarTags) || [];
  const heroImg = (Object.keys(data).length !== 0 && data.asset.file) || "";

  useEffect(() => {
    if (isReady && ARTICLE_TYPES[data.pillarTags[0]] !== undefined)
      setArticleTypeTagData(ARTICLE_TYPES[data.pillarTags[0]]);
  }, [data, isReady]);

  return (
    <>
      {isReady ? (
        <>
          <Helmet>
            <title>
              {data.title} | {articleTypeTagData.name} | Gloji
            </title>
          </Helmet>
          <ContentPage
            bgImage={articleTypeTagData.bgImg}
            heroImage={
              <ContentPageHeroImage
                src={heroImg.url}
                alt={data.asset.description}
                title={data.asset.title}
              />
            }
            container={
              <ContentContainer
                title={data.title}
                body={data.body}
                intro={data.introduction}
                pillars={articleTypesArr}
                longerRead={data.longerRead}
                hasTopBar
              />
            }
          ></ContentPage>
        </>
      ) : (
        <CircularLoader color="primary" size={40} />
      )}
    </>
  );
};

export default Article;
