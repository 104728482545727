import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import useLatestWeight from "../../hooks/useLatestWeight";
import "./AlreadyLoggedWeightToday.scss";

function AlreadyLoggedWeightToday({ allWeights }) {
  const latestWeight = useLatestWeight(allWeights);
  if (latestWeight && Object.keys(latestWeight).length !== 0) {
    let today = moment().startOf("day").format("YYYY-MM-DD");
    let weightDate = moment(latestWeight.weightDate).format("YYYY-MM-DD");
    if (weightDate === today) {
      return (
        <p id="already-logged-weight">
          Already weighed today? No need to weigh more than once a day. This entry will replace what
          you added earlier today.
        </p>
      );
    }
  }

  return null;
}

AlreadyLoggedWeightToday.propTypes = {
  allWeights: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AlreadyLoggedWeightToday;
