import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { CardActions, CardContent, CardMedia } from "@material-ui/core";
import PillGenerator from "../PillGenerator";
import "./ArticleCard.scss";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Pill } from "../../atoms/Pills";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
    backgroundColor: "white",
    borderRadius: "10px",
    "& .MuiSvgIcon-root": {
      fontSize: "12px",
    },
  },
  media: {
    height: 208,
    position: "relative",
    borderRadius: "5px",
    margin: "10px",
  },
  content: {
    padding: "0px 12px 12px 12px",
  },
  footer: {
    padding: "0 0 24px 12px ",
  },
});

const ArticleCard = ({
  loading = false,
  cardTitle,
  description,
  imgUrl,
  imgTitle,
  articleTypes,
  cardVariant,
  longerRead,
}) => {
  const classes = useStyles();

  if (loading) {
    return (
      <Card className={`${classes.root} article-card article-card--skeleton`} elevation={0}>
        <div className={`${classes.media} article-card__image-container`}>
          <div className="article-card__pills"></div>
        </div>
        <div className="article-card__content-container">
          <div className="article-card__title"></div>
          <div className="article-card__description"></div>
          <div className="article-card__description"></div>
          <div className="article-card__description"></div>
        </div>
        <div className="article-card__footer">
          <div className="article-card__read-more"></div>
        </div>
      </Card>
    );
  }

  return (
    <Card className={`${classes.root} article-card ${cardVariant}`} elevation={0}>
      <CardMedia
        className={`${classes.media} article-card__image-container`}
        image={imgUrl}
        title={imgTitle}
      >
        <div className="article-card__pills pills-container">
          <Pill className="pill default" label="Article" />
          {longerRead && <Pill className="pill default" label="Longer read" />}
          <PillGenerator articleTypes={articleTypes} />
        </div>
        <div className="overlay"></div>
      </CardMedia>

      <CardContent className={`${classes.content} article-card__content-container`}>
        <h2 className="article-card__title">{cardTitle}</h2>
        <p className="article-card__description">{description}</p>
      </CardContent>
      <CardActions className={`${classes.footer} article-card__footer`}>
        <span className="article-card__read-more-link">
          Read on <ArrowForwardIosIcon className="article-card__read-more-link-icon" />
        </span>
      </CardActions>
    </Card>
  );
};

ArticleCard.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imgUrl: PropTypes.string.isRequired,
  imgTitle: PropTypes.string,
  articleTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  cardVariant: PropTypes.string,
  longerRead: PropTypes.bool,
};

export default ArticleCard;
