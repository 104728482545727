import React from "react";
import PropTypes from "prop-types";

import "./LinkWithIcon.scss";

const LinkWithIcon = ({ href, children, icon, variant, size, ...rest }) => {
  return (
    <a className={`link-with-icon ${variant} ${size}`} href={href} {...rest}>
      {icon && <span className="icon-container"> {icon}</span>}
      {children}
    </a>
  );
};

LinkWithIcon.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  icon: PropTypes.element,
  variant: PropTypes.string,
  size: PropTypes.string,
};

export { LinkWithIcon };
