import PropTypes from "prop-types";
import { Grid, Container } from "@material-ui/core";
import ListContainer from "../../atoms/ListContainer";

const FooterSecondaryNav = ({ secondaryNavItems }) => {
  const secondaryNavItem = secondaryNavItems
    ? secondaryNavItems.map((item) => {
        return (
          <li key={item.name} className="footer__secondary-nav-item">
            <a href={`/${item.link}`} aria-label={`Visit ${item.name}`}>
              {item.name}
            </a>
          </li>
        );
      })
    : null;

  return (
    <div className="footer__secondary-nav-container">
      <Container>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <ListContainer>{secondaryNavItem}</ListContainer>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

FooterSecondaryNav.propTypes = {
  secondaryNavItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
    })
  ),
};

export default FooterSecondaryNav;
