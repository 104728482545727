import React from "react";
import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import "./Pill.scss";

const CustomChip = withStyles({
  root: {
    borderRadius: 5,
    textTransform: "uppercase",
    height: "21px",
  },
  label: {
    padding: "0 6px",
    fontSize: "11px",
    fontWeight: "700",
    fontFamily: "Poppins",
  },
})(Chip);

const Pill = (props) => {
  return (
    <CustomChip
      size="small"
      className={`pill-basic ${props.className || null}`}
      label={props.label}
      style={{ backgroundColor: props.pillColor || null, color: props.textColor || null }}
      onClick={props.handleClick}
      icon={props.icon || null}
    />
  );
};

Pill.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  pillColor: PropTypes.string,
  textColor: PropTypes.string,
  handleClick: PropTypes.func,
};

export default Pill;
