import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./PillarButton.scss";

const PillarButton = ({ title, link, color, icon }) => {
  return (
    <Link to={link}>
      <div className="pillar-btn" style={{ backgroundColor: color }}>
        <div className="pillar-btn__icon">{icon}</div>
        <span>{title}</span>
      </div>
    </Link>
  );
};

PillarButton.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
};

export default PillarButton;
