import React, { Component } from "react";
import authService from "./AuthorizeService";
import { AuthenticationResultStatus } from "./AuthorizeService";
import { SignupActions, QueryParameterNames } from "./ApiAuthorizationConstants";
import Toast from "../atoms/Toast";

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
export class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: undefined,
    };
  }

  componentDidMount() {
    const action = this.props.action;
    switch (action) {
      case SignupActions.Signup:
        this.signUp(this.getReturnUrl());
        break;
      case SignupActions.SignupCallback:
        this.processSignupCallback();
        break;
      case SignupActions.SignupFailed:
        const params = new URLSearchParams(window.location.search);
        const error = params.get(QueryParameterNames.Message);
        this.setState({ message: error });
        break;
      default:
        throw new Error(`Invalid action '${action}'`);
    }
  }

  render() {
    const action = this.props.action;
    const { message } = this.state;

    if (!!message) {
      return this.showMessage(message, "error");
    } else {
      switch (action) {
        case SignupActions.Signup:
          return this.showMessage("Processing signup");
        case SignupActions.SignupCallback:
          return this.showMessage("Processing signup callback");
        default:
          throw new Error(`Invalid action '${action}'`);
      }
    }
  }

  showMessage(message, type = "info") {
    return (
      <Toast type={type} show={true}>
        {message}
      </Toast>
    );
  }

  async signUp(returnUrl) {
    const state = { returnUrl };
    const result = await authService.signUp(state);
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        break;
      case AuthenticationResultStatus.Success:
        await this.navigateToReturnUrl(returnUrl);
        break;
      case AuthenticationResultStatus.Fail:
        this.setState({ message: result.message });
        break;
      default:
        throw new Error(`Invalid status result ${result.status}.`);
    }
  }

  async processSignupCallback() {
    const url = window.location.href;
    const result = await authService.completeSignIn(url, true);
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        // There should not be any redirects as the only time completeSignIn finishes
        // is when we are doing a redirect sign in flow.
        throw new Error("Should not redirect.");
      case AuthenticationResultStatus.Success:
        await this.navigateToReturnUrl(this.getReturnUrl(result.state));
        break;
      case AuthenticationResultStatus.Fail:
        this.setState({ message: result.message });
        break;
      default:
        throw new Error(`Invalid authentication result status '${result.status}'.`);
    }
  }

  getReturnUrl(state) {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(QueryParameterNames.ReturnUrl);
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
      // This is an extra check to prevent open redirects.
      throw new Error(
        "Invalid return url. The return url needs to have the same origin as the current page."
      );
    }
    return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
  }

  navigateToReturnUrl(returnUrl) {
    // It's important that we do a replace here so that we remove the callback uri with the
    // fragment containing the tokens from the browser history.
    window.location.replace(returnUrl);
  }
}
