import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Container } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import ArticleCards from "../../organisms/Cards";
import { SectionHeader, SectionHeading, SectionIntro } from "../../atoms/SectionHeader";
import { fetch_personalised_content } from "../../../store/actions/getPersonalisedContent";
import { update_current_tab_index } from "../../../store/actions/updateCurrentViewData";
import { get_programme_overview } from "../../../store/actions/getProgrammeOverview";
import { CircularLoader } from "../../atoms/Loaders";
import { greyVelvet } from "../../../assets/img/bg";
import NotificationBar from "../../atoms/NotificationBar/NotificationBar";
import CurrentStepCard from "../../molecules/CurrentStepCard";
import PageBackground from "../../organisms/PageBackground";
import "./Home.scss";
import HeroBanner from "../../organisms/HeroBanner";
import MiddleBanner from "../../organisms/MiddleBanner";
import useUser from "../../hooks/useUser";
import { Programmes } from "../../../data/Programme";
import { IsObjectEmpty } from "../../../utils/IsEmpty";

/**
 * A page component which renders the home page
 */
function Home() {
  const [user] = useUser();
  const dispatch = useDispatch();
  const content = useSelector((state) => state.personalisedContent);
  const step = useSelector((state) => state.programmeStep);
  const greeting = "Welcome to gloji";
  const userId = localStorage.getItem("userID");
  const programmeId = localStorage.getItem("programmeID");
  const journeyId = localStorage.getItem("journeyID");

  const overview = useSelector((state) => state.programmeOverview);
  const [publicTags, setPublicTags] = useState();

  useEffect(() => {
    if (overview && overview.data.programme) {
      const currentProgramme = Programmes.find(
        (item) => item.programmeId === overview.data.programme.id
      );
      setPublicTags([currentProgramme.tag]);
    }
  }, [overview]);

  useEffect(() => {
    if (userId && publicTags) {
      dispatch(fetch_personalised_content(userId, publicTags));
    }
  }, [dispatch, userId, publicTags]);

  useEffect(() => {
    if (!overview.loading && IsObjectEmpty(overview.data)) {
      dispatch(get_programme_overview({userId, programmeId, journeyId}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userId, programmeId, journeyId]);

  useEffect(() => {
    dispatch(update_current_tab_index(step?.data?.currentPosition?.currentPartNumber - 1));
  }, [dispatch, step?.data]);

  return (
    <PageBackground bgImage={greyVelvet}>
      <Helmet>
        <title>Home | Gloji</title>
      </Helmet>
      {content && content.loading === false ? (
        <>
          <NotificationBar />
          <HeroBanner>
            <h1 className="hero-banner__heading">
              {user && user.given_name ? `Hi ${user.given_name}, welcome to gloji` : greeting}
            </h1>
            <CurrentStepCard />
          </HeroBanner>
          <Container className="main-content__container diagonal-banner__top-neighbour">
            <section>
              <SectionHeader>
                <SectionHeading> Just for you...</SectionHeading>
                <SectionIntro>
                  We've picked these tips, guides and recipes to help you succeed on your journey
                  with gloji.
                </SectionIntro>
              </SectionHeader>
              <ArticleCards data={content.data.personalisedContent} />
            </section>
          </Container>
          <MiddleBanner />
          <Container className="main-content__container diagonal-banner__bottom-neighbour">
            <section>
              <SectionHeader>
                <SectionHeading>Got a little more time? </SectionHeading>
                <SectionIntro>
                  Tap into our hive mind and boost your chances of success.
                </SectionIntro>
              </SectionHeader>
              <ArticleCards data={content.data.nonPersonalisedContent} />
            </section>
          </Container>
        </>
      ) : (
        <CircularLoader color="primary" size={40} />
      )}
    </PageBackground>
  );
}

export default Home;
