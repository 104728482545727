import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { ErrorMessage, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { update_signup_form_values } from "../../../../store/actions/signupFlowData";
import { FormikField } from "../../../atoms/FormikField";
import { FormikNumberInput } from "../../../atoms/FormikInput";
import usePostcodeCheck from "../../../hooks/usePostcodeCheck";
import {
  ModalPageBody,
  ModalPageCopy,
  ModalPageFooter,
  ModalPageHeader,
  ModalPageHeading,
  ModalPageWrapper,
} from "../../../organisms/ModalPage";
import { DateOfBirthValidationSchema } from "../EligibilityCheckerValidation";
import { LoadingPage } from "../LoadingPage";
import { determineErrorFieldName, formatValue, getInitialAgeValues } from "./utils";

const AgeChecker = () => {
  const dispatch = useDispatch();
  const agePageData = useSelector((state) => state.signupFlowData.signupPagesData?.age);
  const storeCurrentAge = useSelector((state) => state.signupFlowData.signupFormValues?.age);
  const storePostcode = useSelector((state) => state.signupFlowData.signupFormValues?.postcode);

  const [response, getPostcodeChecked] = usePostcodeCheck();
  const [showLoadingPage, setShowLoadingPage] = useState(false);
  const history = useHistory();
  const { path } = useRouteMatch();

  const initialValues = getInitialAgeValues(storeCurrentAge ?? "");

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Age | Eligibility checker | Sign up | Gloji",
    });
  }, []);

  useEffect(() => {
    if (response.data?.eligibilityCriteria.minimumAge !== undefined) {
      const age = moment().diff(storeCurrentAge, "years", false);
      if (age >= response.data.eligibilityCriteria.minimumAge) {
        history.push(`/sex`);
      } else {
        history.push(`${path}/fail`);

        //Trigger google analytics event tag
        window.gtag("event", "ineligible_age", {
          event_category: "age",
          event_label: age,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLoadingPage]);

  const handleSubmit = (values, formikhelpers) => {
    formikhelpers.setSubmitting(true);
    const dateOfBirth = `${formatValue(values.year)}/${formatValue(values.month)}/${formatValue(
      values.date
    )}`;

    const momentDate = moment(dateOfBirth, "YYYY/MM/DD", true).utc();

    const isValidDate = momentDate.isValid();

    if (isValidDate) {
      const age = new Date(dateOfBirth);
      
      dispatch(update_signup_form_values({ age }));
      setShowLoadingPage(true);
      getPostcodeChecked(storePostcode);
      formikhelpers.setSubmitting(false);
    } else {
      formikhelpers.setErrors({ date: "Please add the right date" });
      formikhelpers.setSubmitting(false);
    }
  };

  return (
    <>
      {showLoadingPage === true ? (
        <LoadingPage
          isLoading={response.loading}
          callback={() => {
            setShowLoadingPage(false);
          }}
          heading={agePageData?.loadingScreen.title ?? ""}
          text={agePageData?.loadingScreen.body ?? ""}
        />
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={DateOfBirthValidationSchema}
          validateOnChange={false}
        >
          {({ errors, touched }) => {
            const fieldName = determineErrorFieldName(errors, touched);
            return (
              <Form className="eligibility-checker-form">
                <Helmet>
                  <title> Age | Eligibility checker | Sign up | Gloji </title>
                </Helmet>
                <ModalPageWrapper>
                  <ModalPageBody className="with-sticky-footer">
                    <ModalPageHeader
                      backButtonOnClick={() => {
                        history.goBack();
                      }}
                    >
                      Checking we're a good fit
                    </ModalPageHeader>
                    <ModalPageHeading>{agePageData?.questions[0].question}</ModalPageHeading>
                    <ModalPageCopy>
                      <span style={{ marginTop: "15px", marginBottom: "20px" }}>
                        {agePageData?.questions[0].description}
                      </span>
                    </ModalPageCopy>
                    <FormGroup id="date-of-birth">
                      <InputLabel
                        htmlFor="date-of-birth"
                        className="eligibility-checker__input-label"
                      >
                        {agePageData?.questions[0].label}
                      </InputLabel>
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <FormikField
                            id="date"
                            name="date"
                            className="eligibility-checker__text-input"
                            type="number"
                            pattern={/^([0-9]{0,2})/g}
                            component={FormikNumberInput}
                            placeholder="DD"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormikField
                            id="month"
                            className="eligibility-checker__text-input"
                            name="month"
                            type="number"
                            pattern={/^([0-9]{0,2})/g}
                            component={FormikNumberInput}
                            placeholder="MM"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormikField
                            id="year"
                            className="eligibility-checker__text-input"
                            name="year"
                            type="number"
                            pattern={/^([0-9]{0,4})/g}
                            component={FormikNumberInput}
                            placeholder="YYYY"
                          />
                        </Grid>
                      </Grid>

                      <ErrorMessage
                        key={fieldName}
                        className="Mui-error"
                        name={fieldName}
                        component={FormHelperText}
                      />
                    </FormGroup>
                    <Accordion
                      square={true}
                      elevation={0}
                      className="eligibility-checker__accrodion"
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon fontSize="large" />}
                        aria-controls={`age-question-explanation`}
                      >
                        <span>{agePageData?.questions[0].whyQuestion}</span>
                      </AccordionSummary>
                      <AccordionDetails>
                        <span>{agePageData?.questions[0].whyQuestionDetails}</span>
                      </AccordionDetails>
                    </Accordion>
                  </ModalPageBody>
                  <ModalPageFooter className="sticky-footer">
                    <Button type="submit" variant="contained" color="primary">
                      Continue
                    </Button>
                  </ModalPageFooter>
                </ModalPageWrapper>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default AgeChecker;
