import React, { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-mux";
import { PropTypes } from "prop-types";
import "./VjsPlayer.scss";
import { programmes } from "../../../enums/programmes";
import { useDispatch } from "react-redux";
import { post_complete_video } from "../../../store/actions/postCompleteVideo";
import { playerOptions } from "./playerOptions";
import { contentService } from "../../services";
import { logException } from "../../../utils/AppInsightsLogging";

const VjsPlayer = ({
  videoSrc,
  thumbnailUrl,
  onReady,
  className,
  children,
  programmeSlug,
  stepId,
  videoId,
  videoWatchedProp,
  playbackId,
}) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const userId = localStorage.getItem("userID");
  const journeyId = localStorage.getItem("journeyID");
  const dispatch = useDispatch();
  const [options, setOptions] = useState(
    playerOptions({
      src: videoSrc,
      userId: userId,
    })
  );
  const [videoWatched, setVideoWatched] = useState(videoWatchedProp);

  const handleTokenExpired = async (reloadPlayer) => {
    var tokenRequest = {
      playbackId: playbackId,
      audience: "v",
      durationInSeconds: 600,
      userId: userId,
    };

    let token = "";

    try {
      token = await contentService.post(`/videos/${playbackId}/token`, tokenRequest);
    } catch (error) {
      console.log("error", error);
      logException(error);
    } finally {
      let secureUrl = `https://stream.mux.com/${playbackId}.m3u8?token=${token}`;
      reloadPlayer({ src: secureUrl });
      setOptions(
        playerOptions({
          src: secureUrl,
          userId: userId,
        })
      );
    }
  };

  useEffect(() => {
    setOptions(
      playerOptions({
        src: videoSrc,
        userId: userId,
      })
    );
  }, [userId, videoSrc]);

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      }));

      // register reloadSourceOnError plugin
      player.reloadSourceOnError({
        // getSource allows you to reload the source object after an error
        getSource: (reload) => {
          console.log("Renewing video playback token...");

          /* Call reload() with a fresh source object, asynchronously if you
           * want (but the error dialog will show up while you're waiting) */
          handleTokenExpired(reload);
        },

        /* Minimum amount of seconds that must pass before another reload
         * will be attempted, stops cascading errors */
        errorInterval: 5,
      });
    } else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [onReady, options]);

  // Video watched ref so that the clean up function of the useEffect has the most up to date value
  const videoWatchedRef = useRef();
  videoWatchedRef.current = videoWatched;

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const currentPlayerInstance = videoRef.current;

    return () => {
      if (playerRef.current) {
        // Implement a logic to store/save currentTime before the player disposes
        const watchTime = Math.round(currentPlayerInstance.currentTime);
        if (userId && !videoWatchedRef.current && watchTime) {
          dispatch(
            post_complete_video(
              userId,
              programmes[programmeSlug].id,
              journeyId,
              stepId,
              videoId,
              watchTime,
              false
            )
          );
        }
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      playerRef.current.dispose();
      playerRef.current = null;
    };
  }, []);

  const handleVideoEnded = () => {
    setVideoWatched(true);
    userId &&
      dispatch(
        post_complete_video(
          userId,
          programmes[programmeSlug].id,
          journeyId,
          stepId,
          videoId,
          Math.round(videoRef.current.currentTime),
          true
        )
      );
  };

  return (
    <div data-vjs-player>
      {children}
      <video
        ref={videoRef}
        id="customVjsPlayer"
        className={`video-js vjs-big-play-centered ${className ? className : ""}`}
        poster={thumbnailUrl ?? ""}
        style={{ width: "100%", heigh: "100%" }}
        onEnded={handleVideoEnded}
      />
    </div>
  );
};

VjsPlayer.propTypes = {
  onReady: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  videoSrc: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string,
  programmeSlug: PropTypes.string,
  stepId: PropTypes.string,
  videoId: PropTypes.string,
  videoWatched: PropTypes.bool,
};

export default VjsPlayer;
