import React from "react";
import PropTypes from "prop-types";

const SectionIntro = ({ children, className }) => {
  return <p className={`section-header__intro ${className || ""}`}>{children} </p>;
};

SectionIntro.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default SectionIntro;
