import React from "react";
import PropTypes from "prop-types";

const ModalPageSubHeading = ({ children }) => {
  return <div className={`modal-page__sub-heading`}>{children}</div>;
};
ModalPageSubHeading.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalPageSubHeading;
