import { Grid, Container } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { ReactComponent as GlojiGymLogo } from "../../../assets/img/glojiGymLogoWhite.svg";
import MiddleBannerIcon from "./MiddleBannerIcon";

const ArrowNext = withStyles({
  root: {
    fontSize: "inherit",
  },
})(ArrowForwardIosIcon);

const MiddleBannerContent = () => {
  return (
    <Container>
      <Grid
        container
        className="middle-banner__content-container"
        justifyContent="center"
        alignItems="center"
      >
        <MiddleBannerIcon>
          <GlojiGymLogo />
        </MiddleBannerIcon>
        <Grid item xs={12}>
          <p className="middle-banner__content-information">
            Have you tried our FREE online gym? Feel great and take your journey to a better you to the 
            next level with live and on-demand sessions to suit everybody.
          </p>
          <a
            href="https://gym.gloji.co.uk/"
            rel="noreferrer"
            target="_blank"
            className="middle-banner__content-link"
          >
            Find out more
            <ArrowNext role="presentation" className="icon" />
          </a>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MiddleBannerContent;
