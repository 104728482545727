import React, { useEffect } from "react";
import MarkedDownContent from "../../../organisms/MarkedDownContent";
import {
  ModalPageCopy,
  ModalPageHeading,
  ModalPageWrapper,
  ModalPageBody,
  ModalPageHeader,
  ModalPageFooter,
  ModalPageSvg,
} from "../../../organisms/ModalPage";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";

const IneligibleScreen = ({
  title,
  heading,
  bodyText,
  buttonText,
  backButtonText,
  backButtonOnClick,
  children,
  onClick,
  hasContactUs = false,
}) => {
  useEffect(() => {
    if (title) {
      window.gtag("event", "page_view", {
        page_title: `${title}`,
      });
    }
  }, [title]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ModalPageWrapper>
        <ModalPageBody>
          <ModalPageHeader backButtonOnClick={backButtonOnClick} backButtonText={backButtonText} />
          {children && <ModalPageSvg>{children}</ModalPageSvg>}
          <ModalPageHeading>{heading}</ModalPageHeading>
          <ModalPageCopy>
            <MarkedDownContent content={bodyText} />
          </ModalPageCopy>
        </ModalPageBody>
        {hasContactUs && (
          <ModalPageFooter>
            <Button label={buttonText} variant="contained" color="primary" onClick={onClick}>
              {buttonText}
            </Button>
          </ModalPageFooter>
        )}
      </ModalPageWrapper>
    </>
  );
};

IneligibleScreen.propTypes = {
  title: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  hasContactUs: PropTypes.bool,
  buttonText: PropTypes.string,
  backButtonText: PropTypes.string,
  backButtonOnClick: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

IneligibleScreen.defaultProps = {
  buttonText: "Contact us",
  backButtonText: "Back to homepage",
  onClick: () => {
    window.location = `mailto:support@gloji.co.uk`;
  },
  backButtonOnClick: () => {
    window.location.href = "https://www.gloji.co.uk";
  },
};

export default IneligibleScreen;
