import { useState } from "react";
import { useSelector } from "react-redux";
import {
  ModalPageBody,
  ModalPageCopy,
  ModalPageWrapper,
} from "../../../organisms/ModalPage";
import LoseWeightJourney from "../ProfileBuilderJourneys/LoseWeightJourney";
import { programmes } from "../../../../enums/programmes";
import "./ProfileBuilderSelfRoot.scss";

/**
 * Renders profileBuilder journeys for self-referrals, based on the programme they are in.
 * @returns profileBuilder journey
 */

const ProfileBuilderSelfRoot = () => {
  const profile = useSelector((state: any) => state.profile);
  const [currentProgrammeId, setCurrentProgrammeId] = useState(
    profile.data.currentJourney.programmeIds[0]
  );

  const renderJourney = (param) => {
    switch (param) {
      case programmes["lose-weight"].id:
        return <LoseWeightJourney />;
      default:
        return (
          <ModalPageWrapper>
            <ModalPageBody>
              <ModalPageCopy> Something went wrong! </ModalPageCopy>
            </ModalPageBody>
          </ModalPageWrapper>
        );
    }
  };

  return <>{renderJourney(currentProgrammeId)}</>;
};

export default ProfileBuilderSelfRoot;
