import React from "react";
import PropTypes from "prop-types";
import { BackButton } from "../../atoms/Button";

const ModalPageHeader = ({ children, className, backButtonOnClick, backButtonText = "Back" }) => {
  return (
    <div className="modal-page__header">
      <BackButton
        className={"modal-page__back-button"}
        btnText={backButtonText}
        color={"secondary"}
        onClick={backButtonOnClick}
      />
      <span className={`modal-page__header-heading ${className}`}>{children}</span>
    </div>
  );
};
ModalPageHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  backButtonOnClick: PropTypes.func.isRequired,
  backButtonText: PropTypes.string,
};

export default ModalPageHeader;
