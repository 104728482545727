import React from "react";
import { ReactComponent as GrayCloseIcon } from "../../../assets/img/icons/closeGray.svg";

import PropTypes from "prop-types";

export const TopRightModalCloseButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className="trm-close-button">
      <GrayCloseIcon />
    </button>
  );
};

TopRightModalCloseButton.protoTypes = {
  onClick: PropTypes.func,
};
