import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import SimpleId from "../../../utils/SimpleId";
import "./Toast.scss";

/**
 * A Toast is an alert that displays a short, important message for a brief moment and then closes itself, in a way that
 * attracts the user's attention without interrupting the user's task.
 * Think properly about where to position a Toast on the screen, the default is bottom right.
 * @returns Alert
 */
function Toast({ type = "info", variant, show, duration = 3500, position, children, handleClose }) {
  const [open, setOpen] = useState(false);
  const defaultPosition = { vertical: "bottom", horizontal: "right" };
  const key = SimpleId();

  if (!handleClose) {
    handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpen(false);
    };
  }

  useEffect(() => {
    setOpen(show);
  }, [show]);

  return (
    <Snackbar
      className="mui-toast"
      open={open}
      anchorOrigin={position || defaultPosition}
      autoHideDuration={duration}
      onClose={handleClose}
      key={key}
    >
      <MuiAlert
        className="mui-toast__alert"
        elevation={6}
        onClose={handleClose}
        severity={type}
        variant={variant}
      >
        {children}
      </MuiAlert>
    </Snackbar>
  );
}

Toast.propTypes = {
  /**
   * The alert type. Should be one of "info", "success", "warning", or "error".
   */
  type: PropTypes.oneOf(["info", "success", "warning", "error"]),
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
  show: PropTypes.bool.isRequired,
  duration: PropTypes.number,
  position: PropTypes.shape({
    horizontal: PropTypes.oneOf(["center", "left", "right"]),
    vertical: PropTypes.oneOf(["bottom", "top"]),
  }),
  children: PropTypes.node.isRequired,
  handleClose: PropTypes.func,
};

export default Toast;
