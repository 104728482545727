import axios from "axios";
import authService from "../components/auth/AuthorizeService";

const authAxios = axios.create({
  headers: { Accept: "application/json" },
});

// Add a request interceptor
authAxios.interceptors.request.use(
  async function (config) {
    const token = await authService.getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default authAxios;
