import authService, { AuthenticationResultStatus } from "./AuthorizeService";
import { LoginActions, SignupActions } from "./ApiAuthorizationConstants";

/**
 * Units utility class for signing in or signing up from anywhere on the app.
 */
class AuthChallenge {
  /**
   * Performs the authentication challenge depending on the mode (signin or signup).
   * @param {String} mode - The challenge mode (signin or signup).
   * @param {Object} extraQueryParams - Extra query parameters supplied to the authentication provider.
   * @returns The authentication result.
   */
  async challenge(mode, extraQueryParams = {}) {
    const returnUrl = `${window.location.origin}/`;
    const state = { returnUrl };
    let result;
    if (mode === LoginActions.Login) {
      result = await authService.signIn(state, extraQueryParams);
    } else {
      result = await authService.signUp(state, extraQueryParams);
    }

    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        break;
      case AuthenticationResultStatus.Success:
        window.location.replace(returnUrl);
        break;
      case AuthenticationResultStatus.Fail:
      default:
        throw new Error(`Invalid status result ${result.status}.`);
    }
  }

  async signIn(extraQueryParams = {}) {
    await this.challenge(LoginActions.Login, extraQueryParams);
  }

  async signUp(extraQueryParams = {}) {
    await this.challenge(SignupActions.Signup, extraQueryParams);
  }

  static get instance() {
    return authChallenge;
  }
}

const authChallenge = new AuthChallenge();

export default authChallenge;
