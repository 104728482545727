import { useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import useContent from "../../hooks/useContent";
import { contentService } from "../../services";
import { Grid } from "@material-ui/core";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { CircularLoader } from "../../atoms/Loaders";
import PageBackground from "../../organisms/PageBackground";
import { RecipeBanner } from "../../organisms/RecipeBanner/RecipeBanner";
import { MarkedDownContent } from "@thrivetribe/gloji.ui.atoms.marked-down-content";
import { Tabs } from "@thrivetribe/gloji.ui.molecules.tabs";
import { ReactComponent as ReturnArrow } from "../../../assets/img/returnArrow.svg";
import { ReactComponent as ForwardArrow } from "../../../assets/img/forwardArrow.svg";
import "./Recipe.scss";

const Recipe = () => {
  const { slug } = useParams();
  const [data, isReady] = useContent(slug, contentService.getRecipe);
  const [value, setValue] = useState(0);

  if (!isReady || !data) {
    return <CircularLoader color="primary" size={40} />;
  }

  const tabChange = (index) => {
    setValue(index);
    // ensure next time the state is new for the Tabs component by resetting this value
    setTimeout(() => {
      setValue(undefined);
    }, 50);
  };

  const tabs = [
    {
      title: "Method",
      children: (
        <div className="tabs-content__container">
          <MarkedDownContent content={data?.method} />
          <Button onClick={() => tabChange(1)}>
            <ForwardArrow /> Ingredients
          </Button>
        </div>
      ),
    },
    {
      title: "Ingredients",
      children: (
        <div className="tabs-content__container">
          <MarkedDownContent content={data?.ingredients} />
          <Button onClick={() => tabChange(0)}>
            <ReturnArrow /> Method
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <PageBackground bgImage="">
        <Helmet>
          <title>{data.title} | Gloji</title>
        </Helmet>
        <div className="recipe-page">
          <RecipeBanner data={data} />

          <Grid item xs={12} sm={12} md={12}>
            <div className="recipe__content">
              <div className="recipe__content--desktop">
                <div className="recipe__content__left-grid">
                  <div className="recipe__content__method">
                    <Tabs
                      tabs={[
                        {
                          title: "Method",
                          children: <MarkedDownContent content={data?.method} />,
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="recipe__content__right-grid">
                  <div className="recipe__content__ingredients">
                    <Tabs
                      tabs={[
                        {
                          title: "Ingredients",
                          children: <MarkedDownContent content={data?.ingredients} />,
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div className="recipe__content--upto-desktop">
                <Tabs tabs={tabs} activeTab={value} />
              </div>
            </div>
          </Grid>
        </div>
      </PageBackground>
    </>
  );
};

export default Recipe;
