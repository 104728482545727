import authAxios from "../../helpers/authAxios";
import { urls } from "../../configs/serviceConfig";
import axios from "axios";
import { logException } from "../../utils/AppInsightsLogging";

class ReferralService {
  constructor() {
    this._client = authAxios;
    this._client.defaults.baseURL = `${urls?.referralService}/api`;
  }

  async get(url) {
    const res = await this._client.get(url, {
      withCredentials: true,
    });
    return res;
  }

  async post(url, data = {}) {
    const res = await this._client.post(url, data, {
      withCredentials: true,
    });

    return res.data;
  }

  static get instance() {
    return referralService;
  }

  async checkReferralExists(email, token) {
    let url = `${urls.referralService}/v2/referrals/self/status`;

    let config = {
      params: {
        email: email,
      },
      headers: {
        "x-csrf-token": token,
      },
    };

    try {
      const res = await axios.get(url, config);
      const isReferralActive = res.data.isReferralActive;
      const isProfileActive = res.data.isProfileActive;
      return {
        referralExists: isReferralActive,
        profileExists: isProfileActive,
        validRequest: true,
      };
    } catch (error) {
      logException(error);
      let { status } = error.response;

      if (status === 404) {
        return {
          referralExists: false,
          profileExists: false,
          validRequest: true,
        };
      }

      if (status === 401) {
        return {
          referralExists: false,
          profileExists: false,
          validRequest: false,
        };
      }

      throw new Error("An error occurred, please try again");
    }
  }

  async createNewSelfReferral(values) {
    try {
      let url = `${urls.referralService}/v2/referrals/self`;
      const res = await axios.post(url, values);
      return res.data;
    } catch (error) {
      logException(error);
      let { status, data } = error.response;

      if (status >= 400 && data?.title) {
        throw new Error(data.title);
      }
      throw new Error("An error occurred, please try again");
    }
  }

  async checkPathwaySpaces(values) {
    try {
      let url = `${urls.referralService}/api/pathway/offer`;
      const res = await axios.post(url, values);
      return res.data;
    } catch (error) {
      logException(error);
      let { status } = error.response;

      if (status >= 400) {
        throw new Error("An error occured, please try again");
      }
      throw new Error("An error occurred, please try again");
    }
  }
}

const referralService = new ReferralService();

export default referralService;
