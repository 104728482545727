import React from "react";
import MuiTextField from "@material-ui/core/TextField";

const FormikNumberInput = ({
  field: { ...fields },
  form: { setFieldValue, touched, errors },
  label,
  ...props
}) => {
  const onChange = React.useCallback(
    (event, value) => {
      value = event.target.value;
      if (value !== "" && props.pattern) {
        const regex = props.pattern;
        value = value.match(regex)[0];
      }
      setFieldValue(fields.name, value);
    },
    [props.pattern, setFieldValue, fields.name]
  );

  return (
    <>
      <MuiTextField
        {...props}
        {...fields}
        error={Boolean(touched[fields.name] && errors[fields.name])}
        onChange={onChange}
      />
    </>
  );
};

export default FormikNumberInput;
