import React, { forwardRef } from "react";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import RecipeCard from "../../molecules/RecipeCard";
import "./RecipeCards.scss";

const RecipeCards = forwardRef(({ data, cardVariant }, ref) => {
  const Cards =
    data !== null && data !== undefined && Object.keys(data).length
      ? data.map((item, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            key={item.id}
            ref={Object.keys(data).length === index + 1 ? ref : undefined}
          >
            <Link to={`/explore/recipes/${item.slug}`} className="recipe-card__container-link">
              <RecipeCard
                loading={false}
                cardTitle={item.title}
                dietTypes={item.dietType}
                allergens={item.allergens}
                description={item.introduction}
                imgUrl={`${item.asset.file.url}?w=450`}
                imgTitle={item.asset.file.title || ""}
                cardVariant={cardVariant || ""}
                mealType={item.mealType && item.mealType[0]}
                time={item.readyIn && `${item.readyIn} mins`}
                servings={item.servings && item.servings}
              />
            </Link>
          </Grid>
        ))
      : null;

  return (
    <Grid container className="recipe-cards__container" spacing={4}>
      {Cards}
    </Grid>
  );
});

export default RecipeCards;
