const MentalHealthCondition = {
  NONE: "None",
  ANXIETY: "Anxiety",
  DEPRESSION: "Depression",
  ANTISOCIAL_PERSONALITY_DISORDER: "AntisocialPersonalityDisorder",
  MULTIPLE_PERSONALITY_DISORDER: "MultiplePersonalityDisorder",
  ADHD: "ADHD",
  OCD: "OCD",
  AUTISM: "Autism",
  BIPOLAR: "BiPolar",
  SCHIZOPHRENIA: "Schizophrenia",
  SELF_HARM: "SelfHarm",
  SELF_MUTISM: "SelfMutism",
  OTHER: "Other",
};

export const mentalHealthConditions = [
  { value: "anxiety", label: "Anxiety" },
  { value: "depression", label: "Depression" },
  { value: "eatingDisorder", label: "Eating disorder" },
  { value: "antisocialPersonalityDisorder", label: "Antisocial personality disorder" },
  { value: "multiplePersonalityDisorder", label: "Multiple personality disorder" },
  { value: "adhd", label: "ADHD" },
  { value: "ocd", label: "OCD" },
  { value: "autism", label: "Autism" },
  { value: "bipolar", label: "Bipolar" },
  { value: "schizophrenia", label: "Schizophrenia" },
];

export default MentalHealthCondition;
