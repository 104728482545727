export const mapProfileBuilderFormValues = (signupFormValues) => {
  const mappedProfileBuilderValues = {
    firstLanguage: signupFormValues?.firstLanguage.value,
    religion: signupFormValues?.religion.value,
    employmentStatus: signupFormValues?.employmentStatus.value,
    sexualOrientation: signupFormValues?.sexualOrientation.value,
    howHeard: [...signupFormValues?.howHeard, signupFormValues?.howHeardOther].filter(Boolean),
  };
  return mappedProfileBuilderValues;
};


export const questionStepperList = [
  "Checking we're a good fit",
  "Create account",
  "Email verified",
  "Profile",
];
