import React from "react";
import PillarButton from "../../molecules/PillarButton/PillarButton";
import "./PillarButtonContainer.scss";
import PropTypes from "prop-types";
import CircularLoader from "@thrivetribe/gloji.ui.atoms.loader/dist/circular-loader";

import { ReactComponent as Nutrition } from "../../../assets/img/icons/pillars/nutrition.svg";
import { ReactComponent as Movement } from "../../../assets/img/icons/pillars/movement.svg";
import { ReactComponent as Alcohol } from "../../../assets/img/icons/pillars/alcohol.svg";
import { ReactComponent as Mind } from "../../../assets/img/icons/pillars/mind.svg";
import { ReactComponent as Sleep } from "../../../assets/img/icons/pillars/sleep.svg";
import { ReactComponent as SmokeFree } from "../../../assets/img/icons/pillars/smokeFree.svg";

const pillarsArray = [
  {
    title: "Nutrition",
    link: "/explore/articles/nutrition",
    color: "#FFEF73",
    icon: <Nutrition />,
  },
  {
    title: "Movement",
    link: "/explore/articles/movement",
    color: "#FF808A",
    icon: <Movement />,
  },
  {
    title: "Alcohol",
    link: "/explore/articles/alcohol",
    color: "#91F2EA",
    icon: <Alcohol />,
  },
  {
    title: "Mind",
    link: "/explore/articles/mind",
    color: "#FFBA7A",
    icon: <Mind />,
  },
  {
    title: "Sleep",
    link: "/explore/articles/sleep",
    color: "#E2B8E5",
    icon: <Sleep />,
  },
  {
    title: "Smoking",
    link: "/explore/articles/smoking",
    color: "#61F2C2",
    icon: <SmokeFree />,
  },
];

const PillarButtonContainer = ({ pillars }) => {
  return (
    <div className="pillar-btn-container">
      {pillars ? (
        pillars.map((item, index) => {
          const pill = pillarsArray.find((element) => element.title === item.displayName);

          return (
            <PillarButton
              title={pill.title}
              link={pill.link}
              color={pill.color}
              icon={pill.icon}
              key={pill.title + index}
            />
          );
        })
      ) : (
        <div style={{ margin: "3rem 0" }}>
          <CircularLoader />
        </div>
      )}
    </div>
  );
};

PillarButtonContainer.propTypes = {
  pillars: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      tag: PropTypes.string,
    })
  ),
};

export default PillarButtonContainer;
