import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useCompletedSteps from "../../hooks/useCompletedSteps";
import Stepper from "../../atoms/Stepper";
import { ReactComponent as CelebrationTrophyLeft } from "../../../assets/img/celebration_trophy_left.svg";
import { ReactComponent as CelebrationTrophyRight } from "../../../assets/img/celebration_trophy_right.svg";
import "./CompletedProgrammeCard.scss";

const CompletedProgrammeCard = () => {
  const overview = useSelector((state) => state.programmeOverview);

  const completedSteps = useCompletedSteps(overview?.data?.steps);
  const steps = overview.data.programme.numberOfSteps - 1;

  const location = useLocation();
  const isMyPath = location.pathname.includes("my-path") ? true : false;

  return (
    <>
      {!isMyPath && overview?.data !== undefined && (
        <div className="hero-card__completed-celebration">
          <CelebrationTrophyLeft />
          <Stepper totalSteps={steps} completedSteps={completedSteps} currentStep={steps} />
          <CelebrationTrophyRight />
        </div>
      )}
      <div className="hero-card__completed-programme">
        <h2>Incredible! You've completed your {steps} week programme</h2>
      </div>
    </>
  );
};

export default CompletedProgrammeCard;
