import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";

const FooterCopyRight = ({ children }) => {
  return (
    <Grid item xs={12} className="footer__copyright">
      <p> {children} </p>
    </Grid>
  );
};

FooterCopyRight.propTypes = {
  children: PropTypes.node,
};
export default FooterCopyRight;
