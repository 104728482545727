import React from "react";
import { Dialog } from "@material-ui/core";
import PropTypes from "prop-types";
import "./CustomModal.scss";

export const CustomModal = ({
  children,
  id,
  open = false,
  onClose,
  modalTitleId,
  modalDescriptionId,
}) => {
  return (
    <Dialog
      id={id}
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
      aria-labelledby={modalTitleId}
      aria-describedby={modalDescriptionId}
      maxWidth={"md"}
      className="custom-modal-container"
    >
      {children}
    </Dialog>
  );
};

CustomModal.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  modalTitleId: PropTypes.string.isRequired,
  modalDescriptionId: PropTypes.string.isRequired,
};
