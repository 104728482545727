import React, { forwardRef } from "react";
import ArticleCard from "../../molecules/Card";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

import "./ArticleCards.scss";

const ArticleCards = forwardRef(({ data, cardVariant }, ref) => {
  const Cards =
    data !== null && data !== undefined && Object.keys(data).length
      ? data.map((item, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            key={item.id}
            ref={Object.keys(data).length === index + 1 ? ref : undefined}
          >
            <Link to={`/explore/articles/${item.slug}`} className="article-card__container-link">
              <ArticleCard
                loading={false}
                cardTitle={item.title}
                articleTypes={item.pillarTags}
                description={item.introduction}
                imgUrl={`${item.asset.file.url}?w=450`}
                imgTitle={item.asset.file.title || ""}
                cardVariant={cardVariant || ""}
                longerRead={item.longerRead}
              />
            </Link>
          </Grid>
        ))
      : null;

  return (
    <Grid container className="article-cards__container" spacing={4}>
      {Cards}
    </Grid>
  );
});

export default ArticleCards;
