import authAxios from "../../helpers/authAxios";
import { urls } from "../../configs/serviceConfig";
import axios from "axios";
import { logException } from "../../utils/AppInsightsLogging";

export class ContentService {
  constructor() {
    this._client = authAxios;
    this._clientDefaults = {
      baseURL: `${urls?.contentService}/api`,
      withCredentials: true,
    };
  }

  async getArticle(slug) {
    const url = `${urls?.contentService}/api/articles/${slug}`;
    try {
      const res = await authAxios.get(url);
      if (res.statusText === "OK") {
        return res.data;
      } else {
        throw new Error(
          `Failed to get article content with error ${res.status}: ${res.statusText}`
        );
      }
    } catch (error) {
      console.error(error);
      logException(error);
      // Return an empty array so UI don't crash.
      return [];
    }
  }

  async getArticlesByStageTag(stage) {
    let url = `${urls?.contentService}/api/content/stage-tags/stage-${stage}`;
    try {
      const res = await authAxios.get(url);
      if (res.statusText === "OK") {
        return res.data;
      } else {
        throw new Error(`Failed to get articles with error ${res.status}: ${res.statusText}`);
      }
    } catch (error) {
      console.error(error);
      logException(error);
      // Return an empty array so UI don't crash.
      return [];
    }
  }

  async getRecipe(slug) {
    const url = `${urls?.contentService}/api/recipes/${slug}`;
    try {
      const res = await authAxios.get(url);
      if (res.statusText === "OK") {
        return res.data;
      } else {
        throw new Error(`Failed to get recipe content with error ${res.status}: ${res.statusText}`);
      }
    } catch (error) {
      console.error(error);
      logException(error);
      // Return an empty array so UI don't crash.
      return [];
    }
  }

  async getPolicyDocment(slug) {
    let url = `${urls?.contentService}/api/policy-documents/${slug}`;
    try {
      const res = await axios.get(url);
      if (res.statusText === "OK") {
        return res.data;
      } else {
        throw new Error(
          `Failed to get policy document content with error ${res.status}: ${res.statusText}`
        );
      }
    } catch (error) {
      console.error(error);
      logException(error);
      // Return an empty array so UI don't crash.
      return [];
    }
  }

  async post(url, data = {}) {
    const res = await this._client.post(url, data, this._clientDefaults);

    return res.data;
  }

  static get instance() {
    return contentService;
  }
}

const contentService = new ContentService();

export default contentService;
