import React, {useEffect, useState} from "react";
import { Helmet } from "react-helmet";
import { Container, Grid } from "@material-ui/core";
import { greyVelvet } from "../../../assets/img/bg";
import { defaultBg } from "../../../assets/img/articles";
import PageBackground from "../../organisms/PageBackground";
import { PageHeroBanner } from "../../organisms/HeroBanner";
import PillarButtonContainer from "../../organisms/PillarButtonContainer/PillarButtonContainer";
import InfiniteScrollArticleCards from "../../organisms/InfiniteScrollArticleCards";
import "./Articles.scss";

import { useFeatureFlag } from "@thrivetribe/gloji.hooks.use-feature-flag";
import Features from "../../../enums/features";
import { featureService } from "../../services";
import { useSelector } from "react-redux";

const Articles = () => {
  const overview = useSelector((state) => state.programmeOverview);

  const [pillars, setPillars] = useState([]);

  const [pillarFilterEnabled, pillarFilterReady] = useFeatureFlag(
    Features.PILLAR_FILTER,
    featureService.isFeatureEnabled
  );

  useEffect(() => {
    if (overview?.data?.programme || overview?.data?.programmes) {
      if (overview?.data?.programmes) {
        setPillars([...new Set(overview.data.programmes.map(p => p.pillars))]);
      } else {
        setPillars(overview.data.programme.pillars);
      }
    }
  }, [overview]);

  return (
    <PageBackground bgImage={greyVelvet}>
      <Helmet>
        <title>Latest articles | Gloji</title>
      </Helmet>
      <div className="articles-page">
        <PageHeroBanner bgImg={defaultBg}>
          <div className="articles-banner__header">
            <h1 className="articles-banner__heading">Articles</h1>
            <h2 className="articles-banner__subheading">What would you like to explore?</h2>
          </div>

          {pillarFilterReady && pillarFilterEnabled && (
            <Grid item xs={12} style={{ marginBottom: 30 }}>
              <PillarButtonContainer pillars={pillars} />
            </Grid>
          )}
        </PageHeroBanner>

        <Container className="main-content__container articles-content">
          <InfiniteScrollArticleCards pillarTags={null} />
        </Container>
      </div>
    </PageBackground>
  );
};

export default Articles;
