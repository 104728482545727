import "@fortawesome/fontawesome-free/css/all.css";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./components/App";
import { ScrollTo } from "./components/atoms/ScrollTo";
import ErrorBoundary from "./components/organisms/ErrorBoundary";
import { Provider } from "react-redux";
import { store } from "./store/reducers/index";

import "./styles/style.css";
//We will eventually remove all the other css files and keep one css file at root level for global styles
import "./styles.scss";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter basename={baseUrl}>
        <ScrollTo />
        <App />
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
