import { useRouteMatch, Switch, Route } from "react-router";
import PhysicalHealth from "./PhysicalHealth";

const PhysicalHealthRoot = (props) => {
  let { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={`${path}`} component={PhysicalHealth} />
      </Switch>
    </>
  );
};

export default PhysicalHealthRoot;
