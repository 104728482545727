import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import HeroImage from "../../../../assets/img/amaezeGlojiMentor.png";
import { update_signup_form_values } from "../../../../store/actions/signupFlowData";
import {
  ModalPageBody,
  ModalPageCopy,
  ModalPageFooter,
  ModalPageHeader,
  ModalPageHeading,
  ModalPageHeroImage,
  ModalPageWrapper,
} from "../../../organisms/ModalPage";
import "./Pathway.scss";

const PathwayEligibleScreen = () => {
  const dispatch = useDispatch();
  const pathwayPageData = useSelector((state) => state.signupFlowData.signupPagesData?.pathway);

  const history = useHistory();

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Supported or digital | Eligibility checker | Sign up | Gloji",
    });
  }, []);

  const handleClick = (pathway) => {
    dispatch(update_signup_form_values({ pathway }));
    history.push("/introduce-yourself");
  };

  return (
    <>
      <Helmet>
        <title>Supported or digital | Eligibility checker | Sign up | Gloji</title>
      </Helmet>
      <ModalPageWrapper>
        <ModalPageBody hasStickyFooter={false}>
          <ModalPageHeader
            backButtonOnClick={() => {
              history.goBack();
            }}
          />
          <ModalPageHeroImage
            className="page-hero"
            altText="Sara, gloji guide"
            imageUrl={HeroImage}
          ></ModalPageHeroImage>
          <ModalPageHeading>{pathwayPageData?.questions[0].question}</ModalPageHeading>
          <ModalPageCopy>
            <p>{pathwayPageData?.questions[0].description}</p>
            <p>{pathwayPageData?.questions[0].label}</p>
          </ModalPageCopy>
        </ModalPageBody>
        <ModalPageFooter hasStickyFooter={false}>
          <div className="button-group">
            <Button
              fullwidth
              className="top-button"
              onClick={() => handleClick("Supported")}
              variant="contained"
              color="primary"
            >
              I'd love bonus support
            </Button>
            <Button
              fullwidth
              onClick={() => handleClick("Digital")}
              variant="contained"
              color="secondary"
            >
              I'll stick with digital
            </Button>
          </div>
        </ModalPageFooter>
      </ModalPageWrapper>
    </>
  );
};

export default PathwayEligibleScreen;
