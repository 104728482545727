import React, { useEffect, useState } from "react";
import {
  ModalPageBody,
  ModalPageCopy,
  ModalPageWrapper,
} from "../../../organisms/ModalPage";
import SmokeFreeJourney from "../ProfileBuilderJourneys/SmokeFreeJourney";
import { programmes } from "../../../../enums/programmes";
import ProfileBuilder from "../../profile/profileBuilder/ProfileBuilder";
import { useFeatureFlag } from "@thrivetribe/gloji.hooks.use-feature-flag";
import Features from "../../../../enums/features";
import { featureService } from "../../../services";
import { ConfigurableProfileBuilder } from "../ProfileBuilderJourneys/ConfigurableProfileBuilder/ConfigurableProfileBuilder";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useGetProfileByIdQuery } from "../../../../redux/profileApi";
import { useGetProfileBuilderConfigQuery } from "../../../../redux/referralApi";
import authService from "../../../auth/AuthorizeService";
import { profileBuilderConfig } from "../../../../configs/serviceConfig";

/**
 * Renders profileBuilder journeys for referrals, based on the programme they are in.
 * @returns profileBuilder journey
 */

const ProfileBuilderRoot = () => {
  const [userId, setUserId] = useState();
  useEffect(() => {
    authService.getUser().then((user) => {
      setUserId(user.sub);
    });
  }, []);

  const {
    data: profile,
    isLoading: isLoadingProfile,
    isError: isErrorProfile,
    isSuccess: isSuccessProfile,
    refetch: refetchProfile,
  } = useGetProfileByIdQuery(userId ?? skipToken);

  const {
    data: builderConfig,
    isError: isErrorBuilderConfig,
    isSuccess: isSuccessBuilderConfig,
    refetch: builderConfigRefetch,
  } = useGetProfileBuilderConfigQuery(
    profile?.currentJourney?.referringServiceId ?? skipToken
  );

  const [configProfileBuilderEnabled, configProfileBuilderReady] =
    useFeatureFlag(
      Features.CONFIGURABLE_PROFILE_BUILDER,
      featureService.isFeatureEnabled
    );

  const renderJourney = (param) => {
    switch (param) {
      case programmes["lose-weight"].id:
      case programmes["sitting-on-chairs"].id:
      case programmes["standing-on-tables"].id:
        return <ProfileBuilder />;
      case programmes["smoke-free"].id:
        // TODO: this will need to be changed to use actual config once implemented
        // if (builderConfig !== undefined) {
        if (configProfileBuilderEnabled) {
          return (
            <ConfigurableProfileBuilder builderConfig={profileBuilderConfig} />
          );
        } else {
          return <SmokeFreeJourney />;
        }

      default:
        return (
          <ModalPageWrapper>
            <ModalPageBody>
              <ModalPageCopy>fdss Something went wrong! </ModalPageCopy>
            </ModalPageBody>
          </ModalPageWrapper>
        );
    }
  };

  return <>{renderJourney(profile?.currentJourney.programmeIds[0])}</>;
};

export default ProfileBuilderRoot;
