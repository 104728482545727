import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as PizzaSlice } from "../../../assets/img/icons/pizzaSlice.svg";
import "./Stepper.scss";

const Stepper = ({ totalSteps, completedSteps, currentStep }) => {
  const currentStepText = currentStep < 10 ? `0${currentStep}` : currentStep;
  const steps = [];
  for (let i = 0; i < totalSteps; i++) {
    const completed = completedSteps.find((step) => step - 1 === i);
    steps.push({ completed: completed !== undefined ? completed : false });
  }

  return (
    <div className="stepper-container">
      <div className="stepper">
        <div className={`stepper__steps stepper__steps--${totalSteps}`}>
          {steps.map((step, index) => (
            <div
              className={`stepper__step ${step.completed ? "stepper__step--completed" : ""}`}
              key={index}
            >
              <PizzaSlice />
            </div>
          ))}
        </div>
        <div className="stepper__ring">
          <div className="stepper__inner">
            <span className="stepper__inner__wording">Step</span>
            <span className="stepper__inner__number">{currentStepText}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

Stepper.propTypes = {
  totalSteps: PropTypes.number.isRequired,
  completedSteps: PropTypes.array.isRequired,
  currentStep: PropTypes.number.isRequired,
};

export default Stepper;
