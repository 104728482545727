import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import NavBar from "../../organisms/NavBar";
import { ThemeProvider } from "@material-ui/core/styles";
import customTheme from "./customTheme";
import Footer from "../../organisms/Footer";
import { AuthenticationRoutes } from "../../routes";
import { default as AccountActivationNavBar } from "@thrivetribe/gloji.ui.organisms.navbar";
import Logo from "@thrivetribe/gloji.assets.logo";
import { NAVY, MINT } from "@thrivetribe/gloji.enums.brand-colors";
import { matchPath } from "react-router";
import {
  isEligiblityCheckerPath,
  isProfileBuilderPath,
  isAccountVerifyPath,
} from "../../../helpers/routerPathHelper";

import "./Layout.scss";

/**
 * The main layout of the app including NavBar, main body, and footer.
 * @param {ReactChildren} children - The child components passed to the layout component
 */
function Layout({ children }) {
  const matchActivationPath = matchPath(`${window.location.pathname}`, {
    path: "/sign-up/activation/:key",
    strict: false,
  });

  const [isEligibilityChecker, setIsEligibilityChecker] = useState(false);
  const [isProfileBuilder, setIsProfileBuilder] = useState(false);
  const [isAccountVerify, setIsAccountVerify] = useState(false);
  const [isEligibilityOrProfileBuilder, setIsEligibilityOrProfileBuilder] = useState(false);

  let location = useLocation();
  useEffect(() => {
    setIsEligibilityChecker(isEligiblityCheckerPath(location.pathname));
    setIsProfileBuilder(isProfileBuilderPath(location.pathname));
    setIsAccountVerify(isAccountVerifyPath(location.pathname));
    setIsEligibilityOrProfileBuilder(isEligibilityChecker || isProfileBuilder || isAccountVerify);
  }, [location.pathname, isEligibilityChecker, isProfileBuilder, isAccountVerify]);

  return (
    <ThemeProvider theme={customTheme}>
      <AuthenticationRoutes />
      {(matchActivationPath !== null && matchActivationPath.isExact === true) ||
      isEligibilityChecker ? (
        <AccountActivationNavBar
          color={MINT}
          borderColor={NAVY}
          className={`${isEligibilityOrProfileBuilder ? "signup-flow-header" : null}`}
        >
          <div className={`header__logo-container nav-left-block header-item__container`}>
            <Logo
              url={`${isEligibilityOrProfileBuilder ? "https://www.gloji.co.uk" : "/"}`}
              id="logo-standard-navy"
              logoColor={NAVY}
              className={`header__logo${
                isEligibilityOrProfileBuilder ? " header__logo--center" : ""
              }`}
              title={"Go to home page"}
            />
          </div>
        </AccountActivationNavBar>
      ) : (
        <NavBar hideMenu={isEligibilityOrProfileBuilder} />
      )}
      <main role="main">{children}</main>
      {isEligibilityOrProfileBuilder ? null : <Footer />}
    </ThemeProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
