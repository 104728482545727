import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PageBackground from "../../organisms/PageBackground";
import { myProgressBg } from "../../../assets/img/bg";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { Container, Grid } from "@material-ui/core";
import { useHistory, useParams } from "react-router";
import useEmailVerificationService from "../../services/useEmailVerificationService";
import { CircularLoader } from "@thrivetribe/gloji.ui.atoms.loader";
import { Alert, AlertTitle } from "@material-ui/lab";
import useResendLink from "../../hooks/useResendLink";
import { IsValueEmpty } from "../../../utils/IsEmpty";
import { BackButtonLink } from "../../atoms/Button";

const ValidateEmailVerification = () => {
  const [emailVerificationStatus, setEmailVerificationStatus] = useState();
  const [userEmail, setUserEmail] = useState(null);
  const history = useHistory();
  let { key } = useParams();
  const response = useEmailVerificationService(key);
  const [resendResponse, resendLink] = useResendLink(userEmail);
  const [newLinkRequested, setNewLinkRequested] = useState(false);
  const [pageTitle, setPageTitle] = useState();

  useEffect(() => {
    switch (response.data.emailVerificationKeyStatus) {
      case "Valid":
        setEmailVerificationStatus("valid");
        setPageTitle("Email Verified | Verify Email | Sign up | Gloji");
        break;
      case "Invalid":
        setEmailVerificationStatus("invalid");
        setPageTitle("Invalid Link | Verify Email | Sign up | Gloji");
        break;
      case "Expired":
        setEmailVerificationStatus("expired");
        setPageTitle("Expired Link | Verify Email | Sign up | Gloji");
        break;
      default:
        setPageTitle("Verify Email | Sign up | Gloji");
        setEmailVerificationStatus(null);
    }
    !IsValueEmpty(response.data.email) && setUserEmail(response.data.email);
  }, [response]);

  const handleRequestNewLink = () => {
    resendLink();
  };
  useEffect(() => {
    resendResponse.linkSent === true && setNewLinkRequested(true);
  }, [resendResponse.linkSent]);

  useEffect(() => {
    if (pageTitle) {
      window.gtag("event", "page_view", {
        page_title: `${pageTitle}`,
      });
    }
  }, [pageTitle]);

  useEffect(() => {
    if (emailVerificationStatus === "valid") {
      //Trigger google analytics event at the end of email verification
      window.gtag("event", "emailVerified", {
        event_category: "Email verification",
        event_label: "Email Verified",
      });
    }
  }, [emailVerificationStatus]);

  const handleGoBack = () => {
    setNewLinkRequested(false);
  };
  return (
    <PageBackground bgImage={myProgressBg} className="verify-email-page">
      <Helmet>
        <title> {`${pageTitle}`} </title>
      </Helmet>
      <>
        <Container>
          <Grid container justifyContent="center">
            <div className="verify-email-page__wrapper">
              <div className="verify-email-page__content-container">
                <div className="verify-email-page__content-wrapper with-footer">
                  <div>
                    {newLinkRequested === true && (
                      <BackButtonLink
                        linkPath={window.location.pathname}
                        btnText="Back"
                        ariaLabel="go back"
                        onClick={handleGoBack}
                        style={{ position: "absolute" }}
                      />
                    )}
                    <h1 className="verify-email-page__title"> Verification </h1>
                  </div>

                  {response.loading === true ? (
                    <CircularLoader color="primary" size={60} style={{ marginTop: "5rem" }} />
                  ) : !response.errorStatus || emailVerificationStatus !== null ? (
                    <>
                      {emailVerificationStatus === "valid" && (
                        <>
                          <div className="verify-email-page__celebration-img-container"></div>
                          <h2
                            className="verify-email-page__sub-title"
                            style={{ textAlign: "center" }}
                          >
                            That’s your email verified!
                          </h2>
                        </>
                      )}
                      {emailVerificationStatus === "expired" &&
                        (resendResponse.loading ? (
                          <CircularLoader
                            color="primary"
                            size={60}
                            style={{ marginTop: "10rem" }}
                          />
                        ) : resendResponse.errorStatus ? (
                          <Alert severity="error" style={{ marginTop: "2rem" }}>
                            <AlertTitle>{resendResponse.error?.message} </AlertTitle>
                            Unable to resend new verification link
                          </Alert>
                        ) : newLinkRequested ? (
                          <>
                            <h2 className="verify-email-page__sub-title">It’s on its way</h2>
                            <p className="verify-email-page__copy">
                              We’ve sent you another chance to verify your email. It might take a
                              few minutes to land in your inbox so don’t worry if you can’t see it
                              right away.
                              <span style={{ marginTop: "30px" }}>
                                Checked your junk mail? Still nothing after 5 minutes? You can
                                always go back and request a new link again.
                              </span>
                            </p>
                          </>
                        ) : (
                          <>
                            <h2 className="verify-email-page__sub-title"> Uh oh! Time out</h2>

                            <p className="verify-email-page__copy">
                              Looks like the link we sent you has expired. Don’t worry, it’s nothing
                              we can’t fix. We’ll send you a new one.
                              <span style={{ marginTop: "30px" }}>
                                Go, go go, the link will expire in 1 hour
                              </span>
                              <span style={{ marginTop: "15px", fontSize: "16px" }}>
                                If you are unable to find the email, please check your spam folder.
                              </span>
                            </p>
                          </>
                        ))}
                      {emailVerificationStatus === "invalid" && (
                        <>
                          <h2 className="verify-email-page__sub-title">
                            Uh oh! Something’s not right
                          </h2>
                          <p className="verify-email-page__copy">
                            Looks like the link is invalid. Try the link again.
                            <span style={{ marginTop: "30px" }}>
                              If that hasn't fixed it, get in touch and we'll see if we can get this
                              sorted.
                            </span>
                          </p>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <h2 className="verify-email-page__sub-title">Something went wrong</h2>
                      <Alert severity="error" style={{ marginTop: "2rem" }}>
                        <AlertTitle>{response?.error?.message} </AlertTitle>
                        Verification failed
                      </Alert>
                    </>
                  )}
                </div>
                {emailVerificationStatus === "valid" && (
                  <div className="verify-email-page__footer">
                    <Button variant="contained" color="primary" onClick={() => history.push("/")}>
                      Time to rock & roll
                    </Button>
                  </div>
                )}
                {emailVerificationStatus === "expired" && newLinkRequested === false && (
                  <div className="verify-email-page__footer">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleRequestNewLink}
                      isLoading={resendResponse.loading}
                    >
                      Request new link
                    </Button>
                  </div>
                )}
                {emailVerificationStatus === "invalid" && (
                  <div className="verify-email-page__footer">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        window.location = "mailto:support@gloji.co.uk";
                      }}
                    >
                      Contact us
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </Container>
      </>
    </PageBackground>
  );
};
export default ValidateEmailVerification;
