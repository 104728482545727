import React from "react";
import PropTypes from "prop-types";

export const ModalTitle = ({ children, id, ...rest }) => {
  return (
    <div className="modal-title__container">
      <h2 className="modal-title" id={id} {...rest}>
        {children}
      </h2>
    </div>
  );
};

ModalTitle.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
};
