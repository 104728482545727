import React from "react";
import PropTypes from "prop-types";

import "./Stepper.scss";

export function Stepper({ steps, currentStep }) {
  return (
    <div className="stepper-container">
      <div className="stepper-wrapper">
        {steps.map((step, index) => {
          return (
            <div
              className={`stepper-item ${currentStep === index + 1 && "active"}`}
              key={index}
            ></div>
          );
        })}
      </div>
    </div>
  );
}

Stepper.propTypes = {
  steps: PropTypes.array.isRequired,
  currentStep: PropTypes.number.isRequired,
};
