import React from "react";
import PropTypes from "prop-types";

const ModalPageCopy = ({ children }) => {
  return <div className="modal-page__copy">{children}</div>;
};

ModalPageCopy.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalPageCopy;
