import PropTypes from "prop-types";

const ModalPageFooter = ({ children, hasStickyFooter = true }) => {
  return (
    <div className={`modal-page__footer ${hasStickyFooter ? "sticky-footer" : ""}`}>{children}</div>
  );
};

ModalPageFooter.propTypes = {
  children: PropTypes.node.isRequired,
  hasStickyFooter: PropTypes.bool,
};

export default ModalPageFooter;
