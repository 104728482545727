import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import LatestWeight from "../../utils/LatestWeight";

/**
 * Custom React hook for using user's latest weight data.
 * @param {Array} weights - An array of the user's logged weights.
 * @returns {Object} latestWeight - The user's last logged weight.
 */
function useLatestWeight(weights) {
  const [latestWeight, setLatestWeight] = useState({});

  useEffect(() => {
    let latestWeight = LatestWeight(weights);
    setLatestWeight(latestWeight);
  }, [weights]);

  return latestWeight;
}

useLatestWeight.propTypes = {
  weights: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default useLatestWeight;
