import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import ListContainer from "../../atoms/ListContainer";
import NavDropdownMenu from "../../molecules/NavDropdownMenu";

const MainNavLinks = ({ mainNavItems }) => {
  const mainNavItem = mainNavItems
    ? mainNavItems.map((item) => {
      return (
        <li key={item.name} className="main-nav-links__item">
          <NavLink
            to={`/${item.link}`}
            exact={item.link === ""} // ensure Home page does not stay permanently active
            aria-label={`Visit ${item.name}`}
          >
            {item.name}
          </NavLink>
        </li>
      );
    })
    : null;

  return (
    <div className="main-nav-links__container">
      <ListContainer>
        {mainNavItem}
        <NavDropdownMenu className={"explore-dropdown-menu"} id={"explore-dropdown"} />
      </ListContainer>
    </div>
  );
};
MainNavLinks.propTypes = {
  mainNavItems: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, link: PropTypes.string })
  ),
};
export default MainNavLinks;
