import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { firstLanguageOptions } from "../../../../enums/profile/FirstLanguage";
import { update_signup_form_values } from "../../../../store/actions/signupFlowData";
import ReactSelect from "../../../atoms/ReactSelect";
import {
  ModalPageBody,
  ModalPageFooter,
  ModalPageHeader,
  ModalPageHeading,
  ModalPageWrapper,
} from "../../../organisms/ModalPage";
import { FirstLanguageValidationSchema } from "../ProfileBuilderValidation";

import { MultiStep } from "../../../molecules/MultiStep";
import { questionStepperList } from "../helpers";

const FirstLanguage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const firstLanguagePageData = useSelector(
    (state) => state.signupFlowData.signupPagesData?.["first-language"]
  );
  const storeFirstLanguageValue = useSelector(
    (state) => state.signupFlowData.signupFormValues?.firstLanguage
  );

  const [initialValues, setInitialValues] = useState({
    firstLanguage: storeFirstLanguageValue || null,
  });

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "First language | Profile builder | Sign up | Gloji",
    });
  }, []);

  const handleSubmit = (values) => {
    storeFirstLanguageValue
      ? setInitialValues({ firstLanguage: storeFirstLanguageValue })
      : setInitialValues(values);
    dispatch(update_signup_form_values(values));
    history.push("/religion");
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={FirstLanguageValidationSchema}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {(props) => (
        <Form className="profile-builder-form">
          <Helmet>
            <title>First language | Profile builder | Sign up | Gloji</title>
          </Helmet>
          <ModalPageWrapper>
            <ModalPageBody className="with-sticky-footer">
              <ModalPageHeader
                className="modal-header_center"
                backButtonOnClick={() => {
                  history.goBack();
                }}
              >
                Profile
              </ModalPageHeader>

              <MultiStep step={3} list={questionStepperList} percent={10} />

              <ModalPageHeading>{firstLanguagePageData?.questions[0].question}</ModalPageHeading>
              <ReactSelect
                name="firstLanguage"
                options={firstLanguageOptions}
                label={firstLanguagePageData?.questions[0].label}
                labelCssClass="profile-builder__input-label"
              />
              <Accordion square={true} elevation={0} className="profile-builder__accrodion">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon fontSize="large" />}
                  aria-controls={`firstLanguage-question-explanation`}
                >
                  <span>{firstLanguagePageData?.questions[0].whyQuestion}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <span>{firstLanguagePageData?.questions[0].whyQuestionDetails}</span>
                </AccordionDetails>
              </Accordion>
            </ModalPageBody>
            <ModalPageFooter className="sticky-footer">
              <Button type="submit" variant="contained" color="primary">
                Continue
              </Button>
            </ModalPageFooter>
          </ModalPageWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default FirstLanguage;
