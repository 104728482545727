import { useState } from "react";
import { NavLink } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import "./NavAccordionMenu.scss";

const NavAccordionMenu = ({ onClick }) => {
  const [expand, setExpand] = useState(false);
  const handleAccordion = () => {
    setExpand((current) => !current);
  };
  return (
    <div
      id="accordion-menu__container"
      className="accordion-menu__container"
      onClick={handleAccordion}
    >
      <li
        className="mobile-nav-links__accordion-item"
        data-for="explore-dropdown"
        id="explore-dropdown"
        data-tip
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <span aria-label="explore">
          explore
          {expand ? (
            <ExpandLessIcon className="arrow-icon" />
          ) : (
            <ExpandMoreIcon className="arrow-icon" />
          )}
        </span>
      </li>
      {expand && (
        <div className="accordion-menu">
          <ul>
            <li className="accordion-menu__links">
              <NavLink
                to={`/explore/articles`}
                exact={true}
                aria-label={`visit articles page`}
                onClick={onClick}
              >
                articles
              </NavLink>
            </li>
            <li className="accordion-menu__links">
              <NavLink
                to={`/explore/recipes`}
                exact={true}
                aria-label="visit recipes page"
                onClick={onClick}
              >
                recipes
              </NavLink>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default NavAccordionMenu;
