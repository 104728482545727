import { UserManager, WebStorageStateStore } from "oidc-client";
import { Adb2cPolicies, ApplicationName, ApplicationPaths } from "./ApiAuthorizationConstants";
import { authConfig } from "../../configs/authConfig";
import { logException } from "../../utils/AppInsightsLogging";
import { engagementsApi } from "../../redux/engagementsApi";
import { store } from "../../store/reducers";
import { v4 as uuidv4 } from "uuid";

export class AuthorizeService {
  _callbacks = [];
  _nextSubscriptionId = 0;
  _user = null;
  _isAuthenticated = false;

  // By default pop ups are disabled because they don't work properly on Edge.
  // If you want to enable pop up authentication simply set this flag to false.
  _popUpDisabled = true;

  async isAuthenticated() {
    const user = await this.getUser();
    return !!user;
  }

  isAccessTokenExpired(unixTimestamp) {
    let expired = true;
    if (unixTimestamp) {
      expired = Date.now() > unixTimestamp * 1000;
    }
    return expired;
  }

  async getAccessToken() {
    this.ensureUserManagerInitialized();
    const user = await this.userManager.getUser();
    const sessionId = localStorage.getItem("sessionId");

    if (user && (user.expired || this.isAccessTokenExpired(user.expires_at))) {
      try {
        const silentUser = await this.userManager.signinSilent(this.createArguments());
        this.updateState(silentUser);

        // Log the access token refresh
        await store.dispatch(
          engagementsApi.endpoints.logRefreshAccessToken.initiate({
            userId: user.profile.oid,
            sessionId: sessionId,
          })
        );

        return silentUser && silentUser.access_token;
      } catch (error) {
        // We can't renew token silently or refresh_token has expired
        // Ask user to log in again
        try {
          const returnUrl = `${window.location}`;
          const state = { returnUrl };
          await this.userManager.signinRedirect(this.createArguments(state));
          return this.redirect();
        } catch (redirectError) {
          logException(redirectError);
          console.log("Redirect authentication error: ", redirectError);
          return this.error(redirectError);
        }
      }
    }
    return user && user.access_token;
  }

  // We try to authenticate the user in three different ways:
  // 1) We try to see if we can authenticate the user silently. This happens
  //    when the user is already logged in on the IdP and is done using a hidden iframe
  //    on the client.
  // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
  //    Pop-Up blocker or the user has disabled PopUps.
  // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
  //    redirect flow.
  async signIn(state, extraQueryParams = {}) {
    this.ensureUserManagerInitialized();
    try {
      const silentUser = await this.userManager.signinSilent(this.createArguments());
      this.updateState(silentUser);
      return this.success(state);
    } catch (silentError) {
      // User might not be authenticated, fallback to popup authentication
      console.log("Silent authentication error: ", silentError);

      try {
        if (this._popUpDisabled) {
          throw new Error(
            "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it."
          );
        }

        const popUpUser = await this.userManager.signinPopup(this.createArguments());
        this.updateState(popUpUser);
        return this.success(state);
      } catch (popUpError) {
        if (popUpError.message === "Popup window closed") {
          // The user explicitly cancelled the login action by closing an opened popup.
          return this.error("The user closed the window.");
        } else if (!this._popUpDisabled) {
          console.log("Popup authentication error: ", popUpError);
        }

        // PopUps might be blocked by the user, fallback to redirect
        try {
          await this.userManager.signinRedirect(this.createArguments(state, extraQueryParams));
          return this.redirect();
        } catch (redirectError) {
          console.log("Redirect authentication error: ", redirectError);
          logException(redirectError);
          return this.error(redirectError);
        }
      }
    }
  }

  async signUp(state, extraQueryParams = {}) {
    this.setUserManagerForSignup();
    try {
      await this.userManager.signinRedirect(this.createArguments(state, extraQueryParams));
      return this.redirect();
    } catch (redirectError) {
      console.log("Redirect authentication error: ", redirectError);
      logException(redirectError);
      return this.error(redirectError);
    }
  }

  async completeSignIn(url, isSignUp = false) {
    try {
      if (isSignUp === true) {
        this.setUserManagerForSignup();
      } else {
        this.ensureUserManagerInitialized();
      }

      const user = await this.userManager.signinCallback(url);
      this.updateState(user);

      // Log the user's sign-in
      const sessionId = uuidv4();
      localStorage.setItem("sessionId", sessionId);

      await store.dispatch(
        engagementsApi.endpoints.logSignIn.initiate({
          userId: user.profile.oid,
          sessionId: sessionId,
        })
      );

      return this.success(user && user.state);
    } catch (error) {
      logException(error);
      console.log("There was an error signing in: ", error);

      if (error.message === "No matching state found in storage") {
        console.log("redirecting to homepage");
        return window.location.replace("/");
      }

      return this.error("There was an error signing in.");
    }
  }

  // We try to sign out the user in two different ways:
  // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
  //    Pop-Up blocker or the user has disabled PopUps.
  // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
  //    post logout redirect flow.
  async signOut(state) {
    const user = await this.userManager.getUser();
    const sessionId = localStorage.getItem("sessionId");

    this.ensureUserManagerInitialized();
    try {
      if (this._popUpDisabled) {
        throw new Error(
          "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it."
        );
      }

      await this.userManager.signoutPopup(this.createArguments());

      // Log the user's sign-out
      await store.dispatch(
        engagementsApi.endpoints.logSignOut.initiate({
          userId: user.profile.oid,
          sessionId: sessionId,
        })
      );
      localStorage.removeItem("sessionId");

      this.updateState(undefined);
      return this.success(state);
    } catch (popupSignOutError) {
      console.log("Popup signout error: ", popupSignOutError);
      try {
        // Log the user's sign-out
        await store.dispatch(
          engagementsApi.endpoints.logSignOut.initiate({
            userId: user.profile.oid,
            sessionId: sessionId,
          })
        );
        localStorage.removeItem("sessionId");

        await this.userManager.signoutRedirect(this.createArguments(state));

        return this.redirect();
      } catch (redirectSignOutError) {
        console.log("Redirect signout error: ", redirectSignOutError);
        logException(redirectSignOutError);
        return this.error(redirectSignOutError);
      }
    }
  }

  async completeSignOut(url) {
    this.ensureUserManagerInitialized();
    try {
      const response = await this.userManager.signoutCallback(url);
      this.updateState(null);
      return this.success(response && response.data);
    } catch (error) {
      logException(error);
      console.log(`There was an error trying to log out '${error}'.`);
      return this.error(error);
    }
  }

  async getUser() {
    if (this._user && this._user.profile) {
      return this._user.profile;
    }

    this.ensureUserManagerInitialized();
    const user = await this.userManager.getUser();
    return user && user.profile;
  }

  updateState(user) {
    this._user = user;
    this._isAuthenticated = !!this._user;
    this.notifySubscribers();
  }

  subscribe(callback) {
    this._callbacks.push({
      callback,
      subscription: this._nextSubscriptionId++,
    });
    return this._nextSubscriptionId - 1;
  }

  unsubscribe(subscriptionId) {
    const subscriptionIndex = this._callbacks
      .map((element, index) =>
        element.subscription === subscriptionId ? { found: true, index } : { found: false }
      )
      .filter((element) => element.found === true);
    if (subscriptionIndex.length !== 1) {
      throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
    }

    this._callbacks.splice(subscriptionIndex[0].index, 1);
  }

  notifySubscribers() {
    for (let i = 0; i < this._callbacks.length; i++) {
      const callback = this._callbacks[i].callback;
      callback();
    }
  }

  createArguments(state, extraQueryParams) {
    return {
      useReplaceToNavigate: true,
      data: state,
      extraQueryParams: extraQueryParams,
    };
  }

  error(message) {
    return { status: AuthenticationResultStatus.Fail, message };
  }

  success(state) {
    return { status: AuthenticationResultStatus.Success, state };
  }

  redirect() {
    return { status: AuthenticationResultStatus.Redirect };
  }

  setUserManagerForSignup() {
    let settings = this.getUserManagerSettings(Adb2cPolicies.SignUpV2);
    settings.redirect_uri = `${window.location.origin}${ApplicationPaths.SignupCallback}`;
    this.userManager = new UserManager(settings); // Sign up uses a new UserManager with the ADB2C SignUp policy.
  }

  getUserManagerSettings(adb2cPolicy) {
    const {
      authority,
      client_id,
      redirect_uri,
      post_logout_redirect_uri,
      response_type,
      scope,
      loadUserInfo,
      revokeAccessTokenOnSignOut,
    } = authConfig;

    let settings = {
      authority,
      client_id,
      redirect_uri,
      post_logout_redirect_uri,
      response_type,
      scope,
      loadUserInfo,
      revokeAccessTokenOnSignOut,
      metadataUrl: `${authority}/${adb2cPolicy}/v2.0/.well-known/openid-configuration`,
      filterProtocolClaims: true,
      automaticSilentRenew: true,
      includeIdTokenInSilentRenew: true,
      userStore: new WebStorageStateStore({
        prefix: ApplicationName,
      }),
    };

    return settings;
  }

  ensureUserManagerInitialized(adb2cPolicy = Adb2cPolicies.SignIn) {
    if (this.userManager !== undefined) {
      return;
    }

    let settings = this.getUserManagerSettings(adb2cPolicy);
    this.userManager = new UserManager(settings);

    this.userManager.events.addAccessTokenExpiring(async () => {
      const sessionId = localStorage.getItem("sessionId");
      const user = await this.userManager.getUser();
      await store.dispatch(
        engagementsApi.endpoints.logRefreshAccessToken.initiate({
          userId: user.profile.oid,
          sessionId: sessionId,
        })
      );
    });

    this.userManager.events.addUserSignedOut(async () => {
      await this.userManager.removeUser();
      this.updateState(undefined);
    });
  }

  static get instance() {
    return authService;
  }
}

const authService = new AuthorizeService();

export default authService;

export const AuthenticationResultStatus = {
  Redirect: "redirect",
  Success: "success",
  Fail: "fail",
};
