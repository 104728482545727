import { Grid } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import classnames from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { update_current_tab_index } from "../../../store/actions/updateCurrentViewData";
import { ReactComponent as TickCircle } from "../../../assets/img/icons/tickCircle.svg";
import { ReactComponent as TrophyIcon } from "../../../assets/img/icons/trophyIcon.svg";
import { ReactComponent as LockedIcon } from "../../../assets/img/icons/lockOutline.svg";
import { ReactComponent as GreenTrophyIcon } from "../../../assets/img/icons/trophyIconGreen.svg";
import { Programmes } from "../../../data/Programme";
import "./ProgrammeStep.scss";

const Part = ({ title, completed, current, locked, onClick = () => {}, ...rest }) => {
  return (
    <button
      className={classnames("part", { current: current && !locked, locked })}
      onClick={onClick}
      {...rest}
    >
      <div className={classnames("part__title-container", { locked })}>
        {!locked ? (
          <div className="part__icon-wrapper">
            {completed ? (
              <TickCircle className="part__check-icon" />
            ) : (
              <RadioButtonUncheckedIcon className="part__check-icon" />
            )}
            {current && <div className="part__current-circle"></div>}
          </div>
        ) : null}
        <h2 className="part__title">{title}</h2>
      </div>
      <ChevronRightIcon fontSize="large" />
    </button>
  );
};

const ProgrammeStep = ({
  title,
  unlockDate,
  stepNumber,
  locked,
  isLastStep,
  completed,
  parts = [],
}) => {
  const dispatch = useDispatch();
  const programme = useSelector((state) => state.programmeOverview);
  const history = useHistory();
  const formattedUnlockDate = moment(unlockDate).format("DD MMMM");
  const formatTitle = locked ? `${title} - Unlocks ${formattedUnlockDate}` : title;
  const [programmeSlug, setProgrammeSlug] = useState();

  const isCurrent = (index) => {
    const currentStep = programme?.data?.nextAction?.stepNumber;
    if (currentStep === undefined) return false;
    if (currentStep === stepNumber) {
      const inCompletedIndex = parts.findIndex((part) => !Boolean(part.completed));
      if (inCompletedIndex !== undefined) {
        return inCompletedIndex === index;
      }
      return false;
    } else return false;
  };

  useEffect(() => {
    const currentProgramme = Programmes.find(
      (item) => item.programmeId === programme.data.programme.id
    );
    setProgrammeSlug(currentProgramme.slug);
  }, [programme]);

  const handlePartClick = (index) => {
    dispatch(update_current_tab_index(index));
    const step = stepNumber < 10 ? `0${stepNumber}` : stepNumber;
    history.push(`/my-path/${programmeSlug}/step-${step}`);
  };

  const determineIcon = () => {
    if (locked) {
      return <LockedIcon />;
    } else if (completed) {
      return <GreenTrophyIcon />;
    }
    return <TrophyIcon />;
  };

  return (
    <div className={classnames("programme-step", { locked })}>
      <div
        className={classnames("programme-step__indicator", {
          "programme-step__margin": stepNumber > 0,
          "programme-step__locked": locked,
        })}
      >
        {determineIcon()}
      </div>
      <div className="programme-step__container">
        {stepNumber > 0 && <p className="programme-step__title">{formatTitle}</p>}
        <Grid container spacing={2}>
          {parts.length > 0 &&
            parts.map((part, idx) => {
              return (
                <Grid item xs={12} key={idx}>
                  <Part
                    title={part.title}
                    completed={part.completed}
                    current={isCurrent(idx)}
                    locked={locked}
                    onClick={() => handlePartClick(idx)}
                  />
                </Grid>
              );
            })}
        </Grid>
      </div>
    </div>
  );
};

export default ProgrammeStep;
