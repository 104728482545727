import authAxios from "../../helpers/authAxios";
import { COMPLETE_PART, PART_COMPLETED, COMPLETE_PART_FAILED, PART_COMPLETED_HIDE_MODAL } from "../constants";
import { urls } from "../../configs/serviceConfig";
import { logException } from "../../utils/AppInsightsLogging";

const complete_part = () => {
  return {
    type: COMPLETE_PART,
  };
};

const part_completed = (payload) => {
  return {
    type: PART_COMPLETED,
    payload,
  };
};

export const part_completed_hide_modal = () => {
  return {
    type: PART_COMPLETED_HIDE_MODAL
  };
};

const complete_part_failed = (error) => {
  return {
    type: COMPLETE_PART_FAILED,
    error,
  };
};

export const post_complete_part = (userId, programmeId, journeyId, partId, stepId) => {
  const url = `${urls.programmeService}/user-programme/${programmeId}/user/${userId}/journey/${journeyId}/complete-part`;
  return (dispatch) => {
    dispatch(complete_part());
    authAxios
      .post(url, {
        stepId: stepId,
        partId: partId,
      })
      .then(async (response) => {
        await dispatch(part_completed(response.data));
      })
      .catch((error) => {
        logException(error);
        dispatch(complete_part_failed(error));
      });
  };
};
