import { Grid, Container } from "@material-ui/core";
import "./Footer.scss";
import FooterContainer from "./FooterContainer";
import FooterCopyRight from "./FooterCopyRight";
import FooterLogo from "./FooterLogo";
import FooterSecondaryNav from "./FooterSecondaryNav";
import { footerBg } from "../../../assets/img/bg/index";

// const primaryNavItems = [
//   { name: "my path", link: "my-path" },
//   { name: "my progress", link: "my-progress" },
//   { name: "my gloji", link: "my-gloji" },
//   { name: "explore", link: "explore" },
//   { name: "connect", link: "connect" },
// ];

const secondaryNavItems = [
  { name: "about us", link: "about" },
  { name: "contact us", link: "contact" },
  { name: "accessibility", link: "accessibility" },
  { name: "terms & conditions", link: "terms-and-conditions" },
  { name: "privacy", link: "privacy" },
];

const date = new Date();

const Footer = () => {
  return (
    <FooterContainer footerBg={footerBg}>
      {/* <FooterPrimaryNav primaryNavItems={primaryNavItems} />
       */}
      <FooterSecondaryNav secondaryNavItems={secondaryNavItems} />
      <Container>
        <Grid container justifyContent="center" alignItems="center">
          <FooterLogo />
          <FooterCopyRight>Copyright © {date.getFullYear()} Thrive Tribe Ltd.</FooterCopyRight>
        </Grid>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
