import { List } from "@material-ui/core";
import PropTypes from "prop-types";

const flexContainer = {
  display: "flex",
  justifyContent: "center",
};

const ListContainer = ({ children }) => {
  return <List style={flexContainer}>{children}</List>;
};

ListContainer.propTypes = {
  children: PropTypes.node,
};

export default ListContainer;
