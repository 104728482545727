import React, { useEffect } from "react";
import {
  ModalPageBody,
  ModalPageFooter,
  ModalPageWrapper,
  ModalPageCopy,
  ModalPageHeroImage,
  ModalPageHeading,
  ModalPageHeader,
} from "../../organisms/ModalPage";
import { Helmet } from "react-helmet";
import HeroImage from "../../../assets/img/meetGlojiTeam.png";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { get_signup_copy } from "../../../store/actions/signupFlowData";
import { CircularLoader } from "../../atoms/Loaders";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useHistory } from "react-router";
import MarkedDownContent from "../../organisms/MarkedDownContent";

const LandingPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const signupFlowData = useSelector((state) => state.signupFlowData);

  useEffect(() => {
    dispatch(get_signup_copy("eligibility-checker"));
  }, [dispatch]);

  const handleContinue = () => {
    //Trigger google analytics event at the start of eligibility checker journey
    window.gtag("event", "startEligibilityChecker", {
      event_category: "Eligibility Checker",
      event_label: "Start Eligibility Checker",
    });
    history.push("/postcode");
  };

  return (
    <>
      <Helmet>
        <title>Lets team up | Eligibility checker | Sign up | Gloji</title>
      </Helmet>
      <ModalPageWrapper>
        <ModalPageBody hasStickyFooter={false}>
          {signupFlowData.loading === true ? (
            <CircularLoader />
          ) : signupFlowData.errorStatus === true ? (
            <>
              <ModalPageCopy>
                <Alert severity="error" style={{ marginTop: "2rem" }}>
                  <AlertTitle>{signupFlowData.error.message} </AlertTitle>
                  Failed to load data
                </Alert>
              </ModalPageCopy>
            </>
          ) : signupFlowData.data.landingPage ? (
            <>
              <ModalPageHeader
                backButtonOnClick={() => {
                  window.location.href = "https://www.gloji.co.uk";
                }}
              />
              <ModalPageHeading size="large">
                {signupFlowData.data.landingPage.title}
              </ModalPageHeading>
              <ModalPageHeroImage imageUrl={HeroImage} altText="gloji team"></ModalPageHeroImage>
              <ModalPageCopy>
                <MarkedDownContent content={signupFlowData.data.landingPage.body} />
              </ModalPageCopy>
            </>
          ) : (
            <CircularLoader />
          )}
        </ModalPageBody>
        <ModalPageFooter hasStickyFooter={false}>
          <Button onClick={handleContinue} variant="contained" color="primary">
            Let's go
          </Button>
        </ModalPageFooter>
      </ModalPageWrapper>
    </>
  );
};

export default LandingPage;
