import { transform, toTitleCase, toSentenceCase, toLowerCase } from "@alduino/humanizer/string";

class SlugConverter {
  toSlug(sentence) {
    if (!sentence) return "";
    return transform(sentence.replace(" ", "-"), toLowerCase);
  }

  toTitleCase(slug) {
    if (!slug) return "";
    return transform(slug.replace("-", " "), toTitleCase);
  }

  toSentenceCase(slug) {
    if (!slug) return "";
    return transform(slug.replace("-", " "), toSentenceCase);
  }

  static get instance() {
    return slugConverter;
  }
}

const slugConverter = new SlugConverter();

export default slugConverter;
