import { useState, useEffect } from "react";
import propTypes from "prop-types";

const useCompletedSteps = (steps) => {
  const [completedSteps, setCompletedSteps] = useState([]);

  useEffect(() => {
    if (steps === undefined || steps === null) return;

    const filteredSteps = steps
      .filter((step) => step.completed && step.stepNumber > 0)
      .map((step) => step.stepNumber);

    setCompletedSteps(filteredSteps);
  }, [steps]);

  return completedSteps;
};

useCompletedSteps.PropTypes = {
  steps: propTypes.array.isRequired,
};

export default useCompletedSteps;
