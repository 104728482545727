import { Link as MuiLink } from "@material-ui/core";
import PropTypes from "prop-types";

const ButtonLink = ({ label, color, onClick, ...rest }) => {
  return (
    <MuiLink component="button" className="button-link" color={color} onClick={onClick} {...rest}>
      {label}
    </MuiLink>
  );
};

ButtonLink.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

export default ButtonLink;
