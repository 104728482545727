import React from "react";
import PropTypes from "prop-types";

const ModalPageQuestionDescription = ({ children }) => {
  return <p className="modal-page__question-description">{children}</p>;
};

ModalPageQuestionDescription.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalPageQuestionDescription;
