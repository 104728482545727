const Disability = {
  NONE: "None",
  PHYSICAL_DISABILITY_OR_INJURY: "PhysicalDisabilityOrInjury",
  DEAFNESS: "Deafness",
  BLIDNESS: "Blindness",
  DYSLEXIA: "Dyslexia",
  LEARNING_DIFFICULTIES: "LearningDifficulties",
  DEVELOPMENTAL_DISORDER: "DevelopmentalDisorder",
  OTHER: "Other",
};

export const disabilities = [
  { value: "physical", label: "Physical disability" },
  { value: "learning", label: "Learning disability" },
  { value: "visualImpairment", label: "Visual impairment" },
  { value: "deafness", label: "Hearing impaired" },
  { value: "dyslexia", label: "Dyslexia" },
  { value: "developmentDisorder", label: "Development disorder" },
];

export default Disability;
