import * as yup from "yup";
import UnitOfMeasure from "../../../enums/UnitOfMeasure";

export const PostcodeValidationSchema = yup.object().shape({
  postcode: yup
    .string()
    .matches(
      "^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$",
      "Please enter a valid UK postcode"
    )
    .required("Please enter a postcode"),
});

export const DateOfBirthValidationSchema = yup.object().shape({
  date: yup
    .string()
    .required("Please add a date")
    .min(1, "Please add a date")
    .test({
      name: "is-date-valid",
      message: "Please add the right date",
      test: (value) => value && Number(value) > 0 && Number(value) < 32,
    }),
  month: yup
    .string()
    .required("Please add a month")
    .min(1, "Please add a month")
    .test({
      name: "is-month-valid",
      message: "Please add the right month",
      test: (value) => value && Number(value) > 0 && Number(value) < 13,
    }),
  year: yup
    .string()
    .required("Please add a year")
    .length(4, "Please add the right year")
    .test({
      name: "is-year-valid",
      message: "Please add the right year",
      test: (value) => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        return value && Number(value) <= currentYear;
      },
    }),
});

export const SexValidationSchema = yup.object().shape({
  sex: yup.string().required("Please select an option"),
  genderIsBirthSex: yup.string().required("Please select an option"),
  gender: yup.string().when("genderIsBirthSex", {
    is: "no",
    then: yup.string().required("Please provide an answer"),
  }),
});

export const PregnancyValidationSchema = yup.object().shape({
  pregnancy: yup.string().required("Please select an option"),
  breastfeeding: yup.string().required("Please select an option"),
});

export const EthnicityValidationSchema = yup.object().shape({
  ethnicity: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required("Please select an option")
    .nullable(),
});

export const PhysicalHealthValidationSchema = yup.object().shape({
  physicalHealth: yup.string().required("Please select an option"),
  physicalConditions: yup.array().when("physicalHealth", {
    is: "yes",
    then: yup.array().min(1, "Please select at least one option"),
  }),
});

export const MentalHealthValidationSchema = yup.object().shape({
  mentalHealth: yup.string().required("Please select an option"),
  mentalConditions: yup.array().when("mentalHealth", {
    is: "yes",
    then: yup.array().min(1, "Please select at least one option"),
  }),
});

export const MentalHealthManagementValidationSchema = yup.object().shape({
  mentalHealthManagement: yup.string().required("Please select an option"),
});

export const MentalillnessRegisterValidationSchema = yup.object().shape({
  mentalillnessRegister: yup.string().required("Please select an option"),
});

export const DisabilitiesValidationSchema = yup.object().shape({
  anyDisabilities: yup.string().required("Please select an option"),
  disabilities: yup.array().when("anyDisabilities", {
    is: "yes",
    then: yup.array().min(1, "Please select at least one option"),
  }),
});

export const DisabilityRegisterValidationSchema = yup.object().shape({
  disabilityRegister: yup.string().required("Please select an option"),
});

export const BmiValidationSchema = yup.object().shape({
  weight: yup.mixed().when("weightDisplayUnitOfMeasure", {
    is: UnitOfMeasure.METRIC,
    then: yup
      .number()
      .required("Please enter your weight")
      .positive("Please enter a valid weight")
      .max(250, "Please enter a valid weight")
      .min(35, "Please enter a valid weight"),
    otherwise: yup.number().notRequired(),
  }),
  weightStones: yup.mixed().when("weightDisplayUnitOfMeasure", {
    is: UnitOfMeasure.IMPERIAL,
    then: yup
      .number()
      .required("Please enter your weight")
      .positive("Please enter a valid weight")
      .max(38, "Please enter a valid weight")
      .min(5, "Please enter a valid weight"),
    otherwise: yup.number().notRequired(),
  }),
  weightPounds: yup.mixed().when("weightDisplayUnitOfMeasure", {
    is: UnitOfMeasure.IMPERIAL,
    then: yup
      .number()
      .positive("Please enter a valid weight")
      .max(13, "Please enter a valid weight")
      .min(0, "Please enter a valid weight"),
    otherwise: yup.number().notRequired(),
  }),
  height: yup.mixed().when("heightDisplayUnitOfMeasure", {
    is: UnitOfMeasure.METRIC,
    then: yup
      .number()
      .required("Please enter your height")
      .positive("Please enter a valid height")
      .max(270, "Please enter a valid height")
      .min(85, "Please enter a valid height"),
    otherwise: yup.number().notRequired(),
  }),
  heightFeet: yup.mixed().when("heightDisplayUnitOfMeasure", {
    is: UnitOfMeasure.IMPERIAL,
    then: yup
      .number()
      .required("Please enter your height")
      .positive("Please enter a valid height")
      .max(8, "Please enter a valid height")
      .min(2, "Please enter a valid height"),
    otherwise: yup.number().notRequired(),
  }),
  heightInches: yup.mixed().when("heightDisplayUnitOfMeasure", {
    is: UnitOfMeasure.IMPERIAL,
    then: yup
      .number()
      .positive("Please enter a valid height")
      .max(11, "Please enter a valid height")
      .min(0, "Please enter a valid height"),
    otherwise: yup.number().notRequired(),
  }),
});

export const KeepInTouchValidationSchema = yup.object().shape({
  keepInTouch: yup
    .boolean()
    .oneOf([true], "We will need to check in from time to time throughout your programme"),
});

const consentErrorMessage =
  "To offer you a free place with gloji, we need your consent before we can carry on signing you up.";

export const DataInformationConsentValidationSchema = yup.object().shape({
  privacyPolicyAgreement: yup.boolean().oneOf([true], consentErrorMessage),
  dataProcessingAgreement: yup.boolean().oneOf([true], consentErrorMessage),
  gpContactAgreement: yup.boolean().oneOf([true], consentErrorMessage),
});

const phoneNumberRegex =
  /\s*(([+](\s?\d)([-\s]?\d)|0)?(\s?\d)([-\s]?\d){9}|[(](\s?\d)([-\s]?\d)+\s*[)]([-\s]?\d)+)\s*/;
export const ContactDetailsValidationSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .matches(phoneNumberRegex, "Please enter a valid phone number")
    .required("Please enter a phone number"),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Please enter an email address"),
  emailConfirmation: yup
    .string()
    .oneOf([yup.ref("email"), null], "Please make sure email addresses are matching")
    .required("Please enter an email confirmation"),
});

export const IntroduceYourselfValidationSchema = yup.object().shape({
  firstName: yup.string().trim().required("Please enter a first name"),
  lastName: yup.string().trim().required("Please enter a surname"),
});
