export enum EngagementCategory {
  Session,
}

export enum EngagementType {
  Login,
  Logout,
  Refresh,
  SessionExpiration,
}
