import { ReactComponent as MealType } from "../../../assets/img/icons/mealType.svg";
import { ReactComponent as CookingTime } from "../../../assets/img/icons/cookingTime.svg";
import { ReactComponent as Servings } from "../../../assets/img/icons/servings.svg";
import PropTypes from "prop-types";

const basicPluralizeFunc = (singlarWord, amount, showValue) => {
  if (amount) {
    if (amount === 1) return showValue ? `${amount} ${singlarWord}` : singlarWord;
    return showValue ? `${amount} ${singlarWord}s` : singlarWord + "s";
  }
  return singlarWord;
};

const formatTime = (time) => {
  if (!time) return;
  const timeAsNum = Number(time.replace(/\D/g, ""));

  if (timeAsNum <= 60) {
    return `${timeAsNum} mins`;
  }

  const hours = Math.trunc(timeAsNum / 60);
  const mins = timeAsNum % 60;
  return `${hours} ${basicPluralizeFunc("hr", hours)} ${mins} ${basicPluralizeFunc("min", mins)}`;
};

const RecipeCardIcons = ({ mealType, time, servings }) => {
  return (
    <div className="recipe-card__icons">
      {mealType && (
        <div className="recipe-card__icon-item">
          <MealType />
          <p>{mealType}</p>
        </div>
      )}
      {time && (
        <div className="recipe-card__icon-item">
          <CookingTime />
          <p>{formatTime(time)}</p>
        </div>
      )}
      {servings && (
        <div className="recipe-card__icon-item">
          <Servings />
          <p className="recipe-card__last-item">
            {`${servings} ${servings > 1 ? "servings" : "serving"}`}
          </p>
        </div>
      )}
    </div>
  );
};

export default RecipeCardIcons;
