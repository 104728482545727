import { useState, useEffect } from "react";
import authService from "../auth/AuthorizeService";

/**
 * Custom React hook for using User profile data across multiple components.
 * @returns {Array} [user, isAuthenticated]
 */
export default function useUserId() {
  const [userId, setUserId] = useState(localStorage.getItem("userID"));

  useEffect(() => {
    const getUserId = async () => {
      if (userId == null || userId === undefined) {
        const user = await authService.getUser();
        setUserId(user.sub);
      }
    };

    getUserId();
  }, [userId]);

  return [userId];
}
