import React from "react";
import { useSelector } from "react-redux";
import "./VideoCard.scss";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { CardContent, CardMedia } from "@material-ui/core";
import PropTypes from "prop-types";
import { ReactComponent as ClockIcon } from "../../../assets/img/icons/clock.svg";
import { ReactComponent as PlayIcon } from "../../../assets/img/icons/bigPlayIcon.svg";
import { ReactComponent as LockIcon } from "../../../assets/img/icons/lock.svg";
import { ReactComponent as TickIcon } from "../../../assets/img/icons/tick.svg";
import ProgressBar from "../../atoms/ProgressBar";
import { secondsToMinutes } from "../../../helpers/secondsToMinutes";
import QuestionReview from "../QuestionReview";
import { Link } from "react-router-dom";
import JourneyStates from "../../../enums/journeyStates";

const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
    backgroundColor: "white",
    borderRadius: "10px",
    "& .MuiSvgIcon-root": {
      //fontSize: "12px",
    },
  },
  media: {
    height: 208,
    position: "relative",
    borderRadius: "5px",
    margin: "10px",
  },
  content: {
    padding: "0px 12px 12px 12px",
  },
  footer: {
    padding: "0 0 24px 12px ",
  },

  tickBox: {},
});

const VideoCard = ({
  cardTitle,
  imgUrl,
  imgTitle,
  cardVariant,
  watched,
  isLocked,
  progress,
  videoDuration,
  videoLocation,
  sectionId,
}) => {
  const classes = useStyles();
  const profile = useSelector((state) => state.profile);

  const Media = () => (
    <CardMedia
      className={`${classes.media} video-card__image-container`}
      image={imgUrl}
      title={imgTitle}
    >
      <div className="overlay">
        <div className="play-lock-icon__container">
          <span
            className="icon"
            aria-label={!isLocked ? "Video is not locked" : "Video is locked"}
            role="button"
          >
            {!isLocked ? <PlayIcon /> : <LockIcon />}
          </span>
        </div>
      </div>

      <div className="progress-and-duration__container">
        <div className="progress-bar__wrapper">
          <ProgressBar progress={progress} />
          <div className="space-placeholder"> </div>
        </div>

        <div className="video-duration">
          <div className="video-duration__icon">
            <ClockIcon />
          </div>
          <div className="video-duration__text">
            <span>{secondsToMinutes(videoDuration)} </span>
          </div>
        </div>
      </div>
    </CardMedia>
  );

  return (
    <Card
      className={`${classes.root} video-card ${cardVariant} ${isLocked && "locked"}`}
      elevation={0}
    >
      {!isLocked ? (
        <Link
          className="video-card__container-link"
          aria-label={`Play video: ${cardTitle}`}
          to={{ pathname: videoLocation.pathname, state: videoLocation.state }}
        >
          <Media />
        </Link>
      ) : (
        <Media />
      )}
      <CardContent className={`${classes.content} video-card__content-container`}>
        <div>
          {profile.data.currentJourney.state === JourneyStates.PENDING && (
            <h2 className="video-card__title video-card__title--h2">{cardTitle}</h2>
          )}
          {profile.data.currentJourney.state !== JourneyStates.PENDING && (
            <h3 className="video-card__title">{cardTitle}</h3>
          )}
        </div>
        <div className="video-card__tick-box-wrapper">
          <span className="video-card__tick-space-placeholder" role="presentation">
            .
          </span>
          <span className={`${classes.tickBox} video-card__tick-box`}>
            {watched && <TickIcon />}
          </span>
        </div>
      </CardContent>
      <QuestionReview sectionId={sectionId} videoLocation={videoLocation}></QuestionReview>
    </Card>
  );
};
VideoCard.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  imgUrl: PropTypes.string.isRequired,
  imgTitle: PropTypes.string,
  cardVariant: PropTypes.string,
  watched: PropTypes.bool,
  isLocked: PropTypes.bool,
  progress: PropTypes.number.isRequired,
  videoLocation: PropTypes.object.isRequired,
  sectionId: PropTypes.string,
};

export default VideoCard;
