import { Grid, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  position: {
    zIndex: 2,
  },
});

const HeroBannerContent = ({ children }) => {
  const classes = useStyles();

  return (
    <Container className={classes.position}>
      <Grid
        container
        className="hero-banner__content-container"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Container>
  );
};

HeroBannerContent.propTypes = {
  children: PropTypes.node,
};

export default HeroBannerContent;
