import React from "react";
import { Helmet } from "react-helmet";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { ReactComponent as ArticlesAndGuidesIcon } from "../../../../../assets/img/icons/articlesAndGuides.svg";
import { ReactComponent as StepByStepVideosIcon } from "../../../../../assets/img/icons/stepByStepVideos.svg";
import { ReactComponent as UnlimitedRecipesIcon } from "../../../../../assets/img/icons/unlimitedRecipes.svg";
import { ReactComponent as OnlineGymIcon } from "../../../../../assets/img/icons/onlineGym.svg";
import { ReactComponent as RegularCheckinsIcon } from "../../../../../assets/img/icons/phoneRegularCheckins.svg";
import { useHistory } from "react-router";
import {
  ModalPageBody,
  ModalPageCopy,
  ModalPageFooter,
  ModalPageHeading,
  ModalPageWrapper,
} from "../../../../organisms/ModalPage";

interface Props {
  navigatToNextStep?: () => void;
}

const LandingPage: React.FC<Props> = (props: Props) => {
  const nextHandler = () => {
    props.navigatToNextStep
      ? props.navigatToNextStep()
      : history.push("/ready-set-go");
  };

  const history = useHistory();
  return (
    <>
      <Helmet>
        <title>Welcome to Gloji | Profile builder | Sign up | Gloji</title>
      </Helmet>
      <ModalPageWrapper>
        <ModalPageBody hasStickyFooter={false}>
          <ModalPageHeading>Welcome to gloji!</ModalPageHeading>
          <ModalPageCopy>
            <p>
              We’re so happy you’ve taken your first step to being smoke free!
              Here’s some of the things you can look forward to enjoying while
              you’re with us.
            </p>
            <div className="list-with-icon">
              <StepByStepVideosIcon />
              <p>Step-by-step videos</p>
            </div>
            <div className="list-with-icon">
              <RegularCheckinsIcon />
              <p>Regular check-ins with your gloji mentor</p>
            </div>
            <div className="list-with-icon">
              <ArticlesAndGuidesIcon />
              <p>Articles and guides to help you stay smoke free</p>
            </div>
            <div className="list-with-icon">
              <UnlimitedRecipesIcon />
              <p>Easy, delicious healthy recipes</p>
            </div>
            <div className="list-with-icon">
              <OnlineGymIcon />
              <p>Bonus access to our online gym</p>
            </div>
            <p className="glory-story">
              “So easy being able to arrange my mentor calls around my shifts.
              It was a lifesaver!” - Ayesha
            </p>
          </ModalPageCopy>
        </ModalPageBody>
        <ModalPageFooter hasStickyFooter={false}>
          <Button onClick={nextHandler} variant="contained" color="primary">
            Let's go
          </Button>
        </ModalPageFooter>
      </ModalPageWrapper>
    </>
  );
};

export default LandingPage;
