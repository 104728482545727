export const religionOptions = [
  { value: "Bahai", label: "Baha'i" },
  { value: "Buddhist", label: "Buddhist" },
  { value: "Christian", label: "Christian" },
  { value: "Hindu", label: "Hindu" },
  { value: "Jain", label: "Jain" },
  { value: "Jewish", label: "Jewish" },
  { value: "Muslim", label: "Muslim" },
  {
    value: "Pagan",
    label: "Pagan",
  },
  { value: "Sikh", label: "Sikh" },
  { value: "Zoroastrian", label: "Zoroastrian" },
  { value: "Other", label: "Other" },
  { value: "None", label: "None" },
  { value: "PreferNotToSay", label: "Prefer not to say" },
];
