import React from "react";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { ReactComponent as ModalCloseIcon } from "../../../assets/img/icons/modalClose.svg";
import PropTypes from "prop-types";

export const ModalCloseButton = ({ onClick }) => {
  return (
    <div className="modal-close-button__container">
      <Button
        className="modal-close-button"
        color="tertiary"
        onClick={onClick}
        startIcon={<ModalCloseIcon />}
        variant="text"
      >
        Close
      </Button>
    </div>
  );
};

ModalCloseButton.protoTypes = {
  onClick: PropTypes.func,
};
