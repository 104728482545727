const Language = {
  UNDEFINED: "Undefined",
  ENGLISH: "English",
  FRENCH: "French",
  GERMAN: "German",
  SPANISH: "Spanish",
  PORTUGUESE: "Portuguese",
  POLISH: "Polish",
  ARABIC: "Arabic",
  PUNJABI: "Punjabi",
  URDU: "Urdu",
  BENGALI: "Bengali",
  GUJARATI: "Gujarati",
  MANDARIN: "Mandarin",
  JAPANESE: "Japanese",
  RUSSIAN: "Russian",
};

export default Language;
