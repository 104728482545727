import { BackButtonLink } from "../../atoms/Button";
import recipeHeaderBackground from "../../../assets/img/bg/recipeBg.webp";
import { ReactComponent as AllergenIcon } from "../../../assets/img/icons/allergen.svg";
import { ReactComponent as MainsIcon } from "../../../assets/img/icons/main.svg";
import { ReactComponent as PersonIcon } from "../../../assets/img/icons/person.svg";
import { ReactComponent as TimeIcon } from "../../../assets/img/icons/time.svg";
import "./RecipeBanner.scss";

const basicPluralizeFunc = (singlarWord, amount, showValue) => {
  if (amount) {
    if (amount === 1)
      return showValue ? `${amount} ${singlarWord}` : singlarWord;
    return showValue ? `${amount} ${singlarWord}s` : singlarWord + "s";
  }
  return singlarWord;
};

const formatTime = (time) => {
  if (time) {
    if (Number(time) <= 60) {
      return `${time} mins`;
    } else {
      const hours = Math.trunc(Number(time) / 60);
      const mins = Number(time) % 60;

      return `${hours} ${basicPluralizeFunc(
        "hr",
        hours
      )} ${mins} ${basicPluralizeFunc("min", mins)}`;
    }
  }
};

const BannerDescriptionAndTags = ({ data }) => {
  return (
    <div className="recipe-banner__extra-content">
      <div className="recipe-banner__extra-content__description">
        <p>{data.introduction}</p>
      </div>
      <div className="recipe-banner__content__list recipe-banner__content__list__details">
        {data.dietType?.map((dietType, index) => (
          <div className="recipe-banner__extra-content__diet-type" key={index}>
            <span>{dietType}</span>
          </div>
        ))}
        {data.allergens?.map((allergen, index) => (
          <div className="recipe-banner__extra-content__allergen" key={index}>
            <AllergenIcon />
            <span>{allergen}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export const RecipeBanner = ({ data }) => {
  return (
    <div
      className="recipe-banner"
      style={{ backgroundImage: `url(${recipeHeaderBackground})` }}
    >
      <div className="recipe-banner__back-btn">
        <BackButtonLink
          linkPath={`/explore/recipes`}
          btnText="Back"
          ariaLabel="Back"
        />
      </div>

      <div className="recipe-banner__title">
        <h1>{data.title}</h1>
      </div>

      <div className="recipe-banner__container">
        {data?.asset?.file?.url && (
          <div
            className="recipe-banner__image"
            style={{ backgroundImage: `url(${data?.asset?.file?.url})` }}
          ></div>
        )}
        <div className="recipe-banner__content">
          <div className="recipe-banner__content__list recipe-banner__content__list__icons">
            {data.mealType && (
              <div className="recipe-banner__content__icon">
                <MainsIcon />
                <span>{data.mealType}</span>
              </div>
            )}
            {data.readyIn && (
              <div className="recipe-banner__content__icon">
                <TimeIcon />
                <span>{formatTime(data.readyIn)}</span>
              </div>
            )}
            {data.servings && (
              <div className="recipe-banner__content__icon">
                <PersonIcon />
                <span>
                  {data.servings}{" "}
                  {Number(data.servings) > 1 ? "servings" : "serving"}
                </span>
              </div>
            )}
          </div>
          {data.healthBenefit && (
            <div className="recipe-banner__content__health-benefits">
              <h2>Why we love this recipe</h2>
              <div className="recipe-banner__content__list recipe-banner__content__list__health">
                {data.healthBenefit?.map((healthBenefit, index) => (
                  <div
                    className="recipe-banner__content__health-benefit"
                    key={index}
                  >
                    <span>{healthBenefit}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
          <BannerDescriptionAndTags data={data} />
        </div>
      </div>

      <BannerDescriptionAndTags data={data} />
    </div>
  );
};
