import { LOAD_PROFILE, PROFILE_LOADED, LOAD_PROFILE_FAILED } from "../constants";

const initialState = {
  loading: true,
  data: {},
  error: null,
  errorStatus: false,
};

const load_profile_reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PROFILE:
      return { ...state, loading: true };
    case PROFILE_LOADED:
      return { ...state, data: action.payload, loading: false };
    case LOAD_PROFILE_FAILED:
      return { ...state, error: action.error, errorStatus: true, loading: false };
    default:
      return state;
  }
};

export default load_profile_reducer;
