export const ApplicationName = "Gloji";

export const QueryParameterNames = {
  ReturnUrl: "returnUrl",
  Message: "message",
};

export const LogoutActions = {
  LogoutCallback: "logout-callback",
  Logout: "logout",
  LoggedOut: "logged-out",
};

export const LoginActions = {
  Login: "login",
  LoginCallback: "login-callback",
  LoginFailed: "login-failed",
  Profile: "profile",
};

export const SignupActions = {
  Signup: "signup",
  SignupCallback: "signup-callback",
  SignupFailed: "signup-failed",
};

const prefix = "/authentication";

export const ApplicationPaths = {
  DefaultLoginRedirectPath: "/",
  ApiAuthorizationClientConfigurationUrl: `/_configuration/${ApplicationName}`,
  ApiAuthorizationPrefix: prefix,
  Login: `${prefix}/${LoginActions.Login}`,
  LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
  LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
  Signup: `${prefix}/${SignupActions.Signup}`,
  SignupFailed: `${prefix}/${SignupActions.SignupFailed}`,
  SignupCallback: `${prefix}/${SignupActions.SignupCallback}`,
  Profile: `${prefix}/${LoginActions.Profile}`,
  LogOut: `${prefix}/${LogoutActions.Logout}`,
  LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
  LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
  IdentityRegisterPath: "/Identity/Account/Register",
  IdentityManagePath: "/Identity/Account/Manage",
};

export const Adb2cPolicies = {
  SignIn: "B2C_1_signin1",
  SignUpV2: "B2C_1_signupnoverify1",
  ResetPassword: "B2C_1_resetpassword1",
};