import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { logException } from "../../utils/AppInsightsLogging";

function useFeatureFlag(featureKey, featureFunc, reference = null) {
  const [state, setState] = useState({ isFeatureEnabled: false, isReady: false });

  useEffect(() => {
    const getFeatureFlag = async () => {
      let featureFlag = false;
      try {
        featureFlag = await featureFunc(featureKey, reference);
      } catch (error) {
        logException(error);
        console.log(error);
      }
      setState({ isFeatureEnabled: featureFlag, isReady: true });
    };

    getFeatureFlag();
  }, [featureFunc, featureKey, reference]);

  return [state.isFeatureEnabled, state.isReady];
}

useFeatureFlag.propTypes = {
  featureKey: PropTypes.string.isRequired,
  featureFunc: PropTypes.func.isRequired,
  reference: PropTypes.string,
};

export default useFeatureFlag;
