import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router";
import AuthorizeRoute from "../auth/AuthorizeRoute";
import useFeatureFlag from "../hooks/useFeatureFlag";
import { CircularLoader } from "../atoms/Loaders";
import { featureService } from "../services";

function FeatureFlagAlternativeRoute({ path, featureKey, yesComponent, noComponent, requireAuth, ...props }) {
  const [featureEnabled, ready] = useFeatureFlag(featureKey, featureService.isFeatureEnabled);
  if (!ready) return <CircularLoader />;
  if (featureEnabled) {
    if (requireAuth) {
      return <AuthorizeRoute exact path={path} component={yesComponent} {...props} />;
    }
    return <Route exact path={path} component={yesComponent} {...props} />;
  }
  if (requireAuth) {
    return <AuthorizeRoute exact path={path} component={noComponent} {...props} />;
  }
  return <Route exact path={path} component={noComponent} {...props} />;
}

FeatureFlagAlternativeRoute.propTypes = {
  path: PropTypes.string.isRequired,
  featureKey: PropTypes.string.isRequired,
  yesComponent: PropTypes.func.isRequired,
  noComponent: PropTypes.func.isRequired,
  requireAuth: PropTypes.bool.isRequired,
};

export default FeatureFlagAlternativeRoute;
