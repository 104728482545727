import {
  LOAD_PROGRAMME_OVERVIEW,
  PROGRAMME_OVERVIEW_LOADED,
  LOAD_PROGRAMME_OVERVIEW_FAILED,
} from "../constants";

const initialState = {
  loading: false,
  data: {},
  errorStatus: false,
  error: null,
};

const get_programme_overview_reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PROGRAMME_OVERVIEW:
      return { ...state, loading: true };
    case PROGRAMME_OVERVIEW_LOADED:
      return { ...state, loading: false, data: action.payload };
    case LOAD_PROGRAMME_OVERVIEW_FAILED:
      return { ...state, loading: false, error: action.error, errorStatus: true };
    default:
      return state;
  }
};

export default get_programme_overview_reducer;
