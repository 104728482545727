const JourneyStates = {
  REFERRAL: "Referral",
  PENDING: "Pending",
  ACTIVE: "Active",
  FOLLOWUP: "FollowUp",
  COMPLETE: "Complete",
  CANCELLED: "Cancelled",
  RESTARTED: "Restarted",
  RELAPSED: "Relapsed",
};

export default JourneyStates;
