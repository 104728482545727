import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container } from "@material-ui/core";
import PageBackground from "../../organisms/PageBackground";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { PageTitle } from "@thrivetribe/gloji.ui.molecules.page-title";
import { MINT } from "@thrivetribe/gloji.enums.brand-colors";
import { Helmet } from "react-helmet";
import { myProgressBg } from "../../../assets/img/bg";
import { useDispatch, useSelector } from "react-redux";
import { get_all_weights } from "../../../store/actions/getAllWeights";
import useUser from "../../hooks/useUser";
import AlreadyLoggedWeightToday from "../../atoms/AlreadyLoggedWeightToday";
import {
  CustomModal,
  ModalContent,
  ModalTitle,
  ModalCloseButton,
} from "../../organisms/CustomModal";
import Toast from "../../atoms/Toast";
import "./MyProgress.scss";
import LogWeightForm from "../../molecules/LogWeightForm";
import {
  log_weight_error_reset,
  log_weight_success_reset,
} from "../../../store/actions/postWeight";
import GraphWithHeaderFooter from "@thrivetribe/gloji.ui.organisms.graph-with-header-footer";
import { profileService } from "../../services";
import Pathway from "../../../enums/profile/Pathway";

import { weightTypes } from "../../../enums/weightTypes";

const MyProgress = () => {
  const [user] = useUser();
  const [open, setOpen] = useState(false);
  const [readyToGoModalOpen, setReadyToGoModalOpen] = useState(false);
  const [awesomeModalOpen, setAwesomeModalOpen] = useState(false);
  const [weightLogSuccessful, setWeightLogSuccessful] = useState(false);
  const [introActive, setIntroActive] = useState(false);
  const [programmeWeights, setProgrammeWeights] = useState([]);

  const allWeights = useSelector((state) => state.allWeights);
  const profile = useSelector((state) => state.profile);
  const weightLogging = useSelector((state) => state.weightLogging);
  const dispatch = useDispatch();
  const history = useHistory();

  const [firstTimeWeightLogged, setFirstTimeWeightLogged] = useState(false);
  const [displayWeightWarning, setDisplayWeightWarning] = useState(false);

  useEffect(() => {
    if (profile?.data?.currentJourney?.flags?.initialWeightLogged === true) {
      setFirstTimeWeightLogged(false);
      setIntroActive(false);
    } else {
      setFirstTimeWeightLogged(true);
      setIntroActive(true);
    }
  }, [profile.data]);

  useEffect(() => {
    dispatch(get_all_weights(localStorage.getItem("userID")));
  }, [dispatch]);

  // Modal functions
  const handleShowModal = () => {
    setOpen(true);
    document.getElementById("root").classList.add("modal-active");
  };

  const handleLogWeight = async () => {
    if (!firstTimeWeightLogged) {
      return handleShowModal();
    }

    const userId = localStorage.getItem("userID");
    const journeyId = localStorage.getItem("journeyID");
    const payload = {
      step0Completed: true,
      initialWeightLogged: true,
      appointmentBooked: false,
    };
    await profileService.setJourneyFlags(userId, journeyId, payload);

    if (profile?.data?.currentJourney?.pathway === Pathway.SUPPORTED) {
      setAwesomeModalOpen(true);
    } else {
      setReadyToGoModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setOpen(false);
    setReadyToGoModalOpen(false);
    setAwesomeModalOpen(false);
    setDisplayWeightWarning(false);

    document.getElementById("root").classList.remove("modal-active");
    dispatch(get_all_weights(localStorage.getItem("userID")));
    dispatch(log_weight_success_reset());
    dispatch(log_weight_error_reset());
  };

  const showSuccessMessage = () => {
    setWeightLogSuccessful(true);
    
  };

  const handleSuccessMessageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setWeightLogSuccessful(false);    
    setDisplayWeightWarning(false); 
  };

  useEffect(() => {
    if (weightLogging.data.length !== 0) setIntroActive(false);
  }, [weightLogging.data]);

  useEffect(() => {
    if (allWeights?.data?.weights === undefined || allWeights?.data?.weights.length === 0) return;

    const programmeWeights = allWeights.data.weights.filter(
      (weight) => weight.weightType === weightTypes.PROGRAMME || weight.weightType === null
    );
    setProgrammeWeights(programmeWeights);
  }, [allWeights.data]);

  const handleReturnMyPath = () => {
    return (window.location.href = "/my-path");
  };

  const handleReturnHome = () => {
    handleModalClose();
    return (window.location.href = "/");
  };

  const handleBookAppointment = () => {
    handleModalClose();
    return history.push("/book-appointment");
  };

  return (
    <PageBackground bgImage="">
      <Helmet>
        <title>My progress | Gloji</title>
      </Helmet>
      <section
        className="chart-section__container"
        style={{ backgroundImage: `url(${myProgressBg})` }}
      >
        <Container>
          <PageTitle color={MINT}>My progress</PageTitle>
          {introActive && (
            <section className="progress-intro">
              <div className="progress-intro__container">
                <div className="progress-intro__content">
                  <h2>Hi {profile?.data?.personalDetails?.firstName}! Let's start fresh</h2>
                  <p>
                    We're so excited for your new beginning and the chance to change your life for
                    the better.
                  </p>
                  <p>
                    Get the ball rolling and log your weight now, we'll check in and see how you're
                    doing in 12 weeks!
                  </p>
                </div>
                <div className="progress-intro__footer">
                  <Button
                    className="progress-intro__footer__button"
                    color="primary"
                    onClick={handleShowModal}
                  >
                    Get Started
                  </Button>
                </div>
              </div>
            </section>
          )}
          {!introActive && (
            <GraphWithHeaderFooter
              dataLoading={allWeights.loading}
              chartData={programmeWeights}
              isAdvisorView={false}
              firstName={user.given_name}
              handleLogWeight={() => handleLogWeight()}
              buttonText={firstTimeWeightLogged ? "All done! Let's move on" : undefined}
              buttonStyle={firstTimeWeightLogged ? "primary" : undefined}
            />
          )}
        </Container>
        {allWeights.loading === false && (
          <CustomModal
            open={open}
            onClose={handleModalClose}
            id="logWeightModal"
            modalTitleId="logWeightModalTitle"
            modalDescriptionId=""
            aria-labelledby={"logWeightModalTitle"}
          >
            <ModalContent
              id="logWeightModalContent"
              modalTitleId="logWeightModalTitle"
              modalDescriptionId=""
            >
              <ModalTitle id="logWeightModalTitle">Log my weight</ModalTitle>
              {allWeights.data.weights !== undefined && !firstTimeWeightLogged && (
                <AlreadyLoggedWeightToday allWeights={allWeights.data.weights} />
              )}

        {displayWeightWarning && (
            <div className="logging-status__error">        
                <p id="low-weight-warning">
                  Can you double check? That doesn't look quite right.
                </p>
                </div>
              )}
                               
              <LogWeightForm
                allWeights={allWeights.data.weights}
                closeModal={handleModalClose}
                onSuccess={showSuccessMessage}   
                setDisplayWeightWarning = {setDisplayWeightWarning}
              />
            </ModalContent>
            <ModalCloseButton onClick={handleModalClose} />
          </CustomModal>
        )}

        <CustomModal
          open={readyToGoModalOpen}
          onClose={handleModalClose}
          id="readyToGoModal"
          modalTitleId="readyToGoModalTitle"
          modalDescriptionId="readyToGoModalDescription"
          aria-labelledby={"readyToGoModalTitle"}
        >
          <ModalContent id="readyToGoModalContent">
            <div className="celebration-img-container"></div>
            <ModalTitle id="readyToGoModalTitle">Ready to go!</ModalTitle>
            <p className="modal-text" id="readyToGoModalDescription">
              Take the first step on your journey with gloji.
            </p>
            <div id="readyToGoModalButtons">
              <Button
                className="complete-button"
                variant="contained"
                color="primary"
                onClick={handleReturnMyPath}
              >
                Show me my path
              </Button>
              <Button
                className="complete-button"
                variant="contained"
                color="secondary"
                onClick={handleReturnHome}
              >
                Return to home
              </Button>
            </div>
          </ModalContent>
        </CustomModal>

        <CustomModal
          open={awesomeModalOpen}
          onClose={handleModalClose}
          id="awesomeModal"
          modalTitleId="awesomeModalTitle"
          modalDescriptionId="awesomeModalDescription"
          aria-labelledby={"awesomeModalTitle"}
        >
          <ModalContent id="awesomeModalContent">
            <div className="celebration-img-container"></div>
            <ModalTitle id="awesomeModalTitle">Awesome</ModalTitle>
            <p className="modal-text" id="awesomeModalDescription">
              Time to book your first appointment with your gloji Health mentor. They'll be by your
              side supporting you every step of the way.
            </p>
            <div id="awesomeModalButtons">
              <Button
                className="complete-button"
                variant="contained"
                color="primary"
                onClick={handleBookAppointment}
              >
                Let's go!
              </Button>
            </div>
          </ModalContent>
        </CustomModal>

        <Toast show={weightLogSuccessful} type="success" handleClose={handleSuccessMessageClose}>
          Your weight was successfully logged!
        </Toast>
      </section>
    </PageBackground>
  );
};

export default MyProgress;
