import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, CardMedia } from "@material-ui/core";
import marked from "marked";
import { Button } from "@thrivetribe/gloji.ui.atoms.button";
import { ReactComponent as PlayIcon } from "../../../assets/img/icons/bigPlayIcon.svg";
import { ReactComponent as LockIcon } from "../../../assets/img/icons/lock.svg";
import { Link, useLocation } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    borderRadius: "10px",
    "& .MuiSvgIcon-root": {
      fontSize: "12px",
    },
  },
  media: {
    height: 208,
    position: "relative",
    borderRadius: "5px",
  },
  content: {
    display: "flex",
    padding: "0px 12px 12px 12px",
  },
  footer: {
    padding: "0 0 24px 12px ",
  },

  buttons: { paddingTop: "0px" },
});

const MarkedDownContent = ({ content }) => {
  return <p dangerouslySetInnerHTML={{ __html: marked(content) }}></p>;
};

const CurrentStepCardWrapper = ({
  step,
  programme,
  cardTitle,
  cardSubtitle,
  imgUrl,
  imgTitle,
  description,
  isLocked,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const isMyPath = location.pathname.includes("my-path") ? true : false;

  const letsGetStartedLink = `/my-path/${programme?.slug}/step-${step?.stepShortTitle}`;

  return (
    <Card className={`${classes.root} current-step-card ${isLocked && "locked"}`} elevation={0}>
      <Link
        to={letsGetStartedLink}
        className="current-step-card__container-link"
        aria-label="Current Step"
        id={"currentStepCardTextWrapper"}
      >
        <div className={`current-step-card__header`}>
          <h2 className={`current-step-card__header--subtitle`}>{cardSubtitle}</h2>
          <h3 className={"current-step-card__header--title"}>{cardTitle}</h3>
        </div>
      </Link>
      <Link
        to={letsGetStartedLink}
        className="current-step-card__container-link"
        aria-label="Current Step"
        id={"currentStepCardContentWrapper"}
      >
        <CardContent className={`video current-step-card__container`}>
          <CardMedia
            className={`${classes.media} video__image-container`}
            image={imgUrl}
            title={imgTitle}
          >
            <div className="overlay">
              <div className="play-lock-icon__container">
                <span className="icon" key="current-step-card__play-icon">
                  {!isLocked ? <PlayIcon /> : <LockIcon />}
                </span>
              </div>
            </div>
          </CardMedia>
          <CardContent className={`${classes.content} current-step-card__content-container`}>
            <MarkedDownContent content={description} />
            {isMyPath && (
              <Button className="current-step-card__button" tabIndex="-1" color="primary">
                Let's get Started
              </Button>
            )}
          </CardContent>
        </CardContent>
      </Link>

      {!isMyPath && (
        <CardContent className={`current-step-card__buttons ${classes.buttons}`}>
          <Link
            to={`/my-path`}
            className="current-step-card__container-link"
            aria-label="Current Step"
            id={"my-path-button"}
          >
            <Button className="current-step-card__button" tabIndex="-1" color="secondary">
              Take me to My Path
            </Button>
          </Link>
          <Link
            to={letsGetStartedLink}
            className="current-step-card__container-link"
            aria-label="Current Step"
            id={"lets-get-started-button"}
          >
            <Button className="current-step-card__button" tabIndex="-1" color="primary">
              Let's get Started
            </Button>
          </Link>
        </CardContent>
      )}
    </Card>
  );
};

CurrentStepCardWrapper.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  imgUrl: PropTypes.string.isRequired,
  imgTitle: PropTypes.string,
  cardVariant: PropTypes.string,
  watched: PropTypes.bool,
  isLocked: PropTypes.bool,
};

export default CurrentStepCardWrapper;
