//Primary color
export const NAVY = "#121e59";
export const SILVER_EXTRA_LIGHT = "#f5f4f2";

//Bright and light - used for bg color for article elements
export const MINT = "#84f08d";
export const LEMON = "#ffef73";
export const BERRY = "#ff808a";
export const SKY = "#91f2ea";
export const PUMPKIN = "#ffba7a";
export const MAUVE = "#e2b8e5";
export const GREENISH_CYAN = "#61F2C2";
export const GREEN = "#23793D";

//Intense and dark
export const GRASS = "#288547";
export const OLIVE = "#917e1f";
export const GRAPE = "#991f33";
export const LAVENDER = "#506899";
export const GOJI = "#c4420a";
export const VIOLET = "#562273";
export const DARK_GREENISH_CYAN = "#3a9174";
